<div class="clients-container">
  <ng-container *ngIf="!update">
    <app-dialog-header [title]="'ADD_SAMPLE'" (iconClick)="ref.close()"></app-dialog-header>
  </ng-container>
  <ng-container *ngIf="update">
    <app-dialog-header [title]="'UPDATE_SAMPLE'" (iconClick)="ref.close()"></app-dialog-header>
  </ng-container>
  <ng-container *ngIf="!loading">
    <ng-container>
      <form [formGroup]="sampleForm">
        <div class="p-grid p-fluid content">
          <div class="p-col-6">
            <div class="p-grid">
              <div class="p-col-12">
                <app-prolabo-dropdown (OnValueChange)="updatePlanValue($event)"
                                      [options]="plans"
                                      placeholder="PLAN">
                </app-prolabo-dropdown>
              </div>
              <div class="p-col-12">
                <app-prolabo-button
                  [name]="'CLEAR'"
                  (click)="cleanUpCircles(true)"
                  type="secondary">
                </app-prolabo-button>
              </div>
              <div class="p-col-12" id="plan" *ngIf="imageSrc">
                <img id="coordinates-img" [src]="imageSrc" (click)="clickImage($event)">
              </div>
            </div>
          </div>
          <div class="p-col-6">
            <div class="p-grid">
              <div class="p-col-12">
                <div class="p-col-12 no-space">
                  <div class="card-input">
                    <p class="order-number-text">{{ 'NUMBER' | translate | uppercase }}</p>
                    <p class="order-number">{{ nextSampleId }}</p>
                  </div>
                </div>
              </div>
              <div class="p-col-12">
                <app-prolabo-dropdown (OnValueChange)="updateTypeValue($event)"
                                      [defaultValue]="sampleId"
                                      [options]="types"
                                      [disabled]="update"
                                      placeholder="TYPE">
                </app-prolabo-dropdown>
              </div>
              <div class="p-col-12">
                <app-prolabo-dropdown (OnValueChange)="updateCollectMethodValue($event)"
                                      [options]="collectMethods"
                                      [disabled]="disabled"
                                      placeholder="DIAGNOSTIC_SAMPLE">
                </app-prolabo-dropdown>
              </div>
              <div class="p-col-12" *ngIf="showRadioList">
                <div class="radio-list-header" (click)="expandRadioList = !expandRadioList">
                  <img src="../../../../../../assets/icons/{{ expandRadioList ? 'up-arrow' : 'down-arrow' }}.svg">
                </div>
                <div class="radio-list" *ngIf="expandRadioList">
                  <ng-container *ngFor="let sample of analogy">
                    <div class="radio-list-item">
                      <p-radioButton [value]="sample.id"
                                     [(ngModel)]="selectedSample"
                                     [ngModelOptions]="{standalone: true}"
                                     (ngModelChange)="onSelectSample($event)"
                                     class="radio">
                      </p-radioButton>
                      <p>{{ sample.sample_number + ' - ' + sample.description }}</p>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="p-col-12" *ngIf="sampleId === 2 || sampleId === 8">
                <app-prolabo-dropdown (OnValueChange)="updateMaterialTypeValue($event)"
                                      [defaultValue]="getMaterialTypeDefaultValue()"
                                      [options]="materialTypes"
                                      placeholder="MATERIAL_TYPE">
                </app-prolabo-dropdown>
              </div>
              <div class="p-col-12">
                <app-prolabo-input [for]="'sample_number'"
                                   [id]="'sample_number'"
                                   [keyword]="'SAMPLENUMBER'"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   [defaultValue]="sampleNumberForm"
                                   (OnValueChange)="updateSampleNumber($event)"
                                   formControlName="sample_number">
                </app-prolabo-input>
              </div>
              <div class="p-col-12">
                <app-prolabo-input [for]="'approximate_quantity'"
                                   [id]="'approximate_quantity'"
                                   [keyword]="'APPROX_QUANTITY'"
                                   [defaultValue]="approxQuantity"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   formControlName="approximate_quantity">
                </app-prolabo-input>
              </div>
              <div class="p-col-12">
                <app-prolabo-icon-input [for]="'plan'"
                                        [regularInput]="true"
                                        [id]="'plan'"
                                        [keyword]="'PHOTO_SAMPLING_LOCATION'"
                                        [type]="'file'"
                                        [icon]="'visible'"
                                        [inputType]="'basic'"
                                        [required]="true"
                                        (change)="imageValueChange($event)">
                </app-prolabo-icon-input>
              </div>
              <div class="p-col-12">
                <div class="p-grid">
                  <div class="p-col-9">
                    <app-prolabo-input [for]="'description'"
                                       [id]="'description'"
                                       [keyword]="'ITEM_DESCRIPTION'"
                                       [type]="'text'"
                                       [inputType]="'basic'"
                                       [defaultValue]="description"
                                       formControlName="description">
                    </app-prolabo-input>
                  </div>
                  <div class="p-col-3">
                    <app-prolabo-dropdown (OnValueChange)="appendColor($event)"
                                          [options]="colors"
                                          [type]="'colors'">
                    </app-prolabo-dropdown>
                  </div>
                </div>
              </div>
              <div class="p-col-12">
                <app-prolabo-input [for]="'additional_description'"
                                   [id]="'additional_description'"
                                   [keyword]="'ADDITIONAL_DESCRIPTION'"
                                   [required]="false"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   [defaultValue]="additionalDescription"
                                   formControlName="additional_description">
                </app-prolabo-input>
              </div>
              <div class="p-col-12">
                <app-prolabo-input [for]="'location'"
                                   [id]="'location'"
                                   [keyword]="'PLACE_LOCATION'"
                                   [defaultValue]="location"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   formControlName="location">
                </app-prolabo-input>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <div class="p-col-12 extra"></div>
    <div class="p-grid client-dialog-footer">
      <div class="p-col-6"></div>
      <div class="p-col-6">
        <app-prolabo-icon-button
          [icon]="'arrow-right-white'"
          [name]="'VALIDATE'"
          (click)="validateSampleForm()"
          type="primary"></app-prolabo-icon-button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loading" class="centered">
    <p class="text">{{"PLEASE.WAIT" | translate}}</p>
  </ng-container>
</div>
