<div class="mobile-header">
  <app-mobile-side-bar [display]="display" [title]="user?.first_name + ' ' + user?.last_name"></app-mobile-side-bar>
  <div class="p-grid">
    <div class="p-col-3">
      <div class="home-icon {{ home ? 'hide-home' : '' }}" (click)="navigate()">
        <img src="../../../../assets/icons/hut.svg"/>
      </div>
    </div>
    <div class="p-col-6 logo-container-wrapper">
      <div class="logo-wrapper {{ home ? 'align-left' : '' }}">
        <div class="logo">
          <p>ANALYSIS</p>
        </div>
      </div>
    </div>
    <div class="p-col-3 wrapper">
      <div class="more-icon" (click)="display = !display">
        <img src="../../../../assets/icons/headline.svg"/>
      </div>
    </div>
  </div>
</div>
