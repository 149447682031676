import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../../shared/services';
import { DropdownModel } from '../../../../shared/components/dropdown/dropdown.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { Type } from '../../../../admin/models/type';
import { AdminStatics } from '../../../../admin/statics/admin-statics';
import { TranslateService } from '@ngx-translate/core';
import { Deadline } from '../../../../models/deadline';
import { AnalystService } from '../../../services/analyst.service';
import { AdminSimpleSamplesService } from '../../../../admin/services/admin-simple-samples.service';
import { AdminOrdersService } from '../../../../admin/services/admin-orders.service';
import { AdminDashboardService } from '../../../../admin/services/admin-dashboard.service';
import { Laboratory } from '../../../../models/laboratorie';
import { CreateOrderComponent } from '../../../../admin/pages/orders/modals/create-order-modal/create-order.component';
import { AdminSimpleOrdersService } from '../../../../admin/services/admin-simple-orders.service';
import { AdminDiagnosticOrdersService } from '../../../../admin/services/admin-diagnostic-orders.service';
import { DialogService } from 'primeng/dynamicdialog';
import { FunctionsService } from '../../../../admin/services/functions.service';

@Component({
  selector: 'app-prolabo-home-analyst',
  templateUrl: './analyst-orders.component.html',
  styleUrls: ['./analyst-orders.component.scss']
})
export class AnalystOrdersComponent implements OnInit {

  orders: [];
  samples: any;
  totalSamples;
  totalOrders;
  sortParams;
  searchParam;
  limit = 10;
  offset = 0;
  stateIds = [];
  stepParam: number;
  deadlines: DropdownModel[] = [];
  sampleTypes: Type[];
  sampleTypeId: number;
  laboratories: Laboratory[] = [];
  laboratoryIds = [];
  private filterByState: boolean;
  private filterByCity: boolean;


  orderHeaders = [
    {title: 'NUMBER', sortLabel: AdminStatics.orderNumber},
    {title: 'OWNER', sortLabel: AdminStatics.company},
    {title: 'SITE', sortLabel: AdminStatics.site},
    {title: 'STATE', sortLabel: AdminStatics.latestState},
    {title: 'SAMPLE', sortLabel: AdminStatics.sample},
    {title: 'TYPE', sortLabel: AdminStatics.orderTypesName},
    {title: 'BRANCH', sortLabel: AdminStatics.branch},
    {title: 'DATE', sortLabel: AdminStatics.createdAt}
  ];

  constructor(
    private ordersService: AdminOrdersService,
    private samplesService: AdminSimpleSamplesService,
    private dashboardService: AdminDashboardService,
    private simpleOrdersService: AdminSimpleOrdersService,
    private diagnosticOrdersService: AdminDiagnosticOrdersService,
    private dialogService: DialogService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private analystService: AnalystService,
    private functionsService: FunctionsService,
    private resources: ResourcesService) {
  }

  ngOnInit(): void {
    const step = this.route.snapshot.queryParamMap.get('step');
    if (step) {
      // tslint:disable-next-line:radix
      this.stepParam = Number.parseInt(step);
      this.stateIds.push(step);
    }
    this.getOrders();
    this.getAllSampleTypes();
    this.getLaboratories();
  }

  getAllSampleTypes() {
    this.ordersService.getAllSampleTypes().subscribe(res => {
      this.sampleTypes = res;
    });
  }

  getLaboratories() {
    this.dashboardService.getLaboratories().subscribe(res => {
      this.laboratories = res.laboratories;
    });
  }


  getOrders() {
    const params: any = {locale: this.resources.getLanguage().description};
    if (this.sortParams) {
      params.order_by = this.sortParams;
    }

    if (this.searchParam) {
      params.search = this.searchParam;
    }

    if (this.stateIds && this.stateIds.length > 0) {
      params.state_ids = this.stateIds;
    }

    if (this.sampleTypeId) {
      params.sample_type_id = this.sampleTypeId;
    }

    if (this.filterByState) {
      params.state_ids = this.stateIds;
    }

    if (this.filterByCity) {
      params.laboratory_ids = this.laboratoryIds;
    }

    params[AdminStatics.limit] = this.limit;
    params[AdminStatics.offset] = this.offset;

    this.analystService.getOrders(params).subscribe(res => {
      this.orders = res[1];
      this.totalOrders = res[0].orders?.total;
      this.totalSamples = res[0].samples?.total;
      this.samples = [];
      if (res[0].samples) {
        Object.entries(res[0].samples).map(sample => {
          if (sample[0] !== AdminStatics.total) {
            this.samples.push(sample[1]);
          }
        });
      }
    });

  }

  handleFilterByOrderState($event: any) {
    this.filterByState = true;
    switch ($event) {
      case -1:
        this.stateIds = [];
        break;
      case 0:
        this.stateIds = [1, 7];
        break;
      case 1:
        this.stateIds = [2, 8];
        break;
      case 2:
        this.stateIds = [3, 9];
        break;
      case 3:
        this.stateIds = [4];
        break;
      case 4:
        this.stateIds = [5, 10];
        break;
      case 5:
        this.stateIds = [6, 11];
        break;
    }

    this.getOrders();
  }

  handleFilterByOrderCity($event: any) {
    this.filterByCity = true;
    if ($event === -1) {
      this.laboratoryIds = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    } else {
      this.laboratoryIds = [$event];
    }

    this.getOrders();

  }

  sortByLabel(param) {
    this.sortParams = param.asc ? param.param + AdminStatics.asc : param.param + AdminStatics.desc;
    this.getOrders();
  }

  changeLimit(limit) {
    this.limit = limit;
    this.offset = 0;
    this.getOrders();
  }

  changeOffset(page) {
    this.offset = page * this.limit;
    this.getOrders();
  }

  onSearchParamChange(param) {
    this.searchParam = param;
    this.getOrders();
  }

  filterBySample(sampleId) {
    if (sampleId === -1) {
      this.sampleTypeId = null;
    } else {
      this.sampleTypeId = sampleId;
    }
    this.getOrders();
  }

  deleteParam(param) {
    this.stepParam = null;
    this.stateIds = [];
    this.router.navigate(
      ['.'],
      {relativeTo: this.route, queryParams: {}}
    );

    this.getOrders();
  }

  private setDeadlines(types: Deadline[]) {
    types.forEach(type => {
      this.deadlines.push({
        id: type.id,
        text: this.translate.instant(type.slug),
        value: type.id
      });
    });
  }

  createOrderTemp(){
    const body = {
      order_type_id: 1,
      locale: this.resources.getLanguage().description,
    };
    this.simpleOrdersService.orderCreate(body).subscribe(result => {
      this.router.navigate(['/admin/orders/simple-order', result.order.id]).then(() => {
      });
    }, err => this.functionsService.showError((err)));
  }

  createOrder() {
    const ref = this.dialogService.open(CreateOrderComponent, {
      showHeader: false,
      width: '70%',
      styleClass: 'dialog',
      data: {deadlines: this.deadlines, user: false, laboratories: this.laboratories}
    });

    ref.onClose.subscribe(res => {
      if (res) {
        if (res.type === AdminStatics.simpleOrder) {
          const body = {
            order_type_id: 1,
            locale: this.resources.getLanguage().description,
            user_id: res.user_id
          };
          this.simpleOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/analyst/orders/simple-order', result.order.id]).then(() => {
            });
          }, err => this.functionsService.showError((err)));
        } else if (res.type === AdminStatics.diagnostics) {
          const body = {
            order_type_id: 2,
            locale: this.resources.getLanguage().description,
          };
          this.diagnosticOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/analyst/orders/diagnostic-order', result.order.id]).then(() => {
            });
          }, err => this.functionsService.showError((err)));
        }
      }

    });
  }
}
