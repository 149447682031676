import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminScorecardModelService } from '../../../../services/admin-scorecard-model.service';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { ScoreCardModel, ScoreCardModelSample } from '../../../../models/score-card-model';
import { forkJoin } from 'rxjs';
import { AdminSimpleOrdersService } from '../../../../services/admin-simple-orders.service';
import { SimpleOrder } from '../../../../models/simple-order-response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../../shared/services';
import { MebData, VdiData } from '../../../../models/type';
import { Sample } from '../../../../models/order';
import { DropdownModel } from '../../../../../shared/components/dropdown/dropdown.model';
import { FunctionsService } from '../../../../services/functions.service';

@Component({
  selector: 'app-prolabo-score-card-modal',
  templateUrl: './score-card-modal.component.html',
  styleUrls: ['./score-card-modal.component.scss']
})
export class ScoreCardModalComponent implements OnInit {

  scoreCardModel: ScoreCardModel;
  samples: Sample[];
  counter: string;
  order: SimpleOrder;
  orderNumber: any;
  orderDate: any;
  orderRemark: any;
  orderLaboratoryId: any;
  validationForm: FormGroup;
  vdiValidationForm: FormGroup;
  bufferValidationForm: FormGroup;
  sampleID: number;
  mebData: MebData;
  mebData2: MebData;
  vdiData: VdiData;
  analysisRemarks: string;
  finalValidate: boolean;
  public dropdownOptions: DropdownModel[];
  selectedControlSamples: number[] = [];


  constructor(public ref: DynamicDialogRef,
              private resources: ResourcesService,
              private scorecard: AdminScorecardModelService,
              private ordersService: AdminSimpleOrdersService,
              private fb: FormBuilder,
              private functions: FunctionsService,
              private userService: UserService,
              private config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.initSimpleValidationForm();
    this.orderNumber = this.config.data.orderNumber;
    this.orderDate = this.config.data.orderDate;
    this.orderRemark = this.config.data.orderRemark;
    this.order = this.config.data.order;
    this.dropdownOptions = this.config.data.dropdownOptions;
    this.finalValidate = this.config.data.finalValidate;
    const sampleIds = new Array(0);
    this.config.data.samples.forEach(x => {
      sampleIds.push(x.sample_type_id);
    });
    this.sampleID = sampleIds[0];
    const body = {
      order_id: this.config.data.order_id,
      sample_type_ids: sampleIds,
      locale: this.resources.getLanguage().description
    };
    const sampleBody = {
      order_id: this.config.data.order_id,
      sample_type_id: sampleIds[0],
      locale: this.resources.getLanguage().description
    };
    const scorecardModels = this.scorecard.getScorecardModels(body);
    const samples = this.scorecard.getSamples(sampleBody);
    forkJoin([samples, scorecardModels]).subscribe(result => {
      this.samples = result[0].samples;
      this.counter = result[0].counter;
      this.scoreCardModel = result[1][0];
      if (this.samples) {
        const scoreCardModel = this.getScoreCardModal(this.order.scorecard_model, this.samples[0].sample_type_id);
        if (scoreCardModel) {
          this.analysisRemarks = scoreCardModel.analysis_remarks;
        } else {
          this.analysisRemarks = '';
        }
      } else {
        this.analysisRemarks = '';
      }
      this.validationForm.patchValue({
        analysis_remarks: this.analysisRemarks
      });
    });

  }

  getScoreCardModal(scorecardModel: ScoreCardModelSample[], sampleTypeId): ScoreCardModelSample {
    return scorecardModel.find(x => x.sample_type_id === sampleTypeId);
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    if(this.finalValidate){
      this.ref.close(this.selectedControlSamples);
    } else {
      this.validationForm.patchValue({
        scorecard_model_id: this.scoreCardModel.id,
        order_id: this.config.data.order_id
      });
      if (this.validationForm.controls.analysis_remarks.value == null || this.validationForm.controls.analysis_remarks.value.length === 0) {
        this.validationForm.removeControl('analysis_remarks');
        this.ref.close(this.validationForm.value);
      } else {
        this.ref.close(this.validationForm.value);
      }
    }

  }

  initSimpleValidationForm() {
    this.validationForm = this.fb.group({
      scorecard_model_id: [''],
      order_id: [''],
      analysis_remarks: [''],
      locale: this.resources.getLanguage().description
    });
  }

  initVdiForm() {
    this.vdiValidationForm = this.fb.group({
      scorecard_model_id: ['', Validators.required],
      order_id: [''],
      analysis_remarks: [''],
      number_of_fields_observed: [0.01, [Validators.required, Validators.pattern('^\\s*(?=[1-9].*[0-9])\\d*(?:\\.\\d{1,5})?\\s*$')]],
      total_filter_area: [1, [Validators.required, Validators.pattern('^\\s*(?=[1-9].*[0-9])\\d*(?:\\.\\d{1,5})?\\s*$')]],
      total_area_analyzed: [380, [Validators.required, Validators.pattern('^\\s*(?=[1-9].*[0-9])\\d*(?:\\.\\d{1,5})?\\s*$')]],
      total_area_of_a_field: [100, Validators.required],
      locale: this.resources.getLanguage().description
    });
  }

  initBufferForm() {
    this.bufferValidationForm = this.fb.group({
      meb: ['', Validators.required],
      scorecard_model_id: ['', Validators.required],
      order_id: [''],
      magnifications: ['', Validators.required],
      number_of_fields: ['', Validators.required],
      area_of_an_image: ['', Validators.required],
      total_surface_analyzed: [''],
      magnifications_2: ['', Validators.required],
      number_of_fields_2: ['', Validators.required],
      area_of_an_image_2: ['', Validators.required],
      total_surface_analyzed_2: [''],
      analysis_remarks: [''],
      locale: this.resources.getLanguage().description
    });
  }

  patchVdiForm() {
    this.vdiValidationForm.patchValue({
      analysis_remarks: this.vdiData.analysis_remarks != null ? this.vdiData.analysis_remarks : '',
      number_of_fields_observed: this.vdiData.number_of_fields_observed,
      total_filter_area: this.vdiData.total_filter_area,
      total_area_analyzed: this.vdiData.total_area_analyzed,
      total_area_of_a_field: this.vdiData.total_area_of_a_field,
    });
  }

  patchBufferForm() {
    this.bufferValidationForm.patchValue({
      meb: this.mebData.meb,
      magnifications: this.mebData.magnifications,
      number_of_fields: this.mebData.numberOfFields,
      area_of_an_image: this.mebData.areaOfImage,
      total_surface_analyzed: this.mebData.totalSurface,
      magnifications_2: this.mebData2.magnifications,
      number_of_fields_2: this.mebData2.numberOfFields,
      area_of_an_image_2: this.mebData2.areaOfImage,
      total_surface_analyzed_2: this.mebData2.totalSurface,
      analysis_remarks: this.mebData.analysis_remarks != null ? this.mebData.analysis_remarks : ''
    });
  }

  private getUserVdiData(laboratoryId: number) {
    switch (laboratoryId) {
      case 1:
        this.vdiData = {
          number_of_fields_observed: 30,
          total_area_of_a_field: 0.0336,
          total_area_analyzed: 380,
          total_filter_area: 1
        };
        break;
      case 2:
        this.vdiData = {
          number_of_fields_observed: 60,
          total_area_of_a_field: 0.0168,
          total_area_analyzed: 380,
          total_filter_area: 1
        };
        break;
      case 3:
        this.vdiData = {
          number_of_fields_observed: 60,
          total_area_of_a_field: 0.0168,
          total_area_analyzed: 380,
          total_filter_area: 1
        };
        break;
      case 4:
        this.vdiData = {
          number_of_fields_observed: 60,
          total_area_of_a_field: 0.0168,
          total_area_analyzed: 380,
          total_filter_area: 1
        };
        break;
      case 5:
        this.vdiData = {
          number_of_fields_observed: 30,
          total_area_of_a_field: 0.0336,
          total_area_analyzed: 380,
          total_filter_area: 1
        };
        break;
      case 6:
        this.vdiData = {
          number_of_fields_observed: 60,
          total_area_of_a_field: 0.0168,
          total_area_analyzed: 380,
          total_filter_area: 1
        };
        break;
      case 7:
        this.vdiData = {
          number_of_fields_observed: 60,
          total_area_of_a_field: 0.0168,
          total_area_analyzed: 380,
          total_filter_area: 1
        };
        break;
    }
  }

  private getUserBufferData(laboratoryId: number) {
    switch (laboratoryId) {
      case 1:
        this.mebData = {
          magnifications: '590x',
          numberOfFields: '28',
          areaOfImage: '0.44',
          totalSurface: '12.32',
          meb: 'Phenom Pro XL (G2)'
        };
        this.mebData2 = {
          magnifications: '1000x',
          numberOfFields: '13',
          areaOfImage: '0.15',
          totalSurface: '1.95'
        };
        break;
      case 2:
        this.mebData = {
          magnifications: '410x',
          numberOfFields: '25',
          areaOfImage: '0.97',
          totalSurface: '24.25',
          meb: 'Phenom Pro X'
        };
        this.mebData2 = {
          magnifications: '1000x',
          numberOfFields: '15',
          areaOfImage: '0.07',
          totalSurface: '1.05'
        };
        break;
      case 3:
        this.mebData = {
          magnifications: '590x',
          numberOfFields: '28',
          areaOfImage: '0.44',
          totalSurface: '12.32',
          meb: 'Phenom Pro XL'
        };
        this.mebData2 = {
          magnifications: '1000x',
          numberOfFields: '13',
          areaOfImage: '0.15',
          totalSurface: '1.95'
        };
        break;
      case 4:
        this.mebData = {
          magnifications: '590x',
          numberOfFields: '28',
          areaOfImage: '0.44',
          totalSurface: '12.32',
          meb: 'Phenom Pro XL'
        };
        this.mebData2 = {
          magnifications: '1000x',
          numberOfFields: '13',
          areaOfImage: '0.15',
          totalSurface: '1.95'
        };
        break;
      case 5:
        this.mebData = {
          magnifications: '590x',
          numberOfFields: '28',
          areaOfImage: '0.44',
          totalSurface: '12.32',
          meb: 'Phenom Pro XL (G2)'
        };
        this.mebData2 = {
          magnifications: '1000x',
          numberOfFields: '13',
          areaOfImage: '0.15',
          totalSurface: '1.95'
        };
        break;
      case 6:
        this.mebData = {
          magnifications: '410x',
          numberOfFields: '25',
          areaOfImage: '0.97',
          totalSurface: '24.25',
          meb: 'Phenom Pro X'
        };
        this.mebData2 = {
          magnifications: '1000x',
          numberOfFields: '15',
          areaOfImage: '0.07',
          totalSurface: '1.05'
        };
        break;
      case 7:
        this.mebData = {
          magnifications: '590x',
          numberOfFields: '28',
          areaOfImage: '0.44',
          totalSurface: '12.32',
          meb: 'Phenom Pro XL'
        };
        this.mebData2 = {
          magnifications: '1000x',
          numberOfFields: '13',
          areaOfImage: '0.15',
          totalSurface: '1.95'
        };
        break;
    }
  }

  updateCounter($event: string) {
    this.counter = $event;
  }

  handleSelectedItems($event: number[]) {
    this.selectedControlSamples = $event;
  }
}
