/* tslint:disable:object-literal-key-quotes */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {
  }

  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, this.getGetOptions(params))
      .pipe(catchError(this.formatErrors));
  }

  // tslint:disable-next-line:ban-types
  public post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, JSON.stringify(body), this.getStandardOptions()
    ).pipe(catchError(this.formatErrors));
  }

  // tslint:disable-next-line:ban-types
  public put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${environment.apiUrl}${path}`, JSON.stringify(body), this.getStandardOptions()
    ).pipe(catchError(this.formatErrors));
  }

  public delete(path): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${path}`, this.getStandardOptions()
    ).pipe(catchError(this.formatErrors));
  }

  // tslint:disable-next-line:ban-types
  public patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(`${environment.apiUrl}${path}`, JSON.stringify(body), this.getStandardOptions()
    ).pipe(catchError(this.formatErrors));
  }

  private getGetOptions(params: HttpParams): any {
    return {
      headers: this.getHeader(),
      params,
    };
  }

  private getStandardOptions(): any {
    return {
      headers: this.getHeader()
    };
  }

  private getHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      'Accept': 'application/ld+json',
    });
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      'Accept': 'application/ld+json',
      'Authorization': `Bearer ${token}`
    });
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }



}
