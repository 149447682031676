import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourcesService } from '../../shared/services/resources.service';
import { environment } from '../../../environments/environment';
import { AnalystRoutesStatics } from '../statics/analyst-routes-statics';
import { Observable } from 'rxjs';
import { LoginResponse, UpdateResponse } from '../../models';
import { AdminRoutesStatics } from '../../admin/statics/admin-routes-statics';
import { DeadlineResponse } from '../../models/deadline';
import { SimpleSampleGetResponse } from '../../admin/models/simple-order-response';
import { DiagnosticSampleGetResponse } from '../../admin/models/diagnostic-order-response';
import { Type } from '../../admin/models/type';

@Injectable({
  providedIn: 'root'
})
export class AnalystService {

  constructor(private http: HttpClient, private resources: ResourcesService) { }

  public getProfileData(body): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getProfileData}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public updateProfile(body): Observable<UpdateResponse> {
    return this.http.post<UpdateResponse>(`${environment.adminApiUrl}${AnalystRoutesStatics.updateProfile}`, body, {
      headers: this.getMultiPartHeader(this.resources.getStorageUser().token)
    });
  }

  public getSimpleOrderDetails(body): Observable<SimpleSampleGetResponse> {
    return this.http.post<SimpleSampleGetResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getAnalystOrder}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getSampleTypes(body): Observable<Type[]> {
    return this.http.post<Type[]>(`${environment.adminApiUrl}${AdminRoutesStatics.getSampleTypes}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getDiagnosticOrderDetails(body): Observable<DiagnosticSampleGetResponse> {
    return this.http.post<DiagnosticSampleGetResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getAnalystOrder}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getOrders(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getAnalystOrders}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getDeadlines(): Observable<DeadlineResponse> {
    return this.http.post<DeadlineResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderDeadlines}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public validatePin(pin): Observable<DeadlineResponse> {
    return this.http.post<DeadlineResponse>(`${environment.apiUrl}${AdminRoutesStatics.pinCheck}`,
      {pin, locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }

  private getMultiPartHeader(token): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }
}

