import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prolabo-order-activities',
  templateUrl: './order-activities.component.html',
  styleUrls: ['./order-activities.component.scss']
})
export class OrderActivitiesComponent implements OnChanges {
  @Input() orderActivities: any[];
  @Input() orderTotals: [];

  @Output() stepClick: EventEmitter<number> = new EventEmitter<number>();

  constructor(private router: Router) {}

  ngOnChanges(): void {
  }

  getTotals(keyword) {
    return this.orderTotals[keyword];
  }

  onStepClick(param) {
    const step = param + 1;
    let value = [];
    if (step === 5) {
      value = [5, 10];
    } else if (step === 4) {
      value = [4];
    } else if (step === 1) {
      value = [1, 7];
    } else if (step === 2) {
      value = [2, 8];
    } else if (step === 3) {
      value = [3, 9];
    }
    this.router.navigateByUrl(`/admin/orders?step=` + value);
  }
}
