<div class="clients-container">
  <app-dialog-header [title]="'NEW_VERSION'" (iconClick)="ref.close()"></app-dialog-header>
  <div class="p-grid">
    <div class="p-col-12">
      <form [formGroup]="newVersionForm">
        <div class="p-grid">
          <div class="p-col-12">
              <textarea pInputTextarea
                        placeholder="{{ 'NEW_VERSION_REASON' | translate }}*"
                        formControlName="new_version_description">
              </textarea>
          </div>
          </div>
      </form>
    </div>
  </div>
  <div class="p-grid extra"></div>
  <div class="p-grid client-dialog-footer">
    <div class="p-col-12 p-md-8">
    </div>
    <div class="p-col-12 p-md-4">
      <app-prolabo-icon-button (click)="submit()"
                               [icon]="'check'"
                               [name]="'VALIDATE'"
                               type="secondary">
      </app-prolabo-icon-button>
    </div>
  </div>
</div>
