import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminUsersService } from 'src/app/admin/services/admin-users.service';
import { I18nService, ToastService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { InputValidation } from 'src/app/models/input-validation';
import { ProlaboAnalyseService } from '../../user/services/prolabo-analyse.service';
import { FunctionsService } from '../../admin/services/functions.service';

@Component({
  selector: 'app-prolabo-style',
  templateUrl: './user-style.component.html',
  styleUrls: ['./user-style.component.scss']
})
export class UserStyleComponent implements OnInit {
  @Input() user: User;
  @Input() state: string;
  @Input() validationClicked = false;
  @Input() isUser: boolean;

  styleForm: FormGroup;
  previewImage: any;
  @Output() styleFormEvent = new EventEmitter<File>();

  constructor(private formBuilder: FormBuilder,
              private toast: ToastService,
              private adminService: AdminUsersService,
              private analyseService: ProlaboAnalyseService,
              private functions: FunctionsService,
              private lang: I18nService) {
  }

  ngOnInit(): void {
    this.styleForm = this.formBuilder.group({
      company_logo: [this.user?.company_logo]
    });
    if (this.state === 'update' && this.user.company_logo !== null) {
      this.previewImage = `${environment.storageUrl}${this.user.company_logo}`;
    }
  }

  valueChange($event: any) {
    if (this.state === 'create') {
      this.readUrl($event).then(formData => {
        setTimeout(() => {
          // @ts-ignore
          this.styleFormEvent.emit({key: 'company_logo', value: formData.get('company_logo')});
        }, 100);
      });
    } else if (this.state === 'update') {
      this.readUrl($event).then(result => {
        const formData = result;
        formData.append('locale', this.lang.currentLang);
        // @ts-ignore
        setTimeout(() => {
          if (this.isUser) {
            this.analyseService.updateProfile(formData).subscribe(value => {
              this.toast.showBottomCenterSuccess(value.message);
            }, error => {
              this.functions.showError(error);
            });
          } else {
            formData.append('id', this.user.id.toString());
            this.adminService.updateUser(formData).subscribe(value => {
              this.toast.showBottomCenterSuccess(value.message);
            }, error => {
              this.functions.showError(error);
            });
          }
        }, 500);
      });

    }
  }

  readUrl(event: any): Promise<FormData> {
    return new Promise<FormData>((resolve) => {
      const formData = new FormData();
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        // tslint:disable-next-line:no-shadowed-variable
        reader.onload = (event: ProgressEvent) => {
          this.previewImage = (event.target as FileReader).result;
          const file = new File([this.dataURLToBlob(this.previewImage)],
            'company_logo', {type: `image/jpeg`});
          formData.append('company_logo', file);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
      resolve(formData);
    });
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }
}
