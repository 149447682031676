<p-card>
  <form [formGroup]="styleForm">
    <app-prolabo-icon-input [for]="'logo'"
                            [id]="'logo'"
                            [keyword]="'LOGO'"
                            [type]="'file'"
                            [icon]="'visible'"
                            [inputType]="'basic'"
                            [regularInput]="true"
                            formControlName="company_logo"
                            (change)="valueChange($event)"
    ></app-prolabo-icon-input>
  </form>
  <ng-container *ngIf="previewImage">
    <img class="pdng-top" [src]="previewImage" width="100%" alt="company_logo">
  </ng-container>
</p-card>

