import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { I18nService, ToastService } from 'src/app/shared/services';
import { AdminUsersService } from 'src/app/admin/services/admin-users.service';
import { Client } from 'src/app/admin/models/client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminStatics } from '../../../../statics/admin-statics';
import { AdminClientsService } from '../../../../services/admin-clients.service';
import { FunctionsService } from '../../../../services/functions.service';

@Component({
  selector: 'app-prolabo-select-user-modal',
  templateUrl: './select-client-modal.component.html',
  styleUrls: ['./select-client-modal.component.scss']
})
export class SelectClientModalComponent implements OnInit {

  existingClient = false;
  clientNew = false;
  clients: Client[];
  clientForm: FormGroup;
  client: Client;
  edit = false;
  create = false;
  slice = 10;
  items: Client[];
  selectedClient: any;

  constructor(public ref: DynamicDialogRef,
              private config: DynamicDialogConfig,
              private lang: I18nService,
              private adminService: AdminUsersService,
              private clientsService: AdminClientsService,
              private toast: ToastService,
              private functions: FunctionsService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.clients = this.config.data;
    this.items = this.clients?.slice(0, this.slice);
    this.clientForm = this.formBuilder.group({
      company: ['', [Validators.required]],
      id: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      postal_code: ['', [Validators.required]],
      city: ['', [Validators.required]],
      locale: ['', Validators.required]
    });
  }

  exitingClient() {
    this.existingClient = true;
  }

  newClient() {
    this.clientForm.reset();
    this.client = null;
    this.clientNew = true;
    this.create = true;
  }

  clientClicked(clientId) {
    const client: Client = this.clients.find(cli => cli.id === clientId);
    this.ref.close({client, state: AdminStatics.stateChoose});
  }

  back() {
    this.existingClient = false;
    this.clientNew = false;
    this.edit = false;
    this.create = false;
  }

  close() {
    this.ref.close();
  }

  deleteClient(userId) {
    this.ref.close();
    const params = {locale: this.lang.currentLang, id: userId};
    this.clientsService.deleteClient(params).subscribe(res => {
        this.toast.showBottomCenterInfo(res.message);
        const client = this.clients.find(cli => cli.id === userId);
        const index = this.clients.indexOf(client, 0);
        if (index > -1) {
          this.clients.splice(index, 1);
        }
      },
      error => {
        this.functions.showError(error);
      });
  }

  editClient(item: Client) {
    this.existingClient = false;
    this.clientNew = true;
    this.client = item;
    this.edit = true;
    this.clientForm.patchValue({
      id: this.client.id,
      company: this.client.company,
      first_name: this.client.first_name,
      last_name: this.client.last_name,
      address: this.client.address,
      postal_code: this.client.postal_code,
      city: this.client.city
    });
  }

  updateClient() {
    const body = {
      id: this.client.id,
      locale: this.lang.currentLang,
    };
    this.detectChange(this.client, this.clientForm.value).then(promiseResult => {
      promiseResult.locale = this.lang.currentLang;
      this.ref.close({form: promiseResult, state: AdminStatics.stateUpdate, body});
    });
  }

  createClient() {
    this.clientForm.patchValue({
      locale: this.lang.currentLang
    });
    this.ref.close({form: this.clientForm.value, state: AdminStatics.stateCreate});
  }

  detectChange(original, updated) {
    return new Promise<any>((resolve) => {
      for (const key of Object.keys(updated)) {
        if (original[key] === updated[key] && key !== AdminStatics.id) {
          delete updated[key];
        }
      }
      resolve(updated);
    });
  }

  onScroll() {
    this.slice += 10;
    this.items = this.clients.slice(0, this.slice);
  }
}

