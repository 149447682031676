import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-prolabo-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnChanges {

  @Input() display = false;
  @Input() closable = false;
  @Input() header: string;

  @Output() confirmClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelClicked: EventEmitter<string> = new EventEmitter<string>();

  ngOnChanges() {
  }

  constructor() {}

  confirm() {
    this.confirmClicked.emit('confirm');
  }

  cancel() {
    this.cancelClicked.emit('cancel');
  }
}
