<form *ngIf="!disabled">
  <span class="p-input">
    <!--suppress HtmlFormInputWithoutLabel -->
    <ng-container *ngIf="!regularInput && !importInput">
          <div class="input-icon" (click)="fileInput.click()">
          <img (click)="focusInput()" src="../../../../assets/icons/{{icon}}.svg"/>
          <input
            [id]="id"
            [type]="type"
            pInputText
            [placeholder]="keyword | translate"
            (change)="itemChange($event)"
            (blur)="onTouch($event)"
            [class]="class"
            #fileInput
            (keydown)="onChange($event.target.value)">
        </div>
    </ng-container>
    <ng-container *ngIf="regularInput">
      <div class="regular-input" pTooltip="{{ keyword | translate }}{{ required ? '*' : '' }}"
           tooltipPosition="top">
        <label for="file"  class="p-label" (click)="focusInput()">  <p style="color:black" (click)="fileInput2.click()">
                {{ keyword | translate | uppercase }}{{ required ? '*' : '' }}
              </p></label>
          <input
            [id]="id"
            [type]="type"
            pInputText
            id="file2"
            #fileInput2
            style="display: none"
            class="regular {{ class ? class : null }}"
            [formControl]="inputControl"
            (change)="itemChange($event)"
            (blur)="onTouch($event)"
            [accept]="accept"
            [multiple]="multiple"
            (keydown)="onChange($event.target.value)">
            <img class="logo-right" (click)="iconClicked()" src="../../../../assets/icons/{{icon}}.svg"/>
        </div>
    </ng-container>
    <ng-container *ngIf="importInput">
      <div class="import-input" pTooltip="{{ keyword | translate }}{{ required ? '*' : '' }}"
           tooltipPosition="top">
        <label for="file" class="p-label" (click)="focusInput()">  <p >
                {{ keyword | translate }}{{ required ? '*' : '' }}
              </p></label>
          <input
            [id]="id"
            [type]="type"
            pInputText
            id="file"
            #fileInput3
            name="file"
            class="regular {{ class ? class : null }}"
            [formControl]="inputControl"
            (change)="itemChange($event)"
            (blur)="onTouch($event)"
            [accept]="accept"
            [multiple]="multiple"
            (keydown)="onChange($event.target.value)">
            <img class="logo-right" (click)="iconClicked()" src="../../../../assets/icons/{{icon}}.svg"/>
        </div>
    </ng-container>
  </span>
</form>
