<div class="clients-container score-card-modal" *ngIf="scoreCardModel">
  <ng-container>
    <div class="content">
      <app-dialog-header [title]="scoreCardModel.name" (iconClick)="ref.close()"></app-dialog-header>
      <div class="p-grid">
        <div class="p-md-3 text-header">
          <p>{{ 'REMARKS' | translate | uppercase }}</p>
          <p class="{{ !orderRemark ? 'alert-color' : ''}}"> {{ !orderRemark ? ('NONE' | translate) : orderRemark}}</p>
        </div>
        <div class="p-md-3">
          <div class="card card-white" *ngIf="sampleID ===2">
            <p>{{ 'NUMBER_OF_SAMPLES' | translate | uppercase }}</p>
            <p class="semi-bold"> {{ counter }}</p>
          </div>
        </div>
        <div class="p-col-12 p-md-3">
          <div class="card">
            <p>{{ 'PROJECT_NUMBER' | translate | uppercase }}</p>
            <p class="semi-bold">{{ orderNumber }}</p>
          </div>
        </div>
        <div class="p-col-12 p-md-3">
          <div class="card card-white">
            <p>{{ 'START_DATE' | translate | uppercase }}</p>
            <p class="semi-bold">{{ orderDate | toDateObj | date: 'yyyy-MM-dd HH:mm' }}</p>
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-12">
          <app-prolabo-score-list [items]="scoreCardModel.scorecard_field"
                                  [samples]="samples"
                                  [finalValidate]="finalValidate"
                                  [allDropdownOptions]="dropdownOptions"
                                  (selectedControlSamples)="handleSelectedItems($event)"
                                  (counter)="updateCounter($event)"
          ></app-prolabo-score-list>
        </div>
      </div>
      <ng-container>
        <form [formGroup]="validationForm">
          <div class="p-grid under-footer">
            <div class="p-col-12">
          <textarea pInputTextarea
                    placeholder="{{ 'REMARKS' | translate }}"
                    id="analysis_remarks"
                    formControlName="analysis_remarks">
          </textarea>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
    <div class="extra"></div>
    <div class="p-grid client-dialog-footer">
      <div class="p-md-3">
        <app-prolabo-icon-button
          (click)="cancel()"
          [icon]="'left-arrow'"
          [position]="'left'"
          [name]="'PREVIOUS'"
          type="primary"></app-prolabo-icon-button>
      </div>
      <div class="p-md-3"></div>
      <div class="p-md-3"></div>
      <div class="p-col-12 p-md-3">
        <app-prolabo-icon-button
          (OnClick)="submit()"
          [name]="'VALIDATE'"
          type="secondary"
          [icon]="'arrow-right-white'"></app-prolabo-icon-button>
      </div>
    </div>
  </ng-container>
</div>

