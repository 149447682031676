import {Injectable} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';

import {UserService} from './user.service';
import {environment} from '../../../environments/environment';
import {Language} from '../../models';

@Injectable()
export class I18nService {

  public get currentLang() {
    return this.translate.currentLang;
  }

  public set currentLang(lang: string) {
    this.translate.use(lang);
  }

  public setCurrentLang(lang, languages: Language) {
    this.currentLang = lang;
    localStorage.setItem('prolaboDefaultLanguage', JSON.stringify(languages));
  }

  public getCurrentLang() {
    return this.currentLang;
  }

  constructor(
    private translate: TranslateService,
    private userService: UserService
  ) {
    for (const lang of environment.availableLanguageCodes) {
      registerLocaleData(lang);
    }
  }

  public init(): void {
    this.translate.addLangs(environment.availableLanguages);
    this.translate.setDefaultLang(environment.defaultLanguage);
    this.currentLang = this.detectLanguage();
  }

  public detectLanguage(): string {
    if (this.userService.currentUser() && this.userService.locale()) {
      return this.userService.locale();
    }

    const browserLang = this.translate.getBrowserLang();
    if (this.translate.getLangs().includes(browserLang)) {
      return browserLang;
    }

    return environment.defaultLanguage;
  }
}
