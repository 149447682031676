<span [class]="icon">
              {{number}}
          </span>
<ng-container *ngIf="dateLabel">
  <p class="text centered">{{dateLabel | translate}}</p>
</ng-container>
<ng-container *ngIf="createdAt">
  <p class="text centered">{{createdAt | date}}</p>
</ng-container>
<button pButton pRipple type="button" [label]="label | translate" [disabled]="true"
        [class]="button+ ' p-button'"></button>

