import { Component, Input, OnInit } from '@angular/core';
import { Statistics } from '../../../models/Statistic';
import { CalendarOrder } from '../../../models/Calendar';
import { Router } from '@angular/router';
import { ResourcesService } from '../../services/resources.service';
import { StorageUser } from '../../../models';

@Component({
  selector: 'app-prolabo-order-pill',
  templateUrl: './order-pill.component.html',
  styleUrls: ['./order-pill.component.scss']
})

export class OrderPillComponent implements OnInit {

  @Input() items: Statistics[];

  @Input() dashboard: boolean;

  @Input() orders: CalendarOrder[];

  constructor(private router: Router, private resources: ResourcesService) { }

  ngOnInit(): void {
    if (this.items) {
      this.items.forEach(x => {
        if (x.slug === 'VDI' || x.slug === 'ASBESTOS' || x.slug === 'QUANTITATIVE_BUFFER') {
          x.slug += '_D';
        }
      });
    }
  }

  navigate(orderTypeId: number, id: number) {
    const storageUser: StorageUser = this.resources.getStorageUser();
    if (storageUser.role === 'admin' || storageUser.role === 'super_admin') {
      if (orderTypeId === 1) {
        this.router.navigate(['/admin/orders/simple-order', id]);
      } else if (orderTypeId === 2) {
        this.router.navigate(['/admin/orders/diagnostic-order', id]);
      }
    } else if (storageUser.role === 'analyst') {
      if (orderTypeId === 1) {
        this.router.navigate(['/analyst/orders/simple-order', id]);
      } else if (orderTypeId === 2) {
        this.router.navigate(['/analyst/orders/diagnostic-order', id]);
      }
    }
  }

  mapToDisplayNumber(id: number) {
    if (id === 1 || id === 7) {
      return 0;
    } else if (id === 2 || id === 8) {
      return 1;
    } else if (id === 3 || id === 9) {
      return 2;
    } else if (id === 4 || id === 10) {
      return 3;
    }
  }
}
