
<p-dialog 
    header="{{ header | translate }}" 
    styleClass="p-dialog"
    [(visible)]="display"
    [(closable)]="closable">
    <ng-template pTemplate="footer">
        <div class="confirm-btn-group">
            <div class="btn-cancel">
                <p-button 
                    (click)="cancel()" 
                    label="{{ 'CANCEL' | translate }}"></p-button>
            </div>
            <div class="btn-confirm">
                <p-button  
                    (click)="confirm()" 
                    label="{{ 'CONFIRM' | translate }}"></p-button>
            </div>
        </div>
    </ng-template>
</p-dialog>