import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-prolabo-order-step',
  templateUrl: './order-step.component.html',
  styleUrls: ['./order-step.component.scss']
})
export class OrderStepComponent {
  @Input() icon: string;
  @Input() createdAt: string;
  @Input() label: string;
  @Input() button: string;
  @Input() number: number;
  @Input() dateLabel: string;
}
