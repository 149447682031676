import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../shared/services/resources.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit, OnDestroy {
  item: any;
  subscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private resources: ResourcesService) { }

  ngOnInit(): void {
    this.subscription = this.route.paramMap.subscribe(snapshot => {
      const orderId = snapshot.get('id');
      const type = snapshot.get('type');
      const userType = snapshot.get('userType');
      this.item = {
        orderId,
        type,
        userType
      };
      if (this.resources.getStorageUser()) {
        if (this.item.userType === 'admin' || this.item.userType === 'super_admin') {
          this.item.userType = 'admin';
          this.router.navigate(['/admin/dashboard', this.item]);
        } else if (this.item.userType === 'user' || this.item.userType === 'client_lenzburg') {
          this.router.navigate(['/user/dashboard', this.item]);
        }
      } else {
        if (this.item.userType === 'admin' || this.item.userType === 'super_admin') {
          this.item.userType = 'admin';
          this.router.navigate(['/admin/login', this.item]);
        } else if (this.item.userType === 'user' || this.item.userType === 'client_lenzburg') {
          this.router.navigate(['/login', this.item]);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
