<div class="clients-container">
    <app-dialog-header [title]="client ? 'UPDATE_CLIENT' : 'ADD_NEW_CLIENT'" (iconClick)="ref.close()"></app-dialog-header>
    <div class="p-grid form">
        <form [formGroup]="clientForm">
          <div class="p-grid p-fluid">
            <div class="p-col-12">
              <app-prolabo-input [for]="'business'"
                                 [id]="'business'"
                                 [keyword]="'COMPANY'"
                                 [type]="'text'"
                                 [inputType]="'basic'"
                                 [defaultValue]="client ? client['company'] : null"
                                 formControlName="company"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-4">
              <app-prolabo-input [for]="'name'"
                                 [id]="'name'"
                                 [keyword]="'NAME'"
                                 [type]="'text'"
                                 [inputType]="'basic'"
                                 [defaultValue]="client ? client['first_name'] : null"
                                 formControlName="first_name"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-8">
                <app-prolabo-input [for]="'surname'"
                                   [id]="'surname'"
                                   [keyword]="'SURNAME'"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   [defaultValue]="client ? client['last_name'] : null"
                                   formControlName="last_name"></app-prolabo-input>
              </div>
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-4">
                  <app-prolabo-input [for]="'address'"
                                     [id]="'address'"
                                     [keyword]="'ADDRESS'"
                                     [type]="'text'"
                                     [inputType]="'basic'"
                                     [defaultValue]="client ? client['address'] : null"
                                     formControlName="address"></app-prolabo-input>
                </div>
                <div class="p-col-4">
                  <app-prolabo-input [for]="'postal_code'"
                                     [id]="'postal_code'"
                                     [keyword]="'POSTCODE'"
                                     [type]="'text'"
                                     [inputType]="'basic'"
                                     [defaultValue]="client ? client['postal_code'] : null"
                                     formControlName="postal_code"></app-prolabo-input>
                </div>
                <div class="p-col-4">
                  <app-prolabo-input [for]="'city'"
                                     [id]="'city'"
                                     [keyword]="'CITY'"
                                     [type]="'text'"
                                     [inputType]="'basic'"
                                     [defaultValue]="client ? client['city'] : null"
                                     formControlName="city"></app-prolabo-input>
                </div>
              </div>
            </div>
            <div class="p-col-12 extra">
            </div>
          </div>
        </form>
      </div>
    <div class="p-grid client-dialog-footer">
        <div class="p-md-4"></div>
        <div class="p-md-4"></div>
        <div class="p-col-12 p-md-4">
          <app-prolabo-icon-button
            (click)="addClient()"
            [name]="'VALIDATE'"
            type="primary"
            [icon]="'arrow-right-white'"></app-prolabo-icon-button>
        </div>
    </div>
</div>
