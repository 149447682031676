<div class="progress-bar-container {{ name == 'CANCELLED' ? 'cancelled' : '' }}">
  <span class="title {{ step == 0 ? 'left-0' : (step == 1 || step == 2) ? 'left-10'
  : (step == 3) ? 'left-30' : 'left-56' }}">
    {{ step }}
    <span class="long {{ class == 'long-hidden' ? 'long-hidden' : '' }}"> {{ name | translate }} </span>
  </span>
  <p-progressBar
    class="progress-bar"
    [value]="value"
    [showValue]="false">
  </p-progressBar>
</div>

