import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '../../../../../shared/services';
import { DropdownModel } from '../../../../../shared/components/dropdown/dropdown.model';
import { DiagnosticOrder } from '../../../../models/diagnostic-order-response';
import { ResourcesService } from '../../../../../shared/services/resources.service';

@Component({
  selector: 'app-prolabo-diagnostic-extra-information',
  templateUrl: './diagnostic-extra-information.component.html',
  styleUrls: ['./diagnostic-extra-information.component.scss']
})
export class DiagnosticExtraInformationComponent implements OnInit {

  extraInfoForm: FormGroup;

  order: DiagnosticOrder;
  additionalInfoOptions: DropdownModel[];
  optionId: number;
  stepDone: boolean = null;
  disableForm = false;

  constructor(private translate: TranslateService,
              private cdr: ChangeDetectorRef,
              public toast: ToastService,
              private resources: ResourcesService,
              private config: DynamicDialogConfig,
              public ref: DynamicDialogRef,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.additionalInfoOptions = this.config.data.additionalInfo;
    this.order = this.config.data.order;

    if (this.config.data.stepDone) {
      this.stepDone = this.config.data.stepDone;
    }

    if (this.stepDone) {
      this.disableForm = true;
    }

    this.extraInfoForm = this.formBuilder.group({
      date_of_diagnosis: [this.order?.date_of_diagnosis, Validators.required],
      deviations: [this.order?.deviations, Validators.required],
      order_additional_information_id: [this.order?.order_additional_information_id, Validators.required],
      potentially_dangerous_situations: [this.order?.potentially_dangerous_situations, Validators.required],
      pre_visit_date: [this.order?.pre_visit_date],
      pre_visit: [this.order?.pre_visit, Validators.required],
      reservations: [this.order?.reservations, Validators.required],
      scope_of_diagnosis: [this.order?.scope_of_diagnosis !== 'null' ? this.order?.scope_of_diagnosis : '', Validators.required],
      locale: [this.resources.getLanguage().description],
      order_id: [this.order?.id]
    });

    if (this.order?.pre_visit) {
      this.extraInfoForm.patchValue({
        pre_visit: this.order?.pre_visit
      });
    }

    if (this.order?.pre_visit_date) {
      this.extraInfoForm.patchValue({
        pre_visit_date: this.order?.pre_visit_date
      });
    }

    if (this.order?.reservations) {
      this.extraInfoForm.patchValue({
        reservations: this.order?.reservations
      });
    }

    if (this.order?.scope_of_diagnosis) {
      this.extraInfoForm.patchValue({
        scope_of_diagnosis: this.order?.scope_of_diagnosis
      });
    }

    if (!this.order?.order_additional_information_id) {
      this.optionId = this.additionalInfoOptions[0].id;
      this.extraInfoForm.patchValue({
        order_additional_information_id: this.optionId
      });
    }
  }

  updateAdditionalOption(id) {
    this.optionId = id;
    this.extraInfoForm.patchValue({
      order_additional_information_id: id
    });
  }

  save() {
    if (this.extraInfoForm.valid) {
      if (this.extraInfoForm.value.pre_visit_date === null) {
        this.extraInfoForm.removeControl('pre_visit_date');
      }
      this.ref.close(this.extraInfoForm.value);
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }
}
