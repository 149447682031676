<form pTooltip="{{ placeholder ? (placeholder | translate) : '' + required ? '*' : ''}}"
      tooltipPosition="top">
  <ng-container *ngIf="type !== 'colors'">
     <span class="p-label">
      <p>
        {{ placeholder | translate | uppercase }} {{required ? '*' : ''}}
      </p>
    </span>
    <p-dropdown [options]="options"
                [formControl]="selectedOptionFormControl"
                (onChange)="onChange($event)"
                (onClick)="onClick($event)"
                [selectId]="defaultValue"
                [inputId]="inputId"
                class="{{ type ? type : '' }}"
                optionValue="id">
      <ng-template let-option pTemplate="item">
        {{translateOption(option.text, option.id)}}
      </ng-template>
      <ng-template pTemplate="selectedItem" let-selectedOption>
        {{translateOption(selectedOption.text, selectedOption.id)}}
      </ng-template>
    </p-dropdown>
  </ng-container>
  <ng-container *ngIf="type === 'colors'">
    <p-dropdown [options]="options"
                [formControl]="selectedOptionFormControl"
                (onChange)="onChange($event)"
                (onClick)="onClick($event)"
                [inputId]="inputId"
                [placeholder]="defaultValue"
                class="{{ type ? type : '' }}"
                optionValue="id">
      <ng-template pTemplate="selectedItem" let-selectedOption>
        <div class="{{selectedOption.text.toLowerCase()}}"></div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <div class="{{option.text.toLowerCase()}}"></div>
      </ng-template>
    </p-dropdown>
  </ng-container>
</form>
