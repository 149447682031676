<div class="side-bar">
  <p-card class="card">
    <div class="side-bar-container">
      <div class="logo-title-container">
        <div class="logo-wrapper" (click)="navigate()">
          <div class="logo">
            <img src="../../../../assets/icons/pwa/analysis-rgb-black-screen.svg"/>
          </div>
          <div class="logo2">
            <img src="../../../../assets/icons/pwa/aatest.png"/>
          </div>
        </div>
        <div class="title-section">
          <p>{{ 'WELCOME' | translate }}</p>
          <p>{{ title }}</p>
        </div>
      </div>
      <div class="custom-list">
        <ul>
          <ng-container *ngFor="let item of sidebarItems">
            <app-prolabo-list-item (click)="routeClicked(item.name)"
                                   [title]="item.name"
                                   [class]="item.class"
                                   [active]="activeRoute"
                                   [icon]="item.icon"
                                   [link]="item.routerLink"
                                   [pinRequested]="pinRequested"></app-prolabo-list-item>
          </ng-container>
        </ul>
      </div>
      <div class="title-section footer text-medium">
        <p>Analysis Lab SA © {{ date.getFullYear() }}</p>
        <p>Powered by Tokiwi</p>
      </div>
    </div>
  </p-card>
</div>
