import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyAccount } from '../../../models';
import { AuthService, I18nService, ToastService } from '../../../shared/services';
import { ResourcesService } from '../../../shared/services/resources.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {
  verifyAccountObj: VerifyAccount;

  constructor(private route: ActivatedRoute,
              private apiService: AuthService,
              private router: Router,
              private lang: I18nService,
              private resources: ResourcesService,
              private toast: ToastService) {
  }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('id');
    this.verifyAccountObj = {
      locale: this.resources.getLanguage()?.description || 'fr',
      token
    };
    this.apiService.verifyAccount(this.verifyAccountObj).subscribe(result => {
      this.toast.showBottomCenterInfo(result.message);
      if (result.role) {
        if (result.ask_for_password) {
          this.router.navigate(['/set-password', result.user_id]);
        } else if (result.role.id === 3 || result.role.id === 5) {
          this.router.navigateByUrl('/login');
        } else {
          this.router.navigateByUrl('/admin/login');
        }
      } else {
        this.router.navigateByUrl('/login');
      }
    }, error => {
      this.router.navigateByUrl('/login');
      this.toast.showBottomCenterError(error?.error?.message);
    });
  }
}
