<div class="p-grid">
  <div class="p-col-12 p-md-12 p-xl-8">
    <app-prolabo-profile-form [currentUser]="currentUser" [validateClicked]="validateClicked"
                              (validationClicked)="handleValidateCLickedProfile($event)"
                              (profileFormEvent)="handleProfileFormEvent($event)"
                              (profileOriginalFormEvent)="handleProfileOriginalFormEvent($event)"></app-prolabo-profile-form>
    <app-prolabo-administration [currentUser]="currentUser"
                                (administrationFormEvent)="handleAdministrationForm($event)"
                                (administrationOriginalFormEvent)="handleAdministrationOriginalForm($event)"
                                [laboratories]="laboratories"
                                [invoiceTypes]="invoiceTypes"
                                (validateClicked)="handleValidateClicked($event)"></app-prolabo-administration>
  </div>

  <div class="p-col-12 p-md-12 p-xl-4">
    <app-prolabo-profile-change [currentUser]="currentUser"></app-prolabo-profile-change>
    <app-prolabo-responsible [currentUser]="currentUser" (dataUpdate)="onDataUpdate()"></app-prolabo-responsible>
  </div>
</div>

