<div class="p-grid">
  <div class="p-col-4">
    <app-prolabo-icon-button name="NEW.TYPE.ANALYSIS" icon="add" (click)="createScoreCard()"></app-prolabo-icon-button>
  </div>
</div>
<ng-container *ngFor="let scoreCardModel of scoreCardModels">
  <p-card>
    <div class="p-grid analyst-creator-title">
      <div class="p-col-12">
        <p class="title"> {{scoreCardModel.sample_type.slug | translate}} </p>
      </div>
    </div>
    <div class="p-grid analyst-creator-container">
      <div class="p-col-12 p-md-4">
        <div class="input">
          <app-prolabo-input [defaultValue]="scoreCardModel.name" keyword="FILE_NAME" [required]="false" type="text"
                             [id]="scoreCardModel.id.toString() + scoreCardModel.name"
                             (OnValueChange)="nameChange($event, scoreCardModel.id)"
                             inputType="basic">
          </app-prolabo-input>
        </div>
        <app-prolabo-dropdown [options]="sampleTypes"
                              (OnValueChange)="updateSampleTypeValue($event, scoreCardModel.id)"
                              [defaultValue]="scoreCardModel.sample_type.id"
                              placeholder="SAMPLE.TYPE">
        </app-prolabo-dropdown>
      </div>
      <div class="p-col-12 p-md-8" *ngIf="fieldTypes">
        <app-prolabo-analyst-creator-pagination-list [items]="scoreCardModel.scorecard_field"
                                                     [fieldTypes]="fieldTypes"
                                                     (itemUpdated)="handleUpdatedField($event)"
                                                     (itemDelete)="handleDeletedFile($event)"
                                                     (itemCreated)="handleCreatedItem($event)"
                                                     [scoreCardModalId]="scoreCardModel.id">
        </app-prolabo-analyst-creator-pagination-list>
      </div>
    </div>
  </p-card>
</ng-container>
