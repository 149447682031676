<div class="clients-container" [ngStyle]="{height: modalOpened ? '550px' : '250px'}">
  <app-dialog-header [title]="'DEADLINE.EIGHT'" (iconClick)="ref.close()"></app-dialog-header>
  <form [formGroup]="form">
    <p-calendar formControlName="date"
                [showIcon]="true"
                (onSelect)="onSelect($event)"
                (click)="onSelect($event)"
                dateFormat="d.mm.yy"
                (onClickOutside)="onClickOutside($event)"
                [disabledDates]="disabledDates"
                inputId="icon"></p-calendar>
  </form>

  <div class="p-grid client-dialog-footer">
    <div class="p-col-12 p-md-8">
    </div>
    <div class="p-col-12 p-md-4">
      <app-prolabo-icon-button (click)="submit()"
                               [icon]="'check'"
                               [name]="'VALIDATE'"
                               [disabled]="!form.valid"
                               type="secondary">
      </app-prolabo-icon-button>
    </div>
  </div>
</div>

