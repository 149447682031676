<div class="p-grid">
  <div class="p-col-8">
    <app-prolabo-user-profile-form [currentUser]="currentUser"
                                   (validateClicked)="handleValidateCLickedProfile($event)"
                                   (profileFormEvent)="handleProfileFormEvent($event)"
                                   (profileOriginalFormEvent)="handleProfileOriginalFormEvent($event)"
                                   [validationClicked]="validateClicked">
    </app-prolabo-user-profile-form>
    <app-prolabo-user-profile-administration [invoiceTypes]="invoiceTypes" [laboratories]="laboratories"
                                             [currentUser]="currentUser"
                                             [laboratoriesModal]="laboratoriesModal"
                                             (administrationFormEvent)="handleAdministrationUpdatedFormEvent($event)"
                                             (administrationOriginalFormEvent)="handleAdministrationFormEvent($event)"
                                             (validateClicked)="handleValidationClicked($event)"></app-prolabo-user-profile-administration>
  </div>
  <div class="p-col-4">
    <app-prolabo-style [user]="currentUser" state="update" [isUser]="true"></app-prolabo-style>
    <app-prolabo-user-responsible [user]="currentUser"
                                  (updatedUser)="onUpdatedUser()"></app-prolabo-user-responsible>
  </div>
</div>

