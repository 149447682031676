<div class="p-grid dialog-header">
    <div class="p-col-12 title">
        <p>
            {{ translateOption(title) }}
        </p>
        <div class="border"></div>
        <div class="icon" (click)="onIconClick()">
            <img src="../../../../../assets/icons/close.svg" />
        </div>
    </div>
</div>
