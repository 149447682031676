import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AnalystOrdersComponent } from './pages/orders/analyst-orders-main/analyst-orders.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { AnalystService } from './services/analyst.service';
import { AnalystContainerComponent } from './pages/container/analyst-container.component';
import { AuthGuard } from '../shared/guards';
import { AnalystProfileComponent } from './pages/profile/analyst-profile/analyst-profile.component';
import { AnalystProfileFormComponent } from './pages/profile/analyst-profile-form/analyst-profile-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CommonModule } from '../common/common.module';
import { AnalystOrdersPaginationListComponent } from './pages/orders/analyst-orders-pagination-list/analyst-orders-pagination-list.component';
import { AnalystOrdersPaginationListSamplesComponent } from './pages/orders/analyst-orders-pagination-list-samples/analyst-orders-pagination-list-samples.component';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { SimpleOrderComponent } from './pages/orders/order-types/simple-order/simple-order.component';
import { DiagnosticOrderComponent } from './pages/orders/order-types/diagnostic-order/diagnostic-order.component';
import { DiagnosticPlanItemComponent } from './pages/orders/order-types/diagnostic-order/diagnostic-plan-item/diagnostic-plan-item.component';
import { AnalystPaginationListDiagnosticSamplesComponent } from './pages/orders/analyst-orders-pagination-list-diagnostic-samples/analyst-pagination-list-diagnostic-samples.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { PreloadingService } from '../shared/services/preloading.service';
import { AdminModule } from '../admin/admin.module';
import { CalendarComponent } from '../common/shared/calendar/calendar.component';
import { SimpleOrderContainerComponent } from '../admin/pages/orders/order-types/simple-order/simple-order-container/simple-order-container.component';
import { DiagnosticOrderContainerComponent } from '../admin/pages/orders/order-types/diagnostic-order/diagnostic-order-container/diagnostic-order-container.component';

const routes: Routes = [
  {
    path: `analyst`, component: AnalystContainerComponent, canActivate: [AuthGuard], children: [
      {path: 'dashboard', component: AnalystOrdersComponent, canActivate: [AuthGuard]},
      {path: 'profile', component: AnalystProfileComponent, canActivate: [AuthGuard]},
      {path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard]},
      {path: 'orders/simple-order/:id', component: SimpleOrderContainerComponent, canActivate: [AuthGuard]},
      {path: 'orders/diagnostic-order/:id', component: DiagnosticOrderContainerComponent, canActivate: [AuthGuard]}
    ]
  },
];

@NgModule({
  declarations: [
    AnalystOrdersComponent,
    AnalystContainerComponent,
    AnalystProfileComponent,
    AnalystProfileFormComponent,
    AnalystOrdersPaginationListComponent,
    AnalystOrdersPaginationListSamplesComponent,
    SimpleOrderComponent,
    DiagnosticOrderComponent,
    DiagnosticPlanItemComponent,
    AnalystPaginationListDiagnosticSamplesComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AdminModule,
    ReactiveFormsModule,
    RadioButtonModule,
    FormsModule,
    CommonModule,
    PaginatorModule,
    TooltipModule,
    CheckboxModule,
    TableModule
  ],
  providers: [
    AnalystService,
    {provide: APP_INITIALIZER, useFactory: preloadServiceFactory, deps: [PreloadingService], multi: true},

  ],
  exports: [
    SimpleOrderComponent
  ]
})

export class AnalystModule {
}

export function preloadServiceFactory(service: PreloadingService) {
  return () => service.load();
}
