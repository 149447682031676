import { Component, Input, OnInit } from '@angular/core';
import { Statistic } from '../../../models/Statistic';
import { Calendar } from '../../../models/Calendar';

@Component({
  selector: 'app-prolabo-order-calendar',
  templateUrl: './order-calendar.component.html',
  styleUrls: ['./order-calendar.component.scss']
})
export class OrderCalendarComponent implements OnInit {

  @Input() items: Calendar[];

  @Input() dashboard: boolean;

  @Input() statistics: Statistic[];

  @Input() firstItem: Calendar;

  constructor() { }

  ngOnInit(): void {
  }

}
