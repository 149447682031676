import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AdminStatics } from '../../../statics/admin-statics';
import { FunctionsService } from '../../../services/functions.service';
import { DiagnosticOrder } from '../../../models/diagnostic-order-response';
import { DatePipe } from '@angular/common';
import { Laboratory } from '../../../../models/laboratorie';

@Component({
  selector: 'app-prolabo-orders-pagination-list',
  templateUrl: './pagination-list.component.html',
  styleUrls: ['./pagination-list.component.scss']
})
export class OrdersPaginationListComponent implements OnInit, OnChanges {

  @Input() items: DiagnosticOrder[];
  @Input() itemValues: any;
  @Input() headers: any[];
  @Input() totalItems;
  @Input() total;
  @Input() routerLinkDetails;
  @Input() filter: number;
  @Input() filterItems: [];
  @Input() laboratories: Laboratory[];
  @Input() selectedLaboratory: string;

  @Output() sortValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() limitValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterBy: EventEmitter<number> = new EventEmitter<number>();
  @Output() filterDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterByDate: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() filterByOrderState: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() filterByOrderCity: EventEmitter<string[]> = new EventEmitter<string[]>();


  sortHeaderValues = [];
  filterValues = [];
  filterByState = [];
  filterByCity = [];
  page = 0;
  numberRows = 10;

  totalSteps = 4;

  displayModal = false;
  itemToDelete = null;

  selectedSample: FormControl = new FormControl();
  selectedState: FormControl = new FormControl();
  selectedCity: FormControl = new FormControl(this.selectedLaboratory);
  cityIsSelected = false;
  sampleIsSelected = false;
  stateIsSelected = false;
  rangeDates: Date[];
  sortingDates: string[];


  constructor(private router: Router,
              private datePipe: DatePipe,
              public functions: FunctionsService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.headers.forEach(header => {
      this.sortHeaderValues.push({param: header[AdminStatics.sortLabel], asc: true});
    });

    this.filterByState = [
      {text: this.translate.instant('ALL'), id: -1, value: -1},
      {text: this.translate.instant('CREATION'), id: 0, value: 0},
      {text: this.translate.instant('ORDERED'), id: 1, value: 1},
      {text: this.translate.instant('ANALYSING'), id: 2, value: 2},
      {text: this.translate.instant('ANALYSED'), id: 3, value: 3},
      {text: this.translate.instant('FINISHED'), id: 4, value: 4},
      {text: this.translate.instant('CANCELLED'), id: 5, value: 5}
    ];

    this.cityIsSelected = this.selectedCity.value !== -1 && this.selectedCity.value;
    this.sampleIsSelected = this.selectedSample.value !== -1 && this.selectedSample.value;
    this.stateIsSelected = this.selectedState.value !== -1 && this.selectedState.value;
  }

  ngOnChanges() {
    if (this.filterItems && this.filterItems.length > 0) {
      this.filterValues = this.functions.getFilterItemValues(this.filterItems);
    }
    if (this.laboratories && this.laboratories.length > 0) {
      this.filterByCity = new Array(0);
      this.filterByCity.push({text: this.translate.instant('ALL'), id: -1, value: -1});
      this.laboratories.forEach(x => {
        this.filterByCity.push({text: x.city, id: x.id, value: x.id});
      });
    }
    this.cityIsSelected = this.selectedCity.value !== -1;
    this.sampleIsSelected = this.selectedSample.value !== -1 && this.selectedSample.value;
    this.stateIsSelected = this.selectedState.value && this.selectedState.value !== -1 || this.selectedState.value === 0;

  }

  getTotalItemName(name: string) {
    let result;
    switch (name) {
      case 'Quantitative buffer':
        result = this.translate.instant('QUANTITATIVE_BUFFER');
        break;
      case 'Qualitative buffer':
        result = this.translate.instant('QUALITATIVE_BUFFER');
        break;
      default:
        result = this.translate.instant(name.toUpperCase());
        break;
    }

    return result;
  }

  sortByLabel(param: string) {
    if (param !== 'latest_state' && param !== 'city') {
      this.clearOtherValues(param);
      const sortValue = this.sortHeaderValues.filter(x => x.param === param)[0];
      sortValue.asc = !sortValue.asc;
      this.sortValue.emit(sortValue);
    }
  }


  deleteItem() {
    this.itemDelete.emit(this.itemToDelete);
    this.displayModal = false;
    this.itemToDelete = null;
  }

  openConfirmDialog(id) {
    this.displayModal = true;
    this.itemToDelete = id;
  }

  closeConfirmDialog() {
    this.itemToDelete = null;
    this.displayModal = false;
  }

  getLastHeader() {
    return this.headers[this.headers.length - 1];
  }

  clearOtherValues(param) {
    this.sortHeaderValues.forEach(header => {
      if (param !== header.param) {
        header.asc = true;
      }
    });
  }

  getAscValueForHeader(header) {
    return this.sortHeaderValues.filter(x => x.param === header)[0].asc;
  }

  onPageChange(event) {
    this.page = event.page;
    this.pageValue.emit(event.page);
  }

  onLimitChange(event) {
    this.numberRows = event.value;
    this.page = 0;
    this.limitValue.emit(event.value);
  }

  navigate(id, type) {
    if (type === AdminStatics.simpleOrder) {
      this.router.navigate(['/admin/orders/simple-order', id]);
    }
    if (type === AdminStatics.diagnosticOrder) {
      this.router.navigate(['/admin/orders/diagnostic-order', id]);
    }
  }

  deleteFilter() {
    this.filterDelete.emit(true);
  }

  handleFilterDropdownChange(event) {
    this.filterBy.emit(event.value);
  }

  handleFilterByStateDropdownChange(event) {
    this.filterByOrderState.emit(event.value);
  }

  handleFilterByCityDropdownChange(event) {
    this.filterByOrderCity.emit(event.value);
  }

  dateChange($event: any) {
    this.sortingDates = new Array(0);
    let countNotNull = 0;
    this.rangeDates.forEach(x => {
      if (x != null) {
        countNotNull++;
        this.sortingDates.push(this.datePipe.transform(x, 'yyyy-MM-dd'));
      }
    });
    if (countNotNull === 2) {
      this.filterByDate.emit(this.sortingDates);
    }
  }
}
