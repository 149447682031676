<div class="p-grid" *ngIf="order != null && this.scorecardModels != null">
  <div class="p-col-12 p-xl-8">
    <div class="main-title-container {{ stepDone ? 'secondary-container' : null }}">
      <div class="p-grid">
        <div class="p-col-12 p-md-6 title-block">
          <img src="../../../../../../../assets/icons/board-white.svg">
          <span class="order-group">
            <p class="order-language">{{'DIAGNOSTIC.ORDER' | translate}} - {{order?.user?.company}}</p>
            <p class="order-date {{ stepDone ? 'white-order-date' : null }}">
              {{order?.order_number}} {{ 'CREATED_ON' | translate }} {{order?.created_at | date }}</p>
          </span>
        </div>
        <div class="p-col-12 p-md-6 buttons-block">
          <div class="p-grid">
            <div class="p-col-6">
              <ng-container
                *ngIf="order.latest_state.name != 'admin.cancelled' && order.latest_state.name !='client.cancelled'">
                <app-prolabo-icon-button [icon]="'close-white'"
                                         [name]="'CANCEL'"
                                         type="{{ stepDone ? 'secondary' : 'primary-radius' }}"
                                         class="{{ stepDone ? 'white-border' : null }}"
                                         (click)="openConfirmDialog3()">
                </app-prolabo-icon-button>
              </ng-container>
              <ng-container
                *ngIf="order.latest_state.name == 'admin.cancelled' || order.latest_state.name =='client.cancelled'">
                <app-prolabo-icon-button [icon]="'check'"
                                         [name]="'REACTIVATE_ORDER'"
                                         class="secondary"
                                         (click)="openConfirmDialog2()">
                </app-prolabo-icon-button>
              </ng-container>
            </div>
            <div class="p-col-6" *ngIf="step1">
              <app-prolabo-icon-button icon="check"
                                       type="secondary"
                                       name="VALIDATE"
                                       (OnClick)="submitOrder()">
              </app-prolabo-icon-button>
            </div>
            <div class="p-col-6" *ngIf="step2">
              <app-prolabo-icon-button icon="check"
                                       type="secondary"
                                       name="VALIDATE"
                                       (OnClick)="submitStep2()">
              </app-prolabo-icon-button>
            </div>
            <div class="p-col-6" *ngIf="step4">
              <app-prolabo-icon-button icon="check"
                                       type="secondary"
                                       name="VALIDATE"
                                       (OnClick)="submitStep3()">
              </app-prolabo-icon-button>
            </div>
            <div class="p-col-6" *ngIf="stepDone">
              <app-prolabo-icon-button icon="check"
                                       type="secondary"
                                       name="NEW_VERSION"
                                       class="white-border"
                                       (OnClick)="createNewVersion()">
              </app-prolabo-icon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="final-step-container" *ngIf="stepDone">
      <div class="p-col-12 p-md-4">
        <app-prolabo-order-final-step-container [title]="'REPORT'"
                                                [secondButtonTitle]="'SEND_MAIL_REPORT'"
                                                [secondButtonIcon]="'upload2'"
                                                [textReport]="'REPORT_SENT'"
                                                [firstButtonIcon]="'printer'"
                                                [firstButtonTitle]="order?.pdf_report_path === null ? 'EXPORT_REPORT': 'PRINT'"
                                                [reportsDate]="generatedReportDate"
                                                [disableFirstButton]="disablePrint"
                                                [disableSecondButton]="!order?.pdf_report_path"
                                                (firstButton)="printReportClicked()"
                                                (secondButton)="sendReportByMail(0)"
                                                [thirdButtonIcon]="'printer'"
                                                [thirdButtonTitle]="'REGENERATE'"
                                                [disableThirdButton]="!order?.pdf_report_path"
                                                (thirdButton)="regenerateReport('basic')">
        </app-prolabo-order-final-step-container>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-order-final-step-container [title]="'REPORT_AFTER_WORK'"
                                                [secondButtonTitle]="'SEND_MAIL_REPORT'"
                                                [secondButtonIcon]="'upload2'"
                                                [textReport]="'REPORT_SENT'"
                                                [firstButtonIcon]="'printer'"
                                                [firstButtonTitle]="order?.pdf_at_report_path === null ? 'GENERATE_REPORT_AFTER_WORK': 'PRINT'"
                                                [reportsDate]="generatedReportAtDate"
                                                [disableFirstButton]="disablePrint"
                                                [disableSecondButton]="!order?.pdf_at_report_path"
                                                (firstButton)="printAtReportClicked()"
                                                (secondButton)="sendReportByMail(1)"
                                                [thirdButtonIcon]="'printer'"
                                                [thirdButtonTitle]="'REGENERATE'"
                                                [disableThirdButton]="!order?.pdf_at_report_path"
                                                (thirdButton)="regenerateReport('after-work')"
        >
        </app-prolabo-order-final-step-container>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-order-final-step-container [title]="'BILLING'"
                                                [firstButtonIcon]="'layer'"
                                                [textInvoices]="'INVOICES_GENERATED'"
                                                [firstButtonTitle]="'GENERATE_INVOICES'"
                                                [invoicesNumber]="numGeneratedInvoices"
                                                [invoicesDate]="generatedInvoicesDate"
                                                (firstButton)="generateInvoice()"
                                                [disableFirstButton]="isAnalyst"
                                                [secondButtonIcon]="'printer'"
                                                [secondButtonTitle]="order.invoice_generated === 1 ? order.invoices.invoice?.name : 'PRINT'"
                                                [disableSecondButton]="order.invoice_generated === 0 || isAnalyst"
                                                (secondButton)="printInvoice()">
        </app-prolabo-order-final-step-container>
      </div>
    </div>
    <app-prolabo-order-step-container [step1]="stepCounter1" [step2]="stepCounter2"
                                      [step3]="stepCounter3" [step4]="stepCounter4">
    </app-prolabo-order-step-container>
    <div class="p-grid">
      <div class="p-col-12 plans">
        <app-prolabo-diagnostic-plan-item [new]="true"
                                          [order]="order"
                                          (addPlan)="planCreate($event)">
        </app-prolabo-diagnostic-plan-item>
        <ng-container *ngFor="let plan of plans">
          <app-prolabo-diagnostic-plan-item [new]="false"
                                            [plan]="plan"
                                            [step1]="step1"
                                            [step2]="step2"
                                            [step3]="step3"
                                            [step4]="step4"
                                            [stepDone]="stepDone"
                                            (deletePlan)="openConfirmationDialog($event)">
          </app-prolabo-diagnostic-plan-item>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="samplesByType && samplesByType.length > 0">
      <ng-container *ngFor="let samples of samplesByType">
        <p-card>
          <div class="p-grid spacing-bottom">
            <div class="p-col-12 p-md-6 title">
              <p-header>
                {{ 'SAMPLING' | translate }}
                {{ functions.getTypeName(samples[0]) | translate }} ({{ samples[1]?.samples.length }})
              </p-header>
              <ng-container *ngIf="deadlines.hasOwnProperty(samples[0])" class="deadline-title">
                <div class="p-grid deadline-container" *ngIf="!stepDone">
                  <div class="p-col-12">
                    <app-prolabo-dropdown [options]="deadlines[samples[0]]"
                                          class="deadline-dropdown"
                                          (OnValueChange)="updateDeadlineValue($event, samples[0])"
                                          [defaultValue]="findDeadline(samples[0]) ? findDeadline(samples[0]).deadline_id : null"
                                          placeholder="DEADLINE"></app-prolabo-dropdown>
                  </div>
                </div>
                <p *ngIf="stepDone" class="deadline-title">
                  {{getDeadline(order.deadlines ? order.deadlines[0].deadline_id : null, samples[0]).text | translate}}
                </p>
              </ng-container>
            </div>
            <div class="p-col-12 p-md-2" *ngIf="samples[0] === '2'">
              <ng-container *ngIf="order.labels_path != null">
                <app-prolabo-icon-button
                  icon="printer"
                  type="primary"
                  [name]="'ETIQUETTE'"
                  (OnClick)="openEtiquets(order.labels_path)">
                </app-prolabo-icon-button>
              </ng-container>
            </div>
            <div class="p-col-12 p-md-2">
              <ng-container *ngIf="step1 || step2 || stepDone">
                <app-prolabo-icon-button
                  icon="add"
                  type="primary"
                  name="ADD.NEW.SAMPLE"
                  (OnClick)="createSample(samples[0])">
                </app-prolabo-icon-button>
              </ng-container>
            </div>
            <div class="p-col-12 p-md-2">
              <ng-container *ngIf="step3 || step4 || stepDone">
                <app-prolabo-icon-button
                  icon="check"
                  type="secondary"
                  [name]="samples[1].analyzed ? 'ANALYSED' : 'ANALYSE' "
                  *ngIf="samples[0] !== '-1' && samples[0] !== '2'"
                  (OnClick)="openScoreCard(samples[1]?.samples, samples[1].analyzed)">
                </app-prolabo-icon-button>
                <app-prolabo-icon-button
                  icon="check"
                  type="secondary"
                  [name]="samples[1].analyzed ? 'ANALYSED' : 'ANALYSE' "
                  *ngIf="samples[0] === '2' && canBeAnalyzed"
                  (OnClick)="openScoreCard(samples[1]?.samples, samples[1].analyzed)">
                </app-prolabo-icon-button>
              </ng-container>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <ng-container *ngIf="order">
                <app-prolabo-pagination-list-diagnostic-samples
                  [items]="samples[1]?.samples"
                  [step1]="step1"
                  [step2]="step2"
                  [step3]="step3"
                  [step4]="step4"
                  [stepDone]="stepDone"
                  [order]="order"
                  [sampleType]="samples[0]"
                  [user]="false"
                  [headers]="stepDone ? samplesHeader : samplesHeaderNoEmpty"
                  [sampleAnalyzed]="samples[1]?.analyzed"
                  [scoreCardModels]="getScoreCardModelHeaders(samples[0])"
                  (riskModal)="riskAssessmentForSample($event)"
                  (itemToEdit)="editSample($event)"
                  (itemDelete)="handleDelete($event)">
                </app-prolabo-pagination-list-diagnostic-samples>
              </ng-container>
            </div>
          </div>
        </p-card>
      </ng-container>
    </ng-container>

    <ng-container>
      <p-card *ngIf="samplesByType && samplesByType.length == 0">
        <div class="p-grid spacing-bottom">
          <div class="p-col-12 p-md-8 title">
            <p-header>
              {{ 'SAMPLING' | translate }}
            </p-header>
          </div>
          <div class="p-col-12 p-md-4">
            <app-prolabo-icon-button
              icon="add"
              type="secondary"
              name="ADD.NEW.SAMPLE"
              [disabled]="!plans || plans.length == 0"
              (OnClick)="createSample(null)">
            </app-prolabo-icon-button>
          </div>
        </div>
        <div class="p-grid">
        </div>
      </p-card>
    </ng-container>
  </div>


  <div class="p-col-12 p-xl-4" *ngIf="order">
    <app-prolabo-diagnostic-order-administration [submitClicked]="initiatedSubmit"
                                                 [additionalInformationOptions]="additionalInfoOptions"
                                                 [order]="order"
                                                 [isUser]="false"
                                                 [invoiceTypes]="invoiceTypes"
                                                 [laboratories]="laboratories"
                                                 [visitInformationError]="visitInformationError"
                                                 (clientEvent)="handleClientEvent($event)"
                                                 (visitInfoUpdate)="updateVisitInfo($event)"
                                                 (orderEvent)="handleOrderError($event)"
                                                 (requestGetOrder)="getOrderDetails(order.id)"
                                                 (orderFormEvent)="handleOrderFormEvent($event)">
    </app-prolabo-diagnostic-order-administration>
    <app-prolabo-diagnostic-order-report [submitClicked]="initiatedSubmit"
                                         (emailEvents)="handleEmailEvent($event)"
                                         (email)="handleEmailError($event)"
                                         (lang)="handleLangError($event)"
                                         [getOrderRequested]="getOrderInitiated"
                                         (languageEvent)="handleLanguageEvent($event)" [order]="order">
    </app-prolabo-diagnostic-order-report>
  </div>
</div>
<app-prolabo-confirmation-modal
  [display]="displayModal"
  [header]="'MODAL_CONFIRMATION'"
  (confirmClicked)="deleteSelectedPlan()"
  (cancelClicked)="displayModal = false;">
</app-prolabo-confirmation-modal>

<app-prolabo-confirmation-modal
  [display]="displayModal2"
  [header]="'MODAL_CONFIRMATION'"
  (confirmClicked)="reActivateOrder()"
  (cancelClicked)="closeConfirmDialog2()">
</app-prolabo-confirmation-modal>

<app-prolabo-confirmation-modal
  [display]="displayModal3"
  [header]="'MODAL_CONFIRMATION_CANCEL'"
  (confirmClicked)="cancelOrder()"
  (cancelClicked)="closeConfirmDialog2()">
</app-prolabo-confirmation-modal>
