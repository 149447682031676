<p-card *ngIf="laboratories.length >0 && invoiceTypes">
  <div class="p-grid spacing-bottom">
    <div class="p-col-6 title">
      <p-header>{{'ADMINISTRATION' | translate}}</p-header>
    </div>
  </div>
  <form [formGroup]="administrationForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-lg-12">
        <app-prolabo-dropdown [options]="invoiceTypes"
                              (OnValueChange)="updateInvoiceTypes($event)"
                              [defaultValue]="currentUser.invoice_type_id"
                              placeholder="INVOICE_TYPE_PROFILE"></app-prolabo-dropdown>
        <app-prolabo-input [for]="'initials'"
                           [id]="'initials'"
                           [keyword]="'INITIALS'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="currentUser.initials"
                           [required]="false"
                           formControlName="initials">
        </app-prolabo-input>
        <app-prolabo-input [for]="'notes'"
                           [id]="'notes'"
                           [keyword]="'NOTES'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="currentUser.notes"
                           [required]="false"
                           class="input"
                           formControlName="notes">
        </app-prolabo-input>
        <app-prolabo-dropdown [options]="laboratories"
                              (OnValueChange)="updateLaboratoriesEvent($event)"
                              [defaultValue]="+currentUser.laboratory_id"
                              placeholder="BRANCH">
        </app-prolabo-dropdown>
      </div>
    </div>
  </form>
  <div class="p-grid">
    <div class="p-col-4"></div>
    <div class="p-col-4"></div>
    <div class="p-col-4">
      <app-prolabo-icon-button
        type="secondary"
        [icon]="'check'"
        [name]="'VALIDATE'"
        (click)="validate()"></app-prolabo-icon-button>
    </div>
  </div>
</p-card>
