import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { VerifyAccountComponent } from './auth/verify-account/verify-account.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoContainerComponent } from './shared/logo-container/logo-container.component';
import { IconButtonComponent } from 'src/app/shared/components/icon-button/icon-button.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { SideBarComponent } from './shared/side-bar/side-bar.component';
import { UserStyleComponent } from './user-style/user-style.component';
import { UserResponsibleComponent } from './user-responsible/user-responsible.component';
import { SimpleOrderAdministrationComponent } from './orders/simple-order-administration/simple-order-administration.component';
import { SimpleOrderReportComponent } from './orders/simple-order-report/simple-order-report.component';
import { OrderTarifsComponent } from './orders/order-tarifs/order-tarifs.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarComponent } from './shared/calendar/calendar.component';
import { RippleModule } from 'primeng/ripple';
import { ChipModule } from 'primeng/chip';
import {InputTextareaModule} from 'primeng/inputtextarea';

const routes: Routes = [
  {
    path: 'admin', children: [
      {path: 'login', component: LoginComponent},
      {path: 'register', component: RegisterComponent},
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset-password/:id', component: ResetPasswordComponent},
  {path: 'verify-account/:id', component: VerifyAccountComponent},
  {path: 'order-redirect/:id', component: RedirectComponent},
  {path: 'set-password/:id', component: ResetPasswordComponent},
];

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    VerifyAccountComponent,
    ResetPasswordComponent,
    LogoContainerComponent,
    IconButtonComponent,
    RedirectComponent,
    BreadcrumbsComponent,
    SideBarComponent,
    UserStyleComponent,
    UserResponsibleComponent,
    SimpleOrderAdministrationComponent,
    SimpleOrderReportComponent,
    OrderTarifsComponent,
    CalendarComponent,
  ],
    imports: [
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        FormsModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        CheckboxModule,
        TooltipModule,
        RippleModule,
        ChipModule,
        InputTextareaModule,
    ],
  exports: [
    LogoContainerComponent,
    IconButtonComponent,
    BreadcrumbsComponent,
    SideBarComponent,
    UserStyleComponent,
    UserResponsibleComponent,
    SimpleOrderAdministrationComponent,
    SimpleOrderReportComponent,
    OrderTarifsComponent
  ],
  providers: []

})

export class CommonModule {
}
