<div class="clients-container">
  <app-dialog-header
    title="{{ 'EVALUATION' | translate }}{{ sample?.sample_type_id === 2 ? ('ACCORDING_TO_FACH' | translate) : '' }}{{' ('}}{{'SAMPLE' | translate | lowercase}}{{' ' + sample.sample_number + ')'}}"
    (iconClick)="ref.close()"></app-dialog-header>
  <form [formGroup]="evaluationForm">
    <div class="p-grid content">
      <div class="p-col-12">
        <p class="text">{{'PICTURE_EXPLANATION_1' | translate }}</p>
      </div>
      <div class="p-col-12" id="coordinates-img" *ngIf="photo">
        <img id="image" [src]="photo" (click)="clickImage($event)">
        <div id="circle-add">
          <span>{{ sampleNumber }}</span>
        </div>
      </div>
      <div class="p-col-12" *ngIf="sample.sample_type_id === 2">
        <div *ngIf="showImg">
          <img src="../../../../../../assets/images/report_value_img.png"/>
        </div>
      </div>
      <div class="p-col-12" *ngIf="sample?.sample_type_id === 2">
        <div class="p-grid">
          <div class="p-col-6 dropdown">
            <app-prolabo-dropdown [options]="asbestosQuantity"
                                  [defaultValue]="sample?.report_value?.asbestos_quantity_id"
                                  (OnValueChange)="updateAsbestosQuantity($event)"
                                  placeholder="ASBESTOS_CONTENT">
            </app-prolabo-dropdown>
          </div>
          <div class="p-col-6 dropdown">
            <app-prolabo-dropdown [options]="surfaceState"
                                  [defaultValue]="sample?.report_value?.surface_state_id"
                                  (OnValueChange)="updateSurfaceState($event)"
                                  placeholder="SURFACE_CONDITION">
            </app-prolabo-dropdown>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-6 dropdown">
            <app-prolabo-dropdown [options]="externalInfluence"
                                  [defaultValue]="sample?.report_value?.external_influence_id"
                                  (OnValueChange)="updateExternalInfluence($event)"
                                  placeholder="EXTERNAL_INFLUENCE">
            </app-prolabo-dropdown>
          </div>
          <div class="p-col-6">
            <div class="p-grid">
              <div class="p-col-10 dropdown">
                <app-prolabo-dropdown [options]="placeUtilisation"
                                      [defaultValue]="sample?.report_value?.place_utilisation_id"
                                      (OnValueChange)="updatePlaceUtilisation($event)"
                                      placeholder="PREMISES_USE">
                </app-prolabo-dropdown>
              </div>
              <div class="p-col-2 question-mark">
                <app-prolabo-icon class="icon-white question-mark"
                                  (click)="showImg = !showImg"
                                  [pTooltip]="'Tableau'"
                                  [tooltipPosition]="'top'"
                                  icon="question">
                </app-prolabo-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-6 dropdown">
            <app-prolabo-dropdown [options]="vdiMeasures"
                                  [defaultValue]="sample?.report_value?.vdiMeasures"
                                  (OnValueChange)="updateVdiMeasures($event)"
                                  placeholder="VDI_MEASUREMENT_BEFORE_WORK">
            </app-prolabo-dropdown>
          </div>
          <div class="p-col-6 dropdown">
            <div class="card">
              <p>{{ 'RISK_ASSESSMENT' | translate | uppercase }}</p>
              <p class="semi-bold">{{ riskValue }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 dropdown">
        <app-prolabo-dropdown [options]="risk1Dropdown"
                              *ngIf="riskValue === 'I'"
                              [defaultValue]="findIMValue(sample?.report_value?.immediate_measurements, risk1Dropdown)"
                              (OnValueChange)="updateImmediateMeasurements($event, risk1Dropdown)"
                              placeholder="IMMEDIATE_ACTIONS">
        </app-prolabo-dropdown>
        <app-prolabo-dropdown [options]="risk2Dropdown"
                              *ngIf="riskValue === 'II'"
                              [defaultValue]="findIMValue(sample?.report_value?.immediate_measurements, risk2Dropdown)"
                              (OnValueChange)="updateImmediateMeasurements($event, risk2Dropdown)"
                              placeholder="IMMEDIATE_ACTIONS">
        </app-prolabo-dropdown>
        <app-prolabo-dropdown [options]="risk3Dropdown"
                              *ngIf="riskValue === 'III'"
                              [defaultValue]="findIMValue(sample?.report_value?.immediate_measurements, risk3Dropdown)"
                              (OnValueChange)="updateImmediateMeasurements($event, risk3Dropdown)"
                              placeholder="IMMEDIATE_ACTIONS">
        </app-prolabo-dropdown>
        <div *ngIf="showOtherField" class="otherInput">
          <app-prolabo-input [for]="'other'"
                             [id]="'other'"
                             [required]="false"
                             [keyword]="'OTHER'"
                             [type]="'text'"
                             [inputType]="'basic'"
                             formControlName="immediate_measurements">
          </app-prolabo-input>
        </div>
      </div>
      <div class="p-col-12 dropdown" *ngIf="sample?.sample_type_id === 2">
        <app-prolabo-dropdown [options]="sanitation"
                              [defaultValue]="sample?.report_value?.sanitation_id"
                              (OnValueChange)="updateSanitation($event)"
                              placeholder="SANITATION">
        </app-prolabo-dropdown>
      </div>
      <div class="p-col-12" *ngIf="sample?.sample_type_id !== 2">
        <app-prolabo-input [for]="'sanitation_txt'"
                           [id]="'sanitation_txt'"
                           [required]="false"
                           [keyword]="'SANITATION'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="sample?.report_value?.sanitation_txt ? sample?.report_value?.sanitation_txt : 'Travaux à réaliser par une entreprise spécialisée'"
                           formControlName="sanitation_txt">
        </app-prolabo-input>
      </div>
    </div>
  </form>
  <div class="p-grid">
    <div class="p-col-8"></div>
    <div class="p-col-4">
      <app-prolabo-icon-button [name]="'VALIDATE'"
                               (click)="save()"
                               [icon]="'check'"
                               type="secondary">
      </app-prolabo-icon-button>
    </div>
  </div>
</div>
