import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models';
import { DropdownModel } from '../../../../shared/components/dropdown/dropdown.model';

@Component({
  selector: 'app-prolabo-users-administration',
  templateUrl: './users-administration.component.html',
  styleUrls: ['./users-administration.component.scss']
})
export class UsersAdministrationComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() invoiceTypes: DropdownModel[];
  @Input() currentUser: User;
  @Input() state: string;
  @Input() laboratories: DropdownModel[] = [];
  @Input() isUser: boolean;

  administrationForm: FormGroup;

  @Output() validateClicked = new EventEmitter<boolean>();
  @Output() administrationFormEvent = new EventEmitter<FormGroup>();
  @Output() administrationOriginalFormEvent = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.administrationForm = this.formBuilder.group({
      initials: [this.user.initials, !this.isUser ? Validators.required : []],
      notes: [this.user.notes],
      laboratory_id: [this.user.laboratory_id, !this.isUser ? Validators.required : []],
      invoice_type_id: [this.user.invoice_type_id, !this.isUser ? Validators.required : []]
    });
    this.administrationOriginalFormEvent.emit(this.administrationForm.value);
  }

  validate() {
    this.validateClicked.emit(true);
    this.administrationFormEvent.emit(this.administrationForm);
  }

  updateLaboratoriesEvent($event: any) {
    this.administrationForm.patchValue({
      laboratory_id: $event
    });
  }

  updateInvoiceTypes($event: string) {
    this.administrationForm.patchValue({
      invoice_type_id: $event
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isUser) {
      if (this.isUser) {
        this.administrationForm.get('initials').setValidators([Validators.required]);
        this.administrationForm.get('laboratory_id').setValidators([Validators.required]);
        this.administrationForm.get('invoice_type_id').setValidators([Validators.required]);
      } else {
        this.administrationForm.get('initials').clearValidators();
        this.administrationForm.get('laboratory_id').clearValidators();
        this.administrationForm.get('invoice_type_id').clearValidators();
      }
    }
  }
}
