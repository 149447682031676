<p-card *ngIf="order">
  <div class="p-grid top-header">
    <div class="p-col-10 title">
      <p-header>{{'PRICES' | translate}}</p-header>
    </div>
  </div>
  <p class="main-title">{{'ASBESTOS.EXPRESS' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.asbestos_express}}</p>

  <p class="main-title">{{'ASBESTOS.STANDARD' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.asbestos_standard}}</p>

  <p class="main-title">{{'ASBESTOS.URGENT' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.asbestos_urgent}}</p>

  <p class="main-title">{{'ASBESTOS.LONG' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.asbestos_long_duration}}</p>

  <p class="main-title">{{'VDI_TEXT' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.vdi}}</p>

  <p class="main-title">{{'QUANTITATIVE.INPUT' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.buffer_quantitative}}</p>

  <p class="main-title">{{'QUALITATIVE.INPUT' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.buffer_qualitative}}</p>

  <p class="main-title">{{'HAP_TEXT' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.hap}}</p>

  <p class="main-title">{{'PCB_TEXT' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.pcb}}</p>

  <p class="main-title">{{'LEAD_TEXT' | translate | uppercase}}</p>
  <p class="sub-main-title">{{order?.prices.lead}}</p>

</p-card>
