<form pTooltip="{{ placeholder ? (placeholder | translate) + '*' : ''}}">
  <span class="p-label">
      <p>
        {{ placeholder | translate | uppercase }}*
      </p>
    </span>
  <p-dropdown [options]="options"
              [formControl]="selectedOptionFormControl"
              (onChange)="onChange($event)"
              (onFilter)="onFilter($event)"
              (onHide)="onHide($event)"
              [selectId]="defaultValue"
              optionLabel="id"
              optionValue="id"
              filterBy="text"
              [inputId]="inputId"
              [placeholder]="defaultValue"
              resetFilterOnHide="true"
              [filter]="true">
    <ng-template let-option pTemplate="item">
      <div class="flex align-items-center gap-2" *ngIf="option.isDeletable ==1">
        <div><img [src]="icon" style="width:15px;"
                  [class]="'flag flag-' + option.text.toLowerCase()"
                  (click)="deleteClicked(option.id)">
          {{option.text  | translate}}</div>
      </div>
      <ng-container *ngIf="option.isDeletable ==0">
        <div>{{option.text  | translate}}</div>
      </ng-container>
      <ng-container *ngIf="option.isDeletable== null">
        <div>{{option.text  | translate}}</div>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="selectedItem" let-selectedOption>
      {{selectedOption.text  | translate}}
    </ng-template>
  </p-dropdown>
</form>
