import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, @Inject(UserService) private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return !!this.userService.currentUser();
  }
}
