import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SimpleOrder, SimpleSample} from '../../../../../admin/models/simple-order-response';
import {Client} from '../../../../../admin/models/client';
import {DropdownModel} from '../../../../../shared/components/dropdown/dropdown.model';
import {AdminStatics} from '../../../../../admin/statics/admin-statics';
import {User} from '../../../../../admin/models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from 'primeng/dynamicdialog';
import {ToastService, UserService} from '../../../../../shared/services';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ResourcesService} from '../../../../../shared/services/resources.service';
import {AdminOrdersService} from '../../../../../admin/services/admin-orders.service';
import {AdminSimpleSamplesService} from '../../../../../admin/services/admin-simple-samples.service';
import {AdminSimpleOrdersService} from '../../../../../admin/services/admin-simple-orders.service';
import {
  SampleCreateModalComponent
} from '../../../../../admin/pages/orders/modals/sample-create-modal/sample-create-modal.component';
import {FormGroup} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {Type} from '../../../../../admin/models/type';
import {LaboratoryAddressComponent} from '../../order-modals/laboratory-address/laboratory-address.component';
import {InvoiceType, Laboratory} from '../../../../../models/laboratorie';
import {ProlaboAnalyseService} from '../../../../services/prolabo-analyse.service';
import {environment} from '../../../../../../environments/environment';
import {Deadline} from '../../../../../models/deadline';
import {
  StepThreeModalDiagnosticComponent
} from '../../../../../admin/pages/orders/modals/step-three-diagnostic-modal/step-three-diagnostic-modal.component';
import {FunctionsService} from '../../../../../admin/services/functions.service';
import {
  RegenerateInvoiceComponent
} from '../../../../../admin/pages/orders/modals/regenerate-invoice/regenerate-invoice.component';
import {BexioService} from '../../../../../common/services/bexio.service';
import {
  DeadlineConfirmationComponent
} from '../../order-modals/deadline-confirmation/deadline-confirmation.component';
import {UpdateDateModalComponent} from '../../../../../admin/pages/orders/modals/update-date-modal/update-date-modal.component';

@Component({
  selector: 'app-prolabo-user-simple-order-container',
  templateUrl: './simple-order-user-container.component.html',
  styleUrls: ['./simple-order-user-container.component.scss']
})
export class SimpleOrderUserContainerComponent implements OnInit {
  order: SimpleOrder;
  clients: Client[];
  selectedClient: Client;
  types: DropdownModel[] = [];
  formValid: boolean;
  samplesHeader = [
    {title: '', sortLabel: ''},
    {title: 'SAMPLE.ID', sortLabel: AdminStatics.id},
    {title: 'SAMPLE.NUMBER', sortLabel: AdminStatics.firstName},
    {title: 'SAMPLE.DESCRIPTION', sortLabel: AdminStatics.company}
  ];
  samples: SimpleSample[] = new Array(0);
  sampleTypeId: number = null;
  samplesByType = [];
  deadlines: Record<string, DropdownModel[]> = {};
  laboratories: DropdownModel[];
  invoiceTypes: DropdownModel[] = [];
  initiatedSubmit = false;
  clientAdded = false;
  samplesAdded = false;
  sampleForm = new FormData();
  step1: boolean;
  step2: boolean;
  step3: boolean;
  getOrderInitiated = false;
  stepDone: boolean;
  orderId: number;
  error = false;
  currentUser: User;
  numGeneratedInvoices = 0;
  generatedInvoicesDate = null;
  generatedReportDate = null;
  displayModal = false;

  @Output() orderState = new EventEmitter<string>();
  private originalTypes: Type[];

  constructor(private route: ActivatedRoute,
              private dialogService: DialogService,
              private analyseService: ProlaboAnalyseService,
              private toast: ToastService,
              private router: Router,
              private datePipe: DatePipe,
              private user: UserService,
              private translate: TranslateService,
              private resources: ResourcesService,
              private orders: AdminOrdersService,
              private samplesService: AdminSimpleSamplesService,
              public functions: FunctionsService,
              private bexio: BexioService,
              private ordersService: AdminSimpleOrdersService) {
    translate.onLangChange.subscribe(x => {
      this.setTypes(this.originalTypes);
    });
  }

  ngOnInit(): void {
    this.currentUser = this.user.getCurrentUser();
    if (this.resources.getInvoiceTypes()) {
      this.setInvoiceTypes(this.resources.getInvoiceTypes());
    } else {
      this.setInvoiceTypes(this.resources.backupInvoiceTypes);
    }
    this.route.paramMap.subscribe(snapshot => {
      this.orderId = +snapshot.get(AdminStatics.id);
      this.getOrderDetails(this.orderId).then(() => {
      });
    });
    this.route.queryParams
      .subscribe(params => {
          this.sampleTypeId = params.sample_type_id;
        }
      );
  }

  private setInvoiceTypes(invoiceTypes: InvoiceType[]) {
    invoiceTypes.forEach(invoiceType => {
      this.invoiceTypes.push({id: invoiceType.id, text: invoiceType.slug, value: invoiceType.id});
    });
  }

  createSample(sampleTypeId: number = null) {
    const body = {
      order_id: this.order.id,
      sample_type_id: sampleTypeId ? sampleTypeId : this.types[1].id,
      no_prolabo: false,
      locale: this.resources.getLanguage().description
    };
    this.orders.getNextSampleId(body).subscribe(id => {
      let data;
      if (this.order.samples?.prolabo != null) {
        data = {
          order: this.order, types: this.types,
          sample_type_id: Object.keys(this.order.samples.prolabo),
          next_sample_id: id.next_sample_id,
          disabled: true,
          state: 'create'
        };
      } else {
        data = {
          order: this.order, types: this.types,
          sample_type_id: sampleTypeId ? sampleTypeId : this.types[1].id,
          next_sample_id: id.next_sample_id,
          disabled: false,
          state: 'create'
        };
      }
      const ref = this.dialogService.open(SampleCreateModalComponent, {
        showHeader: false,
        width: '60%',
        data
      });

      ref.onClose.subscribe(form => {
        if (form) {
          this.samplesService.createSample(form).subscribe((result: any) => {
            this.toast.showBottomCenterSuccess(result.message);
            this.samplesAdded = true;
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        }
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  public submitOrder() {
    let presentAsbestosSample = false;
    const asbestosSamples = this.samples.filter(sample => sample.sample_type_id === 2);
    if (asbestosSamples.length > 0) {
      presentAsbestosSample = true;
    }

    if (presentAsbestosSample) {
      const ref = this.dialogService.open(DeadlineConfirmationComponent, {
        showHeader: false,
        width: '60%',
        data: {deadlines: this.deadlines, deadlineId: this.order.deadlines ? this.order.deadlines[0].deadline_id : null}
      });
      ref.onClose.subscribe(res => {
        if (res !== -1) {
          const body = {
            order_id: this.order.id,
            deadline_id: res,
            locale: this.resources.getLanguage().description,
            sample_type_id: 2
          };
          this.orders.modifyDeadline(body).subscribe(result => {
            this.getOrderDetails(this.order.id).then(() => {
            });
            this.submitOrderAfterConfirm();
          }, error => {
            this.functions.showError(error);
          });
        }
      });
    } else {
      this.submitOrderAfterConfirm();
    }
  }

  private submitOrderAfterConfirm() {
    this.initiatedSubmit = true;
    setTimeout(() => {
      if (!this.error) {
        // @ts-ignore
        this.sampleForm.append('order_id', this.order.id);
        setTimeout(() => {
          this.ordersService.submitOrder(this.sampleForm).subscribe(result => {
            this.toast.showBottomCenterSuccess(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
            this.resources.userState.emit(this.order.latest_state.name);
            this.samplesAdded = false;
            this.clientAdded = false;
            this.initiatedSubmit = false;
            this.checkOrderValidity(this.order.latest_state.name);
            this.sampleForm = new FormData();
          }, error => {
            this.functions.showError(error);
            this.sampleForm = new FormData();
            this.initiatedSubmit = false;
          });
        }, 100);
      }
    }, 100);
  }

  public handleDelete($event: any) {
    const body = {
      sample_id: $event,
      locale: this.resources.getLanguage().description
    };
    this.samplesService.deleteSample(body).subscribe(result => {
      this.toast.showBottomCenterSuccess(result.message);
      this.getOrderDetails(this.orderId).then(() => {
        const sample = this.samples.find(s => s.id === $event);
        const index = this.samples.indexOf(sample, 0);
        if (index > -1) {
          this.samples.splice(index, 1);
        }
        this.samplesAdded = Object.keys(this.order.samples).length > 0;
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  public cancelOrder() {
    const body = {
      id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    this.displayModal = false;
    this.ordersService.cancelOrder(body).subscribe(result => {
      this.router.navigateByUrl('/user/dashboard').then(() => {
        this.toast.showBottomCenterSuccess(result.message);
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  openConfirmDialog() {
    this.displayModal = true;
  }

  closeConfirmDialog() {
    this.displayModal = false;
  }


  public handleOrderFormEvent(form: FormGroup) {
    const remarks = 'remarks';
    if (form.value[remarks] === '') {
      delete form.value[remarks];
    }
    if (this.clientAdded) {
      this.toFormData(form.value);
    } else {
      this.toFormData(form.value, true);
    }
  }

  public handleEmailEvent($event: string[]) {
    // @ts-ignore
    for (const email of $event) {
      this.sampleForm.append('email[]', email);
    }
  }

  public handleLanguageEvent($event: number) {
    // @ts-ignore
    this.sampleForm.append('lang_id', $event);
    this.sampleForm.append('locale', this.resources.getLanguage().description);
  }


  private toFormData<T>(formValue: T, shouldRemove?): void {
    if (shouldRemove) {
      for (const key of Object.keys(formValue)) {
        if (key === 'client_id') {
          continue;
        }
        const value = formValue[key];
        this.sampleForm.append(key, value);
      }
    } else {
      for (const key of Object.keys(formValue)) {
        const value = formValue[key];
        this.sampleForm.append(key, value);
      }
    }
  }

  public getOrderDetails(id): Promise<boolean> {
    this.getOrderInitiated = false;

    return new Promise<boolean>((resolve => {
      const body = {
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const typeBody = {
        order_type_id: 1,
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const order = this.ordersService.getOrder(body);
      const sampleTypes = this.orders.getSampleTypes(typeBody);
      const laboratories = this.ordersService.getLaboratories();
      forkJoin([order, sampleTypes, laboratories]).subscribe(result => {
          this.orders.getDeadlines(body).subscribe(deadlines => {
            this.order = null;
            this.order = result[0].order;
            this.getOrderInitiated = true;
            this.numGeneratedInvoices = this.order.invoices?.invoice ? 1 : 0;
            this.getGeneratedReportDate();
            this.checkOrderValidity(this.order.latest_state.name);
            this.samples = new Array(0);
            this.resources.userState.emit(this.order.latest_state.name);
            if (result[0].order.samples?.prolabo) {
              for (const prolaboEntries of Object.keys(result[0].order.samples.prolabo)) {
                this.samplesByType = Object.entries(result[0].order.samples.prolabo);
                if (Object.entries(result[0].order.samples.prolabo)[0]) {
                  this.samplesAdded = true;
                }
                if (result[0].order.samples.prolabo) {
                  for (const s of Object.keys(result[0].order.samples.prolabo)) {
                    for (const finalSample of result[0].order.samples.prolabo[s].samples) {
                      const existing = this.samples.find(x => x.id === finalSample.id);
                      if (existing == null) {
                        this.samples.push(finalSample);
                      }
                    }
                  }
                }
              }
            } else {
              this.samplesByType = new Array(0);
            }
            this.setTypes(result[1]);
            this.setLaboratories(result[2].laboratories);
            resolve(true);
            this.setDeadlines(deadlines.deadlines);
          });
        }, error => {
          this.functions.showError(error);
        }
      );
    }));
  }

  private setLaboratories(laboratories: Laboratory[]) {
    this.laboratories = new Array(0);
    laboratories.forEach(laboratory => {
      this.laboratories.push({id: laboratory.id, text: laboratory.city, value: laboratory.id});
    });
  }

  private setTypes(types: Type[]) {
    this.originalTypes = types;
    this.types = [{id: 0, text: 'SELECT', value: 0}];
    if (types) {
      Object.entries(types).map(sample => {
        this.types.push({
          id: sample[1].id,
          text: sample[1][this.resources.getLanguage().description],
          value: sample[1].id
        });
      });
    }
  }

  getGeneratedReportDate() {
    if (this.order.action && this.order.action.length > 0) {
      // @ts-ignore
      const report = this.order.action.find(action => action.name === AdminStatics.actionsGeneratedReports);
      if (report) {
        // @ts-ignore
        this.generatedReportDate = this.datePipe.transform(report.pivot?.updated_at, 'dd.MM.yy');
      }
    }
  }

  private checkOrderValidity(orderName) {
    switch (orderName) {
      case 'admin.cancelled':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.stepDone = false;
        break;
      case 'client.preveling':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.stepDone = false;
        break;
      case 'client.sent':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.stepDone = false;
        break;
      case 'client.inprocess':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.stepDone = false;
        break;
      case 'admin.analyzed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.stepDone = false;
        break;
      case 'client.completed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.stepDone = true;
        break;
    }
  }

  handleEmailError($event: boolean) {
    if ($event) {
      this.error = true;
      this.toast.showBottomCenterError(this.translate.instant('FORM.EMAIL'));
      this.initiatedSubmit = false;
    } else {
      this.error = false;
    }
  }

  printReport($event: boolean) {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    if (this.order.pdf_report_path == null && this.order.latest_state_id === 10) {
      this.ordersService.getReportStatus(body).subscribe(result => {
        this.toast.showBottomCenterInfo(result.message);
        if (result.url) {
          window.open(environment.storageUrl + result.url, '_blank');
          window.location.reload();
        }
      });
    } else {
      window.open(environment.storageUrl + this.order.pdf_report_path, '_blank');
    }
  }

  handleSampleUpdate(sampleId: number) {
    const ref = this.dialogService.open(SampleCreateModalComponent, {
      showHeader: false,
      width: '60%',
      data: {
        order: this.order,
        types: this.types,
        sample: this.samples.find(x => x.id === sampleId),
        samples: this.samples,
        disabled: true,
        state: 'update'
      }
    });

    ref.onClose.subscribe(data => {
      if (data) {
        this.samplesService.updateSampleRegular(data)
          .subscribe((result: any) => {
            this.toast.showBottomCenterSuccess(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
      }
    });
  }

  handleOrderError($event: any) {
    if ($event.length > 0) {
      this.error = true;
      $event.forEach(x => {
        this.toast.showBottomCenterError(this.translate.instant(x));
      });
      this.initiatedSubmit = false;
    } else {
      this.error = false;
    }
  }

  viewAddress() {
    this.dialogService.open(LaboratoryAddressComponent, {
      showHeader: false,
      width: '60%',
      data: this.order.laboratory
    });
  }

  updateDeadlineValue($event: string, sampleTypeId: any) {
    const body: any = {
      order_id: this.order.id,
      deadline_id: $event,
      locale: this.resources.getLanguage().description,
      sample_type_id: +sampleTypeId
    };

    if ($event.toString() === '9') {
      const ref = this.dialogService.open(UpdateDateModalComponent, {
        showHeader: false,
        width: '33%',
      });

      ref.onClose.subscribe(res => {
        if (res) {
          body.date = res;
          this.ordersService.modifyDeadline(body).subscribe(result => {
            this.getOrderDetails(this.order.id).then(() => {
            });
            this.toast.showBottomCenterInfo(result.message);
          }, error => {
            this.functions.showError(error);
          });
        }
      });
    } else {
      this.ordersService.modifyDeadline(body).subscribe(result => {
        this.getOrderDetails(this.order.id).then(() => {
        });
        this.toast.showBottomCenterInfo(result.message);
      }, error => {
        this.functions.showError(error);
      });
    }
  }

  public getDeadline(deadlineId, sampleTypeId): DropdownModel {
    return this.deadlines[sampleTypeId].find(x => x.id === deadlineId);
  }

  private setDeadlines(types: any) {
    const entries = Object.entries(types);
    // tslint:disable-next-line:forin
    for (const type in entries) {
      this.deadlines[entries[type[0]][0]] = this.setDeadlineByType(entries[type[0]][1]);
    }
  }

  private setDeadlineByType(types: Deadline[]) {
    const deadlines = new Array(0);
    Object.values(types).forEach(x => {
      deadlines.push({
        id: x.id,
        text: x.slug,
        value: x.id
      });
    });

    return deadlines;
  }

  printClicked() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    if (this.order.pdf_report_path == null && this.order.latest_state_id === 5 || this.order.latest_state_id === 10) {
      this.ordersService.getReportStatus(body).subscribe(result => {
        this.toast.showBottomCenterInfo(result.message);
        if (result.url) {
          window.open(environment.storageUrl + result.url, '_blank');
          window.location.reload();
        }
        this.getOrderDetails(this.order.id).then(() => {
        });
      });
    } else {
      window.open(environment.storageUrl + this.order.pdf_report_path, '_blank');
    }
  }

  sendReportByMail(value) {
    const ref = this.dialogService.open(StepThreeModalDiagnosticComponent, {
      showHeader: false,
      width: '80%',
      data: {order: this.order, reportByMail: true}
    });
    ref.onClose.subscribe(res => {
      if (res) {
        res.after_work_version = value;
        this.ordersService.sendReport(res).subscribe(result => {
          this.toast.showBottomCenterSuccess(result.message);
        }, error => {
          this.functions.showError(error);
        });
      }
    });
  }

  handleLangError($event: boolean) {
    if ($event) {
      this.error = true;
      this.toast.showBottomCenterError(this.translate.instant('FORM.LANG'));
      this.initiatedSubmit = false;
    } else {
      this.error = false;
    }
  }

  generateInvoice() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    this.bexio.generateInvoice(body).subscribe(result => {
      if (result.open_modal) {
        const ref = this.dialogService.open(RegenerateInvoiceComponent, {
          showHeader: false,
          width: '90%',
          data: result.message
        });

        ref.onClose.subscribe(res => {
          if (res) {
            this.regenerateInvoice();
          }
        });
      } else {
        this.toast.showBottomCenterInfo(result.message);
        this.getOrderDetails(this.order.id).then(() => {
        });
      }
    }, error => this.functions.showError(error));
  }


  regenerateInvoice() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    this.bexio.reGenerateInvoice(body).subscribe(result => {
      this.toast.showBottomCenterInfo(result.message);
      this.getOrderDetails(this.order.id).then(() => {
      });
    }, error => this.functions.showError(error));
  }

  printInvoice() {
    this.functions.showPdf(this.order.invoices.invoice.content, this.order.order_number);
  }

  downloadTemplate() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    this.ordersService.downloadExcelTemplate(body).subscribe(x => {
      window.open(`${environment.storageUrl}${x.template}`);
    });
  }

  uploadTemplate(event) {
    const formData = new FormData();
    let reader;
    if (event.target.files && event.target.files[0]) {
      Array.from(event.target.files).forEach((file: any) => {
        reader = new FileReader();
        // tslint:disable-next-line:no-shadowed-variable
        reader.onload = (event: ProgressEvent) => {
          const fileInBlob = new File([this.dataURLToBlob((event.target as FileReader).result)],
            'template', {type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`});
          formData.append(`template`, fileInBlob);
          // @ts-ignore
          formData.append('order_id', this.order.id);
          formData.append('locale', this.resources.getLanguage().description);

          this.ordersService.importExcelTemplate(formData).subscribe(x => {
            // @ts-ignore
            document.getElementById('import').value = null;
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            // @ts-ignore
            document.getElementById('import').value = null;
            this.functions.showError(error);
          });
        };
        reader.readAsDataURL(file);
      });
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  exportSamples() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    this.ordersService.exportSamples(body).subscribe(result => {
      window.open(environment.storageUrl + result.template, '_blank');
    }, error => {
      this.functions.showError(error);
    });
  }

  getTranslation(translate: any) {
    return this.order.deadline_at != null ?
      translate.replace('DEADLINE.EIGHT.DATE', this.datePipe.transform(this.order.deadline_at, 'd.MM.yyyy')) :
      translate.replace('DEADLINE.EIGHT.DATE', this.translate.instant('DEADLINE.EIGHT.DATE'));
  }

  openEtiquets() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    this.ordersService.openLabels(body).subscribe(x => {
      window.open(environment.storageUrl + x.url, '_blank');
    }, error => this.functions.showError(error));
  }
}
