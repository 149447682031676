<div class="clients-container">
  <app-dialog-header [title]="'LABORATORY_ADDRESS'" (iconClick)="ref.close()"></app-dialog-header>
  <div class="p-grid">
    <ng-container *ngFor="let laboratory of laboratories">
      <div class="p-col-4">
        <div class="p-card laboratory">
          <p>    {{laboratory.name}}
          </p>
          <p>    {{laboratory.address}}
          </p>
          <p>    {{laboratory.postal_code}}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
