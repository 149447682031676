import { Component, OnInit } from '@angular/core';
import { Client } from '../../../../../admin/models/client';
import { DropdownModel } from '../../../../../shared/components/dropdown/dropdown.model';
import { AdminStatics } from '../../../../../admin/statics/admin-statics';
import { StepCounter } from '../../../../../admin/models/step-counter';
import { User } from '../../../../../admin/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastService, UserService } from '../../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { forkJoin } from 'rxjs';
import { AnalystService } from '../../../../services/analyst.service';
import { SimpleOrder, SimpleSample } from '../../../../../admin/models/simple-order-response';
import { AdminSimpleSamplesService } from '../../../../../admin/services/admin-simple-samples.service';
import { AdminAnalystCreatorService } from '../../../../../common/services/admin-analyst-creator.service';
import { ScoreCardModel } from '../../../../../admin/models/score-card-model';
import { SampleCreateModalComponent } from '../../../../../admin/pages/orders/modals/sample-create-modal/sample-create-modal.component';
import { Type } from '../../../../../admin/models/type';
import { ScoreCardModalComponent } from '../../../../../admin/pages/orders/modals/score-card-modal/score-card-modal.component';
import { FunctionsService } from '../../../../../admin/services/functions.service';
import { AdminScorecardModelService } from '../../../../../admin/services/admin-scorecard-model.service';

@Component({
  selector: 'app-simple-order',
  templateUrl: './simple-order.component.html',
  styleUrls: ['./simple-order.component.scss']
})
export class SimpleOrderComponent implements OnInit {
  order: SimpleOrder;
  clients: Client[];
  types: DropdownModel[] = [];
  formValid: boolean;
  samplesHeader = [
    {title: '', sortLabel: null},
    {title: 'SAMPLE.ID', sortLabel: AdminStatics.id},
    {title: 'SAMPLE.NUMBER', sortLabel: AdminStatics.firstName},
    {title: 'SAMPLE.TYPE', sortLabel: AdminStatics.lastName},
    {title: 'SAMPLE.DESCRIPTION', sortLabel: AdminStatics.company},
  ];
  samples: SimpleSample[] = new Array(0);
  samplesByType: any;
  deadlines: DropdownModel[] = [];
  initiatedSubmit = false;
  clientAdded = false;
  samplesAdded = false;
  sampleForm = new FormData();
  orderId: number;
  stepCounter1: StepCounter;
  stepCounter2: StepCounter;
  stepCounter3: StepCounter;
  stepCounter4: StepCounter;
  error = false;
  currentUser: User;
  scorecardModels: ScoreCardModel[];
  step1: boolean;
  step2: boolean;
  step3: boolean;
  step4: boolean;
  private originalTypes: Type[];

  constructor(private route: ActivatedRoute,
              private dialogService: DialogService,
              private toast: ToastService,
              private router: Router,
              private user: UserService,
              private translate: TranslateService,
              private resources: ResourcesService,
              private analystCreator: AdminAnalystCreatorService,
              private samplesService: AdminSimpleSamplesService,
              private scorecard: AdminScorecardModelService,
              public functions: FunctionsService,
              private analystService: AnalystService) {
    translate.onLangChange.subscribe(x => {
      this.setTypes(this.originalTypes);
    });
  }

  ngOnInit(): void {
    this.currentUser = this.user.getCurrentUser();
    this.getScoreCardModels();
    this.route.paramMap.subscribe(snapshot => {
      this.orderId = +snapshot.get(AdminStatics.id);
      this.getOrderDetails(this.orderId).then(() => {
      });
    });
  }

  getScoreCardModels() {
    this.analystCreator.getScoreCardModels(this.orderId).subscribe(result => {
      this.scorecardModels = result.models;
    });
  }

  getScoreCardModelHeaders(sampleTypeId: string) {
    return this.scorecardModels.find(x => x.sample_type_id === Number(sampleTypeId)).scorecard_field;
  }

  openScoreCard(samples: any[], isAnalysed) {
    const ref = this.dialogService.open(ScoreCardModalComponent, {
      showHeader: false,
      width: '90%',
      data: {
        order_id: this.order.id,
        samples,
        orderNumber: this.order.order_number,
        orderRemark: this.order.remarks,
        orderDate: this.order.created_at,
        laboratoryId: this.order.laboratory_id,
        order: this.order
      },
    });

    ref.onClose.subscribe(form => {
      if (form) {
        if (isAnalysed) {
          this.scorecard.updateScoreCard(form).subscribe(result => {
            this.toast.showBottomCenterInfo(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        } else {
          this.scorecard.validateScoreCard(form).subscribe(result => {
            this.toast.showBottomCenterInfo(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        }
      }
    });
  }

  private getOrderDetails(id): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      const body = {
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const typeBody = {
        order_type_id: 1,
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const order = this.analystService.getSimpleOrderDetails(body);
      const sampleTypes = this.analystService.getSampleTypes(typeBody);
      forkJoin([order, sampleTypes]).subscribe(result => {
          this.order = result[0].order;
          this.checkOrderValidity(this.order.latest_state.name, this.order.state);
          for (const prolaboEntries of Object.keys(result[0].order.samples.prolabo)) {
            this.samplesByType = Object.entries(result[0].order.samples.prolabo);

            if (Object.entries(result[0].order.samples.prolabo)[0]) {
              this.samplesAdded = true;
            }
            if (result[0].order.samples.prolabo) {
              for (const s of Object.keys(result[0].order.samples.prolabo)) {
                for (const finalSample of result[0].order.samples.prolabo[s].samples) {
                  const existing = this.samples.find(x => x.id === finalSample.id);
                  if (existing == null) {
                    this.samples.push(finalSample);
                  }
                }
              }
            }
          }
          this.setTypes(result[1]);
          resolve(true);
        }, error => {
          this.functions.showError(error);
        }
      );
    }));
  }

  private checkOrderValidity(orderName, state) {
    switch (orderName) {
      case 'admin.ordered':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.inprocess':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.analyzed':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.cancelled':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.creating':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.preveling':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.ordered':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.sent':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.inprocess':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.inprocess':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.analyzed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = true;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.completed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeStep(state, 4, 'FINISHED');
        break;
      case 'client.completed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeStep(state, 4, 'FINISHED');
        break;
    }
  }

  private initializeStep(state, step, label): StepCounter {
    return {
      button: 'button-selected',
      label,
      icon: 'icon-number-selected',
      number: step,
      date: state[step].pivot.updated_at
    };
  }


  // @ts-ignore
  private initializeDefault(step, label): StepCounter {
    return {
      number: step,
      icon: 'icon-number-unselected',
      button: 'button-unselected',
      label,
      dateLabel: 'WAITING'
    };
  }

  handleSampleUpdate(sampleId: number) {
    const ref = this.dialogService.open(SampleCreateModalComponent, {
      showHeader: false,
      width: '60%',
      data: {
        order: this.order,
        types: this.types,
        sample: this.samples.find(x => x.id === sampleId),
        samples: this.samples,
        disabled: true,
        state: 'update'
      }
    });

    ref.onClose.subscribe(data => {
      if (data) {
        this.samplesService.updateSampleRegular(data)
          .subscribe((result: any) => {
            this.toast.showBottomCenterSuccess(result.message);
            this.getOrderDetails(this.order.id).then(() => {});
          }, error => {
            this.functions.showError(error);
          });
      }
    });
  }

  public handleDelete($event: any) {
    const body = {
      sample_id: $event,
      locale: this.resources.getLanguage().description
    };
    this.samplesService.deleteSample(body).subscribe(result => {
      this.toast.showBottomCenterSuccess(result.message);
      this.getOrderDetails(this.orderId).then(() => {
        const sample = this.samples.find(s => s.id === $event);
        const index = this.samples.indexOf(sample, 0);
        if (index > -1) {
          this.samples.splice(index, 1);
        }
        this.samplesAdded = Object.keys(this.order.samples).length > 0;
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  private setTypes(types: Type[]) {
    if (types) {
      this.originalTypes = types;
      Object.entries(types).map(sample => {
        this.types.push({
          id: sample[1].id,
          text: sample[1][this.resources.getLanguage().description],
          value: sample[1].id
        });
      });
    }
  }
}
