import { Component, OnDestroy, OnInit } from '@angular/core';
import { Language, User } from 'src/app/models';
import { SidebarContent } from '../../../models/sidebar-content';
import { Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
// @ts-ignore
import a from '../../../../assets/routes.json';
import { Breadcrumb, Routes } from '../../../models/routes';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { I18nService, UserService } from 'src/app/shared/services';
import { AdminOrdersService } from '../../services/admin-orders.service';

// @ts-ignore
@Component({
  selector: 'app-prolabo-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, OnDestroy {
  sidebarItems: SidebarContent[] = [
    {name: 'ACTIVITIES', class: 'dashboard', routerLink: '/admin/dashboard', icon: 'speedometer'},
    {name: 'ORDERS', class: 'orders', routerLink: '/admin/orders', icon: 'checkmark'},
    {name: 'CALENDAR', class: 'calendar', routerLink: '/admin/calendar', icon: 'calendar'},
    {name: 'USER_ADMIN', class: 'users', routerLink: '/admin/users', icon: 'people-group'},
    {name: 'BILLING', class: 'invoices', routerLink: '/admin/invoices', icon: 'envelope-open'},
    {name: 'PROFILE', class: 'profile', routerLink: '/admin/profile', icon: 'profile'},
  ];

  subscriber: Subscription;
  routes: Routes[] = a.adminRoutes;
  breadcrumbs: Breadcrumb[] = a.adminBreadcrumbs;
  showBreadcrumbs = true;
  showTitle = false;
  dashboardIsActive = false;
  user: User;
  currentUrl: string;
  firstBreadcrumb: Routes;
  secondBreadcrumb: Routes;
  thirdBreadcrumb: Routes;
  currentPageLoaded: Breadcrumb;
  languages: DropdownModel[] = [];
  langs: Language[];
  logout: boolean;
  logoutClicked = false;
  currentLang: any;
  search: boolean;
  orderId: number;
  orderState: string;

  constructor(
    private router: Router,
    public lang: I18nService,
    public orderService: AdminOrdersService,
    public userService: UserService,
    private translate: TranslateService,
    private resources: ResourcesService) {
  }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();
    if (this.user.role_id === 4) {
      this.sidebarItems.splice(5, 0, {name: 'PRICES', class: 'tariffs', routerLink: '/admin/tariffs', icon: 'ticket'});
    }
    this.appendBreadcrumbs(window.location.pathname);
    if (this.resources.getPlatformLanguages()) {
      this.langs = this.resources.getPlatformLanguages().languages;
    } else {
      this.langs = this.resources.backupLanguages.languages;
    }
    this.currentLang = this.langs.find(lang => lang.description === this.lang.currentLang);
    this.setLanguages(this.langs);
    this.subscriber = this.router.events.subscribe((val: RouterEvent) => {
      if (val.url) {
        this.currentUrl = val.url;
      }
      if (!this.logoutClicked) {
        this.appendBreadcrumbs(this.currentUrl);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  appendBreadcrumbs(url: string) {
    const currentUrl = url.split('/');
    const currentUrlLength = currentUrl.length;
    const lastElementOfUrl = +currentUrl[currentUrlLength - 1];

    // check to not take query params into consideration when creating breadcrumbs
    if (currentUrl && currentUrlLength > 0 && currentUrl[currentUrlLength - 1].indexOf('?') !== -1) {
      currentUrl[currentUrlLength - 1] = currentUrl[currentUrlLength - 1]
        .substr(0, currentUrl[currentUrlLength - 1].indexOf('?'));
    } else if (currentUrl && currentUrlLength > 0 && currentUrl[currentUrlLength - 1].indexOf(';') !== -1) {
      const redirectUrl = currentUrl[2].split(';');
      const orderIdUrl = redirectUrl[1];
      const typeUrl = redirectUrl[2];
      if (orderIdUrl.split('=')[1] === 'invoices') {
        this.router.navigateByUrl('admin/invoices');
      } else if (typeUrl.split('=')[1] === 'simple') {
        this.router.navigate(['/admin/orders/simple-order', orderIdUrl.split('=')[1]]).then(() => {
          this.firstBreadcrumb = this.routes.find(route => route.path === 'orders');
          this.secondBreadcrumb = this.routes.find(route => route.path === 'simple-order');
          this.showBreadcrumbs = true;
        });
      } else if (typeUrl.split('=')[1] === 'diagnostic') {
        this.router.navigate(['/admin/orders/diagnostic-order', orderIdUrl.split('=')[1]]).then(() => {
          this.firstBreadcrumb = this.routes.find(route => route.path === 'orders');
          this.secondBreadcrumb = this.routes.find(route => route.path === 'diagnostic-order');
          this.showBreadcrumbs = true;
        });
      }
    }

    this.firstBreadcrumb = this.routes.find(route => route.path === currentUrl[2]);
    this.secondBreadcrumb = this.routes.find(route => route.path === currentUrl[3]);
    this.thirdBreadcrumb = this.routes.find(route => route.path === currentUrl[4]);

    this.dashboardIsActive = currentUrl.length > 2 && currentUrl[2] === 'dashboard';

    if (isNaN(lastElementOfUrl)) {
      this.currentPageLoaded = this.breadcrumbs.find(route => route.router === url);
    } else {
      this.currentPageLoaded = this.breadcrumbs.find(route => route.router + `/${lastElementOfUrl}` === url);
    }

    this.showBreadcrumbs = !!this.currentPageLoaded?.breadcrumbs;
    this.showTitle = !!this.currentPageLoaded?.showTitle;

    if (this.firstBreadcrumb?.name.toLocaleLowerCase() === 'profile') {
      this.logout = true;
      this.search = true;
    } else if (this.secondBreadcrumb?.name.toLocaleLowerCase() === 'simple.order'
      || this.secondBreadcrumb?.name.toLocaleLowerCase() === 'diagnostic.order') {
      this.search = true;
    } else {
      this.logout = false;
      this.search = false;
    }
  }


  onSearchParamChange(param) {
  }

  private setLanguages(languages) {
    languages.forEach(lng => {
      const name = this.translate.instant(lng.description.toUpperCase());
      this.languages.push({id: lng.id, text: name, value: lng.language, description: lng.description});
    });
  }

  updateLanguageValue(value) {
    const language = this.langs.find(lang => lang.id === value);
    this.translate.setDefaultLang(language.description);
    this.lang.setCurrentLang(language.description, language);
    this.resources.setLanguage(language);
  }

  handleLogoutClicked($event: boolean) {
    this.logoutClicked = $event;
  }
}
