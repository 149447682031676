import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Order } from '../../../admin/models/order';
import { InputValidation } from '../../../models/input-validation';
import { ToastService } from '../../../shared/services';
import { AdminSimpleOrdersService } from '../../../admin/services/admin-simple-orders.service';
import { ResourcesService } from '../../../shared/services/resources.service';
import { FunctionsService } from '../../../admin/services/functions.service';

@Component({
  selector: 'app-prolabo-order-tarifs',
  templateUrl: './order-tarifs.component.html',
  styleUrls: ['./order-tarifs.component.scss']
})
export class OrderTarifsComponent implements OnInit, OnChanges {
  @Input() submitClicked = false;
  @Input() order: Order;

  tarifsForm: FormGroup;

  @Output() tarifsFormEvent = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
              private resources: ResourcesService,
              private toast: ToastService,
              private functions: FunctionsService,
              private ordersService: AdminSimpleOrdersService) {
  }

  ngOnInit(): void {

    this.tarifsForm = this.formBuilder.group({
      asbestos_long_duration: [''],
      asbestos_standard: [''],
      asbestos_urgent: [''],
      asbestos_express: [''],
      buffer_qualitative: [''],
      buffer_quantitative: [''],
      lead: [''],
      pcb: [''],
      hap: [''],
      vdi: ['']
    });
    this.tarifsForm.patchValue({
      asbestos_long_duration: [this.order.prices.asbestos_long_duration],
      asbestos_standard: [this.order.prices.asbestos_standard],
      asbestos_urgent: [this.order.prices.asbestos_urgent],
      asbestos_express: [this.order.prices.asbestos_express],
      buffer_qualitative: [this.order.prices.buffer_qualitative],
      buffer_quantitative: [this.order.prices.buffer_quantitative],
      lead: [this.order.prices.lead],
      pcb: [this.order.prices.pcb],
      hap: [this.order.prices.hap],
      vdi: [this.order.prices.vdi]
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submitClicked) {
      if (this.submitClicked) {
        this.tarifsFormEvent.emit(this.tarifsForm);
        this.submitClicked = false;
      } else {
        this.submitClicked = false;
      }
    }
  }

  onValueChange($event: any, field: string) {
    if (this.order.latest_state_id === 5 || this.order.latest_state_id === 10) {
      const body = {
        [field]: $event,
        order_id: this.order.id,
        locale: this.resources.getLanguage().description
      };
      this.ordersService.updateOrder(this.toFormData(body)).subscribe(result => {
        this.toast.showBottomCenterSuccess('');
      }, error => {
        this.functions.showError(error);
      });
    }
  }

  private toFormData(formValue): FormData {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }
    return formData;
  }
}
