<div class="clients-container content">
  <app-dialog-header [title]="'CHECK_SAMPLES'" (iconClick)="ref.close()"></app-dialog-header>
  <div class="corrupted-samples-pagination-list">
    <ng-container *ngIf="items">
      <div class="list-labels">
        <div class="sublist-items">
          <ng-container *ngFor="let header of headers">
            <p class="sort-label overflow" pTooltip="{{ header['title'] | translate }}"
               tooltipPosition="top">
              {{ header['title'] | translate }}
            </p>
          </ng-container>
          <p class="sort-label check-box">
            <span class="overflow" pTooltip="{{ 'SWITCH_EVERYTHING_CORRUPTED' | translate }}"
                  tooltipPosition="top">{{ 'SWITCH_EVERYTHING_CORRUPTED' | translate }}</span>
            <p-checkbox class="checkbox-icon" name="groupname" value="all" [(ngModel)]="selectedAll"
                        (ngModelChange)="check($event)">
            </p-checkbox>
          </p>
        </div>
      </div>
      <ng-container *ngFor="let item of items">
        <div class="list-item">
          <p class="overflow" pTooltip="{{ item['sample_id'] }}"
             tooltipPosition="top">{{ item['sample_id'] }}</p>
          <p class="overflow" pTooltip="{{ item['sample_number'] }}"
             tooltipPosition="top">
            {{item['sample_number']}}
          </p>
          <p class="overflow" pTooltip="{{ item['type']?.slug | translate }}"
             tooltipPosition="top">{{ item['type']?.slug | translate }}</p>
          <p class="overflow" pTooltip="{{ item['description'] }}"
             tooltipPosition="top">{{ item['description'] }}</p>
          <p class="overflow" pTooltip="{{ item['diagnostic']['approximate_quantity'] }}"
             tooltipPosition="top">{{ item['diagnostic']['approximate_quantity'] }}</p>
          <p class="overflow" pTooltip="{{ getCollectMethod(item['diagnostic']['collect_method']?.id) | translate }}"
             tooltipPosition="top">{{ getCollectMethod(item['diagnostic']['collect_method']?.id) | translate }}</p>
          <p class="overflow" pTooltip="{{ item.result }}"
             tooltipPosition="top">{{ item.result }}</p>
          <p class="check-box-item">
            <p-checkbox class="checkbox-icon" name="groupname" [value]="item.id"
                        [(ngModel)]="selectedValues"></p-checkbox>
          </p>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <form [formGroup]="form">
    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <app-prolabo-input [for]="'inspection_date'"
                           [id]="'inspection_date'"
                           [keyword]="'INSPECTION_DATE'"
                           [type]="'date'"
                           [inputType]="'basic'"
                           [required]="false"
                           formControlName="inspection_date">
        </app-prolabo-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-input [for]="'asbestos_removal_company'"
                           [id]="'asbestos_removal_company'"
                           [keyword]="'ASBESTOS_REMOVAL_COMPANY'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [required]="false"
                           formControlName="asbestos_removal_company">
        </app-prolabo-input>
      </div>
      <div class="p-col-12 p-md-4">
        <p pTooltip="{{ 'ANNEX_1' | translate }}"
           tooltipPosition="top" class="margin-padding-zero">
          <app-prolabo-icon-input [for]="'annex_1'"
                                  [id]="'annex_1'"
                                  [keyword]="'ANNEX_1'"
                                  [type]="'file'"
                                  [regularInput]="true"
                                  [inputType]="'basic'"
                                  [required]="true"
                                  [icon]="'visible'"
                                  [accept]="'application/pdf'"
                                  [multiple]="true"
                                  (change)="valueChange($event,'annex_1')">
          </app-prolabo-icon-input>
        </p>
      </div>
      <div class="p-col-12 p-md-4">
        <p pTooltip="{{ 'ANNEX_2' | translate }}"
           tooltipPosition="top" class="margin-padding-zero">
          <app-prolabo-icon-input [for]="'annex_2'"
                                  [id]="'annex_2'"
                                  [keyword]="'ANNEX_2'"
                                  [type]="'file'"
                                  [regularInput]="true"
                                  [inputType]="'basic'"
                                  [required]="true"
                                  [icon]="'visible'"
                                  [accept]="'application/pdf'"
                                  [multiple]="true"
                                  (change)="valueChange($event,'annex_2')">
          </app-prolabo-icon-input>
        </p>
      </div>
      <div class="p-col-12 p-md-4">
       <p pTooltip="{{ 'ANNEX_3' | translate }}"
          tooltipPosition="top" class="margin-padding-zero">
         <app-prolabo-icon-input [for]="'annex_3'"
                                 [id]="'annex_3'"
                                 [keyword]="'ANNEX_3'"
                                 [type]="'file'"
                                 [regularInput]="true"
                                 [inputType]="'basic'"
                                 [required]="true"
                                 [icon]="'visible'"
                                 [accept]="'application/pdf'"
                                 [multiple]="true"
                                 (change)="valueChange($event,'annex_3')">
         </app-prolabo-icon-input>
       </p>
      </div>
      <div class="p-col-12 p-md-4">
       <p pTooltip="{{ 'ANNEX_4' | translate }}"
          tooltipPosition="top" class="margin-padding-zero">
         <app-prolabo-icon-input [for]="'annex_4'"
                                 [id]="'annex_4'"
                                 [keyword]="'ANNEX_4'"
                                 [type]="'file'"
                                 [regularInput]="true"
                                 [inputType]="'basic'"
                                 [required]="true"
                                 [icon]="'visible'"
                                 [accept]="'application/pdf'"
                                 [multiple]="true"
                                 (change)="valueChange($event,'annex_4')">
         </app-prolabo-icon-input>
       </p>
      </div>
      <div class="p-col-12 p-md-4">
        <p pTooltip="{{ 'ANNEX_5' | translate }}"
           tooltipPosition="top" class="margin-padding-zero">
          <app-prolabo-icon-input [for]="'annex_5'"
                                  [id]="'annex_5'"
                                  [keyword]="'ANNEX_5'"
                                  [type]="'file'"
                                  [regularInput]="true"
                                  [inputType]="'basic'"
                                  [required]="true"
                                  [icon]="'visible'"
                                  [accept]="'application/pdf'"
                                  [multiple]="true"
                                  (change)="valueChange($event,'annex_5')">
          </app-prolabo-icon-input>
        </p>
      </div>
      <div class="p-col-12 p-md-4">
        <p pTooltip="{{ 'ANNEX_6' | translate }}"
           tooltipPosition="top" class="margin-padding-zero">
          <app-prolabo-icon-input [for]="'annex_6'"
                                  [id]="'annex_6'"
                                  [keyword]="'ANNEX_6'"
                                  [type]="'file'"
                                  [regularInput]="true"
                                  [inputType]="'basic'"
                                  [required]="true"
                                  [icon]="'visible'"
                                  [accept]="'application/pdf'"
                                  [multiple]="true"
                                  (change)="valueChange($event,'annex_6')">
          </app-prolabo-icon-input>
        </p>
      </div>
      <div class="p-col-12 p-md-4">
        <p pTooltip="{{ 'ANNEX_7' | translate }}"
           tooltipPosition="top" class="margin-padding-zero">
          <app-prolabo-icon-input [for]="'annex_7'"
                                  [id]="'annex_7'"
                                  [keyword]="'ANNEX_7'"
                                  [type]="'file'"
                                  [regularInput]="true"
                                  [inputType]="'basic'"
                                  [required]="true"
                                  [icon]="'visible'"
                                  [accept]="'application/pdf'"
                                  [multiple]="true"
                                  (change)="valueChange($event,'annex_7')">
          </app-prolabo-icon-input>
        </p>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-icon-button
          (click)="validate()"
          [icon]="'arrow-right-white'"
          [name]="'VALIDATE'"
          type="primary"></app-prolabo-icon-button>
      </div>
    </div>
  </form>
</div>
