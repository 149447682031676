import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ModifyOrder,
  SimpleOrderDeleteResponse,
  SimpleOrderResponse,
  SimpleOrderUpdateResponse,
  SampleCounts,
  SubmitOrder, OrderOwners
} from 'src/app/admin/models/simple-order-response';
import { ResourcesService } from '../../shared/services/resources.service';
import { AdminRoutesStatics } from '../statics/admin-routes-statics';
import { LaboratoryResponse } from '../../models/laboratorie';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminSimpleOrdersService {

  constructor(private http: HttpClient, private resources: ResourcesService) {
  }

  public getOrder(body): Observable<SimpleOrderResponse> {
    return this.http.post<SimpleOrderResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrder}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getOrderSampleCounts(body): Observable<SampleCounts> {
    return this.http.post<SampleCounts>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderSamplesCount}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public orderCreate(body): Observable<SimpleOrderResponse> {
    return this.http.post<SimpleOrderResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.createOrder}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getSampleTypes(): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getAvailableSampleTypes}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getAvailableLaboratories(sampleTypeId): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getAvailableLaboratories}`,
      {locale: this.resources.getLanguage().description, sample_type_id: sampleTypeId},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public updateOrder(body): Observable<SimpleOrderUpdateResponse> {
    return this.http.post<SimpleOrderUpdateResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.updateOrder}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public deleteOrder(body): Observable<SimpleOrderDeleteResponse> {
    return this.http.delete<SimpleOrderDeleteResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.deleteOrder}`,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
        params: body
      });
  }

  public submitOrder(body): Observable<SubmitOrder> {
    return this.http.post<SubmitOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.submitOrder}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public submitStep2(body): Observable<SubmitOrder> {
    return this.http.post<SubmitOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.submitStepTwo}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public submitStep3(body): Observable<SubmitOrder> {
    return this.http.post<SubmitOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.submitStepThree}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public cancelOrder(body): Observable<ModifyOrder> {
    return this.http.post<ModifyOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.cancelOrder}`, body,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
      }
    );
  }

  public reActivateOrder(body): Observable<ModifyOrder> {
    return this.http.post<ModifyOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.restore}`, body,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
      }
    );
  }

  public getReportStatus(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getReportStatus}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public createNewVersion(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.newVersion}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public sendReport(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.sendReport}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getCorruptedSamples(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getCorruptedSamples}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getUsers(body): Observable<OrderOwners> {
    return this.http.post<OrderOwners>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderUsers}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getAllUsers(body): Observable<OrderOwners> {
    return this.http.post<OrderOwners>(`${environment.adminApiUrl}${AdminRoutesStatics.getAllUsers}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public downloadExcelTemplate(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.templateDownload}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public exportSamples(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.exportSamples}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public openLabels(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.regenerateLabels}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public importExcelTemplate(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.templateImport}`, body,
      {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public modifyDeadline(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.modifyDeadline}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getLaboratories(): Observable<LaboratoryResponse> {
    return this.http.post<LaboratoryResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getLaboratories}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `
    Bearer ${token}`
    });
  }

  private getMultiPartHeader(token): HttpHeaders {
    return new HttpHeaders({
      Authorization: `
    Bearer ${token}`
    });
  }
}
