<p-dropdown [options]="options"
            [formControl]="selectedOptionFormControl"
            (onChange)="onChange($event)"
            (onClick)="onClick($event)"
            (onHide)="onHide($event)"
            [selectId]="defaultValue"
            [inputId]="inputId"
            class="{{ type ? type : '' }}"
            [placeholder]="defaultValue"
            optionValue="id">
  <ng-template let-option pTemplate="item">
    {{option.text | translate}}
  </ng-template>
  <ng-template pTemplate="selectedItem" let-selectedOption>
    {{selectedOption.text | translate}}
  </ng-template>
</p-dropdown>
