<div class="container">
  <div>
    <ng-container *ngIf="sampleTypes">
      <button pButton pRipple type="button" [label]="'ALL' | translate"
              class="button-unselected p-button" (click)="filterCalendar(null)"></button>
      <ng-container *ngFor="let type of sampleTypes">
        <button pButton pRipple type="button" [label]="type.slug | translate"
                class="button-unselected p-button" (click)="filterCalendar(type.id)"></button>
      </ng-container>
    </ng-container>
  </div>
  <p-card *ngIf="items">
    <app-prolabo-order-calendar [items]="items" [dashboard]="false"
                                [firstItem]="firstItem"></app-prolabo-order-calendar>
  </p-card>
</div>
