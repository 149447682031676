import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AdminRoutesStatics} from '../statics/admin-routes-statics';
import {ResourcesService} from '../../shared/services/resources.service';
import {ScoreCardModel} from '../models/score-card-model';

@Injectable({
  providedIn: 'root'
})
export class AdminScorecardModelService {

  constructor(private http: HttpClient, private resources: ResourcesService) {
  }

  getScorecardModels(body): Observable<ScoreCardModel[]> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getSamplesScoreCardModals}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  getSamples(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getSamples}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  validateScoreCard(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.validateScoreCard}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  updateScoreCard(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.updateScoreCard}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  getDropdownOptions(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.dropdownOptions}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  setFreeOption(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/v1/${AdminRoutesStatics.setFreeOption}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  deleteFreeOption(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/v1/${AdminRoutesStatics.deleteFreeOption}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  deleteRemarks(scorecardId): Observable<any> {
    return this.http.delete<any>(`${environment.adminApiUrl}${AdminRoutesStatics.deleteRemarks}`,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
        params: {
          scorecard_value_id: scorecardId,
          locale: this.resources.getLanguage().description
        }
      });
  }


  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `
    Bearer ${token}`
    });
  }
}
