import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { HttpClientModule } from '@angular/common/http';
import { I18nService, ToastService } from './services';
import { AuthGuard, IsAppReadyGuard, RoleGuard } from './guards';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ListItemComponent } from './components/list-item/list-item.component';
import { RouterModule } from '@angular/router';
import { IconInputComponent } from './components/icon-input/icon-input.component';
import { DialogHeaderComponent } from '../common/shared/dialog-header/dialog-header.component';
import { LanguageDropdownComponent } from './components/language-dropdown/language-dropdown.component';
import { IconComponent } from './components/icon/icon.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { DropdownFieldComponent } from './components/dropdown-field/dropdown-field.component';
import { TooltipModule } from 'primeng/tooltip';
import { OrderPillComponent } from './components/order-pill/order-pill.component';
import { OrderCalendarComponent } from './components/order-calendar/order-calendar.component';
import { DropdownSelectComponent } from './components/dropdown-select/dropdown-select.component';
import { ToDateObjPipe } from './pipes/to-date-obj-pipe.pipe';

@NgModule({
  declarations: [
    InputComponent,
    ButtonComponent,
    DropdownComponent,
    ListItemComponent,
    IconInputComponent,
    DialogHeaderComponent,
    LanguageDropdownComponent,
    IconComponent,
    ProgressBarComponent,
    ConfirmationModalComponent,
    InputFieldComponent,
    DropdownFieldComponent,
    OrderPillComponent,
    OrderCalendarComponent,
    DropdownSelectComponent,
    ToDateObjPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    InputTextModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    CardModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastModule,
    DialogModule,
    RouterModule,
    ProgressBarModule,
    ConfirmDialogModule,
    TooltipModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    InputTextModule,
    InputComponent,
    ButtonComponent,
    DropdownComponent,
    DropdownModule,
    ButtonModule,
    CardModule,
    ToastModule,
    DialogModule,
    ListItemComponent,
    ConfirmationModalComponent,
    IconInputComponent,
    DialogHeaderComponent,
    LanguageDropdownComponent,
    IconComponent,
    ProgressBarComponent,
    InputFieldComponent,
    DropdownFieldComponent,
    OrderPillComponent,
    OrderCalendarComponent,
    DropdownSelectComponent,
    ToDateObjPipe,

  ],
  providers: [
    I18nService,
    AuthGuard,
    RoleGuard,
    IsAppReadyGuard,
    ToastService,
    MessageService,
    ConfirmationService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SharedModule {
}
