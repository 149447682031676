import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LocaleSettings} from 'primeng/calendar';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {PrimeNGConfig} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {InputFieldComponent} from '../../../../../shared/components/input-field/input-field.component';

@Component({
  selector: 'app-free-option',
  templateUrl: './free-option.component.html',
  styleUrls: ['./free-option.component.scss']
})
export class FreeOptionComponent implements OnInit, AfterViewInit {
  form: FormGroup;

  constructor(public ref: DynamicDialogRef,
              private translateService: TranslateService,
              private config: PrimeNGConfig,
              private datePipe: DatePipe,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      input: ['', Validators.required]
    });
  }

  submit() {
    this.ref.close(this.form.controls.input.value);
  }

  ngAfterViewInit(): void {
    const item = document.getElementById('free_option');
    item.autofocus = true;
    item.focus();
  }
}
