<div class="activities-list">
  <ng-container *ngIf="items">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="list-labels">
          <div class="sublist-items">
            <ng-container *ngFor="let header of headers">
              <div class="sort-label {{header.sortLabel == 'latest_state' ? 'no-sort' : ''}} {{header.sortLabel}}">
                <span class="calendar" *ngIf="header.title === 'DATE'">
                   <p-calendar [(ngModel)]="rangeDates"
                               (ngModelChange)="dateChange($event)"
                               selectionMode="range"
                               [showIcon]="true"
                               inputId="icon"
                               [readonlyInput]="true">
                   </p-calendar>
                </span>
                <p class="{{critical && header.sortLabel === 'latest_state' ? 'filter-p' : ''}}">
                  {{ header.title | translate }}
                  <span
                    *ngIf="critical && header.sortLabel === 'latest_state'"
                    pTooltip="{{ 'FILTER_SAMPLES' | translate }}"
                    tooltipPosition="top"
                    class="filter-icon-activities">
                                    <img
                                      src="{{sampleIsSelected ?'../../../../../assets/icons/filter_blue.svg' : '../../../../../assets/icons/filter.svg'}}">
                                    <p-dropdown
                                      [options]="filterValues"
                                      [formControl]="selectedSample"
                                      optionValue="id"
                                      optionLabel="text"
                                      (onChange)="handleFilterDropdownChange($event)"
                                      class="dropdown-hidden"></p-dropdown>
                                  </span>
                </p>
                <ng-container *ngIf="shouldSort">
                  <div *ngIf="getAscValueForHeader(header.sortLabel)"
                       class="icon"
                       (click)="header.sortLabel !== 'latest_state' ? sortByLabel(header.sortLabel) : null">
                    <img src="../../../../assets/icons/down-arrow.svg"/>
                  </div>
                  <div *ngIf="!getAscValueForHeader(header.sortLabel)"
                       class="icon"
                       (click)="header.sortLabel !== 'latest_state' ? sortByLabel(header.sortLabel) : null">
                    <img src="../../../../assets/icons/up-arrow.svg"/>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngFor="let item of items">
          <div class="list-item {{critical && date > item.deadline_at ? 'mark-red' : ''}}">
            <p class="overflow mobile-hidden {{ item.latest_state?.id != 6 ? 'cursor' : '' }}"
               (click)="navigate(item.id, item.type?.name, item.latest_state?.id)"
               pTooltip="{{item.order_number}}"
               tooltipPosition="top">{{ item.order_number }}</p>
            <p class="overflow light-text mobile-hidden" pTooltip="{{item.company}}"
               tooltipPosition="top">{{ item.company }}</p>
            <p class="overflow" pTooltip="{{item.site}}"
               tooltipPosition="top"
               (click)="navigate(item.id,item.type?.name, item.latest_state?.id)">
              {{ item.site }}
            </p>
            <p class="progressbar" pTooltip="{{functions.getProgressBarTooltipValue(item.latest_state?.id)}}"
               tooltipPosition="top">
              <app-prolabo-progress-bar
                [class]="'long-hidden'"
                [value]="functions.getProgressBarValues(item.latest_state?.id)['value']"
                [name]="functions.getProgressBarValues(item.latest_state?.id)['name']"
                [step]="functions.getProgressBarValues(item.latest_state?.id)['step']"></app-prolabo-progress-bar>
            </p>
            <p class="mobile-progress">
              {{ functions.getProgressBarValues(item.latest_state?.id)['step'] }} / {{ totalSteps }}
            </p>
            <p *ngIf="!critical" class="overflow light-text" pTooltip="{{item.type?.name | titlecase}}"
               tooltipPosition="top">
              {{ item.type?.name | titlecase }}
            </p>
            <p *ngIf="critical"
               class="overflow date"
               pTooltip="{{item.deadline_at | toDateObj | date: 'dd.MM.yyyy HH:mm'}}"
               tooltipPosition="top">
              {{ item.deadline_at | toDateObj | date: "dd.MM.yyyy" }}
              <br/>
              <span class="light-text time">{{ item.deadline_at | toDateObj | date: "HH:mm" }}</span>
            </p>
            <p class="overflow date" pTooltip="{{item.created_at | toDateObj | date: 'dd.MM.yyyy HH:mm'}}"
               tooltipPosition="top">
              {{ item.created_at | toDateObj | date: "dd.MM.yyyy" }}
              <br/>
              <span class="light-text time">{{ item.created_at | toDateObj | date: "HH:mm" }}</span>
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
