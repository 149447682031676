import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService, ToastService, UserService } from 'src/app/shared/services';
import { AdminStatics } from '../../../statics/admin-statics';
import { AdminSimpleSamplesService } from '../../../services/admin-simple-samples.service';
import { ScoreCardValues } from '../../../models/simple-order-response';
import { DiagnosticOrder } from '../../../models/diagnostic-order-response';
import { DropdownModel } from '../../../../shared/components/dropdown/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService } from '../../../services/functions.service';

@Component({
  selector: 'app-prolabo-pagination-list-diagnostic-samples',
  templateUrl: './pagination-list-diagnostic-samples.component.html',
  styleUrls: ['./pagination-list-diagnostic-samples.component.scss']
})
export class PaginationListDiagnosticSamplesComponent implements OnInit, OnChanges {

  @Input() items: any[];
  @Input() headers: [];
  @Input() scoreCardModels: any[];
  @Input() userSide = false;
  @Input() totalItems;
  @Input() listType;
  @Input() sampleType: number;
  @Input() routerLinkDetails;
  @Input() step1: boolean;
  @Input() step2: boolean;
  @Input() step3: boolean;
  @Input() step4: boolean;
  @Input() stepDone: boolean;
  @Input() sampleAnalyzed: boolean;
  @Input() order: DiagnosticOrder;
  @Input() user: boolean;


  @Output() sortValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() limitValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemToEdit: EventEmitter<number> = new EventEmitter<number>();
  @Output() riskModal: EventEmitter<number> = new EventEmitter<number>();

  sortHeaderValues = [];
  page = 0;
  numberRows = 10;

  displayModal = false;
  itemToDelete = null;

  itemToFocus: number = null;
  newItemValue: number = null;

  loggedUser = null;

  constructor(private router: Router,
              private lang: I18nService,
              private cdr: ChangeDetectorRef,
              private userService: UserService,
              private translate: TranslateService,
              private samplesService: AdminSimpleSamplesService,
              private functions: FunctionsService,
              private toast: ToastService) {
  }

  ngOnInit() {
    this.loggedUser = this.userService.getCurrentUser();
    this.headers.forEach(header => {
      this.sortHeaderValues.push({param: header[AdminStatics.sortLabel], asc: true});
    });
    if (this.sampleAnalyzed && !this.userSide) {
      this.makeScoreCardValues();
    }
  }

  ngOnChanges() {
    this.sortHeaderValues = new Array();
    this.headers.forEach(header => {
      this.sortHeaderValues.push({param: header[AdminStatics.sortLabel], asc: true});
    });
  }

  makeScoreCardValues() {
    this.items.forEach(x => {
      const values: any = new Array(0);
      this.scoreCardModels.forEach(y => {
        const found = x.scorecard_value.find(z => y.id === z.scorecard_field_id);
        if (found) {
          values.push(found);
        } else {
          // @ts-ignore
          values.push(true);
        }
      });
      x.scorecard_values = values;
    });
  }

  onFocus(event) {
    this.itemToFocus = event.target.getAttribute('id');
  }

  onChange(event) {
    this.newItemValue = event.target.value;
  }

  updateSampleType(item) {
    this.samplesService.updateSampleType({
      id: item.id,
      client_id: this.newItemValue,
      locale: this.lang.currentLang
    }).subscribe(res => {
      this.toast.showBottomCenterSuccess(res.message);
      this.newItemValue = null;
      this.itemToFocus = null;
    }, error => {
      this.functions.showError(error);
    });
  }

  sortByLabel(param: string) {
    this.clearOtherValues(param);
    const sortValue = this.sortHeaderValues.filter(x => x.param === param)[0];
    sortValue.asc = !sortValue.asc;

    this.sortValue.emit(sortValue);
  }

  deleteItem() {
    this.itemDelete.emit(this.itemToDelete);
    this.displayModal = false;
    this.itemToDelete = null;
  }

  openConfirmDialog(id) {
    this.displayModal = true;
    this.itemToDelete = id;
  }

  editItem(id) {
    this.itemToEdit.emit(id);
  }

  openRiskModal(id) {
    this.riskModal.emit(id);
  }

  closeConfirmDialog() {
    this.itemToDelete = null;
    this.displayModal = false;
  }

  getLastHeader() {
    return this.headers[this.headers.length - 1];
  }

  clearOtherValues(param) {
    this.sortHeaderValues.forEach(header => {
      if (param !== header.param) {
        header.asc = true;
      }
    });
  }

  getAscValueForHeader(header) {
    return this.sortHeaderValues.filter(x => x.param === header)[0].asc;
  }

  onPageChange(event) {
    this.page = event.page;
    this.pageValue.emit(event.page);
  }

  onLimitChange(event) {
    this.numberRows = event.value;
    this.page = 0;
    this.limitValue.emit(event.value);
  }

  onItemClicked(item) {
    this.itemClicked.emit(item);
  }

  navigate(id) {
    if (this.routerLinkDetails) {
      this.router.navigate([this.routerLinkDetails, id]);
    }
  }

  getCollectMethod(id) {
    switch (id) {
      case 1:
        return 'COLLECT_METHOD_ID_1';
      case 2:
        return 'COLLECT_METHOD_ID_2';
      case 3:
        return 'COLLECT_METHOD_ID_3';
    }
  }


  getDropdownTranslations(id: number, typeId: number): string {
    if (typeId === 2) {
      if (id === 1) {
        return 'ASBESTOS_NOT_DETECTED';
      } else if (id === 2) {
        return 'ASBESTOS_DETECTED_ACTINOLITE';
      } else if (id === 3) {
        return 'ASBESTOS_DETECTED_AMOSITE';
      } else if (id === 4) {
        return 'ASBESTOS_DETECTED_ANTHOPHYLLITE';
      } else if (id === 12) {
        return 'ASBESTOS_DETECTED_CHRYSOTILE';
      } else if (id === 13) {
        return 'ASBESTOS_DETECTED_CROCIDOLITE';
      } else if (id === 14) {
        return 'ASBESTOS_DETECTED_TREMOLITE';
      }
    } else if (typeId === 3) {
      if (id === 5) {
        return 'LEAD_NOT_DETECTED';
      } else if (id === 6) {
        return 'LEAD_BELOW';
      } else if (id === 7) {
        return 'LEAD_UNDER';
      }
    } else if (typeId === 5) {
      if (id === 12) {
        return 'HAP_NOT_DETECTED';
      } else if (id === 13) {
        return 'HAP_DETECTED';
      }
    }
    return null;
  }

  public setDropdownOptions(option, sampleType): DropdownModel[] {
    const value = this.getDropdownTranslations(option.id, sampleType);
    return value ? this.translate.instant(value) : option.value;
  }

  getSampleValue(slug: any, scorecardField: ScoreCardValues[], sampleType) {
    const field = scorecardField.find(x => x.scorecard_field?.slug === slug);
    let value = null;
    if (field) {
      if (field.scorecard_field?.scorecard_field_type_id === 1) {
        value = field.text_value;
      } else if (field.scorecard_field?.scorecard_field_type_id === 2) {
        value = field.user?.initials;
      } else if (field.scorecard_field?.scorecard_field_type_id === 3 && field.scorecard_field_option) {
        value = this.setDropdownOptions(field.scorecard_field_option, sampleType);
      } else if (field.scorecard_field_id === 60 ||
        field.scorecard_field_id === 61 ||
        field.scorecard_field_id === 66 ||
        field.scorecard_field_id === 67 ||
        field.scorecard_field_id === 68 ||
        field.scorecard_field_id === 69 ||
        field.scorecard_field_id === 70 ||
        field.scorecard_field_id === 71) {
        value = field.text_value;
      }
    }
    return value;
  }
}
