import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourcesService } from '../../shared/services/resources.service';
import {
  DiagnosticSampleGetResponse, ReportValueDropdowns,
  SampleDiagnosticUpdateDeleteResponse
} from '../models/diagnostic-order-response';
import { AdminRoutesStatics } from '../statics/admin-routes-statics';

@Injectable({
  providedIn: 'root'
})
export class AdminDiagnosticSamplesService {

  constructor(private http: HttpClient, private resources: ResourcesService) { }

  public createSample(body): Observable<DiagnosticSampleGetResponse> {
    return this.http.post<DiagnosticSampleGetResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.createDiagnosticSample}`, body,
      {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public updateSampleRegular(body): Observable<SampleDiagnosticUpdateDeleteResponse> {
    return this.http.post<SampleDiagnosticUpdateDeleteResponse>
    (`${environment.adminApiUrl}${AdminRoutesStatics.updateDiagnosticSample}`, body,
      {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public updateSampleMultipart(body): Observable<SampleDiagnosticUpdateDeleteResponse> {
    return this.http.post<SampleDiagnosticUpdateDeleteResponse>
    (`${environment.adminApiUrl}${AdminRoutesStatics.updateDiagnosticSample}`, body,
      {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public deleteSample(body): Observable<SampleDiagnosticUpdateDeleteResponse> {
    return this.http.delete<SampleDiagnosticUpdateDeleteResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.deleteDiagnosticSample}`,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
        params: body
      });
  }

  public addReportValue(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.createReportValue}`, body,
      {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public updateReportValue(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.updateReportValue}`, body,
      {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public getReportDropdowns(): Observable<ReportValueDropdowns> {
    return this.http.post<ReportValueDropdowns>(`${environment.adminApiUrl}${AdminRoutesStatics.getReportValueDropdowns}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public getAnalogySamples(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.analogySamples}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public checkSampleNumber(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.checkSampleNumber}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }

  private getMultiPartHeader(token): HttpHeaders {
    return new HttpHeaders({
      Authorization: `
    Bearer ${token}`
    });
  }
}
