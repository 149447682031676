import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { SelectClientModalComponent } from 'src/app/admin/pages/orders/modals/select-client-modal/select-client-modal.component';
import { Client } from 'src/app/admin/models/client';
import { ToastService, UserService } from 'src/app/shared/services';
import { User } from 'src/app/models';
import { ResponsiblePerson } from 'src/app/admin/models/responsible-person';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { concat, forkJoin } from 'rxjs';
import { ResourcesService } from '../../../shared/services/resources.service';
import { AdminStatics } from '../../../admin/statics/admin-statics';
import { AdminClientsService } from '../../../admin/services/admin-clients.service';
import { Subtype } from 'src/app/admin/models/subtypes';
import { DiagnosticOrder } from '../../../admin/models/diagnostic-order-response';
import { AdminOrdersService } from '../../../admin/services/admin-orders.service';
import { AdminDiagnosticOrdersService } from '../../../admin/services/admin-diagnostic-orders.service';
import { ResponsibleService } from '../../services/responsible.service';
import { TranslateService } from '@ngx-translate/core';
import { DiagnosticExtraInformationComponent } from '../../../admin/pages/orders/modals/diagnostic-extra-information/diagnostic-extra-information.component';
import { FunctionsService } from '../../../admin/services/functions.service';
import { OrderOwner } from '../../../admin/models/simple-order-response';

@Component({
  selector: 'app-prolabo-diagnostic-order-administration',
  templateUrl: './diagnostic-order-administration.component.html',
  styleUrls: ['./diagnostic-order-administration.component.scss']
})
export class DiagnosticOrderAdministrationComponent implements OnInit, OnChanges {
  @Input() submitClicked = false;
  @Input() order: DiagnosticOrder;
  @Input() user = false;
  @Input() additionalInformationOptions: DropdownModel[];
  @Input() isUser: boolean;
  @Input() visitInformationError = false;
  @Input() laboratories: DropdownModel[];
  @Input() invoiceTypes: DropdownModel[];

  orderForm: FormGroup;
  iconClicked: boolean;
  clients: Client[];
  clientValue: string;
  currentUser: User;
  responsibles: DropdownModel[] = [];
  users: DropdownModel[] = [];
  subtypes: DropdownModel[] = [];
  client: Client;
  siteValue: string;
  subtypeValue: string;
  responsibleId: number;
  laboratoryId: number;
  subTypeId: number;
  responsiblesLoaded = false;
  searchParams: any;
  limit = 20;
  offset = 0;

  @Output() clientEvent = new EventEmitter<Client>();
  @Output() orderFormEvent = new EventEmitter<FormGroup>();
  @Output() imageEvent = new EventEmitter<any>();
  @Output() orderEvent = new EventEmitter<string[]>();
  @Output() visitInfoUpdate = new EventEmitter<any>();
  @Output() requestGetOrder = new EventEmitter<any>();


  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private responsibleService: ResponsibleService,
              private clientsService: AdminClientsService,
              private ordersService: AdminOrdersService,
              private diagnosticOrdersService: AdminDiagnosticOrdersService,
              private toast: ToastService,
              private translate: TranslateService,
              private resources: ResourcesService,
              private functions: FunctionsService,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    const body = {
      user_id: this.order.user_id,
      locale: this.resources.getLanguage().description
    };
    this.orderForm = this.formBuilder.group({
      responsible_id: ['', Validators.required],
      laboratory_id: ['', Validators.required],
      site: ['', Validators.required],
      invoicing_address: ['', Validators.required],
      postal_code: ['', Validators.required],
      city: ['', Validators.required],
      order_subtype_id: ['', Validators.required],
      site_img: ['', this.order.site_img == null ? Validators.required : null],
      client_id: [''],
      remarks: [''],
      company: [''],
      invoice_type_id: [''],
      mandate: [this.order?.mandate ? this.order?.mandate : ''],
      principal: [this.order?.principal ? this.order?.principal : ''],
      date_of_diagnosis: [this.order?.date_of_diagnosis],
      deviations: [this.order?.deviations],
      order_additional_information_id: [this.order?.order_additional_information_id],
      potentially_dangerous_situations: [this.order?.potentially_dangerous_situations],
      pre_visit_date: [this.order?.pre_visit_date ? this.order?.pre_visit_date : null],
      pre_visit: [this.order?.pre_visit],
      reservations: [this.order?.reservations],
      scope_of_diagnosis: [this.order?.scope_of_diagnosis],
      locale: [this.resources.getLanguage().description, Validators.required]
    });
    const responsibles = this.responsibleService.getResponsibles(body);
    const subtypes = this.ordersService.getOrderSubtype();
    const clients = this.clientsService.getAllClients();
    if (this.order.client) {
      this.clientValue = `${this.order.client.first_name} ${this.order.client.last_name}`;
    }
    this.getUsers();

    forkJoin([responsibles, subtypes, clients]).subscribe(result => {
        this.setResponsibles(result[0].responsibles);
        this.setSubTypes(result[1].subtypes);
        this.clients = result[2].clients;
        this.responsiblesLoaded = true;
        if (this.order.invoice_address?.length > 0) {
          this.orderForm.patchValue({
            invoicing_address: this.order.invoice_address[this.order?.invoice_address?.length - 1].address,
            postal_code: this.order.invoice_address[this.order?.invoice_address?.length - 1].postal_code,
            city: this.order.invoice_address[this.order?.invoice_address?.length - 1].city,
            company: this.order.invoice_address[this.order?.invoice_address?.length - 1].company
          });
        }
        if (this.order.responsible_id) {
          this.responsibleId = this.order.responsible_id;
          this.orderForm.patchValue({
            responsible_id: this.responsibleId
          });
        } else {
          this.responsibleId = this.responsibles[0].id;
          this.orderForm.patchValue({
            responsible_id: this.responsibleId
          });
        }
        if (this.order.laboratory_id) {
          this.laboratoryId = this.order.laboratory_id;
          this.orderForm.patchValue({
            laboratory_id: this.laboratoryId
          });
        } else {
          this.laboratoryId = this.laboratoryId[0].id;
          this.orderForm.patchValue({
            laboratory_id: this.laboratoryId
          });
        }
        if (this.order.order_subtype_id) {
          this.subTypeId = this.order.order_subtype_id;
          this.orderForm.patchValue({
            order_subtype_id: this.subTypeId
          });
        } else {
          this.subTypeId = this.subtypes[0].id;
          this.orderForm.patchValue({
            order_subtype_id: this.subTypeId
          });
        }
        if (this.order.site) {
          this.siteValue = this.order.site;
          this.orderForm.patchValue({
            site: this.order.site,
          });
        }
        if (this.order.monthly_invoice) {
          if (this.order.monthly_invoice === 1) {
            this.orderForm.patchValue({
              invoice_type_id: 1,
            });
          } else {
            this.orderForm.patchValue({
              invoice_type_id: 2,
            });
          }
        }
      }
      , error => {
        this.functions.showError(error);
      });
  }

  handleIconClick($event) {
    this.iconClicked = $event;
    const ref = this.dialogService.open(SelectClientModalComponent, {
      showHeader: false,
      width: '70%',
      data: this.clients
    });

    ref.onClose.subscribe((res: any) => {
      if (res) {
        if (res.state === AdminStatics.stateChoose) {
          this.updateClient(res);
        } else if (res.state === AdminStatics.stateUpdate) {
          const id = res.form.id;
          const updateClient = this.clientsService.updateClient(res.form);
          const getClient = this.clientsService.getClients(res.body);
          concat(updateClient, getClient).subscribe((result: any) => {
            if (result.message) {
              this.toast.showBottomCenterInfo(result.message);
            }
            if (result.clients && result.clients.filter(client => client.id === id).length !== 0) {
              this.updateClient({client: result.clients.filter(client => client.id === id)[0]});
            }
          });
        } else if (res.state === AdminStatics.stateCreate) {
          this.clientsService.createClient(res.form).subscribe(result => {
            this.updateClient(result);
          });
        }
      }
    });
  }

  updateClient(result) {
    this.clientEvent.emit(result.client);
    this.client = result.client;
    this.clientValue = `${result.client.first_name} ${result.client.last_name}`;
    this.orderForm.patchValue({
      client_id: result.client.id,
      invoicing_address: result.client.address
    });
    this.onValueChange(result.client.id, 'client_id');
    this.onValueChange(result.client.address, 'invoicing_address');
    this.onValueChange(result.client.postal_code, 'postal_code');
    this.onValueChange(result.client.city, 'city');
  }

  updateResponsibleValue(value) {
    this.responsibleId = value;
    this.orderForm.patchValue({
      responsible_id: this.responsibleId
    });
    this.onValueChange(value, 'responsible_id');
  }

  getUsers() {
    const params: any = {
      locale: this.resources.getLanguage().description,
      order_id: this.order.id
    };

    if (this.searchParams) {
      params.search = this.searchParams;
    }

    params[AdminStatics.limit] = this.limit;
    params[AdminStatics.offset] = this.offset;
    this.ordersService.getUsers(params).subscribe(result => {
      this.setUsers(result.users);
    });
  }

  private setUsers(users: OrderOwner[]) {
    this.users = new Array(0);
    users.forEach(user => {
      this.users.push({
        id: user.id,
        text: user.first_name + ' ' + user.last_name,
        value: user.id
      });
    });
  }

  updateUserId($event: string) {
    this.orderForm.patchValue({
      user_id: $event
    });
    this.onValueChange($event, 'user_id');
  }

  onFilter($event) {
    const message = $event.originalEvent.path[3].innerText.toString();
    if (message.includes('No results found')) {
      this.searchParams = $event.filter;
      this.getUsers();
    } else if ($event.filter == null) {
      this.searchParams = null;
      this.getUsers();
    }
  }

  updateSubTypeValue(id) {
    this.subTypeId = id;
    this.orderForm.patchValue({
      order_subtype_id: this.subTypeId
    });
    this.onValueChange(id, 'order_subtype_id');
  }

  valueChange(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: ProgressEvent) => {
        const file = new File([this.dataURLToBlob((event.target as FileReader).result)],
          'site_img', {type: `image/jpeg`});
        this.orderForm.patchValue({
          site_img: file
        });
        if ((this.order.latest_state_id > 1 && this.order.latest_state_id < 7) || this.order.latest_state_id > 7) {
          this.onValueChange(file, 'site_img');
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  private setResponsibles(responsibles: ResponsiblePerson[]) {
    this.responsibles = new Array(0);
    responsibles.forEach(responsible => {
      this.responsibles.push({
        id: responsible.id,
        text: responsible.first_name + ' ' + responsible.last_name,
        value: responsible.id
      });
    });
  }

  private setSubTypes(subtypes: Subtype[]) {
    subtypes.forEach(subtype => {
      this.subtypes.push({
        id: subtype.id,
        text: this.getSubtypeTranslationById(subtype.id),
        value: subtype.id
      });
    });
  }

  getSubtypeTranslationById(id) {
    switch (id) {
      case 1:
        return 'SUBTYPE_1';
      case 2:
        return 'SUBTYPE_2';
      case 3:
        return 'SUBTYPE_3';
    }
  }

  openExtraInfoDialog() {
    const ref = this.dialogService.open(DiagnosticExtraInformationComponent, {
      showHeader: false,
      width: '70%',
      data: {additionalInfo: this.additionalInformationOptions, order: this.order}
    });

    ref.onClose.subscribe((res: any) => {
      if (res) {
        this.visitInfoUpdate.emit(res);
        this.orderForm.patchValue({
          date_of_diagnosis: res.date_of_diagnosis,
          order_additional_information_id: res.order_additional_information_id,
          scope_of_diagnosis: res.scope_of_diagnosis
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visitInformationError) {
      if (this.visitInformationError) {
        this.openExtraInfoDialog();
        this.visitInformationError = false;
      }
    }
    const fieldsRemoved: string[] = new Array(0);
    if (changes.submitClicked) {
      if (this.submitClicked) {
        if (this.orderForm.valid) {
          if (this.orderForm.value.pre_visit_date === null) {
            this.orderForm.removeControl('pre_visit_date');
            fieldsRemoved.push('pre_visit_date');
          }
          if (this.orderForm.controls.mandate.value.length === 0) {
            this.orderForm.removeControl('mandate');
            fieldsRemoved.push('mandate');
          }
          if (this.orderForm.controls.principal.value.length === 0) {
            this.orderForm.removeControl('principal');
            fieldsRemoved.push('principal');
          }
          if (this.order.site_img !== null && this.orderForm.controls.site_img.value.length === 0) {
            this.orderForm.removeControl('site_img');
            fieldsRemoved.push('site_img');
          }
          this.orderEvent.emit([]);
          this.orderFormEvent.emit(this.orderForm);
          this.submitClicked = false;
          if (fieldsRemoved.length > 0) {
            fieldsRemoved.forEach(x => {
              if (x === 'pre_visit_date') {
                this.orderForm.addControl(x, new FormControl(null));
              }
              this.orderForm.addControl(x, new FormControl(''));
            });
          }
        } else {
          if (fieldsRemoved.length > 0) {
            fieldsRemoved.forEach(x => {
              if (x === 'pre_visit_date') {
                this.orderForm.addControl(x, new FormControl(null));
              }
              this.orderForm.addControl(x, new FormControl(''));
            });
          }
          this.orderEvent.emit(this.getErrorFieldsFromAdministrationForm(this.orderForm));
          this.submitClicked = false;
        }
      }
    }
  }

  private getErrorFieldsFromAdministrationForm(orderForm: FormGroup): string[] {
    const errorFields = new Array(0);
    if (orderForm.controls.site.status === 'INVALID') {
      errorFields.push('site'.toUpperCase());
    }
    if (orderForm.controls.invoicing_address.status === 'INVALID') {
      errorFields.push('invoicing_address'.toUpperCase());
    }
    if (orderForm.controls.postal_code.status === 'INVALID') {
      errorFields.push('postal_code'.toUpperCase());
    }
    if (orderForm.controls.city.status === 'INVALID') {
      errorFields.push('city'.toUpperCase());
    }
    if (orderForm.controls.site_img.status === 'INVALID') {
      errorFields.push('site_img'.toUpperCase());
    }
    if (orderForm.controls.locale.status === 'INVALID') {
      errorFields.push('locale'.toUpperCase());
    }
    return errorFields;
  }

  getResponsibles(userId) {
    const body = {
      user_id: userId,
      locale: this.resources.getLanguage().description
    };
    this.responsibleService.getResponsibles(body).subscribe(x => {
      this.setResponsibles(x.responsibles);
    }, error => this.functions.showError(error));
  }

  public onValueChange($event: any, field: string) {
    const body = this.getOnChangeBody($event, field);
    this.diagnosticOrdersService.updateOrder(this.toFormData(body)).subscribe(result => {
      if (field === 'user_id') {
        this.getResponsibles($event);
        this.requestGetOrder.emit();
        this.orderForm.patchValue({
          invoicing_address: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.address,
          postal_code: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.postal_code,
          city: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.city,
          company: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.company,
        });
      }
      if (field === 'laboratory_id') {
        this.requestGetOrder.emit();
      }
      if (field === 'responsible_id') {
        this.requestGetOrder.emit();
      }
      this.orderForm.patchValue({
        [field]: $event
      });
      this.toast.showBottomCenterSuccess('');
    }, error => {
      this.functions.showError(error);
    });
  }

  updateInvoiceTypes($event: string) {
    if (this.order.monthly_invoice === 1) {
      this.orderForm.patchValue({
        invoice_type_id: 1,
      });
    } else {
      this.orderForm.patchValue({
        invoice_type_id: 2,
      });
    }
    this.onValueChange($event, 'invoice_type_id');
  }

  updateLaboratoryId($event: string) {
    this.orderForm.patchValue({
      laboratory_id: $event
    });
    this.onValueChange($event, 'laboratory_id');
  }

  private toFormData(formValue): FormData {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }
    return formData;
  }

  private getOnChangeBody(value, field) {
    switch (field) {
      case 'invoicing_address':
        return {
          [field]: value,
          postal_code: this.orderForm.controls.postal_code.value,
          city: this.orderForm.controls.city.value,
          order_id: this.order.id,
          company: this.orderForm.controls.company.value,
          locale: this.resources.getLanguage().description
        };
      case 'city':
        return {
          invoicing_address: this.orderForm.controls.invoicing_address.value,
          postal_code: this.orderForm.controls.postal_code.value,
          [field]: value,
          order_id: this.order.id,
          company: this.orderForm.controls.company.value,
          locale: this.resources.getLanguage().description
        };
      case 'postal_code':
        return {
          invoicing_address: this.orderForm.controls.invoicing_address.value,
          [field]: value,
          city: this.orderForm.controls.city.value,
          order_id: this.order.id,
          company: this.orderForm.controls.company.value,
          locale: this.resources.getLanguage().description
        };
      case 'company':
        return {
          invoicing_address: this.orderForm.controls.invoicing_address.value,
          [field]: value,
          city: this.orderForm.controls.city.value,
          order_id: this.order.id,
          postal_code: this.orderForm.controls.postal_code.value,
          locale: this.resources.getLanguage().description
        };
      default:
        return {
          [field]: value,
          order_id: this.order.id,
          locale: this.resources.getLanguage().description
        };
    }
  }
}
