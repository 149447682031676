import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService, UserService } from 'src/app/shared/services';
import { DiagnosticOrder, DiagnosticSample } from 'src/app/admin/models/diagnostic-order-response';
import { Client } from 'src/app/admin/models/client';
import { DialogService } from 'primeng/dynamicdialog';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { User } from 'src/app/models';
import { TranslateService } from '@ngx-translate/core';
import { AdminStatics } from '../../../../../admin/statics/admin-statics';
import { StepCounter } from '../../../../../admin/models/step-counter';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { AdminDiagnosticSamplesService } from '../../../../../admin/services/admin-diagnostic-samples.service';
import { AdminDiagnosticOrdersService } from '../../../../../admin/services/admin-diagnostic-orders.service';
import { AnalystService } from '../../../../services/analyst.service';
import { CollectMethod, Plan } from '../../../../../admin/models/plan-response';
import { ScoreCardModel } from '../../../../../admin/models/score-card-model';
import { AdminAnalystCreatorService } from '../../../../../common/services/admin-analyst-creator.service';
import { DiagnosticSampleCreateModalComponent } from '../../../../../admin/pages/orders/modals/diagnostic-sample-create-modal/diagnostic-sample-create-modal.component';
import { forkJoin } from 'rxjs';
import { AdminOrdersService } from '../../../../../admin/services/admin-orders.service';
import { Type } from '../../../../../admin/models/type';
import { ScoreCardModalComponent } from '../../../../../admin/pages/orders/modals/score-card-modal/score-card-modal.component';
import { FunctionsService } from '../../../../../admin/services/functions.service';
import { AdminScorecardModelService } from '../../../../../admin/services/admin-scorecard-model.service';

@Component({
  selector: 'app-prolabo-diagnostic-order',
  templateUrl: './diagnostic-order.component.html',
  styleUrls: ['./diagnostic-order.component.scss']
})
export class DiagnosticOrderComponent implements OnInit {
  order: DiagnosticOrder;
  clients: Client[];
  plans: Plan[];
  types: DropdownModel[] = [];
  plansDropdown: DropdownModel[] = [];
  collectMethods: DropdownModel[] = [];
  samplesHeader = [
    {title: '', sortLabel: null},
    {title: 'Évaluation', sortLabel: null, class: 'red'},
    {title: 'N° Prolabo', sortLabel: AdminStatics.sampleId},
    {title: 'SAMPLE_NUMBER', sortLabel: AdminStatics.sampleNumber},
    {title: 'TYPE', sortLabel: AdminStatics.sampleType},
    {title: 'ITEM_DESCRIPTION', sortLabel: AdminStatics.description},
    {title: 'APPROX_QUANTITY', sortLabel: AdminStatics.approxQuantity},
    {title: 'DIAGNOSTIC_SAMPLE', sortLabel: AdminStatics.collectMethod}
  ];
  samplesHeaderNoEmpty = [
    {title: '', sortLabel: null},
    {title: 'N° Prolabo', sortLabel: AdminStatics.sampleId},
    {title: 'SAMPLE_NUMBER', sortLabel: AdminStatics.sampleNumber},
    {title: 'TYPE', sortLabel: AdminStatics.sampleType},
    {title: 'ITEM_DESCRIPTION', sortLabel: AdminStatics.description},
    {title: 'APPROX_QUANTITY', sortLabel: AdminStatics.approxQuantity},
    {title: 'DIAGNOSTIC_SAMPLE', sortLabel: AdminStatics.collectMethod}
  ];
  samples: DiagnosticSample[] = new Array(0);
  samplesByType = [];
  samplesAdded = false;
  step1: boolean;
  step2: boolean;
  step3: boolean;
  orderId: number;
  stepCounter1: StepCounter;
  stepCounter2: StepCounter;
  stepCounter3: StepCounter;
  stepCounter4: StepCounter;
  currentUser: User;
  displayModal = false;
  planToDelete = null;
  scorecardModels: ScoreCardModel[];
  private originalTypes: Type[];

  constructor(private route: ActivatedRoute,
              private dialogService: DialogService,
              private toast: ToastService,
              private router: Router,
              private user: UserService,
              private translate: TranslateService,
              private resources: ResourcesService,
              private cdr: ChangeDetectorRef,
              private ordersService: AdminOrdersService,
              private diagnosticSamplesService: AdminDiagnosticSamplesService,
              private analystCreator: AdminAnalystCreatorService,
              private diagnosticOrdersService: AdminDiagnosticOrdersService,
              private scorecard: AdminScorecardModelService,
              public functions: FunctionsService,
              private analystService: AnalystService) {
    translate.onLangChange.subscribe(x => {
      this.setTypes(this.originalTypes);
    });
  }

  ngOnInit(): void {
    this.currentUser = this.user.getCurrentUser();
    this.getScoreCardModels();
    this.route.paramMap.subscribe(snapshot => {
      this.orderId = +snapshot.get(AdminStatics.id);
      this.getOrderDetails(this.orderId).then(() => {
      });
    });
  }

  editSample(sampleId: number) {
    const sample = this.samples.filter(s => s.id === sampleId)[0];
    let analogySamples = [];
    const body = {
      order_id: this.orderId,
      sample_id: sampleId,
      sample_type_id: sample.sample_type_id,
      locale: this.resources.getLanguage().description
    };
    this.diagnosticSamplesService.getAnalogySamples(body).subscribe(res => {
      // @ts-ignore
      analogySamples = res.samples;

      const ref = this.dialogService.open(DiagnosticSampleCreateModalComponent, {
        showHeader: false,
        width: '80%',
        data: {
          order: this.order,
          types: this.types,
          sample,
          plans: this.plansDropdown,
          collect_methods: this.collectMethods,
          analogy: analogySamples,
          samples: this.samples,
          update: true,
          disabled: !this.step1,
          plan: this.plansDropdown.find(x => x.id === sample.diagnostic.plan_id)
        },
      });

      ref.onClose.subscribe(data => {
        if (data) {
          if (data.regularHeaders) {
            this.diagnosticSamplesService.updateSampleRegular(data.form)
              .subscribe((result: any) => {
                this.toast.showBottomCenterSuccess(result.message);
                this.getOrderDetails(this.order.id).then(() => {
                });
              }, error => {
                this.functions.showError(error);
              });
          } else {
            this.diagnosticSamplesService.updateSampleMultipart(data.form)
              .subscribe((result: any) => {
                this.toast.showBottomCenterSuccess(result.message);
                this.getOrderDetails(this.order.id).then(() => {
                });
              }, error => {
                this.functions.showError(error);
              });
          }
        }
      });
    });
  }

  getScoreCardModels() {
    this.analystCreator.getScoreCardModels(this.orderId).subscribe(result => {
      this.scorecardModels = result.models;
    });
  }

  getScoreCardModelHeaders(sampleTypeId: string) {
    return this.scorecardModels.find(x => x.sample_type_id === Number(sampleTypeId))?.scorecard_field;
  }

  public handleDelete($event: any) {
    const body = {
      id: $event,
      locale: this.resources.getLanguage().description
    };
    this.diagnosticSamplesService.deleteSample(body).subscribe(result => {
      this.toast.showBottomCenterSuccess(result.message);
      this.getOrderDetails(this.orderId).then(() => {
        const sample = this.samples.find(s => s.id === $event);
        const index = this.samples.indexOf(sample, 0);
        if (index > -1) {
          this.samples.splice(index, 1);
        }
        this.samplesAdded = Object.keys(this.order.samples).length > 0;
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  openConfirmationDialog(id) {
    this.displayModal = true;
    this.planToDelete = id;
  }

  openScoreCard(samples: any[], isAnalysed) {
    const ref = this.dialogService.open(ScoreCardModalComponent, {
      showHeader: false,
      width: '90%',
      data: {
        order_id: this.order.id,
        samples,
        orderNumber: this.order.order_number,
        orderRemark: this.order.remarks,
        orderDate: this.order.created_at,
        laboratoryId: this.order.laboratory_id,
        order: this.order
      },
    });

    ref.onClose.subscribe(form => {
      if (form) {
        if (isAnalysed) {
          this.scorecard.updateScoreCard(form).subscribe(result => {
            this.toast.showBottomCenterInfo(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        } else {
          this.scorecard.validateScoreCard(form).subscribe(result => {
            this.toast.showBottomCenterInfo(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        }
      }
    });
  }

  private getOrderDetails(id): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      const body = {
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const typeBody = {
        order_type_id: 1,
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const order = this.analystService.getDiagnosticOrderDetails(body);
      const sampleTypes = this.ordersService.getSampleTypes(typeBody);
      const plans = this.diagnosticOrdersService.getPlans(id);
      const collectMethods = this.ordersService.getCollectMethods();
      forkJoin([order, sampleTypes, plans, collectMethods]).subscribe(result => {
          this.order = null;
          this.order = result[0].order;
          this.plans = new Array(0);
          this.plans = result[0].order.media.filter(media => media.media_type_id === 3) as unknown as Plan[];
          this.checkOrderValidity(this.order.latest_state.name, this.order.state);
          this.samples = new Array(0);
          if (this.order && this.order.samples) {
            for (const sample of Object.keys(this.order.samples)) {
              if (sample === 'prolabo') {
                this.samplesByType = Object.entries(this.order.samples[sample]);
                this.checkSamplesByType();

                if (this.order?.samples[sample]) {
                  for (const s of Object.keys(this.order.samples[sample])) {
                    for (const finalSample of this.order.samples[sample][s].samples) {
                      this.samples.push(finalSample);
                    }
                  }
                }
              }
            }
          }
          this.setPlans(result[2].plans);
          this.setTypes(result[1]);
          this.setCollectMethods(result[3].collect_methods);
          resolve(true);
        }, error => {
          this.functions.showError(error);
        }
      );
    }));
  }

  private setPlans(plans: Plan[]) {
    this.plansDropdown = [];
    if (plans) {
      Object.entries(plans).map(plan => {
        this.plansDropdown.push({
          id: plan[1].id,
          text: plan[1].name,
          value: plan[1].path,
          additional: {
            width: plan[1].width,
            height: plan[1].height
          }
        });
      });
    }
  }

  private setTypes(types: Type[]) {
    this.types = [{id: 0, text: 'SELECT', value: 0}];
    if (types) {
      this.originalTypes = types;
      Object.entries(types).map(sample => {
        this.types.push({
          id: sample[1].id,
          text: sample[1][this.resources.getLanguage().description],
          value: sample[1].id
        });
      });
    }
  }

  private setCollectMethods(methods: CollectMethod[]) {
    this.collectMethods = [];
    if (methods) {
      Object.entries(methods).map(method => {
        this.collectMethods.push({
          id: method[1].id,
          text: method[1].id === 1 ? 'COLLECT_METHOD_ID_1' : method[1].id === 2 ?
            'COLLECT_METHOD_ID_2' : 'COLLECT_METHOD_ID_3',
          value: method[1].id
        });
      });
    }
  }

  checkSamplesByType() {
    this.samplesByType.forEach(sample => {
      sample[1].samples.forEach(item => {
        // @ts-ignore
        item.mark_red = this.shouldMarkRed(item);
        // @ts-ignore
      });
    });
  }

  shouldMarkRed(item): boolean {
    if (this.step1 || this.step2) {
      if (item.diagnostic && item.diagnostic?.collect_method_id !== 1) {
        return true;
      }
    }
    return false;
  }

  private checkOrderValidity(orderName, state) {
    switch (orderName) {
      case 'admin.cancelled':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.creating':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.ordered':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.inprocess':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.analyzed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.preveling':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.sent':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.inprocess':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.completed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeStep(state, 4, 'FINISHED');
        break;
      case 'client.completed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeStep(state, 4, 'FINISHED');
        break;
    }
  }

  private initializeStep(state, step, label): StepCounter {
    return {
      button: 'button-selected',
      label,
      icon: 'icon-number-selected',
      number: step,
      date: state[step]?.pivot.updated_at
    };
  }

  // @ts-ignore
  private initializeDefault(step, label): StepCounter {
    // @ts-ignore
    return {
      number: step,
      icon: 'icon-number-unselected',
      button: 'button-unselected',
      label,
      dateLabel: 'WAITING'
    };
  }
}
