x<div class="clients-container">
  <div class="p-grid add-padding">
    <div class="p-col-12">
      <form [formGroup]="scorecardForm">
       <div class="p-grid">
         <div class="p-col-6">
           <app-prolabo-input keyword="FILE_NAME" [required]="true" type="text"
                              id="file_name" for="file_name"
                              formControlName="name"
                              inputType="basic">
           </app-prolabo-input>
         </div>
         <div class="p-col-6">
           <app-prolabo-dropdown [options]="sampleTypes"
                                 (OnValueChange)="updateSampleTypeValue($event)"
                                 [defaultValue]="sampleTypes[0].id"
                                 placeholder="SAMPLE.TYPE">
           </app-prolabo-dropdown>
         </div>
       </div>
      </form>
    </div>
  </div>
  <div class="extra"></div>
  <div class="p-grid client-dialog-footer">
    <div class="p-col-12 p-md-6">
      <app-prolabo-icon-button
        (click)="back()"
        [icon]="'left-arrow'"
        [name]="'PREVIOUS'"
        [position]="'left'"
        type="primary"></app-prolabo-icon-button>
    </div>
    <div class="p-col-12 p-md-6">
      <app-prolabo-icon-button
        (click)="submit()"
        [icon]="'arrow-right-white'"
        [name]="'NEXT'"
        [position]="'right-arrow'"
        type="primary">
      </app-prolabo-icon-button>
    </div>
  </div>
</div>
