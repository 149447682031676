import { EventEmitter, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toastPresetEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private messageService: MessageService) {
  }

  public showBottomCenterError(message: string, sticky: boolean = false): void {
    this.toastPresetEvent.emit(true);
    this.messageService.add({key: 'bc', severity: 'error', summary: 'Error', detail: message, sticky, closable: false});
    setTimeout(() => {
      this.toastPresetEvent.emit(false);
    }, 3000);
  }

  public showBottomCenterSuccess(message: string): void {
    this.toastPresetEvent.emit(true);
    this.messageService.add({key: 'bc', severity: 'success', summary: 'Success', detail: message, closable: false});
    setTimeout(() => {
      this.toastPresetEvent.emit(false);
    }, 3000);
  }

  public showBottomCenterInfo(message: string): void {
    this.toastPresetEvent.emit(true);
    this.messageService.add({key: 'bc', severity: 'info', summary: 'Info', detail: message, closable: false});
    setTimeout(() => {
      this.toastPresetEvent.emit(false);
    }, 3000);
  }

  public showBottomCenterWarn(message: string): void {
    this.toastPresetEvent.emit(true);
    this.messageService.add({key: 'bc', severity: 'warn', summary: 'Warning', detail: message, closable: false});
    setTimeout(() => {
      this.toastPresetEvent.emit(false);
    }, 3000);
  }
}
