import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarContent } from '../../../models/sidebar-content';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideBarComponent implements OnInit, OnChanges {
  @Input() sidebarItems: SidebarContent[];
  @Input() title: string;
  @Input() stockRoute: string;
  @Input() pinRequested: boolean;

  activeRoute: string;
  userType: string;
  date = new Date();

  @Output() billingClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.setActiveRoute();
  }

  ngOnChanges(): void {
    this.setActiveRoute();

    this.router.events.subscribe(val => {
      this.setActiveRoute();
    });
  }

  setActiveRoute() {
    const urlSegments = this.router.url.split('/');
    const urlSegmentsLength = urlSegments.length;

    // check to not take query params into consideration when creating breadcrumbs
    if (urlSegments && urlSegmentsLength > 0 && urlSegments[urlSegmentsLength - 1].indexOf('?') !== -1) {
      urlSegments[urlSegmentsLength - 1] = urlSegments[urlSegmentsLength - 1]
        .substr(0, urlSegments[urlSegmentsLength - 1].indexOf('?'));
    }

    this.activeRoute = urlSegmentsLength > 3 ? urlSegments[3] : urlSegments[2];
    this.userType = urlSegments[1];

    if (this.userType === 'admin') {
      switch (this.activeRoute) {
        case 'simple-order':
          this.activeRoute = 'orders';
          break;
        case 'diagnostic-order-order':
          this.activeRoute = 'orders';
          break;
        case 'analyst-creator':
          this.activeRoute = 'orders';
          break;
        case 'update-user':
          this.activeRoute = 'users';
          break;
        case 'create-user':
          this.activeRoute = 'users';
          break;
      }
    } else if (this.userType === 'analyst') {
      switch (this.activeRoute) {
        case 'simple-order':
          this.activeRoute = 'dashboard';
          break;
        case 'diagnostic-order-order':
          this.activeRoute = 'dashboard';
          break;
      }
    } else if (this.userType === 'user' || this.userType === 'client_lenzburg') {
      switch (this.activeRoute) {
        case 'simple-order':
          this.activeRoute = 'dashboard';
          break;
        case 'diagnostic-order-order':
          this.activeRoute = 'dashboard';
          break;
      }
    }

  }

  navigate() {
    this.router.navigateByUrl(`${this.stockRoute}/dashboard`);
  }

  routeClicked($event: string) {
    if ($event === 'BILLING_USER') {
      this.billingClicked.emit(true);
    }
  }
}
