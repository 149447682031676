import {Component, OnInit} from '@angular/core';
import {BexioService} from '../../../common/services/bexio.service';
import {FunctionsService} from '../../services/functions.service';
import {AdminStatics} from '../../statics/admin-statics';
import {ResourcesService} from '../../../shared/services/resources.service';
import {ToastService, UserService} from '../../../shared/services';
import {InvoiceBexio} from '../../../common/models/bexio';
import {BexioStatics} from '../../../common/statics/bexio-statics';
import {User} from '../../models/user';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  invoices: InvoiceBexio[];
  total;
  sortParams;
  searchParam;
  limit = 10;
  offset = 0;

  user: User;
  statusIds: number;
  headers = [
    {title: 'PREVIEW'},
    {title: 'DOCUMENT', sortLabel: BexioStatics.documentNr},
    {title: 'TOTAL_NET', sortLabel: BexioStatics.totalNet},
    {title: 'TOTAL', sortLabel: BexioStatics.total},
    {title: 'VALID_FROM', sortLabel: BexioStatics.isValidFrom},
    {title: 'VALID_TO', sortLabel: BexioStatics.isValidTo},
    {title: 'STATUS', sortLabel: BexioStatics.status},
    {title: 'DATE', sortLabel: BexioStatics.updatedAt}
  ];
  private filterByStatus: boolean;

  constructor(public bexio: BexioService,
              private functions: FunctionsService,
              private resources: ResourcesService,
              private userService: UserService,
              private toast: ToastService) {
  }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();
    this.getInvoices();
    this.filterByStatus = false;
  }

  getPdf(id) {
    const body = {
      invoice_id: id,
      locale: this.resources.getLanguage().description
    };

    this.bexio.getPdf(body).subscribe(result => {
      this.functions.showPdf(result.invoice.content, result.invoice.name);
    });
  }

  getInvoices() {
    const params: any = {locale: this.resources.getLanguage().description};
    if (this.sortParams) {
      params.order_by = this.sortParams;
    }

    if (this.searchParam) {
      params.search = this.searchParam;
    }

    if (this.filterByStatus) {
      if (this.statusIds) {
        params.status = this.statusIds;
      } else {
        if (params.status) {
          delete params.status;
        }
      }
    }

    params[AdminStatics.limit] = this.limit;
    params[AdminStatics.offset] = this.offset;

    this.bexio.getInvoices(params).subscribe(res => {
        this.invoices = res.invoices;
        this.total = res.invoices.length;
      },
      error => this.functions.showError(error));
  }

  deleteInvoice(id) {
    const params = {locale: this.resources.getLanguage().description, invoice_id: id};
    this.bexio.deleteInvoice(params).subscribe(res => {
        this.toast.showBottomCenterInfo(res.message);
        this.searchParam = null;
        this.getInvoices();
      },
      error => {
        this.functions.showError(error);
      });
  }

  sortByLabel(param) {
    this.sortParams = param.asc ? param.param : param.param + BexioStatics.desc;
    this.getInvoices();
  }

  changeLimit(limit) {
    this.limit = limit;
    this.offset = 0;
    this.getInvoices();
  }

  changeOffset(page) {
    this.offset = page * this.limit;
    this.getInvoices();
  }

  onSearchParamChange(param) {
    this.searchParam = param;
    this.getInvoices();
  }

  handleStatusFilter($event: number) {
    this.filterByStatus = true;
    if ($event === -1) {
      this.statusIds = null;
    } else {
      this.statusIds = $event;
    }
    this.getInvoices();
  }
}
