<ng-container *ngIf="!dashboard">
  <div class="p-grid">
    <div class="p-col">
      <p-card styleClass="red-background">
        <p class="day-name">{{"LATE" | translate}}</p>
        <div style="padding-bottom: 30px" align="center">
          <div class="order-count-late">
            <p>{{firstItem.count}}</p>
          </div>
        </div>
        <app-prolabo-order-pill [orders]="firstItem.orders" [dashboard]="dashboard"></app-prolabo-order-pill>
      </p-card>
    </div>
    <ng-container *ngFor="let item of items">
      <div class="p-col">
        <p-card styleClass="normal">
          <p class="day-date"> {{item.day_name | translate}}</p>
          <p class="day-name-normal">{{item.group }}</p>
          <div style="padding-bottom: 30px" align="center">
            <div class="order-count">
              <p>{{item.count}}</p>
            </div>
          </div>
          <app-prolabo-order-pill [orders]="item.orders"></app-prolabo-order-pill>
        </p-card>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="dashboard">
  <div class="p-grid">
    <ng-container *ngFor="let item of statistics">
      <div class="p-col">
        <p-card styleClass="dashboard">
          <p class="item-date" style="margin-top: -20px"> {{item.day_name | translate}}</p>
          <p class="item-day">{{item.day }}</p>
          <div style="padding-bottom: 30px" align="center">
            <div class="statistics">
            </div>
          </div>
          <app-prolabo-order-pill [items]="item.statistics" [dashboard]="dashboard"></app-prolabo-order-pill>
        </p-card>
      </div>
    </ng-container>
  </div>
</ng-container>
