import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ToastService } from 'src/app/shared/services';
import { AdminUsersService } from 'src/app/admin/services/admin-users.service';
import { Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { AdminStatics } from '../../../statics/admin-statics';
import { AdminDashboardService } from '../../../services/admin-dashboard.service';
import { FunctionsService } from '../../../services/functions.service';
import { Statistic } from '../../../../models/Statistic';

@Component({
  selector: 'app-prolabo-last-orders',
  templateUrl: './last-orders.component.html',
  styleUrls: ['./last-orders.component.scss']
})
export class LastOrdersComponent implements OnInit, OnChanges {

  activities: [];
  critical: [];
  sortParams;
  filterParams;
  @Input() searchParam: string;

  items: Statistic[];

  headers = [
    {title: 'NUMBER', sortLabel: AdminStatics.orderNumber},
    {title: 'OWNER', sortLabel: AdminStatics.company},
    {title: 'SITE', sortLabel: AdminStatics.site},
    {title: 'STATE', sortLabel: AdminStatics.latestState},
    {title: 'TYPE', sortLabel: AdminStatics.orderTypesName},
    {title: 'DATE', sortLabel: AdminStatics.createdAt}
  ];

  headersCritical = [
    {title: 'NUMBER', sortLabel: AdminStatics.orderNumber},
    {title: 'OWNER', sortLabel: AdminStatics.company},
    {title: 'SITE', sortLabel: AdminStatics.site},
    {title: 'STATE', sortLabel: AdminStatics.latestState},
    {title: 'DEADLINES', sortLabel: AdminStatics.deadline},
    {title: 'DATE', sortLabel: AdminStatics.createdAt}
  ];

  sortByDate: boolean;
  lastOrCritical: string;
  fromDate: string;
  toDate: string;

  constructor(private adminService: AdminUsersService,
              private dashboardService: AdminDashboardService,
              private resources: ResourcesService,
              private router: Router,
              private functions: FunctionsService,
              private toast: ToastService) {
  }

  ngOnInit(): void {
    this.getActivities();
  }

  ngOnChanges(): void {
    if (this.searchParam) {
      this.getActivities();
    }
  }

  get date(): string {
    return `${new Date().getDay().toString()}/${new Date().getMonth().toString()}/${new Date().getFullYear().toString()}`;
  }

  getActivities() {
    const params = {locale: this.resources.getLanguage().description};
    if (this.sortParams) {
      params[this.critical ? AdminStatics.secondOrderBy : AdminStatics.orderBy] = this.sortParams;
    }

    if (this.filterParams) {
      params['state_ids'] = this.filterParams;
    }

    if (this.sortByDate) {
      if (this.lastOrCritical === 'last') {
        params['date_from'] = this.fromDate;
        params['date_to'] = this.toDate;
      } else if (this.lastOrCritical === 'critical') {
        params['date_from_2'] = this.fromDate;
        params['date_to_2'] = this.toDate;
      }
    }

    if (this.searchParam) {
      params[AdminStatics.search] = this.searchParam;
    }

    this.dashboardService.getActivities(params).subscribe(res => {
      this.items = new Array(0);
      Object.entries(res.statistics).forEach(x => {
        const statistics = new Array(0);
        // @ts-ignore
        Object.entries(x[1].statistics).forEach(y => {
          statistics.push(y[1]);
        });
        // @ts-ignore
        x[1].statistics = statistics;

        this.items.push(x[1] as Statistic);
      });
      this.activities = res.activities;
      this.critical = res.critical_orders;
    });
  }

  filter(stateIds) {
    if (stateIds.length > 0) {
      this.filterParams = stateIds;
    }

    if (stateIds.length === 0) {
      this.filterParams = null;
    }

    this.getActivities();
  }

  deleteUser(userId) {
    const params = {locale: this.resources.getLanguage().description, id: userId};
    this.adminService.deleteUser(params).subscribe(res => {
        this.router.navigateByUrl('/admin/users');
        this.toast.showBottomCenterInfo(res.message);
        this.searchParam = null;
        this.getActivities();
      },
      error => {
        this.functions.showError(error);
      });
  }

  sortByLabel(param) {
    this.sortParams = param.asc ? param.param + AdminStatics.asc : param.param + AdminStatics.desc;
    this.getActivities();
  }

  onSearchParamChange(param) {
    this.searchParam = param;
    this.getActivities();
  }

  filterByDate($event: string[], orderTypes) {
    this.sortByDate = true;
    this.lastOrCritical = orderTypes;
    this.fromDate = $event[0];
    this.toDate = $event[1];

    this.getActivities();
  }
}
