import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import {Sample} from '../../../../models/order';

@Component({
  selector: 'app-prolabo-step-two-diagnostic',
  templateUrl: './step-two-modal-diagnostic.component.html',
  styleUrls: ['./step-two-modal-diagnostic.component.scss']
})
export class StepTwoModalDiagnosticComponent implements OnInit {

  items: Sample[];
  headers: [];
  selectedValues: any[] = null;
  allIds: number[];
  allItemsSelected = false;
  selectedAll: any = null;
  emailSelected: any = [true];

  constructor(public ref: DynamicDialogRef,
              private toast: ToastService,
              private translate: TranslateService,
              private config: DynamicDialogConfig) {
  }

  ngOnInit() {
    this.allIds = new Array(0);
    this.items = this.config.data.samples;
    this.headers = this.config.data.headers;
    this.items.forEach(sample => {
      this.allIds.push(sample.id);
    });
  }

  check($event: any) {
    if ($event.find(x => x === 'all')) {
      this.allItemsSelected = true;
      this.selectedValues = this.allIds;
    } else {
      this.allItemsSelected = false;
      this.selectedValues = null;
    }
  }

  validate() {
    if (this.allItemsSelected) {
      if (this.emailSelected[0] === true) {
        this.ref.close({ids: this.allIds.sort((n1, n2) => n1 - n2), email: true});
      } else if (this.emailSelected[0] == null) {
        this.ref.close({ids: this.allIds.sort((n1, n2) => n1 - n2), email: false});
      }
    } else {
      if (this.selectedValues != null) {
        if (this.emailSelected[0] === true) {
          this.ref.close({ids: this.selectedValues.sort((n1, n2) => n1 - n2), email: true});
        } else if (this.emailSelected[0] == null) {
          this.ref.close({ids: this.selectedValues.sort((n1, n2) => n1 - n2), email: false});
        }
      } else {
        this.toast.showBottomCenterError(this.translate.instant('FORM.SAMPLE'));
      }
    }
  }

  getCollectMethod(id) {
    switch (id) {
      case 1:
        return 'COLLECT_METHOD_ID_1';
      case 2:
        return 'COLLECT_METHOD_ID_2';
      case 3:
        return 'COLLECT_METHOD_ID_3';
    }
  }
}
