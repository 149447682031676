import { EventEmitter, Injectable, Output } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Language, Languages, Role, Roles, StorageUser, Title, Titles } from 'src/app/models';
import { forkJoin } from 'rxjs';
import { InvoiceType } from '../../models/laboratorie';
import { DropdownModel } from '../components/dropdown/dropdown.model';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  @Output() userState = new EventEmitter<string>();

  constructor(private authService: AuthService) {
  }

  backupLanguages: Languages = {
    languages: [
      {
        id: 1,
        description: 'en',
        language: 'English'
      },
      {
        id: 2,
        description: 'it',
        language: 'Italian'
      },
      {
        id: 3,
        description: 'fr',
        language: 'French'
      },
      {
        id: 4,
        description: 'de',
        language: 'German'
      },
    ],
    status: 'success'
  };

  backupTitles: Titles = {
    titles: [
      {
        id: 1,
        name: 'Mister'
      },
      {
        id: 2,
        name: 'Mistress'
      }
    ],
    status: 'success'
  };

  backupRoles: Roles = {
    roles: [
      {
        id: 1,
        role: 'Admin'
      },
      {
        id: 2,
        role: 'Analyst',
      },
      {
        id: 3,
        role: 'User'
      }
    ],
    type: 'success'
  };

  backupInvoiceTypes: InvoiceType[] = [
    {id: 1, slug: 'MONTHLY'},

    {id: 2, slug: 'PER_ORDER'}
  ];


  private languages: Languages;
  private titles: Titles;
  private roles: Role[];
  private storageUser: StorageUser;
  private language: Language;
  private invoiceTypes: InvoiceType[];

  load() {
    const titles = this.authService.getTitles();
    const languages = this.authService.getLanguages();
    const invoiceTypes = this.authService.getInvoiceTypes();
    forkJoin([titles, languages, invoiceTypes]).subscribe(result => {
      this.setPlatformTitles(result[0]);
      this.setPlatformLanguages(result[1]);
      this.setInvoiceTypes(result[2]);
    }, error => {
      this.setPlatformLanguages(this.backupLanguages);
      this.setPlatformTitles(this.backupTitles);
      this.setInvoiceTypes(this.backupInvoiceTypes);
    });
    if (this.language) {
      this.authService.getRoles(this.language.description).subscribe(result => {
        this.setPlatformRoles(result);
      }, error => {
        this.setPlatformRoles(this.backupRoles);
      });
    } else {
      this.authService.getRoles('fr').subscribe(result => {
        this.setPlatformRoles(result);
      }, error => {
        this.setPlatformRoles(this.backupRoles);
      });
    }
  }

  public setPlatformLanguages(lang) {
    this.languages = lang;
  }

  public setPlatformTitles(titles) {
    this.titles = titles;
  }

  public setPlatformRoles(roles: Roles) {
    this.roles = roles.roles;
  }

  public getPlatformLanguages() {
    return this.languages;
  }

  public getPlatformTitles() {
    return this.titles;
  }

  public getPlatformRoles() {
    return this.roles;
  }

  public getStorageUser(): StorageUser {
    return this.storageUser;
  }

  public getLanguage(): Language {
    return this.language;
  }

  public setStorageUser(user: StorageUser): void {
    this.storageUser = user;
  }

  public setLanguage(lang: Language): void {
    this.language = lang;
  }

  public setInvoiceTypes(invoiceTypes: InvoiceType[]) {
    this.invoiceTypes = invoiceTypes;
  }

  public getInvoiceTypes() {
    return this.invoiceTypes;
  }
}

