import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-prolabo-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() type: string;
  @Input() routerLink: string;

  @Output() OnClick: EventEmitter<any> = new EventEmitter<any>();

  click(event) {
    this.OnClick.emit(event);
  }
}
