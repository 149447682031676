import { NgModule } from '@angular/core';
import { UserOrdersComponent } from './pages/orders/user-orders/user-orders.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UserContainerComponent } from './pages/user-container/user-container.component';
import { CommonModule } from '../common/common.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserProfileComponent } from './pages/profile/user-profile/user-profile.component';
import { UserProfileFormComponent } from './pages/profile/user-profile-form/user-profile-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SimpleOrderUserContainerComponent } from './pages/orders/order-types/simple-order/simple-order-user-container.component';
import { AdminModule } from '../admin/admin.module';
import { LaboratoryAddressComponent } from './pages/orders/order-modals/laboratory-address/laboratory-address.component';
import { OrderViewTariffsComponent } from './pages/orders/order-view-tarrifs/order-view-tariffs.component';
import { OrderItemComponent } from './pages/orders/user-orders/order-item/order-item.component';
import { TooltipModule } from 'primeng/tooltip';
import { DiagnosticOrderUserContainerComponent } from './pages/orders/order-types/diagnostic-order/diagnostic-order-container/diagnostic-order-user-container.component';
import { DiagnosticUserPlanItemComponent } from './pages/orders/order-types/diagnostic-order/diagnostic-order-container/diagnostic-plan-item/diagnostic-plan-item.component';
import { UserProfileAdministrationComponent } from './pages/profile/user-profile-administration/user-profile-administration.component';
import { LaboratoriesModalComponent } from './pages/profile/laboratories-modal/laboratories-modal.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InvoicesComponent } from '../admin/bexio/invoices/invoices.component';
import { PaginatorModule } from 'primeng/paginator';
import {
  DeadlineConfirmationComponent
} from "./pages/orders/order-modals/deadline-confirmation/deadline-confirmation.component";

const routes: Routes = [
  {
    path: `user`, component: UserContainerComponent, children: [
      {path: 'dashboard', component: UserOrdersComponent},
      {path: 'profile', component: UserProfileComponent},
      {path: 'orders/simple-order/:id', component: SimpleOrderUserContainerComponent},
      {path: 'orders/diagnostic-order/:id', component: DiagnosticOrderUserContainerComponent},
      {path: 'invoices', component: InvoicesComponent}
    ]
  },
];

@NgModule({
  declarations: [
    UserOrdersComponent,
    UserContainerComponent,
    UserProfileComponent,
    UserProfileFormComponent,
    SimpleOrderUserContainerComponent,
    LaboratoryAddressComponent,
    OrderViewTariffsComponent,
    UserProfileFormComponent,
    OrderItemComponent,
    DiagnosticOrderUserContainerComponent,
    DiagnosticUserPlanItemComponent,
    UserProfileAdministrationComponent,
    LaboratoriesModalComponent,
    DeadlineConfirmationComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule,
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    RadioButtonModule,
    FormsModule,
    TooltipModule,
    AdminModule,
    PaginatorModule,
  ],
  providers: [DynamicDialogRef]
})
export class UserModule {
}
