import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService, ToastService } from '../../../../shared/services';
import { AdminStatics } from '../../../statics/admin-statics';
import { ScoreCardField, ScoreCardFieldShort } from '../../../models/score-card-model';
import { DropdownModel } from '../../../../shared/components/dropdown/dropdown.model';
import { AdminAnalystCreatorService } from '../../../../common/services/admin-analyst-creator.service';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateScorecardFieldComponent } from '../analyst-creator-modals/create-scorecard-field/create-scorecard-field.component';
import { CreateScorecardDropdownOptionComponent } from '../analyst-creator-modals/create-scorecard-dropdown-option/create-scorecard-dropdown-option.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prolabo-analyst-creator-pagination-list',
  templateUrl: './analyst-creator-pagination-list.component.html',
  styleUrls: ['./analyst-creator-pagination-list.component.scss']
})
export class AnalystCreatorPaginationListComponent implements OnInit {

  @Input() items: ScoreCardField[];
  @Input() totalItems;
  @Input() listType;
  @Input() routerLinkDetails;
  @Input() fieldTypes: DropdownModel[] = [];
  @Input() scoreCardModalId: number;

  @Output() sortValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() limitValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemCreated: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemUpdated: EventEmitter<any> = new EventEmitter<any>();

  sortHeaderValues = [];
  page = 0;
  numberRows = 10;
  headers = [
    {title: 'FIELD.LABEL'},
    {title: 'FIELD.TYPE'},
    {title: 'FIELD.PARAMETERS'}
  ];

  displayModal = false;
  itemToDelete = null;

  itemToFocus: number = null;
  newItemValue: number = null;
  dropdownField: ScoreCardFieldShort;
  deleteState: string;

  constructor(private router: Router,
              private lang: I18nService,
              private translate: TranslateService,
              private analystCreatorService: AdminAnalystCreatorService,
              private resources: ResourcesService,
              private dialogService: DialogService,
              private toast: ToastService) {
  }

  ngOnInit() {
    this.headers.forEach(header => {
      this.sortHeaderValues.push({param: header[AdminStatics.sortLabel], asc: true});
    });
  }

  getDropdownTranslations(id: number, typeId: number): string {
    if (typeId === 2) {
      if (id === 1) {
        return 'ASBESTOS_NOT_DETECTED';
      } else if (id === 2) {
        return 'ASBESTOS_DETECTED_ACTINOLITE';
      } else if (id === 3) {
        return 'ASBESTOS_DETECTED_AMOSITE';
      } else if (id === 4) {
        return 'ASBESTOS_DETECTED_ANTHOPHYLLITE';
      } else if (id === 12) {
        return 'ASBESTOS_DETECTED_CHRYSOTILE';
      } else if (id === 13) {
        return 'ASBESTOS_DETECTED_CROCIDOLITE';
      } else if (id === 14) {
        return 'ASBESTOS_DETECTED_TREMOLITE';
      }
    } else if (typeId === 3) {
      if (id === 5) {
        return 'LEAD_NOT_DETECTED';
      } else if (id === 6) {
        return 'LEAD_BELOW';
      } else if (id === 7) {
        return 'LEAD_UNDER';
      }
    } else if (typeId === 5) {
      if (id === 12) {
        return 'HAP_NOT_DETECTED';
      } else if (id === 13) {
        return 'HAP_DETECTED';
      }
    }
    return null;
  }

  public setDropdownOptions(option, sampleType): DropdownModel[] {
    const value = this.getDropdownTranslations(option.id, sampleType);
    return value ? this.translate.instant(value) : option.value;
  }

  deleteItem() {
    if (this.deleteState === 'model') {
      const modalBody = {
        scorecard_model_id: this.itemToDelete,
        locale: this.resources.getLanguage().description
      };
      this.analystCreatorService.deleteScoreCardModal(modalBody).subscribe(result => {
        this.toast.showBottomCenterInfo(result.message);
        this.itemDelete.emit(this.itemToDelete);
      });
    } else if (this.deleteState === 'field') {
      const fieldBody = {
        scorecard_field_id: this.itemToDelete,
        locale: this.resources.getLanguage().description
      };
      this.analystCreatorService.deleteScoreCardField(fieldBody).subscribe(result => {
        this.toast.showBottomCenterInfo(result.message);
        this.itemDelete.emit(this.itemToDelete);
      });
    } else if (this.deleteState === 'dropdown') {
      const dropdownBody = {
        scorecard_field_option_id: this.itemToDelete,
        locale: this.resources.getLanguage().description
      };
      this.analystCreatorService.deleteScoreCardDropdownOption(dropdownBody).subscribe(result => {
        this.toast.showBottomCenterInfo(result.message);
        this.itemDelete.emit(this.itemToDelete);
      });
    }
    this.displayModal = false;
    this.itemToDelete = null;
  }

  openConfirmDialog(id, state) {
    this.displayModal = true;
    this.itemToDelete = id;
    this.deleteState = state;
  }

  closeConfirmDialog() {
    this.itemToDelete = null;
    this.displayModal = false;
  }

  clearOtherValues(param) {
    this.sortHeaderValues.forEach(header => {
      if (param !== header.param) {
        header.asc = true;
      }
    });
  }

  createField() {
    const ref = this.dialogService.open(CreateScorecardFieldComponent, {
      showHeader: false,
      width: '70%',
      styleClass: 'app-dynamic-dialog',
      data: {dropdownModels: this.fieldTypes, scoreCardModelId: this.scoreCardModalId}
    });

    ref.onClose.subscribe(res => {
      if (res) {
        this.analystCreatorService.createScoreCardField(res).subscribe(result => {
          this.toast.showBottomCenterInfo(result.message);
          this.itemCreated.emit(true);
        });
      }
    });
  }


  updateFieldType($event: string, scoreCardFieldId) {
    if (scoreCardFieldId >= 77) {
      const body = {
        scorecard_field_id: scoreCardFieldId,
        scorecard_field_type_id: $event,
        locale: this.resources.getLanguage().description
      };
      this.analystCreatorService.updateScoreCardField(body).subscribe(result => {
        this.itemUpdated.emit(true);
        this.toast.showBottomCenterInfo(result.message);
      });
    }
  }

  inputChange($event: any, scoreCardFieldId) {
    if (scoreCardFieldId >= 77) {
      const body = {
        scorecard_field_id: scoreCardFieldId,
        slug: $event.target.value,
        locale: this.resources.getLanguage().description
      };
      this.analystCreatorService.updateScoreCardField(body).subscribe(result => {
        this.dropdownField = result.field;
        this.toast.showBottomCenterInfo(result.message);
      });
    }
  }

  createDropdownOption(id: number) {
    const ref = this.dialogService.open(CreateScorecardDropdownOptionComponent, {
      showHeader: false,
      width: '70%',
      styleClass: 'app-dynamic-dialog',
      data: id
    });

    ref.onClose.subscribe(res => {
      if (res) {
        this.analystCreatorService.createScoreCardDropdownOption(res).subscribe(result => {
          this.toast.showBottomCenterInfo(result.message);
          this.itemCreated.emit(true);
        });
      }
    });
  }

  getCheckBoxValue(scoreCardField: ScoreCardField) {
    if (scoreCardField.required === 1) {
      return true;
    } else if (scoreCardField.required === 0) {
      return false;
    }
  }


  updateCheckBoxValue($event: any, scoreCardFieldId: number) {
    if (scoreCardFieldId >= 77) {
      const body = {
        scorecard_field_id: scoreCardFieldId,
        required: $event.checked ? 1 : 0,
        locale: this.resources.getLanguage().description
      };
      this.analystCreatorService.updateScoreCardField(body).subscribe(result => {
        this.dropdownField = result.field;
        this.toast.showBottomCenterInfo(result.message);
      });
    }
  }
}
