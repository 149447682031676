<ng-container *ngIf="!dashboard">
  <div class="p-grid">
    <ng-container *ngFor="let item of orders">
      <div class="col-12" style="padding-bottom: 7px; width: 100%">
        <div class="pill-container">
          <span [class]="item.latest_state_id === 4 || item.latest_state_id === 10 ? 'pill-number-blue' : 'pill-number'">
            {{mapToDisplayNumber(item.latest_state_id)}}
          </span>
          <span class="pill-text" pTooltip="{{item.order_number}}"
                tooltipPosition="top" (click)="navigate(item.order_type_id, item.id)">
            {{item.order_number}}
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="dashboard">
  <div class="p-grid">
    <ng-container *ngFor="let item of items">
      <div class="col-12" style="width: 100%">
        <div class="pill-container-dashboard">
          <p class="pill-number-dashboard">
            {{item.count}}
          </p>
          <p class="pill-text-dashboard">
            {{item.slug | translate}}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
