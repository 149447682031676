<div class="p-grid report-container {{user? 'small-size' : ''}}">
  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-col-12 title">
        <p>{{title | translate}}</p>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 placeholder" *ngIf="firstButtonTitle && firstButton">
        <app-prolabo-icon-button [icon]="firstButtonIcon"
                                 type="primary"
                                 [loading]="loading"
                                 [name]="firstButtonTitle"
                                 [disabled]="disableFirstButton"
                                 (OnClick)="firstButtonClicked()">
        </app-prolabo-icon-button>
      </div>
      <div class="p-col-12 placeholder">
        <app-prolabo-icon-button *ngIf="secondButtonTitle"
                                 [icon]="secondButtonIcon"
                                 type="primary"
                                 [name]="secondButtonTitle"
                                 [disabled]="disableSecondButton"
                                 (OnClick)="secondButtonClicked()">
        </app-prolabo-icon-button>
      </div>
      <div class="p-col-12 placeholder">
        <app-prolabo-icon-button *ngIf="thirdButtonTitle"
                                 [icon]="thirdButtonIcon"
                                 type="primary"
                                 [name]="thirdButtonTitle"
                                 [disabled]="disableThirdButton"
                                 (OnClick)="thirdButtonClicked()">
        </app-prolabo-icon-button>
      </div>
    </div>
  </div>
  <div class="p-col-12">
    <p *ngIf="textReport" class="text">
      {{ textReport | translate }}
      <span class="semi-bold">&nbsp;{{ reportsDate ? reportsDate : '' }}</span>
    </p>
    <p *ngIf="emailReport" class="text">
      {{ emailReport | translate }}
      <span class="semi-bold"> {{ emailDate ? emailDate : ''}}</span>
    </p>
    <p *ngIf="textInvoices && !user" class="text">
      {{ invoicesNumber + ' ' }}{{ textInvoices | translate }}
      <span class="semi-bold">{{ ' ' + invoicesDate ? invoicesDate : '' }}</span>
    </p>
    <p *ngIf="textInvoices && allowedToDownload && user" class="text">
      {{ invoicesNumber + ' ' }}{{ textInvoices | translate }}
      <span class="semi-bold">{{ ' ' + invoicesDate ? invoicesDate : '' }}</span>
    </p>
    <p *ngIf="textInvoices && !allowedToDownload && user" class="text">
      {{ 0 + ' ' }}{{ textInvoices | translate }}
      <span class="semi-bold">{{ ' ' + invoicesDate ? invoicesDate : '' }}</span>
    </p>
    <p *ngIf="textControl" class="text">
      {{ textControl | translate }}
      <span class="semi-bold">{{ ' ' + dateControl ? dateControl : ''}}</span>
    </p>
    <p *ngIf="noteControl" class="text" style="padding-bottom: 25px">
      {{ noteControl | translate }}
      <span class="semi-bold">{{ ' ' + noteValue ? noteValue : '' }}</span>
    </p>
  </div>
</div>
