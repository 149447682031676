import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../../admin/models/user';
import { SidebarContent } from '../../../models/sidebar-content';
import { Breadcrumb, Routes } from '../../../models/routes';
// @ts-ignore
import a from '../../../../assets/routes.json';
import { DropdownModel } from '../../../shared/components/dropdown/dropdown.model';
import { Language } from '../../../models';
import { Subscription } from 'rxjs';
import { I18nService, UserService } from '../../../shared/services';
import { Router, RouterEvent } from '@angular/router';
import { ResourcesService } from '../../../shared/services/resources.service';
import { AnalystService } from '../../../analyst/services/analyst.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FunctionsService } from '../../../admin/services/functions.service';

@Component({
  selector: 'app-prolabo-user-container',
  templateUrl: './user-container.component.html',
  styleUrls: ['./user-container.component.scss']
})
export class UserContainerComponent implements OnInit, OnDestroy {
  user: User;
  sidebarItems: SidebarContent[] = [
    {name: 'MY_ORDERS', class: 'dashboard', routerLink: '/user/dashboard', icon: 'checkmark'},
    {name: 'BILLING_USER', class: 'invoices', routerLink: '/user/invoices', icon: 'envelope-open'},
    {name: 'PROFILE', class: 'profile', routerLink: '/user/profile', icon: 'profile'},
  ];
  routes: Routes[] = a.userRoutes;
  breadcrumbs: Breadcrumb[] = a.userBreadcrumbs;

  currentUrl: string;
  firstBreadcrumb: Routes;
  secondBreadcrumb: Routes;
  thirdBreadcrumb: Routes;
  currentPageLoaded: Breadcrumb;
  languages: DropdownModel[] = [];
  langs: Language[];
  logout: boolean;
  logoutClicked = false;
  currentLang: any;
  search: boolean;
  showBreadcrumbs = true;
  showTitle = false;
  subscriber: Subscription;
  orderId: number;
  orderState: string;
  subscription: any;
  displayResponsive = false;
  pinForm: FormGroup;


  constructor(private userService: UserService,
              private router: Router,
              private resources: ResourcesService,
              private functions: FunctionsService,
              private lang: I18nService,
              private fb: FormBuilder,
              private analystService: AnalystService,
              private translate: TranslateService) {
    this.subscription = this.resources.userState.subscribe(value => {
      if (value) {
        this.orderState = value;
      }
    });
  }

  ngOnInit(): void {
    this.pinForm = this.fb.group({
      p1: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]],
      p2: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]],
      p3: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]],
      p4: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]],
      p5: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]],
      p6: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]],
    });
    this.user = this.userService.getCurrentUser();
    this.appendBreadcrumbs(window.location.pathname);
    if (this.resources.getPlatformLanguages()) {
      this.langs = this.resources.getPlatformLanguages().languages;
    } else {
      this.langs = this.resources.backupLanguages.languages;
    }
    this.currentLang = this.langs.find(lang => lang.description === this.lang.currentLang);
    this.setLanguages(this.langs);
    this.subscriber = this.router.events.subscribe((val: RouterEvent) => {
      if (val.url) {
        this.currentUrl = val.url;
      }
      if (!this.logoutClicked) {
        this.appendBreadcrumbs(this.currentUrl);
      }
    });
  }

  appendBreadcrumbs(url: string) {
    const currentUrl = url.split('/');
    const currentUrlLength = currentUrl.length;
    const lastElementOfUrl = +currentUrl[currentUrlLength - 1];

    // check to not take query params into consideration when creating breadcrumbs
    if (currentUrl && currentUrlLength > 0 && currentUrl[currentUrlLength - 1].indexOf('?') !== -1) {
      currentUrl[currentUrlLength - 1] = currentUrl[currentUrlLength - 1]
        .substr(0, currentUrl[currentUrlLength - 1].indexOf('?'));
    } else if (currentUrl && currentUrlLength > 0 && currentUrl[currentUrlLength - 1].indexOf(';') !== -1) {
      const redirectUrl = currentUrl[2].split(';');
      const orderIdUrl = redirectUrl[1];
      if (orderIdUrl.split('=')[1] === 'invoices') {
        this.router.navigateByUrl('user/invoices');
      }
    }

    this.firstBreadcrumb = this.routes.find(route => route.path === currentUrl[2]);
    this.secondBreadcrumb = this.routes.find(route => route.path === currentUrl[3]);
    this.thirdBreadcrumb = this.routes.find(route => route.path === currentUrl[4]);

    if (isNaN(lastElementOfUrl)) {
      this.currentPageLoaded = this.breadcrumbs.find(route => route.router === url);
    } else {
      this.currentPageLoaded = this.breadcrumbs.find(route => route.router + `/${lastElementOfUrl}` === url);
    }

    if (currentUrl && currentUrlLength > 0 && currentUrl[currentUrlLength - 1].indexOf(';') !== -1) {
      const redirectUrl = currentUrl[2].split(';');
      const orderIdUrl = redirectUrl[1];
      const typeUrl = redirectUrl[2];
      if (typeUrl.split('=')[1] === 'simple') {
        this.router.navigate(['/user/orders/simple-order', orderIdUrl.split('=')[1]]).then(() => {
          this.firstBreadcrumb = this.routes.find(route => route.path === 'orders');
          this.secondBreadcrumb = this.routes.find(route => route.path === 'simple-order');
          this.showBreadcrumbs = true;
        });
      } else if (typeUrl.split('=')[1] === 'diagnostic') {
        this.router.navigate(['/user/orders/diagnostic-order', orderIdUrl.split('=')[1]]).then(() => {
          this.firstBreadcrumb = this.routes.find(route => route.path === 'orders');
          this.secondBreadcrumb = this.routes.find(route => route.path === 'diagnostic-order');
          this.showBreadcrumbs = true;
        });
      }
    }

    this.showBreadcrumbs = !!this.currentPageLoaded?.breadcrumbs;
    this.showTitle = !!this.currentPageLoaded?.showTitle;
    if (this.firstBreadcrumb?.name.toLocaleLowerCase() === 'profile') {
      this.logout = true;
      this.search = true;
    } else if (this.secondBreadcrumb?.name.toLocaleLowerCase() === 'simple.order' ||
      this.secondBreadcrumb?.name.toLocaleLowerCase() === 'diagnostic.order') {
      this.orderId = Number(currentUrl[currentUrlLength - 1]);
      this.search = true;
    } else {
      this.logout = false;
      this.search = false;
    }
  }

  onSearchParamChange(param) {
  }

  private setLanguages(languages) {
    languages.forEach(lng => {
      const name = this.translate.instant(lng.description.toUpperCase());
      this.languages.push({id: lng.id, text: name, value: lng.language, description: lng.description});
    });
  }

  updateLanguageValue(value) {
    const language = this.langs.find(lang => lang.id === value);
    this.translate.setDefaultLang(language.description);
    this.lang.setCurrentLang(language.description, language);
    this.resources.setLanguage(language);
  }

  handleLogoutClicked($event: boolean) {
    this.logoutClicked = $event;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  billingClicked($event: boolean) {
    if (this.user.pin_protect === 1) {
      this.displayResponsive = true;
    }
  }

  submit() {
    const p1 = this.pinForm.controls.p1.value;
    const p2 = this.pinForm.controls.p2.value;
    const p3 = this.pinForm.controls.p3.value;
    const p4 = this.pinForm.controls.p4.value;
    const p5 = this.pinForm.controls.p5.value;
    const p6 = this.pinForm.controls.p6.value;
    const pin = p1 + p2 + p3 + p4 + p5 + p6;
    this.analystService.validatePin(pin).subscribe(result => {
      this.router.navigateByUrl('/user/invoices');
    }, error => {
      this.functions.showError(error);
    });
    this.displayResponsive = false;
    this.pinForm.reset();
  }

  itemChange($event: Event) {

  }

  onChange(currentFieldId: any, nextFieldId: any) {
    if (this.pinForm.controls[currentFieldId].value.length === 1) {
      document.getElementById(nextFieldId).focus();
    }
  }
}
