export class AdminRoutesStatics {
  public static readonly getClients = 'clients';
  public static readonly updateClient = 'client/update';
  public static readonly createClient = 'client/create';
  public static readonly deleteClient = 'client/delete';
  public static readonly getAllClients = 'clients/all';

  public static readonly createSimpleSample = 'sample/simple/create';
  public static readonly updateSimpleSample = 'sample/simple/update';
  public static readonly deleteSimpleSample = 'sample/simple/delete';

  public static readonly createDiagnosticSample = 'sample/diagnostic/create';
  public static readonly updateDiagnosticSample = 'sample/diagnostic/update';
  public static readonly deleteDiagnosticSample = 'sample/diagnostic/delete';

  public static readonly getNextSampleId = 'sample/next_sample_id/get';
  public static readonly getSampleTypes = 'sample/sample_types/get';
  public static readonly modifySampleId = 'sample/client_id/modify';

  public static readonly getOrder = 'order/get';
  public static readonly getAnalystOrder = 'order/get';
  public static readonly getOrders = 'orders';
  public static readonly exportOrders = 'orders/csv/export';
  public static readonly getAnalystOrders = 'orders';
  public static readonly createOrder = 'order/create';
  public static readonly getAvailableSampleTypes = 'sample/sample_types/available/get';
  public static readonly getAvailableLaboratories = 'sample/laboratories/available/get';
  public static readonly getOrderSubtypes = 'order/subtypes/get';
  public static readonly getOrderSamplesCount = 'orders/samples_counts';
  public static readonly getAllSampleTypes = 'sample/sample_types/all';
  public static readonly getAdditionalInfoOptions = 'order/additional_info_options/get';
  public static readonly getOrderDeadlines = 'order/deadlines/get';
  public static readonly pinCheck = '/v1/analyse/pin_check';
  public static readonly modifyDeadline = 'order/deadline/modify';
  public static readonly submitOrder = 'order/submit';
  public static readonly submitStepTwo = 'order/state-2/validate';
  public static readonly submitStepThree = 'order/state-3/validate';
  public static readonly newVersion = 'order/create-new-version';
  public static readonly updateOrder = 'order/update';
  public static readonly deleteOrder = 'order/delete';
  public static readonly cancelOrder = 'order/cancel';
  public static readonly restore = 'order/restore';
  public static readonly getReportStatus = 'order/report-status';
  public static readonly getReportAtStatus = 'order/at-report-status';
  public static readonly getDiagnosticReport = 'order/generate-diagnostic-report';
  public static readonly sendReport = 'order/report/send';
  public static readonly analyzeMultipleCheckboxes = 'sample/analyze/multiple-checkboxes';
  public static readonly updateVisitInfo = 'order/visit-information/update';

  public static readonly getDashboardNumbers = 'dashboard_numbers';
  public static readonly getActivities = 'activities';

  public static readonly getProfileData = 'profile';
  public static readonly updateProfile = 'profile/update';
  public static readonly getLaboratories = 'profile/laboratories/get';
  public static readonly getPrices = 'prices';
  public static readonly updatePrice = 'price/update';
  public static readonly updateUserPrice = 'user/price/update';
  public static readonly restoreUserPrices = 'user/prices/restore';
  public static readonly getInvoiceTypes = '/v1/invoice_types';

  public static readonly getUsers = 'users';
  public static readonly getUser = 'user/get';
  public static readonly createUser = 'user/create';
  public static readonly updateUser = 'user/update';
  public static readonly deleteUser = 'user/delete';

  public static readonly createResponsible = '/v1/responsible/create';
  public static readonly getResponsibles = '/v1/user_responsibles/get';
  public static readonly deleteResponsible = '/v1/responsible/delete';

  public static readonly createPlan = 'order/plan/create';
  public static readonly deletePlan = 'order/plan/delete';
  public static readonly getPlans = 'order/plans/get';

  public static readonly getCollectMethods = '/v1/collect_methods/get';

  public static readonly getScoreCardModels = '/v1/analyst_creator/scorecard-models/get';
  public static readonly createScoreCardModel = '/v1/analyst_creator/scorecard-model/create';
  public static readonly updateScoreCardModel = '/v1/analyst_creator/scorecard-model/update';
  public static readonly deleteScoreCardModel = '/v1/analyst_creator/scorecard-model/delete';
  public static readonly getScoreCardField = '/v1/analyst_creator/field-types/get';
  public static readonly createScoreCardField = '/v1/analyst_creator/scorecard-model/create-field';
  public static readonly updateScoreCardField = '/v1/analyst_creator/scorecard-model/update-field';
  public static readonly deleteScoreCardField = '/v1/analyst_creator/scorecard-model/delete-field';
  public static readonly createFieldDropdownOption = '/v1/analyst_creator/scorecard-model/create-dropdown-option';
  public static readonly deleteFieldDropdownOption = '/v1/analyst_creator/scorecard-model/delete-dropdown-option';

  public static readonly getSamplesScoreCardModals = 'order/scorecard-models/get';
  public static readonly analyzeField = 'sample/analyze';
  public static readonly updateAnalyzedField = 'sample/analysis/update';
  public static readonly getSamples = 'order/filtered_samples';
  public static readonly getCorruptedSamples = 'order/filtered_samples/all';
  public static readonly checkReportValues = 'order/check-report-values';
  public static readonly validateScoreCard = 'scorecard_model/validate';
  public static readonly updateScoreCard = 'scorecard_model/update';
  public static readonly dropdownOptions = 'buffer_sample/magnification/dropdown_options/get';
  public static readonly setFreeOption = 'analyst_creator/scorecard-model/create-dropdown-option';
  public static readonly deleteFreeOption = 'analyst_creator/scorecard-model/delete-dropdown-option';
  public static readonly deleteRemarks = 'sample/analysis/delete';

  public static readonly getReportValueDropdowns = 'sample/report-value/dropdowns/get';
  public static readonly createReportValue = 'sample/diagnostic/report-value/create';
  public static readonly updateReportValue = 'sample/diagnostic/report-value/update';
  public static readonly analogySamples = 'sample/diagnostic/analogy_options/get';
  public static readonly checkSampleNumber = 'sample/check-sample-number';
  public static readonly splitSample = 'sample/simple/split';
  public static readonly getCalendar = 'calendar/get';
  public static readonly getCalendarAnalyst = 'calendar-analyst/get';
  public static readonly getOrderUsers = 'order/users/search';
  public static readonly getAllUsers = 'order/users/search_2';
  public static readonly deleteScoreCardRow = 'sample/analysis/row/delete';
  public static readonly templateDownload = 'template/download';
  public static readonly exportSamples = 'results/export';
  public static readonly regenerateLabels = 'order/labels/regenerate';
  public static readonly templateImport = 'template/import';

}
