import {Inject, Injectable} from '@angular/core';
import {CanActivate, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService, I18nService} from '../services';

@Injectable({
  providedIn: 'root'
})
export class IsAppReadyGuard implements CanActivate {
  private isReady = false;

  constructor(@Inject(UserService) private userService: UserService, @Inject(I18nService) private i18n: I18nService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.isReady) {
      return this.isReady;
    }

    this.i18n.init();
    if (this.userService.currentUser()) {
      this.i18n.currentLang = this.userService.locale();
    }

    this.isReady = true;
    return this.isReady;
  }
}
