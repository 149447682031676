import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {InvoiceBexio} from '../../../common/models/bexio';
import {FunctionsService} from '../../services/functions.service';
import {AdminStatics} from '../../statics/admin-statics';
import {DatePipe} from '@angular/common';
import {User} from '../../models/user';
import {TranslateService} from '@ngx-translate/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-prolabo-invoices-pagination-list',
  templateUrl: './pagination-list.component.html',
  styleUrls: ['./pagination-list.component.scss']
})
export class InvoicesPaginationListComponent implements OnInit, OnChanges {

  @Input() items: InvoiceBexio[];
  @Input() itemValues: any;
  @Input() headers: any[];
  @Input() user: User;
  @Input() total;

  @Output() sortValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() limitValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() invoicePreview: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterByStatusEvent: EventEmitter<number> = new EventEmitter<number>();

  sortHeaderValues = [];
  page = 0;
  numberRows = 10;

  displayModal = false;
  itemToDelete = null;
  statusIsSelected = false;
  filterByStatus = [];
  selectedStatus: FormControl = new FormControl();

  constructor(public functions: FunctionsService,
              public translate: TranslateService,
              public datePipe: DatePipe) {
  }

  ngOnInit() {
    this.headers.forEach(header => {
      this.sortHeaderValues.push({param: header[AdminStatics.sortLabel], asc: true});
    });

    this.translateFilter();

    this.translate.onLangChange.subscribe(() => {
      this.translateFilter();
    });

  }

  translateFilter() {
    this.filterByStatus = [
      {text: this.translate.instant('ALL'), id: -1, value: -1},
      {text: this.translate.instant('DRAFT'), id: 7, value: 7},
      {text: this.translate.instant('PENDING'), id: 8, value: 8},
      {text: this.translate.instant('PAID'), id: 9, value: 9},
      {text: this.translate.instant('CANCELED'), id: 19, value: 19},
      {text: this.translate.instant('OVERDUE'), id: 4, value: 4},
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  getStatus(id: number, isValidTo): string {
    let result = '';
    if (id === 7) {
      result = 'DRAFT';
    }

    if (id === 8) {
      result = 'PENDING';
    }

    if (id === 9) {
      result = 'PAID';
    }

    if (id === 19) {
      result = 'CANCELED';
    }

    const currentDate = this.datePipe.transform(new Date().toISOString(), 'yyyy-MM-dd');
    if (new Date(currentDate) > new Date(isValidTo) && id !== 19 && id !== 9) {
      result = 'OVERDUE';
    }

    return result;
  }

  sortByLabel(param: string) {
    this.clearOtherValues(param);
    const sortValue = this.sortHeaderValues.filter(x => x.param === param)[0];
    sortValue.asc = !sortValue.asc;
    this.sortValue.emit(sortValue);
  }

  deleteItem() {
    this.itemDelete.emit(this.itemToDelete);
    this.displayModal = false;
    this.itemToDelete = null;
  }

  openConfirmDialog(id) {
    this.displayModal = true;
    this.itemToDelete = id;
  }

  closeConfirmDialog() {
    this.itemToDelete = null;
    this.displayModal = false;
  }

  getLastHeader() {
    return this.headers[this.headers.length - 1];
  }

  clearOtherValues(param) {
    this.sortHeaderValues.forEach(header => {
      if (param !== header.param) {
        header.asc = true;
      }
    });
  }

  getAscValueForHeader(header) {
    return this.sortHeaderValues.filter(x => x.param === header)[0].asc;
  }

  onPageChange(event) {
    this.page = event.page;
    this.pageValue.emit(event.page);
  }

  onLimitChange(event) {
    this.numberRows = event.value;
    this.page = 0;
    this.limitValue.emit(event.value);
  }

  preview(id: number) {
    this.invoicePreview.emit(id);
  }

  edit(id: number) {
    const url = 'https://office.bexio.com/index.php/kb_invoice/show/id';
    window.open(`${url}/${id}`, '_blank');
  }

  handleFilterStatus($event: any) {
    this.filterByStatusEvent.emit($event.value);
  }
}
