import { Component, OnInit } from '@angular/core';
import { Sample } from '../../../../models/order';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '../../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modals-corrupted-samples',
  templateUrl: './corrupted-samples.component.html',
  styleUrls: ['./corrupted-samples.component.scss']
})
export class CorruptedSamplesComponent implements OnInit {
  items: Sample[];
  headers: [];
  selectedValues: any[] = null;
  allIds: number[];
  allItemsSelected = false;
  selectedAll: any = null;
  form: FormGroup;
  formData: FormData = new FormData();

  constructor(public ref: DynamicDialogRef,
              private toast: ToastService,
              private translate: TranslateService,
              private config: DynamicDialogConfig,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.allIds = new Array(0);
    this.items = this.config.data.samples;
    this.headers = this.config.data.headers;
    this.items.forEach(sample => {
      this.allIds.push(sample.id);
    });

    this.form = this.formBuilder.group({
      inspection_date: ['', Validators.required],
      asbestos_removal_company: ['', Validators.required],
    });
  }

  check($event: any) {
    if ($event.find(x => x === 'all')) {
      this.allItemsSelected = true;
      this.selectedValues = this.allIds;
    } else {
      this.allItemsSelected = false;
      this.selectedValues = null;
    }
  }

  validate() {
    if (this.allItemsSelected) {
      this.ref.close({
        ids: this.allIds.sort((n1, n2) => n1 - n2),
        form: this.form.value,
        annexes: this.formData
      });
    } else {
      if (this.selectedValues != null) {
        this.ref.close({
          ids: this.selectedValues.sort((n1, n2) => n1 - n2),
          form: this.form.value,
          annexes: this.formData
        });
      } else {
        if (this.selectedValues == null) {
          this.toast.showBottomCenterError(this.translate.instant('FORM.SAMPLE'));
        }
        if (!this.form.valid) {
          this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
        }
      }
    }
  }

  getCollectMethod(id) {
    switch (id) {
      case 1:
        return 'COLLECT_METHOD_ID_1';
      case 2:
        return 'COLLECT_METHOD_ID_2';
      case 3:
        return 'COLLECT_METHOD_ID_3';
    }
  }

  valueChange(event, annex) {
    this.formData.delete(`${annex}[]`);
    let reader;
    if (event.target.files && event.target.files[0]) {
      Array.from(event.target.files).forEach((file: any) => {
        reader = new FileReader();
        // tslint:disable-next-line:no-shadowed-variable
        reader.onload = (event: ProgressEvent) => {
          const fileInBlob = new File([this.dataURLToBlob((event.target as FileReader).result)],
            annex, {type: `application/pdf`});
          this.formData.append(`${annex}[]`, fileInBlob);
        };
        reader.readAsDataURL(file);
      });
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }
}
