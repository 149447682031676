<div class="clients-container">
  <app-dialog-header [title]="'CLIENT_ASSIGNED'" (iconClick)="ref.close()"></app-dialog-header>
  <div class="p-grid" *ngIf="!existingClient && !clientNew">
    <div class="p-col-12 p-xl-6">
      <p-card (click)="exitingClient()">
        <img src="../../../../../../assets/icons/male-people-group.svg" width="81" height="81" alt="">
        <p class="main-text">{{'CLIENT.EXISTING' | translate}}</p>
      </p-card>
    </div>
    <div class="p-col-12 p-xl-6">
      <p-card (click)="newClient()">
        <img src="../../../../../../assets/icons/add-large.svg" alt="">
        <p class="main-text">{{'ADD_NEW_CLIENT' | translate}}</p>
      </p-card>
    </div>
  </div>
  <ng-container *ngIf="existingClient">
    <ng-container *ngFor="let item of clients">
      <div class="list-item">
        <div class="sublist-items">
          <div class="p-grid item-wrapper">
            <div class="p-col-12 p-md-8 label-radio" (click)="clientClicked(item.id)">
              <p-radioButton [name]="item.first_name" [(ngModel)]="selectedClient"
                             class="radio"></p-radioButton>
              <p>{{ item.first_name }} {{ item.last_name }}</p>
            </div>
            <div class="p-col12 p-md-4 icons">
              <p class="space">
                <app-prolabo-icon class="icon-white" icon="edit" (click)="editClient(item)"></app-prolabo-icon>
              </p>
              <p>
                <app-prolabo-icon class="icon-white" icon="trash-bin" (click)="deleteClient(item.id)"></app-prolabo-icon>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="clientNew">
    <div class="p-grid form">
      <form [formGroup]="clientForm">
        <div class="p-grid p-fluid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'business'"
                               [id]="'business'"
                               [keyword]="'COMPANY'"
                               [type]="'text'"
                               [inputType]="'basic'"
                               [defaultValue]="client ? client.company : null"
                               formControlName="company"></app-prolabo-input>
          </div>
          <div class="p-col-12 p-md-4">
            <app-prolabo-input [for]="'name'"
                               [id]="'name'"
                               [keyword]="'NAME'"
                               [type]="'text'"
                               [inputType]="'basic'"
                               [defaultValue]="client ? client.first_name : null"
                               formControlName="first_name"></app-prolabo-input>
          </div>
          <div class="p-col-12 p-md-8">
            <app-prolabo-input [for]="'surname'"
                               [id]="'surname'"
                               [keyword]="'SURNAME'"
                               [type]="'text'"
                               [inputType]="'basic'"
                               [defaultValue]="client ? client.last_name : null"
                               formControlName="last_name"></app-prolabo-input>
          </div>
          <div class="p-col-12">
            <div class="p-grid">
              <div class="p-col-4">
                <app-prolabo-input [for]="'address'"
                                   [id]="'address'"
                                   [keyword]="'ADDRESS'"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   [defaultValue]="client ? client.address : null"
                                   formControlName="address"></app-prolabo-input>
              </div>
              <div class="p-col-4">
                <app-prolabo-input [for]="'postal_code'"
                                   [id]="'postal_code'"
                                   [keyword]="'POSTCODE'"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   [defaultValue]="client ? client['postal_code'] : null"
                                   formControlName="postal_code"></app-prolabo-input>
              </div>
              <div class="p-col-4">
                <app-prolabo-input [for]="'city'"
                                   [id]="'city'"
                                   [keyword]="'CITY'"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   [defaultValue]="client ? client['city'] : null"
                                   formControlName="city"></app-prolabo-input>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
  <div class="p-col-12 extra"></div>
  <div class="p-grid client-dialog-footer">
    <div class="p-col-12 p-md-4" *ngIf="existingClient || clientNew">
      <app-prolabo-icon-button
        (click)="back()"
        [icon]="'left-arrow'"
        [position]="'left'"
        [name]="'PREVIOUS'"
        type="primary"></app-prolabo-icon-button>
    </div>
    <div class="p-col-12 p-md-4" *ngIf="!existingClient && !clientNew">
      <app-prolabo-icon-button
        (click)="close()"
        [icon]="'left-arrow'"
        [name]="'PREVIOUS'"
        [position]="'left'"
        type="primary"></app-prolabo-icon-button>
    </div>
    <div class="p-md-4"></div>
    <div class="p-md-4">
      <ng-container *ngIf="clientNew">
        <ng-container *ngIf="edit">
          <app-prolabo-icon-button
            (click)="updateClient()"
            [icon]="'check'"
            [name]="'UPDATE'"
            type="secondary"></app-prolabo-icon-button>
        </ng-container>
        <ng-container *ngIf="create">
          <app-prolabo-icon-button
            (click)="createClient()"
            [icon]="'check'"
            [name]="'CREATE'"
            type="secondary"></app-prolabo-icon-button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>


