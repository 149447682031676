<div class="clients-container">
  <app-dialog-header [title]="'ADD_PLAN'" (iconClick)="ref.close()"></app-dialog-header>
  <form [formGroup]="planForm">
    <div class="p-grid p-fluid content">
      <div class="p-col-12" *ngIf="!update">
        <app-prolabo-input [for]="'name'"
                           [id]="'name'"
                           [keyword]="'PLAN_NAME'"
                           [type]="'text'"
                           placeholder="Exemple (1er étage est)"
                           [required]="'true'"
                           [inputType]="'basic'"
                           formControlName="name">
        </app-prolabo-input>
      </div>
      <div class="p-col-12 update" *ngIf="update">
        <div class="name">
          <p class="small">{{ 'PLAN_NAME' | translate | uppercase }}</p>
          <p>{{ plan.name }}</p>
        </div>
      </div>
    </div>
    <div class="p-grid content" *ngIf="!update">
      <div class="p-col-12">
        <app-prolabo-icon-input [for]="'plan'"
                                [id]="'plan'"
                                [keyword]="'Plan (Seul les pdfs d’une seule page contenant un seul plan sont acceptés.)'"
                                [type]="'file'"
                                [icon]="'visible'"
                                [inputType]="'basic'"
                                [required]="'true'"
                                [accept]="'application/pdf, image/*'"
                                [regularInput]="true"
                                (change)="valueChange($event)">
        </app-prolabo-icon-input>
      </div>
    </div>
    <div class="p-grid content" *ngIf="imageSrc">
      <div class="p-col-12" id="photo">
        <img id="plan-img" [src]="imageSrc">
      </div>
    </div>
  </form>
  <div class="p-grid extra" *ngIf="!update"></div>
  <div class="p-grid client-dialog-footer" *ngIf="!update">
    <div class="p-col-12 p-md-6">
    </div>
    <div class="p-col-12 p-md-6">
      <app-prolabo-icon-button
        (click)="submit()"
        [icon]="'arrow-right-white'"
        [name]="'VALIDATE'"
        type="primary"></app-prolabo-icon-button>
    </div>
  </div>
</div>
