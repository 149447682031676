import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdminStatics } from '../statics/admin-statics';
import { InputValidation } from '../../models/input-validation';
import { ToastService } from '../../shared/services';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(private translate: TranslateService, private toast: ToastService) { }

  public getProgressBarTooltipValue(id) {
    let tooltipValue = '';
    switch (id) {
      case 1:
        tooltipValue = this.translate.instant('CREATION');
        break;
      case 7:
        tooltipValue = this.translate.instant('CREATION');
        break;
      case 2:
        tooltipValue = '1 ' + this.translate.instant('ORDERED');
        break;
      case 8:
        tooltipValue = '1 ' + this.translate.instant('ORDERED');
        break;
      case 3:
        tooltipValue = '2 ' + this.translate.instant('ANALYSING');
        break;
      case 9:
        tooltipValue = '2 ' + this.translate.instant('ANALYSING');
        break;
      case 4:
        tooltipValue = '3 ' + this.translate.instant('ANALYSED');
        break;
      case 5:
        tooltipValue = '4 ' + this.translate.instant('FINISHED');
        break;
      case 10:
        tooltipValue = '4 ' + this.translate.instant('FINISHED');
        break;
      case 11:
        tooltipValue = '0 ' + this.translate.instant('CANCELLED');
        break;
      case 6:
        tooltipValue = '0 ' + this.translate.instant('CANCELLED');
        break;
      default:
        break;
    }
    return tooltipValue;
  }

  public getProgressBarValues(id) {
    const progressBarValues: any = [
      {value: 0},
      {name: ''},
      {step: 0}
    ];

    switch (id) {
      case 1:
        progressBarValues.value = 0;
        progressBarValues.name = 'CREATION';
        progressBarValues.step = 0;
        break;
      case 7:
        progressBarValues.value = 0;
        progressBarValues.name = 'CREATION';
        progressBarValues.step = 0;
        break;
      case 2:
        progressBarValues.value = 25;
        progressBarValues.name = 'ORDERED';
        progressBarValues.step = 1;
        break;
      case 8:
        progressBarValues.value = 25;
        progressBarValues.name = 'ORDERED';
        progressBarValues.step = 1;
        break;
      case 3:
        progressBarValues.value = 25;
        progressBarValues.name = 'ANALYSING';
        progressBarValues.step = 2;
        break;
      case 9:
        progressBarValues.value = 25;
        progressBarValues.name = 'ANALYSING';
        progressBarValues.step = 2;
        break;
      case 4:
        progressBarValues.value = 50;
        progressBarValues.name = 'ANALYSED';
        progressBarValues.step = 3;
        break;
      case 5:
        progressBarValues.value = 100;
        progressBarValues.name = 'FINISHED';
        progressBarValues.step = 4;
        break;
      case 10:
        progressBarValues.value = 100;
        progressBarValues.name = 'FINISHED';
        progressBarValues.step = 4;
        break;
      case 6:
        progressBarValues.value = 0;
        progressBarValues.name = 'CANCELLED';
        progressBarValues.step = 0;
        break;
      case 11:
        progressBarValues.value = 0;
        progressBarValues.name = 'CANCELLED';
        progressBarValues.step = 0;
        break;
      default:
        break;
    }
    return progressBarValues;
  }

  public getFilterName(filter: number) {
    switch (filter) {
      case 2:
        return 'ORDERED';
      case 3:
        return 'ANALYSING';
      case 4:
        return 'ANALYSED';
      case 5:
        return 'FINISHED';
      default:
        return '';
    }
  }

  public getItemValues(items: []) {
    let result = '';
    if (items && items.length > 0) {
      items.forEach((item: any, index) => {
        let name = '';
        switch (item.type_id) {
          case 1:
            name = AdminStatics.vdi;
            result += this.translate.instant(name) + '(' + item.count + ')';
            if (index !== items.length - 1) {
              result += ', ';
            } else {
              result += ' ';
            }
            break;
          case 2:
            name = AdminStatics.asbestos;
            result += this.translate.instant(name) + '(' + item.count + ')';
            if (index !== items.length - 1) {
              result += ', ';
            } else {
              result += ' ';
            }
            break;
          case 3:
            name = AdminStatics.lead;
            result += this.translate.instant(name) + '(' + item.count + ')';
            if (index !== items.length - 1) {
              result += ', ';
            } else {
              result += ' ';
            }
            break;
          case 4:
            name = AdminStatics.pcb;
            result += this.translate.instant(name) + '(' + item.count + ')';
            if (index !== items.length - 1) {
              result += ', ';
            } else {
              result += ' ';
            }
            break;
          case 5:
            name = AdminStatics.hap;
            result += this.translate.instant(name) + '(' + item.count + ')';
            if (index !== items.length - 1) {
              result += ', ';
            } else {
              result += ' ';
            }
            break;
          case 6:
            name = AdminStatics.asbestosInPowder;
            result += this.translate.instant(name) + '(' + item.count + ')';
            if (index !== items.length - 1) {
              result += ', ';
            } else {
              result += ' ';
            }
            break;
          case 7:
            name = AdminStatics.pcbcp;
            result += this.translate.instant(name) + '(' + item.count + ')';
            if (index !== items.length - 1) {
              result += ', ';
            } else {
              result += ' ';
            }
            break;
          case 8:
            name = AdminStatics.asbestosRocks;
            result += this.translate.instant(name) + '(' + item.count + ')';
            if (index !== items.length - 1) {
              result += ', ';
            } else {
              result += ' ';
            }
            break;
          default:
            break;
        }
      });
    }
    return result;
  }

  public getFilterItemValues(itemsToList) {
    const items = [];
    items.push({id: -1, text: this.translate.instant('NONE')});
    itemsToList.forEach((item: any) => {
      switch (item.id) {
        case 7:
          items.push({id: item.id, text: this.translate.instant('PCB_CP')});
          break;
        default:
          items.push({id: item.id, text: this.translate.instant(String(item.slug).toUpperCase())});
          break;
      }
    });
    return items;
  }

  public showError(error) {
    if (typeof error.error.message === 'string') {
      this.toast.showBottomCenterError(error.error.message);
    } else {
      this.showErrorFromField(error.error.message);
    }
  }

  private showErrorFromField(error: InputValidation) {
    this.checkObj(error.locale);
    this.checkObj(error.lang_id);
    this.checkObj(error.email);
    this.checkObj(error.invoice_email);
    this.checkObj(error.contact_person);
    this.checkObj(error.department);
    this.checkObj(error['email.0']);
    this.checkObj(error['email.1']);
    this.checkObj(error['email.2']);
    this.checkObj(error['email.3']);
    this.checkObj(error['email.4']);
    this.checkObj(error['email.5']);
    this.checkObj(error['email.6']);
    this.checkObj(error['email.7']);
    this.checkObj(error.user_id);
    this.checkObj(error.id);
    this.checkObj(error.locale);
    this.checkObj(error.address);
    this.checkObj(error.site);
    this.checkObj(error.invoicing_address);
    this.checkObj(error.responsible_id);
    this.checkObj(error.remarks);
    this.checkObj(error.locale);
    this.checkObj(error.asbestos_long_duration);
    this.checkObj(error.asbestos_standard);
    this.checkObj(error.asbestos_urgent);
    this.checkObj(error.asbestos_express);
    this.checkObj(error.buffer_qualitative);
    this.checkObj(error.buffer_quantitative);
    this.checkObj(error.lead);
    this.checkObj(error.date_of_sampling);
    this.checkObj(error.pcb);
    this.checkObj(error.hap);
    this.checkObj(error.vdi);
    this.checkObj(error.date);
    this.checkObj(error.first_name);
    this.checkObj(error.air_intake_volume);
    this.checkObj(error.last_name);
    this.checkObj(error.title_id);
    this.checkObj(error.company);
    this.checkObj(error.email);
    this.checkObj(error.mobile);
    this.checkObj(error.phone);
    this.checkObj(error.address);
    this.checkObj(error.postal_code);
    this.checkObj(error.city);
    this.checkObj(error.password);
    this.checkObj(error.password_confirmation);
    this.checkObj(error.locale);
    this.checkObj(error.relative_humidity);
    this.checkObj(error.temperature);
    this.checkObj(error.signature);
    this.checkObj(error.company_logo);
    this.checkObj(error.text_value);
    this.checkObj(error.order_type_id);
    this.checkObj(error.sample_number);
    this.checkObj(error.order_id);
    this.checkObj(error.locale);
    this.checkObj(error.id);
    this.checkObj(error.remarks);
    this.checkObj(error.no_prolabo);
    this.checkObj(error.send_email);
    this.checkObj(error.sample_ids);
    this.checkObj(error.sample_id);
    this.checkObj(error.total_filter_area);
    this.checkObj(error.total_area_of_a_field);
    this.checkObj(error.total_area_analyzed);
    this.checkObj(error.number_of_fields_observed);
    this.checkObj(error.volume_of_air_taken);
    this.checkObj(error.sample_type_id);
    this.checkObj(error.description);
    this.checkObj(error.client_id);
    this.checkObj(error.site_img);
    this.checkObj(error['cc.0']);
    this.checkObj(error['cc.1']);
    this.checkObj(error['cc.2']);
    this.checkObj(error['cc.3']);
    this.checkObj(error['cc.4']);
    this.checkObj(error['cc.5']);
    this.checkObj(error.order_subtype);
    this.checkObj(error.analysis_remarks);
    this.checkObj(error.magnifications);
    this.checkObj(error.number_of_fields);
    this.checkObj(error.area_of_an_image);
    this.checkObj(error.total_surface_analyzed);
    this.checkObj(error.magnifications_2);
    this.checkObj(error.number_of_fields_2);
    this.checkObj(error.area_of_an_image_2);
    this.checkObj(error.total_surface_analyzed_2);
    this.checkObj(error.place_of_sampling);
    this.checkObj(error.mandate);
    this.checkObj(error.principal);
    this.checkObj(error.asbestos_quantity_id);
    this.checkObj(error.place_utilisation_id);
    this.checkObj(error.external_influence_id);
    this.checkObj(error.vdi_measure_before_works);
    this.checkObj(error.immediate_measurements);
    this.checkObj(error.sanitation);
    this.checkObj(error.risk_evaluation);
    this.checkObj(error.surface_state_id);
    this.checkObj(error.accompanying);
    this.checkObj(error.date_of_diagnosis);
    this.checkObj(error.deviations);
    this.checkObj(error.order_additional_information_id);
    this.checkObj(error.potentially_dangerous_situations);
    this.checkObj(error.pre_visit_date);
    this.checkObj(error.reservations);
    this.checkObj(error.scope_of_diagnosis);
    this.checkObj(error.material_type);
    this.checkObj(error.analogy_id);
    this.checkObj(error.order);
    this.checkObj(error.plan);
    this.checkObj(error.notes);
    this.checkObj(error.coordinate_x);
    this.checkObj(error.coordinate_y);
    this.checkObj(error.sample_img);
    this.checkObj(error.additional_description);
    this.checkObj(error.subject);
    this.checkObj(error.content);
    this.checkObj(error.annex_1);
    this.checkObj(error.annex_2);
    this.checkObj(error.annex_3);
    this.checkObj(error.annex_4);
    this.checkObj(error.annex_5);
    this.checkObj(error.annex_6);
    this.checkObj(error.cc);
    this.checkObj(error.sanitation_id);
    this.checkObj(error.urgency_and_measures);
    this.checkObj(error.process_of_elimination);
    this.checkObj(error.process_of_elimination_img);
    this.checkObj(error.sanitation_txt);
    this.checkObj(error.initials);
    this.checkObj(error.asbestos_removal_company);
    this.checkObj(error.inspection_date);
    this.checkObj(error['annex_1.0']);
    this.checkObj(error['annex_1.1']);
    this.checkObj(error['annex_1.2']);
    this.checkObj(error['annex_1.3']);
    this.checkObj(error['annex_2.0']);
    this.checkObj(error['annex_2.1']);
    this.checkObj(error['annex_2.2']);
    this.checkObj(error['annex_2.3']);
    this.checkObj(error['annex_3.0']);
    this.checkObj(error['annex_3.1']);
    this.checkObj(error['annex_3.2']);
    this.checkObj(error['annex_3.3']);
    this.checkObj(error['annex_4.0']);
    this.checkObj(error['annex_4.1']);
    this.checkObj(error['annex_4.2']);
    this.checkObj(error['annex_4.3']);
    this.checkObj(error['annex_5.0']);
    this.checkObj(error['annex_5.1']);
    this.checkObj(error['annex_5.2']);
    this.checkObj(error['annex_5.3']);
    this.checkObj(error['annex_6.0']);
    this.checkObj(error['annex_6.1']);
    this.checkObj(error['annex_6.2']);
    this.checkObj(error['annex_6.3']);
    this.checkObj(error['annex_7.0']);
    this.checkObj(error['annex_7.1']);
    this.checkObj(error['annex_7.2']);
    this.checkObj(error['annex_7.3']);
  }

  private checkObj(error: string): void {
    if (error) {
      this.toast.showBottomCenterError(error);
    }
  }

  getTypeName(typeId) {
    let result = '';
    // tslint:disable-next-line:radix
    switch (Number.parseInt(typeId)) {
      case 1:
        result = AdminStatics.vdi;
        break;
      case 2:
        result = AdminStatics.asbestos;
        break;
      case 3:
        result = AdminStatics.lead;
        break;
      case 4:
        result = AdminStatics.pcb;
        break;
      case 5:
        result = AdminStatics.hap;
        break;
      case 6:
        result = AdminStatics.asbestosInPowder;
        break;
      case 7:
        result = AdminStatics.pcbcp;
        break;
      case 8:
        result = AdminStatics.asbestosRocks;
        break;
    }
    return result;
  }

  showPdf(content, text): void {
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const a = document.createElement('a');

    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {type: 'application/pdf;base64'});
    const fileURL = URL.createObjectURL(file);
    a.href = fileURL;
    a.download = text;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
    URL.revokeObjectURL(fileURL);
  }

}
