import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropdownModel, RadioModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { User } from 'src/app/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { I18nService, ToastService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from 'src/app/shared/services/resources.service';

@Component({
  selector: 'app-prolabo-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit, OnChanges {
  @Input() currentUser: User;
  @Input() validateClicked = false;
  languages: DropdownModel[] = [];
  titles: DropdownModel[] = [];
  profileForm: FormGroup;
  signaturePad: any;
  profileOriginalValue: any;
  company: RadioModel[] = [];

  type: FormControl;
  selectedType: any = null;
  isCompany: boolean;

  @Output() profileFormEvent = new EventEmitter<FormGroup>();
  @Output() profileOriginalFormEvent = new EventEmitter<any>();
  @Output() validationClicked = new EventEmitter<boolean>();
  @Output() isCompanyEvent = new EventEmitter<boolean>();

  constructor(private translate: TranslateService,
              private resources: ResourcesService,
              private formBuilder: FormBuilder,
              private toast: ToastService,
              private lang: I18nService) {
  }

  ngOnInit(): void {
    if (this.resources.getPlatformTitles()) {
      this.setTitles(this.resources.getPlatformTitles().titles);
    } else {
      this.setTitles(this.resources.backupTitles.titles);
    }
    if (this.resources.getPlatformLanguages()) {
      this.setLanguages(this.resources.getPlatformLanguages().languages);
    } else {
      this.setLanguages(this.resources.backupLanguages.languages);
    }
    this.setEnterprise();
    this.type = this.formBuilder.control('');
    this.profileForm = this.formBuilder.group({
      first_name: [this.currentUser.first_name, [Validators.required]],
      last_name: [this.currentUser.last_name, [Validators.required]],
      email: [this.currentUser.email, [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      invoice_email: [this.currentUser.invoice_email, [Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]],
      company: [this.currentUser.company, [Validators.required]],
      title_id: [this.currentUser.title_id, [Validators.required]],
      address: [this.currentUser.address.address, [Validators.required]],
      postal_code: [this.currentUser.address.postal_code, [Validators.required]],
      city: [this.currentUser.address.city, [Validators.required]],
      phone: [this.currentUser.phone, [Validators.required]],
      mobile: [this.currentUser.mobile, [Validators.required]],
      lang_id: [this.currentUser.lang_id, [Validators.required]],
      is_company: [''],
      signature: [''],
      locale: [this.lang.currentLang]
    });
    this.profileOriginalValue = this.profileForm.value;
    this.profileOriginalFormEvent.emit(this.profileOriginalValue);
    if (+this.currentUser.is_company === 0) {
      this.selectedType = this.company[0];
      this.isCompany = false;
      this.profileForm.patchValue({
        is_company: 0
      });
    } else if (+this.currentUser.is_company === 1) {
      this.selectedType = this.company[1];
      this.isCompany = true;
      this.profileForm.patchValue({
        is_company: 1
      });
    }
  }

  updateLanguageValue(value) {
    this.profileForm.patchValue({
      lang_id: value
    });
  }

  updateTitleValue(value) {
    this.profileForm.patchValue({
      title_id: value
    });
  }

  private setLanguages(languages) {
    languages.forEach(lng => {
      const name = this.translate.instant(lng.language.toUpperCase());
      this.languages.push({id: lng.id, text: name, value: lng.language, description: lng.description});
    });
  }

  private setTitles(titles) {
    titles.forEach(title => {
      const name = this.translate.instant(title.name.toUpperCase());
      this.titles.push({id: title.id, text: name, value: title.name});
    });
  }

  private setEnterprise() {
    this.company.push({key: 0, name: 'PRIVATE'});
    this.company.push({key: 1, name: 'COMPANY'});
  }

  clear() {
    this.signaturePad.clear();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  checkPasswords(value) {
    this.profileForm.patchValue({
      password_confirmation: value
    });
    if (this.profileForm.controls.password.value !== this.profileForm.controls.password_confirmation.value) {
      this.toast.showBottomCenterError(this.translate.instant('FORM.PASSWORD'));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validateClicked) {
      setTimeout(() => {
        if (this.validateClicked) {
          if (this.isCompany) {
            this.profileFormEvent.emit(this.profileForm);
            this.validationClicked.emit(false);
          } else if (!this.isCompany) {
            this.profileForm.removeControl('company');
            this.profileFormEvent.emit(this.profileForm);
            this.validationClicked.emit(false);
          }
        }
      }, 0);
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  valueChange(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: ProgressEvent) => {
        const file = new File([this.dataURLToBlob((event.target as FileReader).result)],
          'signature', {type: `image/jpeg`});
        this.profileForm.patchValue({
          signature: file
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  handleTypeChange($event: any) {
    if ($event.key === 0) {
      this.isCompany = false;
      this.profileForm.patchValue({
        is_company: 0
      });
    } else if ($event.key === 1) {
      this.isCompany = true;
      this.profileForm.patchValue({
        is_company: 1
      });
    }
  }
}
