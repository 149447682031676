import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Routes} from '../../../models/routes';
import {AuthService, I18nService} from 'src/app/shared/services';
import {Router} from '@angular/router';
import {ResourcesService} from '../../../shared/services/resources.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnChanges {
  @Input() first: Routes;
  @Input() second: Routes;
  @Input() third: Routes;
  @Input() state: string;
  @Input() logout = false;
  @Input() stockRoute: string;

  @Output() logoutClicked = new EventEmitter<boolean>();

  orderState = null;

  constructor(private authService: AuthService, private resources: ResourcesService, private router: Router, private lang: I18nService) {
  }

  ngOnInit(): void {
    this.getState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.state) {
      this.getState();
    }
  }

  getState() {
    switch (this.state) {
      case 'client.preveling':
        this.orderState = 'PREVELING';
        break;
      case 'client.sent':
        this.orderState = 'SENT';
        break;
      case 'client.inprocess':
        this.orderState = 'ANALYZING';
        break;
      case 'admin.analyzed':
        this.orderState = 'ANALYSED';
        break;
      case 'client.completed':
        this.orderState = 'FINISHED';
        break;
    }
  }

  logOut() {
    this.logoutClicked.emit(true);
    this.authService.logOut('/v1/logout', {locale: this.lang.currentLang}, this.resources.getStorageUser().token).subscribe(result => {
      if (this.stockRoute === 'user') {
        this.router.navigateByUrl(`/login`).then(() => {
          localStorage.removeItem('prolaboUser');
        });
      } else {
        this.router.navigateByUrl(`/admin/login`).then(() => {
          localStorage.removeItem('prolaboUser');
        });
      }
    }, error => {
      if (this.stockRoute === 'user') {
        this.router.navigateByUrl('/login');
      } else {
        this.router.navigateByUrl('/admin/login');
      }
    });
  }
}
