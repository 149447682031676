<p-card>
  <div class="p-grid">
    <div class="p-xs-hide p-md-12">
      <div class="p-grid">
        <div class="p-col-4">
          <app-prolabo-icon-button
            [name]="'NEW_ORDER'"
            type="primary"
            [icon]="'add'" (click)="createOrderTemp()"></app-prolabo-icon-button>
        </div>
        <div class="p-col-8">
          <app-prolabo-icon-input [keyword]="'RESEARCH'"
                                  [icon]="'search'"
                                  (OnValueChange)="onSearchParamChange($event)">
          </app-prolabo-icon-input>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-12 orders">
            <app-prolabo-order-item [items]="orders"
                                    [headers]="orderHeaders"
                                    [total]="totalOrders"
                                    [itemValues]="samples"
                                    [totalItems]="totalSamples"
                                    [filter]="stepParam"
                                    [laboratories]="laboratories"
                                    [filterItems]="sampleTypes"
                                    (filterByOrderCity)="handleFilterByOrderCity($event)"
                                    (filterBy)="filterBySample($event)"
                                    (filterDelete)="deleteParam($event)"
                                    (limitValue)="changeLimit($event)"
                                    (pageValue)="changeOffset($event)"
                                    (sortValue)="sortByLabel($event)">
            </app-prolabo-order-item>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-hidden">
      <app-prolabo-last-orders-list
        [items]="orders"
        [shouldSort]="false"
        [user]="true"
        [headers]="headers">
      </app-prolabo-last-orders-list>
      <div class="p-grid">
        <div class="p-col-12 new-order-mobile">
          <app-prolabo-icon-button
            [name]="'NEW_ORDER'"
            type="primary"
            [icon]="'add'" (click)="createOrder()">
          </app-prolabo-icon-button>
        </div>
      </div>
    </div>
  </div>
</p-card>
