import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from 'src/app/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { I18nService, ToastService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { DropdownModel, RadioModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { ResourcesService } from 'src/app/shared/services/resources.service';

@Component({
  selector: 'app-prolabo-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() state: string;
  @Input() validateClicked = false;
  @Input() isUser: boolean;
  userForm: FormGroup;
  languages: DropdownModel[] = [];
  titles: DropdownModel[] = [];
  company: RadioModel[] = [];

  selectedType: any = null;
  isCompany: boolean;

  @Output() validationClicked = new EventEmitter<boolean>();
  @Output() userFormEvent = new EventEmitter<FormGroup>();
  @Output() userFormOriginalFormEvent = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private formBuilder: FormBuilder,
              private resources: ResourcesService,
              private toast: ToastService,
              private lang: I18nService) {
  }

  ngOnInit(): void {
    if (this.resources.getPlatformTitles()) {
      this.setTitles(this.resources.getPlatformTitles().titles);
    } else {
      this.setTitles(this.resources.backupTitles.titles);
    }
    if (this.resources.getPlatformLanguages()) {
      this.setLanguages(this.resources.getPlatformLanguages().languages);
    } else {
      this.setLanguages(this.resources.backupLanguages.languages);
    }
    this.setEnterprise();
    this.userForm = this.formBuilder.group({
      first_name: [this.user.first_name, [Validators.required]],
      last_name: [this.user.last_name, [Validators.required]],
      email: [this.user.email, [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      invoice_email: [this.user.invoice_email, [Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      company: [this.user.company],
      title_id: [this.user.title_id, [Validators.required]],
      address: [this.user.address.address],
      postal_code: [this.user.address.postal_code],
      city: [this.user.address.city],
      phone: [this.user.phone],
      mobile: [this.user.mobile],
      lang_id: [this.user.lang_id, [Validators.required]],
      is_company: [''],
      signature: [''],
      locale: [this.lang.currentLang]
    });
    this.userFormOriginalFormEvent.emit(this.userForm.value);
    if (this.user.is_company === 0) {
      this.selectedType = this.company[0];
      this.isCompany = false;
      this.userForm.patchValue({
        is_company: 0
      });
    } else if (this.user.is_company === 1) {
      this.selectedType = this.company[1];
      this.isCompany = true;
      this.userForm.patchValue({
        is_company: 1
      });
    }
  }

  get form() {
    return this.userForm.controls;
  }

  updateLanguageValue(value) {
    this.userForm.patchValue({
      lang_id: value
    });
  }

  updateTitleValue(value) {
    this.userForm.patchValue({
      title_id: value
    });
  }

  private setLanguages(languages) {
    languages.forEach(lng => {
      const name = this.translate.instant(lng.language.toUpperCase());
      this.languages.push({id: lng.id, text: name, value: lng.language, description: lng.description});
    });
  }

  private setTitles(titles) {
    titles.forEach(title => {
      const name = this.translate.instant(title.name.toUpperCase());
      this.titles.push({id: title.id, text: name, value: title.name});
    });
  }

  checkPasswords(value) {
    this.userForm.patchValue({
      password_confirmation: value
    });
    if (this.userForm.controls.password.value !== this.userForm.controls.password_confirmation.value) {
      this.toast.showBottomCenterError(this.translate.instant('FORM.PASSWORD'));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validateClicked) {
      setTimeout(() => {
        if (this.validateClicked) {
          if (this.isCompany) {
            this.userFormEvent.emit(this.userForm);
          } else if (!this.isCompany) {
            this.userForm.removeControl('company');
            this.userFormEvent.emit(this.userForm);
          }
        }
      }, 0);
    }
    if (changes.isUser) {
      if (this.isUser) {
        this.userForm.get('address').setValidators([Validators.required]);
        this.userForm.get('postal_code').setValidators([Validators.required]);
        this.userForm.get('city').setValidators([Validators.required]);
      } else {
        this.userForm.get('address').clearValidators();
        this.userForm.get('postal_code').clearValidators();
        this.userForm.get('city').clearValidators();
      }
    }
  }

  handleTypeChange($event: any) {
    if ($event.key === 0) {
      this.isCompany = false;
      this.userForm.patchValue({
        is_company: 0
      });
    } else if ($event.key === 1) {
      this.isCompany = true;
      this.userForm.patchValue({
        is_company: 1
      });
    }
  }

  private setEnterprise() {
    this.company.push({key: 0, name: 'PRIVATE'});
    this.company.push({key: 1, name: 'COMPANY'});
  }
}
