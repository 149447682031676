import { Component, OnInit } from '@angular/core';
import { AuthService, I18nService, UserService, } from '../../../shared/services';
import { Language, User } from '../../../models';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { AdminUsersService } from '../../services/admin-users.service';
import { CreateOrderComponent } from 'src/app/admin/pages/orders/modals/create-order-modal/create-order.component';
import { DialogService } from 'primeng/dynamicdialog';
import { AdminSimpleOrdersService } from 'src/app/admin/services/admin-simple-orders.service';
import { forkJoin } from 'rxjs';
import { AdminStatics } from '../../statics/admin-statics';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { AdminOrdersService } from '../../services/admin-orders.service';
import { AdminDiagnosticOrdersService } from '../../services/admin-diagnostic-orders.service';
import { Laboratory } from '../../../models/laboratorie';
import { FunctionsService } from '../../services/functions.service';


@Component({
  selector: 'app-prolabo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  languages: DropdownModel[] = [];
  langs: Language[];
  currentUser: User;
  currentLang: any;
  searchParam: string;
  redirectedFromMail: any;
  laboratories: Laboratory[] = [];

  orderActivities = [
    {name: 'ORDERED', keyword: 'Ordered', icon: 'calculator', step: 1},
    {name: 'ANALYSING', keyword: 'Request', icon: 'chemistry', step: 2},
    {name: 'ANALYSED', keyword: 'Analyzed', icon: 'microscope', step: 3},
    {name: 'FINISHED', keyword: 'Validated', icon: 'computer', step: 4}
  ];

  orderTotals = [];

  deadlines: DropdownModel[] = [];

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private adminService: AdminUsersService,
    private dashboardService: AdminDashboardService,
    private ordersService: AdminOrdersService,
    private diagnosticOrdersService: AdminDiagnosticOrdersService,
    private simpleOrdersService: AdminSimpleOrdersService,
    private translate: TranslateService,
    private router: Router,
    private functions: FunctionsService,
    public lang: I18nService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private resources: ResourcesService) {
  }

  ngOnInit(): void {
    if (this.resources.getPlatformLanguages()) {
      this.langs = this.resources.getPlatformLanguages().languages;
    } else {
      this.langs = this.resources.backupLanguages.languages;
    }
    this.currentLang = this.langs.find(lang => lang.description === this.lang.currentLang);
    this.setLanguages(this.langs);

    this.currentUser = this.userService.currentUser();

    this.getOrderNumbers();
    this.getLaboratories();
  }

  logout() {
    this.authService.logOut('/v1/logout', {locale: this.lang.currentLang}, this.resources.getStorageUser().token).subscribe(() => {
      this.router.navigateByUrl(`/admin/login`).then(() => {
        localStorage.removeItem('prolaboUser');
      });
    }, () => {
      this.router.navigateByUrl('/admin/login');
    });
  }

  private setLanguages(languages) {
    languages.forEach(lng => {
      const name = this.translate.instant(lng.description.toUpperCase());
      this.languages.push({id: lng.id, text: name, value: lng.language, description: lng.description});
    });
  }

  updateLanguageValue(value) {
    const language = this.langs.find(lang => lang.id === value);
    this.translate.setDefaultLang(language.description);
    this.lang.setCurrentLang(language.description, language);
    this.resources.setLanguage(language);
  }

  onSearchParamChange(param) {
    this.searchParam = param;
  }

  getOrderNumbers() {
    const dashboardNumbers = this.dashboardService.getDashboardNumbers({locale: this.resources.getLanguage().description});
    forkJoin([dashboardNumbers]).subscribe(result => {
      this.orderTotals = result[0];
    });
  }

  getLaboratories() {
    this.dashboardService.getLaboratories().subscribe(res => {
      this.laboratories = res.laboratories;
    });
  }

  createOrderTemp() {
    const body = {
      order_type_id: 1,
      locale: this.resources.getLanguage().description,
    };
    this.simpleOrdersService.orderCreate(body).subscribe(result => {
      this.router.navigate(['/admin/orders/simple-order', result.order.id]).then(() => {
      });
    }, err => this.functions.showError((err)));
  }

  createOrder() {
    const ref = this.dialogService.open(CreateOrderComponent, {
      showHeader: false,
      width: '70%',
      styleClass: 'dialog',
      data: {deadlines: this.deadlines, user: false, laboratories: this.laboratories, isUser: false},
    });

    ref.onClose.subscribe(res => {
      if (res) {
        if (res.type === AdminStatics.simpleOrder) {
          const body = {
            order_type_id: 1,
            locale: this.resources.getLanguage().description,
            user_id: res.user_id
          };
          this.simpleOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/admin/orders/simple-order', result.order.id]).then(() => {
            });
          });
        } else if (res.type === AdminStatics.diagnostics) {
          const body = {
            order_type_id: 2,
            locale: this.resources.getLanguage().description,
          };
          this.diagnosticOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/admin/orders/diagnostic-order', result.order.id]).then(() => {
            });
          });
        }
      }
    });
  }
}
