<div class="clients-container">
  <app-dialog-header [title]="'LABORATORY_ADDRESS'" (iconClick)="ref.close()"></app-dialog-header>
  <div class="laboratory">
    <p>    {{laboratory.name}}
    </p>
    <p>    {{laboratory.address}}
    </p>
    <p>    {{laboratory.postal_code}}
    </p>
  </div>
</div>
