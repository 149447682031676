import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Languages, Response, Titles } from '../../models';
import { RegisterResponse } from '../../models/register-response';
import { InvoiceType } from '../../models/laboratorie';
import { AdminRoutesStatics } from '../../admin/statics/admin-routes-statics';
import { ResourcesService } from './resources.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {
  }

  url = environment.apiUrl;
  adminUrl = environment.adminApiUrl;

  private static getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }

  private static getMultiPartHeader(): HttpHeaders {
    return new HttpHeaders({
      // Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
    });
  }

  public getLanguages(): Observable<Languages> {
    return this.http.get<Languages>(`${this.url}/v1/languages`);
  }

  public verifyAccount(body): Observable<Response> {
    return this.http.post<Response>(`${this.url}/v1/account/activate`, body);
  }

  public resendVerificationToken(body): Observable<Response> {
    return this.http.post<Response>(`${this.url}/v1/verification/resend`, body);
  }

  public registerAdmin(body): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(`${this.adminUrl}register`, body);
  }

  public registerUser(body): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(`${this.url}/v1/analyse/register`, body);
  }

  public forgotPassword(body): Observable<Response> {
    return this.http.post<Response>(`${this.url}/v1/forgot/password`, body);
  }

  public resetPasswordTokenVerification(body): Observable<Response> {
    return this.http.post<Response>(`${this.url}/v1/reset/password/token/verify`, body);
  }

  public setPasswordVerification(body): Observable<any> {
    return this.http.post<any>(`${this.url}/v1/set/password`, body);
  }

  public resetPassword(body): Observable<Response> {
    return this.http.post<Response>(`${this.url}/v1/reset/password`, body);
  }

  public logOut(path, body, token): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${path}`, body, {headers: AuthService.getHeaders(token)});
  }

  public getTitles(): Observable<Titles> {
    return this.http.get<Titles>(`${this.url}/v1/titles`);
  }

  public getRoles(body): Observable<any> {
    return this.http.post(`${environment.adminApiUrl}roles`, {locale: body});
  }

  public getInvoiceTypes(): Observable<InvoiceType[]> {
    return this.http.get<InvoiceType[]>(`${environment.apiUrl}${AdminRoutesStatics.getInvoiceTypes}`);
  }
}
