import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { I18nService, ToastService } from 'src/app/shared/services';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { AdminSimpleOrdersService } from 'src/app/admin/services/admin-simple-orders.service';
import { TranslateService } from '@ngx-translate/core';
import { AdminSimpleSamplesService } from '../../../../services/admin-simple-samples.service';
import { AdminOrdersService } from '../../../../services/admin-orders.service';
import { SimpleOrder, SimpleSample } from '../../../../models/simple-order-response';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-prolabo-sample-create-modal',
  templateUrl: './sample-create-modal.component.html',
  styleUrls: ['./sample-create-modal.component.scss']
})
export class SampleCreateModalComponent implements OnInit {
  order: SimpleOrder;
  types: DropdownModel[];
  nextSampleId: string;
  sampleForm: FormGroup;
  vdiForm: FormGroup;
  bufferForm: FormGroup;
  vdiFormType = false;
  loading = false;
  sampleId: number;
  disabled: boolean;
  sample: SimpleSample;
  state: string;
  rangeDate: any;
  dateSelected: any;

  constructor(private config: DynamicDialogConfig,
              public ref: DynamicDialogRef,
              private lang: I18nService,
              private translate: TranslateService,
              private toast: ToastService,
              private ordersService: AdminOrdersService,
              private simpleOrdersService: AdminSimpleOrdersService,
              private samplesService: AdminSimpleSamplesService,
              private resources: ResourcesService,
              private datePipe: DatePipe,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.order = this.config.data.order;
    this.types = this.config.data.types;
    this.disabled = this.config.data.disabled;
    this.sample = this.config.data.sample;
    this.state = this.config.data.state;
    this.types.forEach(type => {
      type.text = this.translate.instant(type.text);
    });

    this.nextSampleId = this.config.data.next_sample_id;
    if (this.state === 'update' || this.state === 'create') {
      this.sampleId = Number(this.config.data.sample_type_id);
    } else {
      this.sampleId = Number(0);
    }

    if (this.sampleId === 1 || this.sample?.sample_type_id === 1) {
      this.dateSelected = this.state === 'create' ? new Date() : new Date(this.sample.vdi_info.date_of_sampling);
    }

    this.sampleForm = this.formBuilder.group({
      order_id: [this.order.id],
      sample_type_id: [this.sampleId, Validators.required],
      description: ['', Validators.required],
      sample_number: ['', Validators.required],
      locale: [this.lang.currentLang, Validators.required]
    });
    this.vdiForm = this.formBuilder.group({
      order_id: [this.order.id],
      sample_type_id: [this.sampleId, Validators.required],
      sample_number: ['', Validators.required],
      locale: [this.lang.currentLang, Validators.required],
      volume_of_air_taken: ['', [Validators.required, Validators.pattern('^\\d+$')]],
      temperature: ['', [Validators.pattern('^\\d+$')]],
      relative_humidity: ['', [Validators.pattern('^\\d+$')]],
      description: ['', Validators.required],
      date_of_sampling: [this.dateSelected],
      date_sampling: [this.dateSelected],
    });

    if (this.disabled && this.state === 'update') {
      if (this.sample.sample_type_id === 1) {
        this.nextSampleId = this.sample.sample_id;
        this.vdiFormType = true;
        this.fillVdiForm();
      } else {
        this.vdiFormType = false;
        this.fillSampleForm();
      }
    }
    if (this.state === 'create') {
      this.vdiFormType = this.sampleId === 1;
    }
  }

  fillVdiForm() {
    this.vdiForm.patchValue({
      order_id: this.sample.order_id,
      sample_type_id: this.sample.sample_type_id,
      sample_number: this.sample.sample_number,
      description: this.sample.description,
      locale: this.lang.currentLang,
      volume_of_air_taken: this.sample.vdi_info.volume_of_air_taken,
      location: this.sample.vdi_info.location,
      temperature: this.sample.vdi_info.temperature,
      relative_humidity: this.sample.vdi_info.relative_humidity,
      date_of_sampling: this.dateSelected,
    });
  }

  fillSampleForm() {
    this.sampleForm.patchValue({
      order_id: this.sample.order_id,
      sample_type_id: this.sample.sample_type_id,
      description: this.sample.description,
      sample_number: this.sample.sample_number,
      locale: this.lang.currentLang
    });
  }

  updateTypeValue($event) {
    if ($event > 0) {
      this.sampleId = $event;
      this.loading = true;
      const type = this.types.find(t => t.id === $event);
      this.vdiFormType = type.id === 1;
      this.sampleForm.patchValue({
        sample_type_id: $event
      });
      const body = {
        order_id: this.order.id,
        sample_type_id: $event,
        no_prolabo: false,
        locale: this.lang.currentLang
      };

      this.ordersService.getNextSampleId(body).subscribe(id => {
        this.loading = false;
        this.nextSampleId = id.next_sample_id;
      });
    }
  }

  validateVdiForm() {
    if (this.vdiForm.valid) {
      this.vdiForm.removeControl('date_sampling');
      if (this.vdiForm.controls.temperature.value === '' || this.vdiForm.controls.temperature.value == null) {
        this.vdiForm.removeControl('temperature');
      }
      if (this.vdiForm.controls.relative_humidity.value === '' || this.vdiForm.controls.relative_humidity.value == null) {
        this.vdiForm.removeControl('relative_humidity');
      }
      if (this.vdiForm.controls.date_of_sampling.value === '' || this.vdiForm.controls.date_of_sampling.value == null) {
        this.vdiForm.removeControl('date_of_sampling');
      }
      if (this.disabled && this.state === 'update') {
        this.vdiForm.addControl('sample_id', new FormControl(this.sample.id));
        this.ref.close(this.vdiForm.value);
      } else {
        this.ref.close(this.vdiForm.value);
      }
    } else {
      this.vdiForm.removeControl('temperature');
      this.vdiForm.removeControl('relative_humidity');
      this.vdiForm.removeControl('date_of_sampling');
      this.vdiForm.addControl('temperature', new FormControl(''));
      this.vdiForm.addControl('relative_humidity', new FormControl(''));
      this.vdiForm.addControl('date_of_sampling', new FormControl(this.datePipe.transform(this.dateSelected.toISOString(), 'yyyy-MM-dd')));
      this.vdiForm.addControl('date_sampling', new FormControl(this.dateSelected));
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  validateSampleForm() {
    if (this.sampleForm.valid) {
      if (this.disabled && this.state === 'update') {
        this.sampleForm.addControl('sample_id', new FormControl(this.sample.id));
        this.ref.close(this.sampleForm.value);
      } else {
        this.ref.close(this.sampleForm.value);
      }
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  updateSampleNumber(value) {
    const body = {
      sample_type_id: !this.vdiFormType ? this.sampleForm.controls.sample_type_id.value : this.vdiForm.controls.sample_type_id.value,
      sample_number: value,
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    this.samplesService.checkSampleNumber(body).subscribe(result => {
      this.sampleForm.patchValue({sample_number: value});
    }, error => {
      this.toast.showBottomCenterError(this.translate.instant('SAMPLE_NUMBER_EXISTS'));
      this.sampleForm.patchValue({
        sample_number: ''
      });
    });
  }

  dateChange($event: any) {
    this.vdiForm.patchValue({
      date_of_sampling: this.datePipe.transform($event.toISOString(), 'yyyy-MM-dd')
    });
  }

  clearClicked($event: any) {
    this.vdiForm.patchValue({
      date_of_sampling: null
    });
  }
}
