<ng-container *ngIf="new">
  <div class="plan-item-container" (click)="add()">
    <div class="content">
      <img src="../../../../../../../../assets/icons/add.svg">
      <p>{{ 'NEW_PLAN' | translate }}</p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!new">
  <div class="plan-item-container">
    <div class="existing-content">
      <div class="title">
        <p>{{ plan.name }}</p>
        <div class="icons">
          <app-prolabo-icon class="icon-white border-white preview"
                            icon="visible-white"
                            (click)="update()">
          </app-prolabo-icon>
          <app-prolabo-icon *ngIf="!stepDone"
                            class="icon-white border-white"
                            icon="trash-bin-white"
                            (click)="delete(plan?.id)">
          </app-prolabo-icon>
        </div>
      </div>
      <div class="image-wrapper">
        <img [src]="imageSrc" (click)="update()">
      </div>
    </div>
  </div>
</ng-container>
