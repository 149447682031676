<form *ngIf="!disabled">
  <ng-container>
    <p-dropdown
      [options]="options"
      optionLabel="text"
      [formControl]="selectedOptionFormControl"
      (onChange)="onChange($event)"
      (onClick)="onClick($event)"
      class="no-border"
      optionValue="id">
    </p-dropdown>
  </ng-container>
</form>
