import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownModel } from '../../../../../shared/components/dropdown/dropdown.model';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '../../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-scorecard-dropdown-option',
  templateUrl: './create-scorecard-dropdown-option.component.html',
  styleUrls: ['./create-scorecard-dropdown-option.component.scss']
})
export class CreateScorecardDropdownOptionComponent implements OnInit {


  scoreCardDropdownOption: FormGroup;
  scoreCardFieldId: DropdownModel[] = [];

  constructor(private formBuilder: FormBuilder,
              private resources: ResourcesService,
              private config: DynamicDialogConfig,
              private toast: ToastService,
              private translate: TranslateService,
              private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.scoreCardFieldId = this.config.data;
    this.scoreCardDropdownOption = this.formBuilder.group({
      option_value: ['', Validators.required],
      scorecard_field_id: [this.scoreCardFieldId, Validators.required],
      locale: [this.resources.getLanguage().description, Validators.required]
    });
  }

  submit() {
    if (this.scoreCardDropdownOption.valid) {
      this.ref.close(this.scoreCardDropdownOption.value);
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  back() {
    this.ref.close();
  }
}
