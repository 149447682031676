<div class="p-grid">
  <div class="p-col-12">
    <app-prolabo-analyst-profile-form [currentUser]="currentUser"
                                      (validateClicked)="handleValidateCLickedProfile($event)"
                                      (profileFormEvent)="handleProfileFormEvent($event)"
                                      (profileOriginalFormEvent)="handleProfileOriginalFormEvent($event)">
    </app-prolabo-analyst-profile-form>
  </div>
</div>

