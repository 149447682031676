import { Component, OnInit } from '@angular/core';
import { SidebarContent } from '../../../models/sidebar-content';
import { User } from '../../../admin/models/user';
import { I18nService, UserService } from '../../../shared/services';
import { Breadcrumb, Routes } from '../../../models/routes';
import { DropdownModel } from '../../../shared/components/dropdown/dropdown.model';
import { Language } from '../../../models';
import { TranslateService } from '@ngx-translate/core';
// @ts-ignore
import a from '../../../../assets/routes.json';
import { ResourcesService } from '../../../shared/services/resources.service';
import { Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { AnalystService } from '../../services/analyst.service';


@Component({
  selector: 'app-analyst-container',
  templateUrl: './analyst-container.component.html',
  styleUrls: ['./analyst-container.component.scss']
})
export class AnalystContainerComponent implements OnInit {
  user: User;
  sidebarItems: SidebarContent[] = [
    {name: 'ORDERS', class: 'dashboard', routerLink: '/analyst/dashboard', icon: 'checkmark'},
    {name: 'CALENDAR', class: 'calendar', routerLink: '/analyst/calendar', icon: 'people-group'},
    {name: 'PROFILE', class: 'profile', routerLink: '/analyst/profile', icon: 'profile'},
  ];
  routes: Routes[] = a.analystRoutes;
  breadcrumbs: Breadcrumb[] = a.analystBreadcrumbs;

  currentUrl: string;
  firstBreadcrumb: Routes;
  secondBreadcrumb: Routes;
  thirdBreadcrumb: Routes;
  currentPageLoaded: Breadcrumb;
  languages: DropdownModel[] = [];
  langs: Language[];
  logout: boolean;
  logoutClicked = false;
  currentLang: any;
  search: boolean;
  showBreadcrumbs = true;
  showTitle = false;
  subscriber: Subscription;
  orderId: number;
  orderState: string;

  constructor(private userService: UserService,
              private router: Router,
              private resources: ResourcesService,
              private lang: I18nService,
              private analystService: AnalystService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();
    this.appendBreadcrumbs(window.location.pathname);
    if (this.resources.getPlatformLanguages()) {
      this.langs = this.resources.getPlatformLanguages().languages;
    } else {
      this.langs = this.resources.backupLanguages.languages;
    }
    this.currentLang = this.langs.find(lang => lang.description === this.lang.currentLang);
    this.setLanguages(this.langs);
    this.subscriber = this.router.events.subscribe((val: RouterEvent) => {
      if (val.url) {
        this.currentUrl = val.url;
      }
      if (!this.logoutClicked) {
        this.appendBreadcrumbs(this.currentUrl);
      }
    });
  }

  getOrder(id: number, param: string) {
    if (param === 'simple') {
      this.analystService.getSimpleOrderDetails({ order_id: id, locale: this.resources.getLanguage().description })
        .subscribe(res => {
          if (res) {
            this.orderState = res.order.latest_state?.name;
          }
        });
    } else {
      this.analystService.getDiagnosticOrderDetails({ order_id: id, locale: this.resources.getLanguage().description })
        .subscribe(res => {
          if (res) {
            this.orderState = res.order.latest_state?.name;
          }
        });
    }
  }

  appendBreadcrumbs(url: string) {
    const currentUrl = url.split('/');
    const currentUrlLength = currentUrl.length;
    const lastElementOfUrl = +currentUrl[currentUrlLength - 1];

    // check to not take query params into consideration when creating breadcrumbs
    if (currentUrl && currentUrlLength > 0 && currentUrl[currentUrlLength - 1].indexOf('?') !== -1) {
      currentUrl[currentUrlLength - 1] = currentUrl[currentUrlLength - 1]
        .substr(0, currentUrl[currentUrlLength - 1].indexOf('?'));
    }

    this.firstBreadcrumb = this.routes.find(route => route.path === currentUrl[2]);
    this.secondBreadcrumb = this.routes.find(route => route.path === currentUrl[3]);
    this.thirdBreadcrumb = this.routes.find(route => route.path === currentUrl[4]);

    if (isNaN(lastElementOfUrl)) {
      this.currentPageLoaded = this.breadcrumbs.find(route => route.router === url);
    } else {
      this.currentPageLoaded = this.breadcrumbs.find(route => route.router + `/${lastElementOfUrl}` === url);
    }

    this.showBreadcrumbs = !!this.currentPageLoaded?.breadcrumbs;
    this.showTitle = !!this.currentPageLoaded?.showTitle;
    if (this.firstBreadcrumb?.name.toLocaleLowerCase() === 'profile') {
      this.logout = true;
      this.search = true;
    } else if (this.secondBreadcrumb?.name.toLocaleLowerCase() === 'simple.order' ||
      this.secondBreadcrumb?.name.toLocaleLowerCase() === 'diagnostic.order') {
      this.orderId = Number(currentUrl[currentUrlLength - 1]);
      this.getOrder(this.orderId, this.secondBreadcrumb?.name.toLocaleLowerCase() === 'simple.order' ? 'simple' : 'diagnostic');
      this.search = true;
    } else {
      this.logout = false;
      this.search = false;
    }
  }

  onSearchParamChange(param) {
  }

  private setLanguages(languages) {
    languages.forEach(lng => {
      const name = this.translate.instant(lng.description.toUpperCase());
      this.languages.push({id: lng.id, text: name, value: lng.language, description: lng.description});
    });
  }

  updateLanguageValue(value) {
    const language = this.langs.find(lang => lang.id === value);
    this.translate.setDefaultLang(language.description);
    this.lang.setCurrentLang(language.description, language);
    this.resources.setLanguage(language);
  }

  handleLogoutClicked($event: boolean) {
    this.logoutClicked = $event;
  }

}
