<div class="home-container">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="p-grid">
        <div class="p-col-12 p-sm-4">
          <div class="p-grid large-view">
            <div class="p-col-12 p-sm-12">
              <app-prolabo-new-order></app-prolabo-new-order>
            </div>
          </div>
          <div class="p-grid large-view large-view-activities">
            <div class="p-col-12">
              <app-prolabo-order-activities
                [orderActivities]="orderActivities"
                [orderTotals]="orderTotals"></app-prolabo-order-activities>
            </div>
          </div>
        </div>
        <div class="p-col-12 p-sm-8">
          <div class="p-grid large-view">
            <div class="p-col-12 p-sm-8">
              <app-prolabo-icon-input
                [keyword]="'RESEARCH'"
                [icon]="'search'"
                (OnValueChange)="onSearchParamChange($event)"></app-prolabo-icon-input>
            </div>
            <div class="p-sm-4 profile large-view">
              <app-prolabo-language-dropdown
                  [options]="languages"
                  (OnValueChange)="updateLanguageValue($event)"
                  [defaultValue]="currentLang.id">
              </app-prolabo-language-dropdown>
              <img routerLink="/admin/profile" class="profile-image"
                   src="../../../../assets/icons/pharmacy.svg" alt="profile">
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <app-prolabo-last-orders [searchParam]="searchParam"></app-prolabo-last-orders>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12 new-order-mobile">
   `           <app-prolabo-icon-button
                [name]="'NEW_ORDER'"
                type="primary"
                [icon]="'add'" (click)="currentUser.role_id === 4 ? createOrderTemp() : createOrder()"></app-prolabo-icon-button>`
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
