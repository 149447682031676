import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/shared/services';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../../environments/environment';
import { AdminDiagnosticOrdersService } from '../../../../services/admin-diagnostic-orders.service';
import { AdminDiagnosticSamplesService } from '../../../../services/admin-diagnostic-samples.service';
import { DiagnosticOrder, DiagnosticSample } from '../../../../models/diagnostic-order-response';
import { AdminOrdersService } from '../../../../services/admin-orders.service';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { forkJoin } from 'rxjs';
import { FunctionsService } from '../../../../services/functions.service';

@Component({
  selector: 'app-prolabo-diagnostic-sample-create-modal',
  templateUrl: './diagnostic-sample-create-modal.component.html',
  styleUrls: ['./diagnostic-sample-create-modal.component.scss']
})
export class DiagnosticSampleCreateModalComponent implements OnInit {
  order: DiagnosticOrder;
  samples: DiagnosticSample[];
  analogy: DiagnosticSample[];
  types: DropdownModel[];
  plans: DropdownModel[];
  collectMethods: DropdownModel[];

  colors: DropdownModel[] = [
    {id: 1, value: this.translate.instant('GREEN'), text: 'green'},
    {id: 2, value: this.translate.instant('BLUE'), text: 'blue'},
    {id: 3, value: this.translate.instant('BLACK'), text: 'black'},
    {id: 4, value: this.translate.instant('RED'), text: 'red'},
    {id: 5, value: this.translate.instant('YELLOW'), text: 'yellow'},
    {id: 6, value: this.translate.instant('WHITE'), text: 'white'},
    {id: 7, value: this.translate.instant('GRAY'), text: 'gray'},
    {id: 8, value: this.translate.instant('BEIGE'), text: 'beige'},
    {id: 9, value: this.translate.instant('BROWN'), text: 'brown'}
  ];

  materialTypes: DropdownModel[] = [
    {id: 1, value: 'C', text: 'C'},
    {id: 2, value: 'FP', text: 'FP'},
    {id: 3, value: 'SOL', text: 'SOL'},
    {id: 4, value: 'FA', text: 'FA'},
    {id: 5, value: 'NFA', text: 'NFA'},
    {id: 6, value: 'MSP', text: 'MSP'},
    {id: 7, value: 'MP', text: 'MP'},
    {id: 8, value: 'R', text: 'R'},
    {id: 9, value: 'MNP', text: 'MNP'}
  ];

  sampleForm: FormGroup;

  nextSampleId: string;
  loading = false;
  sampleId: number;
  sampleNumber = '+';
  sampleNumberForm = null;
  imageSrc = null;
  selectedPlan = null;
  loadingCircles = false;
  description = '';
  additionalDescription = '';
  approxQuantity = '';
  location = '';
  showRadioList = false;
  expandRadioList = false;
  selectedSample: any = null;
  update = false;
  user = false;
  sampleToUpdate = null;
  materialType = '';
  analogyId = null;
  disabled = false;
  sample: DiagnosticSample;
  plan: number;

  get coordinates(): FormArray {
    return this.sampleForm.get('coordinates') as FormArray;
  }

  constructor(private config: DynamicDialogConfig,
              public ref: DynamicDialogRef,
              private resources: ResourcesService,
              private cdr: ChangeDetectorRef,
              private translate: TranslateService,
              private toast: ToastService,
              private diagnosticOrdersService: AdminDiagnosticOrdersService,
              private diagnosticSamplesService: AdminDiagnosticSamplesService,
              private ordersService: AdminOrdersService,
              private samplesService: AdminDiagnosticSamplesService,
              private functions: FunctionsService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.order = this.config.data.order;
    this.nextSampleId = this.config.data.next_sample_id;
    this.types = this.config.data.types;
    this.plans = this.config.data.plans;
    this.samples = this.config.data.samples;
    this.analogy = this.config.data.analogy;
    this.update = this.config.data.update;
    this.disabled = this.config.data.disabled;
    if (this.update) {
      this.sampleId = Number(this.config.data.sample_type_id);
    } else {
      this.sampleId = Number(0);
    }
    this.sample = this.config.data.sample;
    this.plan = this.config.data.plan;

    this.setPlan();

    if (this.config.data.user === true) {
      this.user = this.config.data.user;
    }

    this.sampleForm = this.formBuilder.group({
      order_id: [this.order.id],
      sample_type_id: [this.sampleId, Validators.required],
      description: [' ', Validators.required],
      sample_number: [''],
      locale: [this.resources.getLanguage().description, Validators.required],
      collect_method_id: [this.collectMethods[0]?.id, Validators.required],
      analogy_id: [''],
      material_type: [this.materialTypes[0]?.value],
      approximate_quantity: ['', Validators.required],
      location: ['', Validators.required],
      plan_id: [this.plans[0].id, Validators.required],
      coordinates: this.formBuilder.array([], Validators.required),
      sample_img: ['', !this.update ? Validators.required : null],
      no_prolabo: [this.user ? 1 : 0, Validators.required],
      additional_description: [''],
    });

    if (this.update) {
      this.sampleToUpdate = this.sample;
      this.prefillSampleForm(this.sample).then(() => {
        this.setCoordinatesByPlan();
        this.cdr.detectChanges();
      });
    }
  }

  buildCoordinates(x?, y?): FormGroup {
    return this.formBuilder.group({
      coordinate_x: x != null ? x : [''],
      coordinate_y: y != null ? y : ['']
    });
  }

  addCoordinate(x, y): void {
    this.coordinates.push(this.buildCoordinates(x, y));
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  async prefillSampleForm(sampleToUpdate) {
    if (sampleToUpdate) {
      this.sampleId = sampleToUpdate.sample_type_id;
      this.sampleNumberForm = sampleToUpdate.sample_number;
      this.approxQuantity = sampleToUpdate.diagnostic.approximate_quantity;
      this.description = sampleToUpdate.description;
      this.additionalDescription = sampleToUpdate.diagnostic.additional_description;
      this.location = sampleToUpdate.diagnostic.location;
      this.nextSampleId = sampleToUpdate.sample_id;
      // this.selectedPlan = this.plans.filter(p => p.id === sampleToUpdate.diagnostic.plan_id)[0];
      this.materialType = sampleToUpdate.material_type;
      this.analogyId = sampleToUpdate.analogy_id;
      await this.sampleForm.patchValue({
        order_id: this.order.id,
        sample_type_id: this.sampleId,
        description: this.description,
        sample_number: sampleToUpdate.sample_number,
        locale: this.resources.getLanguage().description,
        collect_method_id: sampleToUpdate.diagnostic.collect_method_id,
        analogy_id: this.analogyId ? this.analogyId : '',
        material_type: this.materialType ? this.materialType : '',
        approximate_quantity: this.approxQuantity,
        location: this.location,
        plan_id: sampleToUpdate.diagnostic?.plan_id,
        coordinates: sampleToUpdate.diagnostic?.coordinates,
        sample_img: '',
        no_prolabo: 0,
        // tslint:disable-next-line:max-line-length
        additional_description: this.additionalDescription ? this.additionalDescription : '',
      });
    }
  }

  setPlan() {
    this.imageSrc = `${environment.storageUrl}${this.plans[0]?.value}`;
    this.selectedPlan = this.plans[0];
    this.collectMethods = Array.from(this.config.data.collect_methods);

    if (this.sampleId !== 2) {
      this.collectMethods.pop();
    }

    setTimeout(() => {
      this.setCoordinatesByPlan();
    }, 500);
    this.types.forEach(type => {
      type.text = this.translate.instant(type.text);
    });
  }

  updateTypeValue(id) {
    if (id !== 0) {
      this.sampleId = id;
      this.loading = true;

      this.sampleForm.patchValue({
        sample_type_id: id
      });
      const body = {
        order_id: this.order.id,
        sample_type_id: id,
        no_prolabo: false,
        locale: this.resources.getLanguage().description
      };
      const body1 = {
        order_id: this.order.id,
        sample_type_id: id,
        locale: this.resources.getLanguage().description
      };

      const analogy = this.diagnosticSamplesService.getAnalogySamples(body1);
      const nextSampleId = this.ordersService.getNextSampleId(body);
      forkJoin([analogy, nextSampleId]).subscribe(result => {
        this.loading = false;
        this.analogy = result[0].samples;
        this.nextSampleId = result[1].next_sample_id;
        this.setPlan();
      });
    }
  }

  updateMaterialTypeValue(id) {
    this.materialType = this.materialTypes.filter(material => material.id === id)[0].text;

    this.sampleForm.patchValue({
      material_type: this.materialType
    });
  }

  getMaterialTypeDefaultValue(): number {
    if (this.materialTypes && this.materialTypes.length > 0) {
      if (this.materialType) {
        return this.materialTypes.find(material => material.text === this.materialType).id;
      }

      return this.materialTypes[0].id;
    }
  }

  updateSampleNumber(value) {
    const body = {
      sample_type_id: this.sampleForm.controls.sample_type_id.value,
      sample_number: value,
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    if (this.sampleForm.controls.sample_type_id.value === 0) {
      this.toast.showBottomCenterError(this.translate.instant('CHOOSE_SAMPLE_TYPE'));
    } else {
      this.diagnosticSamplesService.checkSampleNumber(body).subscribe(result => {
        this.sampleForm.patchValue({sample_number: value});
        this.sampleNumberForm = value;
        this.sampleNumber = value;
        this.cleanUpCircles(true);
      }, error => {
        this.toast.showBottomCenterError(this.translate.instant('SAMPLE_NUMBER_EXISTS'));
        this.cleanUpCircles(true);
        this.sampleForm.patchValue({
          sample_number: ''
        });
        this.sampleNumberForm = '';
        this.cdr.detectChanges();
      });
    }
  }

  updatePlanValue(id) {
    if (this.sampleForm.controls.sample_type_id.value === 0) {
      this.toast.showBottomCenterError(this.translate.instant('CHOOSE_SAMPLE_TYPE'));
    } else {
      this.sampleForm.patchValue({
        plan_id: id
      });

      const findPlan = this.plans.filter(plan => plan.id === id);
      if (findPlan.length !== 0) {
        this.selectedPlan = findPlan[0];
        this.imageSrc = `${environment.storageUrl}${findPlan[0].value}`;
        this.cleanUpCircles();
        setTimeout(() => {
          this.setCoordinatesByPlan();
        }, 500);
        this.cdr.detectChanges();
      }
    }
  }

  setCoordinatesByPlan() {
    if (this.selectedPlan && this.imageSrc) {
      const photoDiv = document.getElementById('plan');
      this.samples.forEach(sample => {
        // @ts-ignore
        if (sample.diagnostic?.plan_id === this.selectedPlan.id) {
          sample.diagnostic?.coordinates.forEach(coordinates => {
            const circle = this.createCircle(coordinates.coordinate_x, coordinates.coordinate_y, sample.sample_number);
            photoDiv.appendChild(circle);
          });
        }
      });
    }
  }

  updateCollectMethodValue(id) {
    this.sampleForm.patchValue({
      collect_method_id: id
    });

    const circle = document.createElement('div');
    circle.setAttribute('class', 'new-circle');
    if (id === 3) {
      circle.style.backgroundColor = '#FF5757';
    } else {
      circle.style.backgroundColor = '#C3D800';
    }

    if (id === 2) {
      if (this.analogy.length <= 0) {
        this.toast.showBottomCenterError(this.translate.instant('NO_SAMPLES_ANALOGY_ERROR'));
      } else {
        this.showRadioList = true;
        this.expandRadioList = true;
      }
    } else {
      this.showRadioList = false;
    }
  }

  onSelectSample(id) {
    this.expandRadioList = false;
    const selectedSample = this.analogy.find(sample => sample.id === id);

    this.description = selectedSample.description;
    // @ts-ignore
    // this.approxQuantity = selectedSample.diagnostic?.approximate_quantity;
    // @ts-ignore
    this.additionalDescription = selectedSample.diagnostic?.additional_description;
    // @ts-ignore
    // this.location = selectedSample.diagnostic?.location;

    this.sampleForm.patchValue({
      description: this.description,
      // approximate_quantity: this.approxQuantity,
      additional_description: this.additionalDescription ? this.additionalDescription : '',
      sample_type_id: selectedSample.sample_type_id,
      // location: this.location,
      analogy_id: id
    });

    this.cdr.detectChanges();
  }

  appendColor(id) {
    const desc = this.sampleForm.controls.description.value.toString();
    this.colors.forEach(color => {
      if (desc.includes(color.value.toLowerCase())) {
        this.sampleForm.controls.description.setValue(desc.replace(color.value.toLowerCase(), ''));
      }
    });
    const selectedColor = this.colors.find(color => color.id === id);
    this.description = `${this.sampleForm.controls.description.value} ${selectedColor.value.toLowerCase()}`;
    this.sampleForm.patchValue({
      description: this.description
    });
  }

  validateSampleForm() {
    if (this.sampleForm.controls.material_type.value.length === 0
      && (this.sampleId === 2 || this.sampleId === 8)) {
      this.toast.showBottomCenterError(this.translate.instant('MISSING_VISIT_INFORMATION'));
    } else {
      if (this.sampleForm.valid) {
        if (this.update) {
          this.sampleForm.addControl('id', new FormControl(this.sampleToUpdate.id, Validators.required));
        }
        if (this.sampleForm.controls.additional_description.value.length === 0) {
          this.sampleForm.removeControl('additional_description');
        }
        if (this.sampleForm.controls.sample_img.value.length === 0 && this.update) {
          this.sampleForm.removeControl('sample_img');
          const formData = this.toFormData(this.sampleForm.value);
          formData.delete('coordinates');
          this.coordinates.value.forEach((c, i) => {
            formData.append(`coordinates[${i}][]`, c.coordinate_x);
            formData.append(`coordinates[${i}][]`, c.coordinate_y);
          });
          this.ref.close({form: formData, regularHeaders: true});
        } else {
          const formData = this.toFormData(this.sampleForm.value);
          formData.delete('coordinates');
          this.coordinates.value.forEach((c, i) => {
            formData.append(`coordinates[${i}][]`, c.coordinate_x);
            formData.append(`coordinates[${i}][]`, c.coordinate_y);
          });
          this.ref.close({form: formData, regularHeaders: false});
        }
      } else {
        if (this.coordinates.value.length === 0) {
          this.toast.showBottomCenterError(this.translate.instant('MISSING_COORDINATES'));
        } else if (!this.sampleForm.value.sample_img) {
          this.toast.showBottomCenterError(this.translate.instant('UPLOAD_PHOTO'));
        } else if (!this.sampleForm.value.approximate_quantity) {
          this.toast.showBottomCenterError(this.translate.instant('APPROX_QUANTITY_ERROR'));
        } else {
          this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
        }
      }
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  imageValueChange(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: ProgressEvent) => {
        const file = new File([this.dataURLToBlob((event.target as FileReader).result)],
          'sample_img', {type: `image/jpeg`});
        this.sampleForm.patchValue({
          sample_img: file
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  clickImage(event) {
    if (this.sampleForm.get('sample_number').value.length > 0) {
      const img = document.getElementById('coordinates-img');
      const imgSize = document.getElementById('coordinates-img') as HTMLImageElement;

      if (this.sampleForm.get('sample_number').value) {
        this.sampleNumber = this.sampleForm.get('sample_number').value;
      }

      // format coordinates for natural size image
      const naturalWidth = this.selectedPlan.additional?.width;
      const naturalHeight = this.selectedPlan.additional?.height;

      // format values for preview
      const x = event.offsetX ? (event.offsetX) : img.clientWidth - img.offsetLeft;
      const y = event.offsetY ? (event.offsetY) : img.clientHeight - img.offsetTop;

      const naturalClickPosX = (naturalWidth / imgSize.width) * x;
      const naturalClickPosY = (naturalHeight / imgSize.height) * y;

      this.sampleForm.patchValue({
        coordinate_x: Math.round(naturalClickPosX),
        coordinate_y: Math.round(naturalClickPosY)
      });

      this.addCoordinate(Math.round(naturalClickPosX), Math.round(naturalClickPosY));

      const photoDiv = document.getElementById('plan');
      const circle = this.createCircle(Math.round(naturalClickPosX),
        Math.round(naturalClickPosY),
        this.sampleNumber, true);
      photoDiv.append(circle);
    } else {
      this.toast.showBottomCenterError(this.translate.instant('SAMPLENUMBERERROR'));
    }
  }

  public cleanUpCircles(clean = false) {
    const elements = document.getElementsByClassName(clean ? 'new-circle' : 'circle');

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }

    this.clearFormArray(this.coordinates);
    this.sampleForm.controls.coordinates.reset({});
  }

  private createCircle(sampleX, sampleY, sampleNumber, added = false) {
    const imgSize = document.getElementById('coordinates-img') as HTMLImageElement;
    const x = (imgSize.width / this.selectedPlan.additional?.width) * sampleX;
    const y = (imgSize.height / this.selectedPlan.additional?.height) * sampleY;

    const circle = document.createElement('div');
    circle.setAttribute('class', added ? 'new-circle' : 'circle');
    circle.style.display = 'block';
    circle.style.position = 'absolute';
    circle.style.width = '40px';
    circle.style.height = '40px';
    circle.style.textAlign = 'center';
    circle.style.borderRadius = '50%';
    circle.style.backgroundColor = '#C3D800';
    circle.style.fontFamily = 'Nunito-SemiBold';
    circle.style.color = 'white';
    circle.style.left = x + 'px';
    circle.style.top = y + 'px';

    const span = document.createElement('span');
    span.style.marginTop = '9px';
    span.style.display = 'flex';
    span.style.justifyContent = 'center';

    circle.appendChild(span);
    span.innerText = sampleNumber;

    return circle;
  }

  private toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }

    return formData;
  }
}
