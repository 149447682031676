<p-card *ngIf="order && responsiblesLoaded">
  <div class="p-grid top-header spacing-bottom">
    <div class="p-col-6 title">
      <p-header>{{'ADMINISTRATION' | translate}}</p-header>
    </div>
  </div>
  <form [formGroup]="orderForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'site'"
                               [id]="'site'"
                               [keyword]="'SITE'"
                               [type]="'text'"
                               [required]="'true'"
                               [inputType]="'basic'"
                               [defaultValue]="order?.site"
                               (OnValueChange)="onValueChange($event,'site')"
                               formControlName="site">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-dropdown [options]="subtypes"
                                  (OnValueChange)="updateSubTypeValue($event)"
                                  [defaultValue]="subTypeId"
                                  placeholder="SAMPLE.TYPE">
            </app-prolabo-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-icon-input [keyword]="'CLIENT'"
                                [regularInput]="'true'"
                                [defaultValue]="clientValue"
                                [class]="'client'"
                                [icon]="'male-people-group-black'"
                                (click)="handleIconClick($event)">
        </app-prolabo-icon-input>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-input [for]="'principal'"
                           [id]="'principal'"
                           [keyword]="'PRINCIPAL'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [defaultValue]="order?.principal"
                           (OnValueChange)="onValueChange($event,'principal')"
                           formControlName="principal">
        </app-prolabo-input>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 responsible">
        <app-prolabo-dropdown [options]="responsibles"
                              (OnValueChange)="updateResponsibleValue($event)"
                              [defaultValue]="responsibleId"
                              placeholder="SAMPLING.MANAGER">
        </app-prolabo-dropdown>
        <p [routerLink]="user ? '/user/profile' : '/admin/profile'" class="add-responsible-link">
          {{ '+ ' }}
          {{ 'ADD.SAMPLING.MANAGER' | translate }}
        </p>
      </div>
      <div class="p-col-12">
        <app-prolabo-dropdown [options]="invoiceTypes"
                              (OnValueChange)="updateInvoiceTypes($event)"
                              [defaultValue]="order.monthly_invoice === 1 ? 1 : 2"
                              placeholder="INVOICE_TYPE"></app-prolabo-dropdown>
      </div>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-12" *ngIf="!isUser">
        <app-dropdown-select
          [options]="users"
          (OnValueChange)="updateUserId($event)"
          (OnFilter)="onFilter($event)"
          [defaultValue]="order.user_id"
          [offset]="limit"
          placeholder="ORDER.OWNER"></app-dropdown-select>
      </div>
      <div class="p-col-12">
        <app-prolabo-dropdown [options]="laboratories"
                              (OnValueChange)="updateLaboratoryId($event)"
                              [defaultValue]="laboratoryId"
                              placeholder="BRANCH">
        </app-prolabo-dropdown>
      </div>
      <div class="p-col-12">
        <app-prolabo-icon-input [for]="'site_img'"
                                [id]="'site_img'"
                                [keyword]="'SITE_IMG'"
                                [type]="'file'"
                                [regularInput]="true"
                                [inputType]="'basic'"
                                [required]="true"
                                [icon]="'visible'"
                                (change)="valueChange($event)">
        </app-prolabo-icon-input>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-input [for]="'mandate'"
                           [id]="'mandate'"
                           [keyword]="'MANDATE'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [defaultValue]="order?.mandate"
                           (OnValueChange)="onValueChange($event,'mandate')"
                           formControlName="mandate">
        </app-prolabo-input>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-input [for]="'remarks'"
                           [id]="'remarks'"
                           [keyword]="'REMARKS'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [defaultValue]="order?.remarks"
                           (OnValueChange)="onValueChange($event,'remarks')"
                           formControlName="remarks">
        </app-prolabo-input>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-button (click)="openExtraInfoDialog()"
                            [name]="'VISIT_INFORMATION'"
                            type="primary">
        </app-prolabo-button>
      </div>
    </div>
    <div class="p-grid top-header spacing-bottom">
      <div class="p-col-12 title">
        <p-header>{{'BILING_ADDRESS' | translate}}</p-header>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-input [for]="'company'"
                           [id]="'company'"
                           [keyword]="'ENTERPRISE'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           (OnValueChange)="onValueChange($event,'company')"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[(order?.invoice_address?.length -1)]?.company"
                           formControlName="company">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'invoicing_address'"
                           [id]="'invoicing_address'"
                           [keyword]="'ADDRESS'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[order?.invoice_address?.length -1]?.address"
                           (OnValueChange)="onValueChange($event,'invoicing_address')"
                           formControlName="invoicing_address">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'postal_code'"
                           [id]="'postal_code'"
                           [keyword]="'POSTCODE'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           (OnValueChange)="onValueChange($event,'postal_code')"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[(order?.invoice_address?.length -1)]?.postal_code"
                           formControlName="postal_code">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'city'"
                           [id]="'city'"
                           [keyword]="'CITY'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           (OnValueChange)="onValueChange($event,'city')"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[(order?.invoice_address?.length -1)]?.city"
                           formControlName="city">
        </app-prolabo-input>
      </div>
    </div>
  </form>
</p-card>
