import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownModel } from '../../../../../shared/components/dropdown/dropdown.model';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '../../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-scorecard-field',
  templateUrl: './create-scorecard-field.component.html',
  styleUrls: ['./create-scorecard-field.component.scss']
})
export class CreateScorecardFieldComponent implements OnInit {

  scorecardFieldForm: FormGroup;
  dropdownModels: DropdownModel[] = [];
  scoreCardModelId: number;

  constructor(private formBuilder: FormBuilder,
              private resources: ResourcesService,
              private config: DynamicDialogConfig,
              private toast: ToastService,
              private translate: TranslateService,
              private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.dropdownModels = this.config.data.dropdownModels;
    this.scoreCardModelId = this.config.data.scoreCardModelId;

    this.scorecardFieldForm = this.formBuilder.group({
      slug: ['', Validators.required],
      scorecard_model_id: [this.scoreCardModelId, Validators.required],
      scorecard_field_type_id: [this.dropdownModels[0].id, Validators.required],
      locale: [this.resources.getLanguage().description, Validators.required]
    });
  }

  submit() {
    if (this.scorecardFieldForm.valid) {
      this.ref.close(this.scorecardFieldForm.value);
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  updateDropdownModel($event: string) {
    this.scorecardFieldForm.patchValue({
      scorecard_field_type_id: $event
    });
  }

  back() {
    this.ref.close();
  }
}
