import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {DiagnosticPlanModalComponent} from '../../../../modals/diagnostic-plan-modal/diagnostic-plan-modal.component';
import {Media} from '../../../../../../models/plan-response';
import {environment} from '../../../../../../../../environments/environment';
import { DiagnosticOrder } from '../../../../../../models/diagnostic-order-response';

@Component({
  selector: 'app-prolabo-diagnostic-plan-item',
  templateUrl: './diagnostic-plan-item.component.html',
  styleUrls: ['./diagnostic-plan-item.component.scss']
})
export class DiagnosticPlanItemComponent implements OnChanges{
  @Input() new = false;
  @Input() plan: any;
  @Input() order: DiagnosticOrder;
  @Input() step1 = false;
  @Input() step2 = false;
  @Input() step3 = false;
  @Input() step4 = false;
  @Input() stepDone = false;

  @Output() deletePlan: EventEmitter<number> = new EventEmitter<number>();
  @Output() addPlan: EventEmitter<FormData> = new EventEmitter<FormData>();

  imageSrc: string;

  constructor(private dialogService: DialogService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.plan) {
      this.imageSrc = `${environment.storageUrl}${this.plan?.path}`;
    }
  }

  add() {
    const ref = this.dialogService.open(DiagnosticPlanModalComponent, {
      showHeader: false,
      width: '70%',
      data: { order_id: this.order?.id, update: false }
    });

    ref.onClose.subscribe(form => {
      this.addPlan.emit(form);
    });
  }

  update() {
    const ref = this.dialogService.open(DiagnosticPlanModalComponent, {
      showHeader: false,
      width: '70%',
      data: { plan: this.plan, update: true }
    });

    ref.onClose.subscribe(form => {
      // update plan
    });
  }

  delete(id) {
    this.deletePlan.emit(id);
  }
}
