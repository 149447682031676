<p-card *ngIf="currentUser">
  <div class="p-grid spacing-bottom">
    <div class="p-col-6 title header-container">
      <p-header>{{'PRICES' | translate}}</p-header>
    </div>
    <div class="p-col-6" *ngIf="userPrices">
      <app-prolabo-button
        type="primary"
        [name]="'RESTORE_PRICES'"
        (click)="restorePrices()"></app-prolabo-button>
    </div>
  </div>
  <div style="text-align: center; padding-bottom: 20px">
    <p-selectButton [(ngModel)]="selectedOption" [options]="stateOptions" (onChange)="selectionChanged($event)"
                    optionLabel="label"
                    optionValue="value"></p-selectButton>
  </div>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-6" *ngFor="let price of prices">
      <ng-container *ngIf="isCompany">
        <app-prolabo-input [for]="price.sample_type"
                           [id]="price.sample_type"
                           [keyword]="price.sample_type.toUpperCase()"
                           [type]="'number'"
                           [inputType]="'basic'"
                           [required]="false"
                           [defaultValue]="price.company"
                           class="paddingBetween"
                           [disabled]="price.company === 0"
                           (OnValueChange)="priceChanged($event, price)"
        ></app-prolabo-input>
      </ng-container>
      <ng-container *ngIf="!isCompany">
        <app-prolabo-input [for]="price.sample_type"
                           [id]="price.sample_type"
                           [keyword]="price.sample_type.toUpperCase()"
                           [type]="'number'"
                           [inputType]="'basic'"
                           [required]="false"
                           [defaultValue]="price.private"
                           class="paddingBetween"
                           [disabled]="price.private === 0"
                           (OnValueChange)="priceChanged($event, price)"
        ></app-prolabo-input>
      </ng-container>
    </div>
  </div>
</p-card>
