<div class="p-grid">
  <div class="p-col-12 p-md-12 p-xl-8">
    <ng-container *ngIf="loaded">
      <app-prolabo-user-form [user]="user" [state]="state" (validationClicked)="handleValidationClicked($event)"
                             (userFormEvent)="handleUserForm($event)"
                             [validateClicked]="validateClicked"
                             (userFormOriginalFormEvent)="handleOriginalUserForm($event)"></app-prolabo-user-form>
      <app-prolabo-profile-tariff [currentUser]="user" [prices]="user.prices" [userPrices]="true" (priceChangedEvent)="priceChanged($event)"></app-prolabo-profile-tariff>

      <app-prolabo-users-administration [user]="user" [state]="state"
                                        [laboratories]="laboratories"
                                        [currentUser]="currentUser"
                                        [invoiceTypes]="invoiceTypes"
                                        (validateClicked)="handleValidationClicked($event)"
                                        (administrationOriginalFormEvent)="handleOriginalAdministrationForm($event)"
                                        (administrationFormEvent)="handleAdministrationForm($event)"></app-prolabo-users-administration>
    </ng-container>
    <ng-container *ngIf="!loaded">
      <app-prolabo-user-form [user]="emptyUser" [state]="state" (validationClicked)="handleValidationClicked($event)"
                             (userFormEvent)="handleUserForm($event)"
                             [validateClicked]="validateClicked" [isUser]="isUser"></app-prolabo-user-form>
      <app-prolabo-users-administration [user]="emptyUser" [state]="state" [laboratories]="laboratories"
                                        [invoiceTypes]="invoiceTypes"
                                        (validateClicked)="handleValidationClicked($event)" [isUser]="isUser"
                                        (administrationFormEvent)="handleAdministrationForm($event)"></app-prolabo-users-administration>
    </ng-container>
  </div>

  <div class="p-col-12 p-md-12 p-xl-4">
    <ng-container *ngIf="loaded">
      <app-prolabo-user-role-change [user]="user" [state]="state"></app-prolabo-user-role-change>
      <app-prolabo-style [user]="user" [state]="state"></app-prolabo-style>
      <app-prolabo-user-responsible [user]="user" (updatedUser)="onUpdatedUser($event)"></app-prolabo-user-responsible>
    </ng-container>
    <ng-container *ngIf="!loaded">
      <app-prolabo-user-role-change [user]="emptyUser" [state]="state" [validationClicked]="validateClicked"
                                    (roleFormEvent)="handleRoleFormEvent($event)"></app-prolabo-user-role-change>
      <app-prolabo-style [user]="emptyUser" [state]="state" [validationClicked]="validateClicked"
                         (styleFormEvent)="handleStyleFormEvent($event)"></app-prolabo-style>
    </ng-container>
  </div>

</div>
