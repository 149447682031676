<p-card>
  <div class="p-grid spacing-bottom">
    <div class="p-col-6 title">
      <p-header>{{'BASIC.INFORMATION' | translate}}</p-header>
    </div>
  </div>
  <form [formGroup]="profileForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-4">
        <app-prolabo-input [for]="'name'"
                           [id]="'name'"
                           [keyword]="'NAME'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="currentUser.first_name"
                           [required]="false"
                           formControlName="first_name"
        ></app-prolabo-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-input [for]="'surname'"
                           [id]="'surname'"
                           [keyword]="'SURNAME'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="currentUser.last_name"
                           [required]="false"
                           formControlName="last_name"></app-prolabo-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-dropdown [options]="titles"
                              (OnValueChange)="updateTitleValue($event)"
                              [defaultValue]="currentUser.title_id"
                              placeholder="TITLE"></app-prolabo-dropdown>
      </div>
      <div class="p-col-12">
        <div class="p-grid">
          <div class="p-col-4">
            <div class="p-grid company-radio-container">
              <ng-container *ngFor="let type of company">
                <div class="p-col-6 company-radio">
                  <p-radioButton [inputId]="type.key" [(ngModel)]="selectedType" [ngModelOptions]="{standalone: true}"
                                 class="radio-icon"
                                 name="type"
                                 (ngModelChange)="handleTypeChange($event)"
                                 [value]="type"></p-radioButton>
                  <p class="radio-label">{{type.name}}</p>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="p-col-8">
            <ng-container *ngIf="isCompany">
              <app-prolabo-input [for]="'company'"
                                 [id]="'company'"
                                 [keyword]="'COMPANY'"
                                 [type]="'text'"
                                 [inputType]="'basic'"
                                 [defaultValue]="currentUser.company"
                                 [required]="false"
                                 formControlName="company"></app-prolabo-input>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'email'"
                           [id]="'email'"
                           [keyword]="'EMAIL'"
                           [type]="'text'"
                           [inputType]="'email'"
                           [defaultValue]="currentUser.email"
                           [required]="false"
                           formControlName="email"></app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'invoice_email'"
                           [id]="'invoice_email'"
                           [keyword]="'INVOICE_MAIL'"
                           [type]="'text'"
                           [inputType]="'email'"
                           [defaultValue]="currentUser.invoice_email"
                           [required]="false"
                           formControlName="invoice_email"></app-prolabo-input>
      </div>
      <div class="p-col-12 p-md-6">
        <app-prolabo-input [for]="'phone'"
                           [id]="'phone'"
                           [keyword]="'PHONE'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="currentUser.phone"
                           [required]="false"
                           formControlName="phone"></app-prolabo-input>
      </div>
      <div class="p-col-12 p-md-6">
        <app-prolabo-input [for]="'mobile'"
                           [id]="'mobile'"
                           [keyword]="'MOBILE'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="currentUser.mobile"
                           [required]="false"
                           formControlName="mobile"></app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-dropdown [options]="languages"
                              (OnValueChange)="updateLanguageValue($event)"
                              [defaultValue]="currentUser.lang_id"
                              placeholder="LANGUAGE"></app-prolabo-dropdown>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-input [for]="'address'"
                           [id]="'address'"
                           [keyword]="'ADDRESS'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="currentUser.address.address"
                           [required]="false"
                           formControlName="address"></app-prolabo-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-input [for]="'postal_code'"
                           [id]="'postal_code'"
                           [keyword]="'POSTCODE'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="currentUser.address.postal_code"
                           [required]="false"
                           formControlName="postal_code"></app-prolabo-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-input [for]="'city'"
                           [id]="'city'"
                           [keyword]="'CITY'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="currentUser.address.city"
                           [required]="false"
                           formControlName="city"></app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'password'"
                           [id]="'password'"
                           [keyword]="'PASSWORD'"
                           [type]="'password'"
                           [inputType]="'password'"
                           [required]="false"
                           formControlName="password"></app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'confirm_password'"
                           [id]="'confirm_password'"
                           [keyword]="'PASSWORD_CONFIRMATION'"
                           [type]="'password'"
                           [inputType]="'password'"
                           (OnValueChange)="checkPasswords($event)"
                           [required]="false"
                           formControlName="password_confirmation"></app-prolabo-input>
      </div>
      <div class="p-col-12 p-md-6 p-lg-3">
        <p class="label-like-text">{{'DATE.CREATION' | translate}}</p>
        <p class="ordinary-text">{{currentUser.created_at | date}}</p>
      </div>
      <div class="p-col-12 p-md-6 p-lg-2">
        <p class="label-like-text">{{'CREATED_BY' | translate}}</p>
        <p class="ordinary-text">{{currentUser.created_by }}</p>
      </div>
      <div class="p-col-12 p-md-6 p-lg-4">
        <app-prolabo-icon-input [for]="'signature'"
                                [id]="'signature'"
                                [keyword]="'SIGNATURE'"
                                [icon]="'visible'"
                                [type]="'file'"
                                [regularInput]="true"
                                [inputType]="'basic'"
                                (change)="valueChange($event)"
                                [required]="false"
        ></app-prolabo-icon-input>
      </div>
      <div class="p-col-12 p-md-6 p-lg-3">
        <app-prolabo-icon-button
          type="secondary"
          [icon]="'check'"
          [name]="'VALIDATE'"
          (click)="validate()"></app-prolabo-icon-button>
      </div>
    </div>

  </form>
</p-card>
