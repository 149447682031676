<div class="clients-container content">
  <app-dialog-header [title]="'VISIT_INFORMATION'" (iconClick)="ref.close()"></app-dialog-header>
  <form [formGroup]="extraInfoForm">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="p-grid">
          <div class="p-col-6" *ngIf="order?.pre_visit_date !== null">
            <app-prolabo-input [for]="'pre_visit_date'"
                               [id]="'pre_visit_date'"
                               [keyword]="'PRE_VISIT_DATE'"
                               [disabled]="disableForm"
                               [type]="'date'"
                               [inputType]="'basic'"
                               [defaultValue]="order?.pre_visit_date"
                               formControlName="pre_visit_date">
            </app-prolabo-input>
          </div>
          <div class="p-col-6" *ngIf="order?.pre_visit_date === null">
            <app-prolabo-input [for]="'pre_visit'"
                               [id]="'pre_visit'"
                               [keyword]="'PRE_VISIT'"
                               [disabled]="disableForm"
                               [type]="'text'"
                               [inputType]="'basic'"
                               [defaultValue]="order?.pre_visit"
                               formControlName="pre_visit">
            </app-prolabo-input>
          </div>
          <div class="p-col-6">
            <app-prolabo-input [for]="'date_of_diagnosis'"
                               [id]="'date_of_diagnosis'"
                               [keyword]="'DATE_OF_DIAGNOSIS'"
                               [disabled]="disableForm"
                               [type]="'date'"
                               [inputType]="'basic'"
                               [defaultValue]="order?.date_of_diagnosis"
                               formControlName="date_of_diagnosis">
            </app-prolabo-input>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'scope_of_diagnosis'"
                           [id]="'scope_of_diagnosis'"
                           [keyword]="'DIAGNOSIS_SCOPE'"
                           [disabled]="disableForm"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [defaultValue]="order?.scope_of_diagnosis !== 'null' ? order?.scope_of_diagnosis : ''"
                           formControlName="scope_of_diagnosis">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'deviations'"
                           [id]="'deviations'"
                           [keyword]="'DEVIATIONS_FROM'"
                           [type]="'text'"
                           [disabled]="disableForm"
                           [inputType]="'basic'"
                           [defaultValue]="order?.deviations"
                           formControlName="deviations">
        </app-prolabo-input>
      </div>
      <div class="p-col-12 options-dropdown">
        <app-prolabo-dropdown [options]="additionalInfoOptions"
                              [disabled]="disableForm"
                              (OnValueChange)="updateAdditionalOption($event)"
                              [defaultValue]="order.order_additional_information_id != null ? order.order_additional_information_id : optionId"
                              placeholder="ADDITIONAL_INFORMATION">
        </app-prolabo-dropdown>
      </div>
      <div class="p-col-12">
        <textarea pInputTextarea
                  placeholder="{{ 'DANGEROUS_SITUATIONS' | translate }}"
                  id="potentially_dangerous_situations"
                  [defaultValue]="order?.potentially_dangerous_situations"
                  [disabled]="disableForm"
                  formControlName="potentially_dangerous_situations">
          </textarea>
      </div>
      <div class="p-col-12">
        <textarea pInputTextarea
                  placeholder="{{ 'RESERVATIONS' | translate }}"
                  id="reservations"
                  [defaultValue]="order?.reservations"
                  [disabled]="disableForm"
                  formControlName="reservations">
          </textarea>
      </div>
    </div>
  </form>
  <div class="p-grid">
    <div class="p-col-8"></div>
    <div class="p-col-4">
      <app-prolabo-button *ngIf="!disableForm"
                          [name]="'VALIDATE'"
                          (click)="save()"
                          type="primary">
      </app-prolabo-button>
    </div>
  </div>
</div>
