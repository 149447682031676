import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/shared/services';
import { AdminUsersService } from '../../services/admin-users.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AddClientComponent } from './add-client/add-client.component';
import { ResourcesService } from '../../../shared/services/resources.service';
import { AdminStatics } from '../../statics/admin-statics';
import { AdminClientsService } from '../../services/admin-clients.service';
import { Client } from '../../models/client';
import { FunctionsService } from '../../services/functions.service';

@Component({
  selector: 'app-prolabo-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  providers: [DialogService]
})
export class ClientsComponent implements OnInit {

  clients: Client[];
  totalClients;
  sortParams;
  searchParam;
  limit = 10;
  offset = 0;

  clientHeaders = [
    {title: 'N°', sortLabel: AdminStatics.id},
    {title: 'NAME', sortLabel: AdminStatics.firstName},
    {title: 'SURNAME', sortLabel: AdminStatics.lastName},
    {title: 'COMPANY', sortLabel: AdminStatics.company},
    {title: 'ADDRESS', sortLabel: AdminStatics.address},
    {title: 'DATE', sortLabel: AdminStatics.createdAt}
  ];

  constructor(
    private clientsService: AdminClientsService,
    private toast: ToastService,
    private resources: ResourcesService,
    private functions: FunctionsService,
    private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.getClients();
  }

  createClient() {
    const ref = this.dialogService.open(AddClientComponent, {
      showHeader: false,
      width: '70%',
      styleClass: 'app-dynamic-dialog'
    });

    ref.onClose.subscribe(res => {
      this.getClients();
    });
  }

  updateClient(client) {
    const ref = this.dialogService.open(AddClientComponent, {
      data: {client, update: true},
      showHeader: false,
      width: '70%',
      styleClass: 'app-dynamic-dialog'
    });

    ref.onClose.subscribe(res => {
      this.getClients();
    });
  }

  getClients() {
    const params = {locale: this.resources.getLanguage().description};
    if (this.sortParams) {
      params[AdminStatics.orderBy] = this.sortParams;
    }

    if (this.searchParam) {
      params[AdminStatics.search] = this.searchParam;
    }

    params[AdminStatics.limit] = this.limit;
    params[AdminStatics.offset] = this.offset;

    this.clientsService.getClients(params).subscribe(res => {
      this.clients = res.clients;
      this.totalClients = res.total;
    });
  }

  deleteClient(userId) {
    const params = {locale: this.resources.getLanguage().description, id: userId};
    this.clientsService.deleteClient(params).subscribe(res => {
        this.toast.showBottomCenterInfo(res.message);
        this.searchParam = null;
        this.getClients();
      },
      error => {
        this.functions.showError(error);
      });
  }

  sortByLabel(param) {
    this.sortParams = param.asc ? param.param + AdminStatics.asc : param.param + AdminStatics.desc;
    this.getClients();
  }

  changeLimit(limit) {
    this.limit = limit;
    this.offset = 0;
    this.getClients();
  }

  changeOffset(page) {
    this.offset = page * this.limit;
    this.getClients();
  }

  onSearchParamChange(param) {
    this.searchParam = param;
    this.getClients();
  }
}
