import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService, UserService} from '../../../../../shared/services';
import {AdminClientsService} from '../../../../services/admin-clients.service';
import {AdminSimpleOrdersService} from '../../../../services/admin-simple-orders.service';
import {ResourcesService} from '../../../../../shared/services/resources.service';
import {TranslateService} from '@ngx-translate/core';
import { ResponsibleService } from '../../../../../common/services/responsible.service';

@Component( {
  selector: 'app-prolabo-new-version-modal',
  templateUrl: './new-version-modal.component.html',
  styleUrls: [ './new-version-modal.component.scss' ]
} )
export class NewVersionModalComponent implements OnInit {
  newVersionForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private responsibleService: ResponsibleService,
              private clientsService: AdminClientsService,
              private ordersService: AdminSimpleOrdersService,
              private toast: ToastService,
              private translate: TranslateService,
              private resources: ResourcesService,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.newVersionForm = this.formBuilder.group({
      order_id: [this.config.data.order, Validators.required],
      new_version_description: ['', Validators.required],
      locale: [this.resources.getLanguage().description, Validators.required]
    });
  }

  submit() {
    if (!this.newVersionForm.valid) {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    } else {
      this.ref.close(this.newVersionForm.value);
    }
  }

  close() {
    this.ref.close();
  }
}
