<div class="centered" *ngIf="tokenValid">
  <div class="p-mr-2">
    <app-logo-container></app-logo-container>
    <p-card>
      <div class="p-d-flex p-jc-between">
        <div class="p-mr-2 title">
          <p-header>{{'FORGOT.PASSWORD' | translate}}</p-header>
        </div>
      </div>
      <form [formGroup]="resetPasswordForm">
        <app-prolabo-input [keyword]="'PASSWORD'"
                           [type]="'password'"
                           [for]="'password'"
                           [id]="'password'"
                           [inputType]="'email'"
                           formControlName="password"></app-prolabo-input>
        <app-prolabo-input [keyword]="'CONFIRM.PASSWORD'"
                           [type]="'password'"
                           [for]="'password_confirmation'"
                           [id]="'password_confirmation'"
                           [inputType]="'email'"
                           (OnValueChange)="checkPasswords($event)"
                           formControlName="password_confirmation"></app-prolabo-input>
      </form>
      <app-prolabo-button (click)="resetPassword()" [name]="'RESET.PASSWORD'"></app-prolabo-button>
    </p-card>
  </div>
</div>

<div class="centered" *ngIf="isSetPassword">
  <div class="p-mr-2">
    <app-logo-container></app-logo-container>
    <p-card>
      <div class="p-d-flex p-jc-between">
        <div class="p-mr-2 title">
          <p-header>{{'SET.PASSWORD' | translate}}</p-header>
        </div>
      </div>
      <form [formGroup]="setPasswordForm">
        <app-prolabo-input [keyword]="'PASSWORD'"
                           [type]="'password'"
                           [for]="'password'"
                           [id]="'password'"
                           [inputType]="'email'"
                           formControlName="password"></app-prolabo-input>
        <app-prolabo-input [keyword]="'CONFIRM.PASSWORD'"
                           [type]="'password'"
                           [for]="'password_confirmation'"
                           [id]="'password_confirmation'"
                           [inputType]="'email'"
                           (OnValueChange)="checkPasswords($event)"
                           formControlName="password_confirmation"></app-prolabo-input>
      </form>
      <app-prolabo-button (click)="resetPassword()" [name]="'SUBMIT'"></app-prolabo-button>
    </p-card>
  </div>
</div>
