import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {not} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-prolabo-order-final-step-container',
  templateUrl: './order-final-step-container.component.html',
  styleUrls: ['./order-final-step-container.component.scss']
})
export class OrderFinalStepContainerComponent implements OnInit {
  @Input() title: string;
  @Input() firstButtonTitle: string;
  @Input() secondButtonTitle: string;
  @Input() thirdButtonTitle: string;
  @Input() firstButtonIcon: string;
  @Input() secondButtonIcon: string;
  @Input() thirdButtonIcon: string;
  @Input() textReport: string;
  @Input() emailReport: string;
  @Input() loading = false;
  @Input() user = false;
  @Input() textInvoices: string;
  @Input() textControl: string;
  @Input() noteControl: string;
  @Input() noteValue: string;
  @Input() dateControl: string;
  @Input() disableFirstButton = false;
  @Input() disableSecondButton = false;
  @Input() disableThirdButton = false;
  @Input() invoicesNumber: number;
  @Input() invoicesDate: string;
  @Input() reportsDate: string;
  @Input() emailDate: string;
  @Input() allowedToDownload: boolean;

  @Output() firstButton = new EventEmitter<boolean>();
  @Output() secondButton = new EventEmitter<boolean>();
  @Output() thirdButton = new EventEmitter<boolean>();

  ngOnInit(): void {}

  firstButtonClicked() {
    this.firstButton.emit(true);
  }

  secondButtonClicked() {
    this.secondButton.emit(true);
  }

  thirdButtonClicked() {
    this.thirdButton.emit(true);
  }

  protected readonly not = not;
}
