<div class="centered">
  <div class="p-mr-2">
    <app-logo-container [routerLink]="path"></app-logo-container>
    <p-card>
      <div class="p-grid">
        <div class="p-col-8 title">
          <p-header>{{'FORGOT.PASSWORD' | translate}}</p-header>
        </div>
        <div class="p-mt-1 p-col-4 title-right  text-gray text-large link">
          <p-header [routerLink]="path">{{'LOGIN' | translate}}</p-header>
        </div>
      </div>
      <form [formGroup]="forgotPasswordForm">
        <app-prolabo-input [keyword]="'EMAIL'"
                           [type]="'text'"
                           [for]="'email'"
                           [id]="'email'"
                           [inputType]="'email'"
                           formControlName="email"></app-prolabo-input>
      </form>
      <app-prolabo-button (click)="forgotPassword()" [name]="'FORGOT.PASSWORD'"></app-prolabo-button>
    </p-card>
  </div>
</div>
