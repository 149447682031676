import { Component, OnInit } from '@angular/core';
import { AdminAnalystCreatorService } from '../../../../common/services/admin-analyst-creator.service';
import { ScoreCardModel } from '../../../models/score-card-model';
import { AdminOrdersService } from '../../../services/admin-orders.service';
import { concat, forkJoin, } from 'rxjs';
import { Type } from '../../../models/type';
import { DropdownModel } from '../../../../shared/components/dropdown/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { FieldTypes } from '../../../models/field-types';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateScorecardModalComponent } from '../analyst-creator-modals/create-scorecard-modal/create-scorecard-modal.component';
import { ToastService } from '../../../../shared/services';
import { ResourcesService } from '../../../../shared/services/resources.service';

@Component({
  selector: 'app-prolabo-analyst-creator-container',
  templateUrl: './analyst-creator-container.component.html',
  styleUrls: ['./analyst-creator-container.component.scss']
})
export class AnalystCreatorContainerComponent implements OnInit {
  scoreCardModels: ScoreCardModel[];
  sampleTypes: DropdownModel[] = [];
  fieldTypes: DropdownModel[] = [];

  constructor(private analystCreator: AdminAnalystCreatorService,
              private translate: TranslateService,
              private dialogService: DialogService,
              private toastService: ToastService,
              private resources: ResourcesService,
              private ordersService: AdminOrdersService) { }

  ngOnInit(): void {
    this.getDetails();
  }

  private getDetails(): void {
    const scoreCardModels = this.analystCreator.getScoreCardModels();
    const allSampleTypes = this.ordersService.getAllSampleTypes();
    const scoreCardFieldTypes = this.analystCreator.getScoreCardFieldTypes();
    forkJoin([scoreCardModels, allSampleTypes, scoreCardFieldTypes]).subscribe(result => {
      this.scoreCardModels = result[0].models;
      this.setTypes(result[1]);
      this.setFieldTypes(result[2].field_types);
    });
  }

  private setTypes(types: Type[]) {
    types.forEach(type => {
      this.sampleTypes.push(
        {
          id: type.id,
          text: this.translate.instant(type.slug),
          value: type.id
        }
      );
    });
  }

  private setFieldTypes(types: FieldTypes[]) {
    types.forEach(type => {
      this.fieldTypes.push(
        {
          id: type.id,
          text: this.translate.instant(type.slug),
          value: type.id
        }
      );
    });
  }

  createScoreCard() {
    const ref = this.dialogService.open(CreateScorecardModalComponent, {
      showHeader: false,
      width: '40%',
      styleClass: 'app-dynamic-dialog',
      data: this.sampleTypes
    });

    ref.onClose.subscribe(res => {
      if (res) {
        const createField = this.analystCreator.createScoreCardModal(res);
        const scoreCardModels = this.analystCreator.getScoreCardModels();

        concat(createField, scoreCardModels).subscribe(result => {
          if (result.message) {
            this.toastService.showBottomCenterInfo(result.message);
          }
          if (result.models) {
            this.scoreCardModels = result.models;
          }
        }, error => {
          this.toastService.showBottomCenterError(error.error.message);
        });
      }
    });
  }

  updateSampleTypeValue($event: string, scoreCardModelId) {
    const body = {
      scorecard_model_id: scoreCardModelId,
      sample_type_id: $event,
      locale: this.resources.getLanguage().description
    };
    const updateField = this.analystCreator.updateScoreCardModal(body);
    const scoreCardModels = this.analystCreator.getScoreCardModels();

    concat(updateField, scoreCardModels).subscribe(result => {
      if (result.message) {
        this.toastService.showBottomCenterInfo(result.message);
      }
      if (result.models) {
        this.scoreCardModels = result.models;
      }
    }, error => {
      this.toastService.showBottomCenterError(error.error.message);
    });
  }


  handleUpdatedField($event: any) {
    this.analystCreator.getScoreCardModels().subscribe(result => {
      this.scoreCardModels = result.models;
    });
  }

  handleDeletedFile($event: any) {
    this.analystCreator.getScoreCardModels().subscribe(result => {
      this.scoreCardModels = result.models;
    });
  }

  handleCreatedItem($event: any) {
    this.analystCreator.getScoreCardModels().subscribe(result => {
      this.scoreCardModels = result.models;
    });
  }

  nameChange($event: any, scoreCardModelId) {
    const body = {
      scorecard_model_id: scoreCardModelId,
      name: $event,
      locale: this.resources.getLanguage().description
    };
    const updateField = this.analystCreator.updateScoreCardModal(body);
    const scoreCardModels = this.analystCreator.getScoreCardModels();

    concat(updateField, scoreCardModels).subscribe(result => {
      if (result.message) {
        this.toastService.showBottomCenterInfo(result.message);
      }
      if (result.models) {
        this.scoreCardModels = result.models;
      }
    }, error => {
      this.toastService.showBottomCenterError(error.error.message);
    });
  }
}
