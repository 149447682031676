<div class="pagination-list-analyst-creator">
  <ng-container *ngIf="items">
    <div class="p-grid">
      <div class="p-col-12 list">
        <div class="p-grid list-labels" *ngIf="headers && headers.length > 0">
          <div class="p-col-2">
            <p>{{ headers[0]['title'] | translate }}</p>
          </div>
          <div class="p-col-2">
            <p>{{ headers[1]['title'] | translate }}</p>
          </div>
          <div class="p-col-6">
            <p>{{ headers[2]['title'] | translate }}</p>
          </div>
          <div class="p-col-2">
            <p class="delete">
              {{"REQUIRED" | translate}}
            </p>
          </div>
        </div>
        <ng-container *ngFor="let scoreCardField of items">
          <div class="p-grid list-item">
            <div class="p-col-2">
              <app-prolabo-input-field [defaultValue]="scoreCardField.field_name" type="text" inputType="text"
                                       (change)="inputChange($event, scoreCardField.id)"
                                       [id]="scoreCardField.id.toString() + scoreCardField.slug">
              </app-prolabo-input-field>
            </div>
            <div class="p-col-2">
              <app-prolabo-dropdown-field [options]="fieldTypes"
                                          (OnValueChange)="updateFieldType($event, scoreCardField.id)"
                                          [defaultValue]="scoreCardField.scorecard_field_type_id">
              </app-prolabo-dropdown-field>
            </div>
            <div class="p-col-6">
              <div class="p-grid" *ngIf="scoreCardField.scorecard_field_type.slug === 'dropdown'">
                <div class="p-col-2">
                  <app-prolabo-icon icon="add" class="icon-black"
                                    (click)="createDropdownOption(scoreCardField.id)">
                  </app-prolabo-icon>
                </div>
                <div class="p-col-10 tags">
                  <ng-container *ngFor="let option of scoreCardField.scorecard_field_option">
                    <p tooltipPosition="top" pTooltip="{{setDropdownOptions(option, scoreCardModalId)}}">
                      <button pButton
                              type="button"
                              [label]="setDropdownOptions(option, scoreCardModalId)"
                              [disabled]="true"
                              [class]="'button-selected'">
                        <ng-container *ngIf="option.id > 15">
                           <span class="icon" (click)="openConfirmDialog(option.id,'dropdown')">
                           <img src="../../../../../assets/icons/close.svg"/>
                         </span>
                        </ng-container>
                      </button>
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="p-col-1">
              <p class="checkbox-icon">
                <p-checkbox class="checkbox-icon"
                            [ngModel]="[getCheckBoxValue(scoreCardField)]"
                            [value]="true"
                            [disabled]="scoreCardField.id < 77"
                            (onChange)="updateCheckBoxValue($event,scoreCardField.id)">
                </p-checkbox>
              </p>
            </div>
            <div class="p-col-1">
              <p class="delete" *ngIf="scoreCardField.id >= 77">
                <app-prolabo-icon class="icon-white"
                                  (click)="openConfirmDialog(scoreCardField.id,'field')"
                                  icon="trash-bin">
                </app-prolabo-icon>
              </p>
            </div>
          </div>
        </ng-container>
      </div>
      <app-prolabo-icon icon="add" class="icon-black" (click)="createField()"></app-prolabo-icon>
    </div>
  </ng-container>
</div>
<app-prolabo-confirmation-modal
  [display]="displayModal"
  [header]="'MODAL_CONFIRMATION'"
  (confirmClicked)="deleteItem()"
  (cancelClicked)="closeConfirmDialog()">
</app-prolabo-confirmation-modal>
