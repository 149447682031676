import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdminRoutesStatics } from '../statics/admin-routes-statics';
import {
  ClientCreateResponse,
  ClientDeleteResponse,
  ClientResponse,
  ClientResponseAll,
  ClientUpdateResponse
} from '../models/client';
import { ResourcesService } from '../../shared/services/resources.service';

@Injectable({
  providedIn: 'root'
})
export class AdminClientsService {

  constructor(private http: HttpClient, private resources: ResourcesService) { }

  public getClients(body): Observable<ClientResponse> {
    return this.http.post<ClientResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getClients}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public createClient(body): Observable<ClientCreateResponse> {
    return this.http.post<ClientCreateResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.createClient}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public updateClient(body): Observable<ClientUpdateResponse> {
    return this.http.put<ClientUpdateResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.updateClient}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public deleteClient(body): Observable<ClientDeleteResponse> {
    return this.http.delete<ClientDeleteResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.deleteClient}`, {
      headers: this.getHeaders(this.resources.getStorageUser().token),
      params: body
    });
  }

  public getAllClients(): Observable<ClientResponseAll> {
    return this.http.post<ClientResponseAll>(`${environment.adminApiUrl}${AdminRoutesStatics.getAllClients}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }

  private getMultiPartHeader(token): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }

}
