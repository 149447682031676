import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-prolabo-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() icon: string;
  @Input() type: string;
  @Input() class: string;
  @Input() loading = false;
  @Input() position: string;

  @Output() OnClick = new EventEmitter<any>();

  ngOnInit() {}

  ngOnChanges() {
  }

  click(event) {
    this.OnClick.emit(event);
  }
}
