import { Component, OnInit } from '@angular/core';
import { CalendarService } from '../../../shared/services/calendar.service';
import { Calendar } from '../../../models/Calendar';
import { ResourcesService } from '../../../shared/services/resources.service';
import { StorageUser } from '../../../models';
import { AdminOrdersService } from '../../../admin/services/admin-orders.service';
import { Type } from '../../../admin/models/type';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  items: Calendar[];
  firstItem: Calendar;
  sampleTypes: Type[];

  constructor(private calendar: CalendarService, private ordersService: AdminOrdersService, private resources: ResourcesService) { }

  ngOnInit(): void {
    this.getSampleTypes();
    this.getCalendarAdmin();
  }

  private getSampleTypes() {
    this.ordersService.getAllSampleTypes().subscribe(result => {
      this.sampleTypes = result;
    });
  }

  private getCalendarAdmin(id = null) {
    const body: any = {
      locale: this.resources.getLanguage().description
    };
    if (id != null) {
      body.sample_type_id = id;
    }
    this.calendar.getCalendarAdmin(body).subscribe(result => {
      this.items = new Array(0);
      Object.entries(result['0']).forEach(x => {
        if (x[0] === '0') {
          const orders = new Array(0);
          Object.entries(x[1].orders).forEach(y => {
            orders.push(y[1]);
          });
          x[1].orders = orders;
          this.firstItem = x[1];
        } else {
          const orders = new Array(0);
          Object.entries(x[1].orders).forEach(y => {
            orders.push(y[1]);
          });
          x[1].orders = orders;
          this.items.push(x[1]);
        }
      });
    });
  }

  filterCalendar(id: any) {
    this.getCalendarAdmin(id);
  }
}
