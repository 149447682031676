import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastService } from 'src/app/shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InputValidationError } from 'src/app/models/input-error';
import { InputValidation } from 'src/app/models/input-validation';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { Client } from '../../../models/client';
import { AdminClientsService } from '../../../services/admin-clients.service';
import { FunctionsService } from '../../../services/functions.service';

@Component({
  selector: 'app-prolabo-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddClientComponent implements OnInit {

  clientUpdate: boolean;
  client: Client;
  clientForm: FormGroup;

  constructor(
    private clientsService: AdminClientsService,
    private toast: ToastService,
    public ref: DynamicDialogRef,
    private formBuilder: FormBuilder,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    private resources: ResourcesService,
    private functions: FunctionsService,
    private router: Router) {

    this.clientForm = this.formBuilder.group({
      company: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      postal_code: ['', [Validators.required]],
      city: ['', [Validators.required]],
      locale: [this.resources.getLanguage().description]
    });
  }

  ngOnInit(): void {
    this.clientUpdate = !!(this.config.data && this.config.data.update);
    if (this.clientUpdate && this.config.data && this.config.data.client) {
      this.client = this.config.data.client;
      this.clientForm.patchValue({
        company: this.client.company,
        first_name: this.client.first_name,
        last_name: this.client.last_name,
        address: this.client.address,
        postal_code: this.client.postal_code,
        city: this.client.city
      });
    }
  }

  addClient() {
    if (this.clientForm.valid) {
      if (this.clientUpdate) {
        const result = this.clientForm.value;
        result.id = this.client.id;

        this.clientsService.updateClient(result).subscribe(res => {
          this.toast.showBottomCenterInfo(res.message);
          this.ref.close();
          this.router.navigateByUrl('admin/clients');
        }, (error) => {
          this.functions.showError(error);
        });
      } else {
        this.clientsService.createClient(this.clientForm.value).subscribe(res => {
          this.toast.showBottomCenterInfo(res.message);
          this.ref.close();
          this.router.navigateByUrl('admin/clients');
        }, (error) => {
          this.functions.showError(error);
        });
      }
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }
}
