import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService, UserService } from 'src/app/shared/services';
import {
  AdditionalInfoOptions,
  DiagnosticOrder,
  DiagnosticSample
} from 'src/app/admin/models/diagnostic-order-response';
import { Client } from 'src/app/admin/models/client';
import { DialogService } from 'primeng/dynamicdialog';
import { Type } from 'src/app/admin/models/type';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { FormGroup } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { TranslateService } from '@ngx-translate/core';
import { ScoreCardModalComponent } from '../../../modals/score-card-modal/score-card-modal.component';
import { ResourcesService } from '../../../../../../shared/services/resources.service';
import { AdminStatics } from '../../../../../statics/admin-statics';
import { StepCounter } from '../../../../../models/step-counter';
import { DiagnosticSampleCreateModalComponent } from '../../../modals/diagnostic-sample-create-modal/diagnostic-sample-create-modal.component';
import { CollectMethod, Plan } from '../../../../../models/plan-response';
import { AdminDiagnosticSamplesService } from '../../../../../services/admin-diagnostic-samples.service';
import { AdminDiagnosticOrdersService } from '../../../../../services/admin-diagnostic-orders.service';
import { AdminOrdersService } from '../../../../../services/admin-orders.service';
import { StepTwoModalDiagnosticComponent } from '../../../modals/step-two-modal-diagnostic/step-two-modal-diagnostic.component';
import { AdminScorecardModelService } from '../../../../../services/admin-scorecard-model.service';
import { StepThreeModalDiagnosticComponent } from '../../../modals/step-three-diagnostic-modal/step-three-diagnostic-modal.component';
import { NewVersionModalComponent } from '../../../modals/new-version-modal/new-version-modal.component';
// tslint:disable-next-line:max-line-length
import { DiagnosticEvaluationModalComponent } from '../../../modals/diagnostic-evaluation-modal/diagnostic-evaluation-modal.component';
import { environment } from '../../../../../../../environments/environment';
import { ScoreCardModel } from '../../../../../models/score-card-model';
import { AdminAnalystCreatorService } from '../../../../../../common/services/admin-analyst-creator.service';
import { DatePipe } from '@angular/common';
import { CorruptedSamplesComponent } from '../../../modals/corrupted-samples/corrupted-samples.component';
import { BasicReportInfoComponent } from '../../../modals/basic-report-info/basic-report-info.component';
import { FunctionsService } from '../../../../../services/functions.service';
import { Deadline } from '../../../../../../models/deadline';
import { BexioService } from '../../../../../../common/services/bexio.service';
import { RegenerateInvoiceComponent } from '../../../modals/regenerate-invoice/regenerate-invoice.component';
import { InvoiceType, Laboratory } from '../../../../../../models/laboratorie';
import { AdminDashboardService } from '../../../../../services/admin-dashboard.service';

@Component({
  selector: 'app-prolabo-diagnostic-order-container',
  templateUrl: './diagnostic-order-container.component.html',
  styleUrls: ['./diagnostic-order-container.component.scss']
})
export class DiagnosticOrderContainerComponent implements OnInit, OnDestroy {
  private originalTypes: Type[];

  constructor(private route: ActivatedRoute,
              private dialogService: DialogService,
              private toast: ToastService,
              private router: Router,
              private user: UserService,
              private translate: TranslateService,
              private resources: ResourcesService,
              private cdr: ChangeDetectorRef,
              private datePipe: DatePipe,
              private scorecard: AdminScorecardModelService,
              private analystCreator: AdminAnalystCreatorService,
              private diagnosticSamplesService: AdminDiagnosticSamplesService,
              private diagnosticOrdersService: AdminDiagnosticOrdersService,
              public functions: FunctionsService,
              private bexio: BexioService,
              private ordersService: AdminOrdersService,
              private dashboardService: AdminDashboardService) {
    translate.onLangChange.subscribe(x => {
      this.setTypes(this.originalTypes);
    });
  }

  order: DiagnosticOrder;
  laboratories: DropdownModel[] = new Array(0);
  clients: Client[];
  getOrderInitiated = false;
  plans: Plan[];
  types: DropdownModel[] = [];
  plansDropdown: DropdownModel[] = [];
  collectMethods: DropdownModel[] = [];
  additionalInfoOptions: DropdownModel[] = [];
  formValid: boolean;
  deadlines: Record<string, DropdownModel[]> = {};
  samplesHeader = [
    {title: '', sortLabel: null},
    {title: 'Évaluation', sortLabel: null, class: 'red'},
    {title: 'N° Prolabo', sortLabel: AdminStatics.sampleId},
    {title: 'SAMPLE_NUMBER', sortLabel: AdminStatics.sampleNumber},
    {title: 'ITEM_DESCRIPTION', sortLabel: AdminStatics.description},
    {title: 'APPROX_QUANTITY', sortLabel: AdminStatics.approxQuantity},
    {title: 'DIAGNOSTIC_SAMPLE', sortLabel: AdminStatics.collectMethod}
  ];
  samplesHeaderNoEmpty = [
    {title: '', sortLabel: null},
    {title: 'N° Prolabo', sortLabel: AdminStatics.sampleId},
    {title: 'SAMPLE_NUMBER', sortLabel: AdminStatics.sampleNumber},
    {title: 'ITEM_DESCRIPTION', sortLabel: AdminStatics.description},
    {title: 'APPROX_QUANTITY', sortLabel: AdminStatics.approxQuantity},
    {title: 'DIAGNOSTIC_SAMPLE', sortLabel: AdminStatics.collectMethod}
  ];
  samples: DiagnosticSample[] = new Array(0);
  samplesByType = [];
  initiatedSubmit = false;
  clientAdded = false;
  samplesAdded = false;
  sampleForm = new FormData();
  step1: boolean;
  step2: boolean;
  step3: boolean;
  step4: boolean;
  orderId: number;
  stepCounter1: StepCounter;
  stepCounter2: StepCounter;
  stepCounter3: StepCounter;
  stepCounter4: StepCounter;
  stepDone = false;
  isAnalyst: boolean;
  error = false;
  currentUser: User;
  displayModal = false;
  displayModal2 = false;
  displayModal3 = false;
  planToDelete = null;
  scorecardModels: ScoreCardModel[];
  disablePrint = false;
  numGeneratedInvoices = 0;
  generatedInvoicesDate = null;
  generatedReportDate = null;
  generatedReportAtDate = null;
  loadingReport = false;
  canBeAnalyzed = false;
  visitInformationError = false;

  // report modals
  asbestosQuantity: DropdownModel[] = [];
  externalInfluence: DropdownModel[] = [];
  placeUtilisation: DropdownModel[] = [];
  surfaceState: DropdownModel[] = [];
  sanitation: DropdownModel[] = [];
  invoiceTypes: DropdownModel[] = [];


  protected subscriptions = new Subscription();

  ngOnInit(): void {
    this.getScoreCardModels();
    this.currentUser = this.user.getCurrentUser();
    if (this.resources.getInvoiceTypes()) {
      this.setInvoiceTypes(this.resources.getInvoiceTypes());
    } else {
      this.setInvoiceTypes(this.resources.backupInvoiceTypes);
    }
    this.route.paramMap.subscribe(snapshot => {
      this.dashboardService.getLaboratories().subscribe(x => this.setLaboratories(x.laboratories))
      this.orderId = +snapshot.get(AdminStatics.id);
      this.getOrderDetails(this.orderId).then(() => {
      });
    });
  }

  openConfirmationDialog(id) {
    this.displayModal = true;
    this.planToDelete = id;
  }

  deleteSelectedPlan() {
    this.displayModal = false;
    const body = {
      locale: this.resources.getLanguage().description,
      plan_id: this.planToDelete
    };

    const subscription = this.diagnosticOrdersService.deletePlan(body).subscribe(res => {
      this.toast.showBottomCenterSuccess(res.message);
      this.getOrderDetails(this.orderId).then(() => {
      });
      this.planToDelete = null;
    }, error => {
      this.toast.showBottomCenterError(error.message);
    });

    this.subscriptions.add(subscription);
  }

  updateVisitInfo(form) {
    const subscription = this.diagnosticOrdersService.updateVisitInfo(form).subscribe(res => {
      this.toast.showBottomCenterSuccess(res.message);
      this.getOrderDetails(this.orderId).then(() => {
      });
    }, error => {
      this.functions.showError(error);
    });
    this.subscriptions.add(subscription);
  }

  planCreate(form) {
    if (form) {
      const subscription = this.diagnosticOrdersService.planCreate(form).subscribe(res => {
        this.toast.showBottomCenterSuccess(res.message);
        this.plans.push(res.plan as unknown as Plan);
        this.plansDropdown.push({
          id: res.plan.id,
          text: res.plan.name,
          value: res.plan.path,
          additional: {
            width: res.plan.width,
            height: res.plan.height
          }
        });
        this.cdr.detectChanges();
      }, error => {
        this.functions.showError(error);
      });

      this.subscriptions.add(subscription);
    }
  }

  openEtiquets(labelsPath: string) {
    window.open(environment.storageUrl + labelsPath, '_blank');
  }

  private setLaboratories(laboratories: Laboratory[]) {
    this.laboratories = new Array(0);
    laboratories.forEach(laboratory => {
      this.laboratories.push({id: laboratory.id, text: laboratory.city, value: laboratory.id});
    });
  }

  openScoreCard(samples: any[], isAnalysed) {
    const ref = this.dialogService.open(ScoreCardModalComponent, {
      showHeader: false,
      width: '90%',
      data: {
        order_id: this.order.id,
        samples,
        orderRemark: this.order.remarks,
        orderNumber: this.order.order_number,
        orderDate: this.order.created_at,
        laboratoryId: this.order.laboratory_id,
        order: this.order
      },
    });
    ref.onClose.subscribe(form => {
      if (form) {
        if (isAnalysed) {
          this.scorecard.updateScoreCard(form).subscribe(result => {
            this.toast.showBottomCenterInfo(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        } else {
          this.scorecard.validateScoreCard(form).subscribe(result => {
            this.toast.showBottomCenterInfo(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        }
      }
    });
  }

  editSample(sampleId: number) {
    const sample = this.samples.filter(s => s.id === sampleId)[0];
    let analogySamples = [];
    const body = {
      order_id: this.orderId,
      sample_id: sampleId,
      sample_type_id: sample.sample_type_id,
      locale: this.resources.getLanguage().description
    };
    this.diagnosticSamplesService.getAnalogySamples(body).subscribe(res => {
      // @ts-ignore
      analogySamples = res.samples;

      const ref = this.dialogService.open(DiagnosticSampleCreateModalComponent, {
        showHeader: false,
        width: '80%',
        data: {
          order: this.order,
          types: this.types,
          sample,
          plans: this.plansDropdown,
          collect_methods: this.collectMethods,
          analogy: analogySamples,
          samples: this.samples,
          update: true,
          disabled: !this.step1,
          plan: this.plansDropdown.find(x => x.id === sample.diagnostic.plan_id)
        },
      });

      ref.onClose.subscribe(data => {
        if (data) {
          if (data.regularHeaders) {
            const subscription = this.diagnosticSamplesService.updateSampleRegular(data.form)
              .subscribe((result: any) => {
                this.toast.showBottomCenterSuccess(result.message);
                this.getOrderDetails(this.order.id).then(() => {
                });
              }, error => {
                this.functions.showError(error);
              });
            this.subscriptions.add(subscription);
          } else {
            const subscription = this.diagnosticSamplesService.updateSampleMultipart(data.form)
              .subscribe((result: any) => {
                this.toast.showBottomCenterSuccess(result.message);
                this.getOrderDetails(this.order.id).then(() => {
                });
              }, error => {
                this.functions.showError(error);
              });

            this.subscriptions.add(subscription);
          }
        }
      });
    });
  }

  createSample(sampleTypeId = null) {
    let analogySamples = [];
    const body1 = {
      order_id: this.orderId,
      sample_type_id: sampleTypeId && sampleTypeId !== '-1' ? sampleTypeId : this.types[1].id,
      locale: this.resources.getLanguage().description
    };
    const body = {
      order_id: this.order.id,
      sample_type_id: sampleTypeId && sampleTypeId !== '-1' ? sampleTypeId : this.types[1].id,
      no_prolabo: sampleTypeId === '-1',
      locale: this.resources.getLanguage().description
    };
    const analogy = this.diagnosticSamplesService.getAnalogySamples(body1);
    const nextSampleId = this.ordersService.getNextSampleId(body);

    const subscription = forkJoin([analogy, nextSampleId]).subscribe(res => {
      analogySamples = res[0].samples;
      const ref = this.dialogService.open(DiagnosticSampleCreateModalComponent, {
        showHeader: false,
        width: '80%',
        data: {
          order: this.order,
          types: this.types,
          next_sample_id: res[1].next_sample_id,
          sample_type_id: sampleTypeId && sampleTypeId !== '-1' ? sampleTypeId : this.types[1].id,
          plans: this.plansDropdown,
          collect_methods: this.collectMethods,
          analogy: analogySamples,
          samples: this.samples,
          update: false,
          user: sampleTypeId === '-1'
        }
      });
      ref.onClose.subscribe(data => {
        if (data) {
          this.diagnosticSamplesService.createSample(data.form).subscribe((result: any) => {
            this.toast.showBottomCenterSuccess(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        }
      });
    }, error => {
      this.functions.showError(error);
    });
    this.subscriptions.add(subscription);
  }

  public submitOrder() {
    this.initiatedSubmit = true;
    setTimeout(() => {
      if (!this.error) {
        // @ts-ignore
        this.sampleForm.append('order_id', this.order.id);
        setTimeout(() => {
          const subscription = this.diagnosticOrdersService.submitOrder(this.sampleForm).subscribe(result => {
            this.toast.showBottomCenterSuccess(result.message);
            this.order = result.order;
            this.samplesAdded = false;
            this.clientAdded = false;
            this.initiatedSubmit = false;
            this.checkOrderValidity(this.order.latest_state.name, this.order.state);
            this.sampleForm = new FormData();
          }, error => {
            this.functions.showError(error);
            if (error.error.message?.date_of_diagnosis || error.error.message?.order_additional_information_id) {
              this.visitInformationError = true;
              setTimeout(() => {
                this.visitInformationError = false;
              }, 100);
            }
            this.sampleForm = new FormData();
            this.initiatedSubmit = false;
          });
          this.subscriptions.add(subscription);
        }, 100);
      }
    }, 100);
  }

  createNewVersion() {
    const ref = this.dialogService.open(NewVersionModalComponent, {
      showHeader: false,
      width: '80%',
      data: {order: this.orderId}
    });
    ref.onClose.subscribe(result => {
      if (result) {
        const subscription = this.diagnosticOrdersService.createNewVersion(result).subscribe(res => {
          this.toast.showBottomCenterSuccess(res.message);
          if (this.isAnalyst) {
            this.router.navigateByUrl('/analyst/orders/diagnostic-order/' + res.order.id)
              .then(() => {
                this.stepDone = false;
                this.step3 = false;
                this.step4 = false;
              });
          } else {
            this.router.navigateByUrl('/admin/orders/diagnostic-order/' + res.order.id)
              .then(() => {
                this.stepDone = false;
                this.step3 = false;
                this.step4 = false;
              });
          }
        }, error => {
          this.functions.showError(error);
        });
        this.subscriptions.add(subscription);
      }
    });
  }

  public handleClientEvent($event: Client) {
    if ($event) {
      this.clientAdded = true;
    }
  }

  public handleDelete($event: any) {
    const body = {
      id: $event,
      locale: this.resources.getLanguage().description
    };
    const subscription = this.diagnosticSamplesService.deleteSample(body).subscribe(result => {
      this.toast.showBottomCenterSuccess(result.message);
      this.getOrderDetails(this.orderId).then(() => {
        const sample = this.samples.find(s => s.id === $event);
        const index = this.samples.indexOf(sample, 0);
        if (index > -1) {
          this.samples.splice(index, 1);
        }
      });
    }, error => {
      this.functions.showError(error);
    });
    this.subscriptions.add(subscription);
  }

  public cancelOrder() {
    const body = {
      id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    this.displayModal3 = false;
    const subscription = this.diagnosticOrdersService.cancelOrder(body).subscribe(result => {
      if (this.isAnalyst) {
        this.router.navigateByUrl('/analyst/orders').then(() => {
          this.toast.showBottomCenterSuccess(result.message);
        });
      } else {
        this.router.navigateByUrl('/admin/orders').then(() => {
          this.toast.showBottomCenterSuccess(result.message);
        });
      }

    }, error => {
      this.functions.showError(error);
    });

    this.subscriptions.add(subscription);
  }

  public reActivateOrder() {
    const body = {
      id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    this.displayModal2 = false;
    const subscription = this.diagnosticOrdersService.reActivateOrder(body).subscribe(result => {
      this.toast.showBottomCenterSuccess(result.message);
      this.getOrderDetails(this.order.id).then(() => {});
    }, error => {
      this.functions.showError(error);
    });
    this.subscriptions.add(subscription);
  }

  openConfirmDialog2() {
    this.displayModal2 = true;
  }

  closeConfirmDialog2() {
    this.displayModal2 = false;
  }

  openConfirmDialog3() {
    this.displayModal3 = true;
  }

  closeConfirmDialog3() {
    this.displayModal2 = false;
  }

  riskAssessmentForSample(sampleId: number) {
    const sample = this.samples.filter(s => s.id === sampleId)[0];
    const ref = this.dialogService.open(DiagnosticEvaluationModalComponent, {
      showHeader: false,
      width: '80%',
      data: {
        sample,
        surfaceState: this.surfaceState,
        placeUtilisation: this.placeUtilisation,
        asbestosQuantity: this.asbestosQuantity,
        externalInfluence: this.externalInfluence,
        sanitation: this.sanitation
      }
    });
    ref.onClose.subscribe(result => {
      if (result) {
        if (sample.report_value !== null) {
          const subscription = this.diagnosticSamplesService.updateReportValue(result).subscribe(res => {
            this.toast.showBottomCenterSuccess(res.message);
            this.getOrderDetails(this.orderId).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });

          this.subscriptions.add(subscription);
        } else {
          const subscription = this.diagnosticSamplesService.addReportValue(result).subscribe(res => {
            this.toast.showBottomCenterSuccess(res.message);
            this.getOrderDetails(this.orderId).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });

          this.subscriptions.add(subscription);
        }
      }
    });
  }

  shouldMarkRed(item): boolean {
    if (this.step1 || this.step2) {
      if (item.diagnostic && item.diagnostic?.collect_method_id !== 1) {
        return true;
      }
    }
    return false;
  }

  public handleOrderFormEvent(form: FormGroup) {
    const remarks = 'remarks';
    if (form.value[remarks] === '') {
      delete form.value[remarks];
    }
    if (this.clientAdded) {
      this.toFormData(form.value);
    } else {
      this.toFormData(form.value, true);
    }
  }

  public handleEmailEvent($event: string[]) {
    // @ts-ignore
    for (const email of $event) {
      this.sampleForm.append('email[]', email);
    }
  }

  public handleLanguageEvent($event: number) {
    // @ts-ignore
    this.sampleForm.append('lang_id', $event);
    this.sampleForm.append('locale', this.resources.getLanguage().description);
  }

  getScoreCardModels() {
    this.analystCreator.getScoreCardModels(this.orderId).subscribe(result => {
      this.scorecardModels = result.models;
    });
  }

  public submitStep2() {
    this.initiatedSubmit = true;
    setTimeout(() => {
      if (!this.error) {
        // @ts-ignore

        this.sampleForm.append('order_id', this.order.id);
        setTimeout(() => {
          const body = {
            order_id: this.order.id,
            locale: this.resources.getLanguage().description
          };
          const hdrs = [
            {title: 'N° Prolabo', sortLabel: AdminStatics.sampleId},
            {title: 'SAMPLE_NUMBER', sortLabel: AdminStatics.sampleNumber},
            {title: 'TYPE', sortLabel: AdminStatics.sampleType},
            {title: 'ITEM_DESCRIPTION', sortLabel: AdminStatics.description},
            {title: 'APPROX_QUANTITY', sortLabel: AdminStatics.approxQuantity},
            {title: 'DIAGNOSTIC_SAMPLE', sortLabel: AdminStatics.collectMethod}
          ];
          this.diagnosticOrdersService.getCorruptedSamples(body).subscribe(res1 => {
            const ref = this.dialogService.open(StepTwoModalDiagnosticComponent, {
              showHeader: false,
              width: '80%',
              data: {samples: res1.samples, headers: hdrs}
            });
            ref.onClose.subscribe(result => {
              if (result) {
                const body1 = {
                  order_id: this.order.id,
                  send_email: result.email,
                  locale: this.resources.getLanguage().description,
                  sample_ids: result.ids
                };
                const subscription = this.diagnosticOrdersService.submitStep2(body1).subscribe(res => {
                  if (res.url) {
                    window.open(environment.storageUrl + res.url, '_blank');
                  }
                  this.getOrderDetails(this.orderId).then();
                  this.toast.showBottomCenterSuccess(res.message);
                }, error => {
                  this.functions.showError(error);
                  this.sampleForm = new FormData();
                  this.initiatedSubmit = false;
                });
                this.subscriptions.add(subscription);
              }
            });
          });
        }, 100);
      }
    }, 100);
  }

  submitStep3() {
    if (!this.error) {
      const ref = this.dialogService.open(StepThreeModalDiagnosticComponent, {
        showHeader: false,
        width: '80%',
        data: {order: this.order, reportByMail: false}
      });
      ref.onClose.subscribe(result => {
        if (result) {
          const subscription = this.diagnosticOrdersService.submitStep3(result).subscribe(res => {
            this.getOrderDetails(this.order.id).then(() => {
            });
            this.toast.showBottomCenterSuccess(res.message);
          }, error => {
            this.functions.showError(error);
          });

          this.subscriptions.add(subscription);
        }
      });
    }
  }

  private setInvoiceTypes(invoiceTypes: InvoiceType[]) {
    invoiceTypes.forEach(invoiceType => {
      this.invoiceTypes.push({id: invoiceType.id, text: invoiceType.slug, value: invoiceType.id});
    });
  }

  private toFormData<T>(formValue: T, shouldRemove?): void {
    if (shouldRemove) {
      for (const key of Object.keys(formValue)) {
        if (key === 'client_id') {
          continue;
        }
        const value = formValue[key];
        this.sampleForm.append(key, value);
      }
    } else {
      for (const key of Object.keys(formValue)) {
        const value = formValue[key];
        this.sampleForm.append(key, value);
      }
    }
  }

  public getOrderDetails(id): Promise<boolean> {
    this.isAnalyst = this.resources.getStorageUser().role === 'analyst';
    this.getOrderInitiated = false;
    return new Promise<boolean>((resolve => {
      const body = {
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const typeBody = {
        order_type_id: 1,
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const order = this.diagnosticOrdersService.getOrder(body);
      const sampleTypes = this.ordersService.getSampleTypes(typeBody);
      const plans = this.diagnosticOrdersService.getPlans(id);
      const collectMethods = this.ordersService.getCollectMethods();
      const additionalInfoOptions = this.ordersService.getAdditionalInfoOptions();
      const reportSampleDropdowns = this.diagnosticSamplesService.getReportDropdowns();
      const subscription = forkJoin([order, sampleTypes, plans, collectMethods,
        additionalInfoOptions, reportSampleDropdowns]).subscribe(result => {
          this.ordersService.getDeadlines(body).subscribe(deadlines => {
            this.order = null;
            this.getOrderInitiated = true;
            this.order = result[0].order;
            this.numGeneratedInvoices = this.order.invoices?.invoice ? 1 : 0;
            this.getGeneratedReportDate();
            this.getGeneratedReportAtDate();
            this.plans = new Array(0);
            this.plans = result[0].order.media.filter(media => media.media_type_id === 3) as unknown as Plan[];
            this.checkOrderValidity(this.order.latest_state.name, this.order.state);
            this.samplesByType = [];
            this.samples = new Array(0);
            if (this.order && this.order.samples) {
              for (const sample of Object.keys(this.order.samples)) {
                if (sample === 'prolabo') {
                  this.samplesAdded = true;
                  this.samplesByType = Object.entries(this.order.samples[sample]);
                  this.checkSamplesByType();
                  if (this.order?.samples[sample]) {
                    for (const s of Object.keys(this.order.samples[sample])) {
                      for (const finalSample of this.order.samples[sample][s].samples) {
                        this.samples.push(finalSample);
                        this.disablePrint = finalSample.report_value === null && finalSample.diagnostic?.collect_method_id === 3;
                      }
                    }
                  }
                }
              }
            }
            this.samples.forEach(sample => {
              if (sample.sample_type_id === 2) {
                if (sample.diagnostic.collect_method_id === 1) {
                  this.canBeAnalyzed = true;
                }
              }
            });
            this.setPlans(result[2].plans);
            this.setTypes(result[1]);
            this.setCollectMethods(result[3].collect_methods);
            this.setAdditionalInfoOptions(result[4].options);
            this.setAsbestosQuantity(result[5].dropdowns.asbestos_quantity);
            this.setExternalInfluence(result[5].dropdowns.external_influence);
            this.setPlaceUtilisation(result[5].dropdowns.place_utilisation);
            this.setSurfaceState(result[5].dropdowns.surface_state);
            this.setSanitation(result[5].dropdowns.sanitation);
            this.setDeadlines(deadlines.deadlines);
            resolve(true);
          }, error => this.functions.showError(error));
        }, error => {
          this.functions.showError(error);
        }
      );
      this.subscriptions.add(subscription);
    }));
  }

  getGeneratedReportDate() {
    if (this.order.action && this.order.action.length > 0) {
      // @ts-ignore
      const report = this.order.action.find(action => action.name ===
        AdminStatics.actionsGeneratedReports && action.pivot?.report_type === AdminStatics.reportStandard);
      if (report) {
        // @ts-ignore
        this.generatedReportDate = this.datePipe.transform(report.pivot?.updated_at, 'dd.MM.yy');
      }
    }
  }

  getGeneratedReportAtDate() {
    if (this.order.action && this.order.action.length > 0) {
      // @ts-ignore
      const report = this.order.action.find(action => action.name ===
        AdminStatics.actionsGeneratedReports && action.pivot?.report_type === AdminStatics.reportAfterWork);
      if (report) {
        // @ts-ignore
        this.generatedReportAtDate = this.datePipe.transform(report?.pivot?.updated_at, 'dd.MM.yy');
      }
    }
  }

  checkSamplesByType() {
    this.samplesByType.forEach(sample => {
      sample[1].samples.forEach(item => {
        // @ts-ignore
        item.mark_red = this.shouldMarkRed(item);
        // @ts-ignore
      });
    });
  }

  private setTypes(types: Type[]) {
    this.originalTypes = types;
    this.types = [{id: 0, text: 'SELECT', value: 0}];
    if (types) {
      Object.entries(types).map(sample => {
        this.types.push({
          id: sample[1].id,
          text: sample[1][this.resources.getLanguage().description],
          value: sample[1].id
        });
      });
    }
  }

  private setDeadlines(types: any) {
    const entries = Object.entries(types);
    // tslint:disable-next-line:forin
    for (const type in entries) {
      this.deadlines[entries[type[0]][0]] = this.setDeadlineByType(entries[type[0]][1]);
    }
  }

  private setDeadlineByType(types: Deadline[]) {
    const deadlines = new Array(0);
    Object.values(types).forEach(x => {
      deadlines.push({
        id: x.id,
        text: x.slug,
        value: x.id
      });
    });

    return deadlines;
  }

  private setCollectMethods(methods: CollectMethod[]) {
    this.collectMethods = [];
    if (methods) {
      Object.entries(methods).map(method => {
        this.collectMethods.push({
          id: method[1].id,
          text: method[1].id === 1 ? 'COLLECT_METHOD_ID_1' : method[1].id === 2 ?
            'COLLECT_METHOD_ID_2' : 'COLLECT_METHOD_ID_3',
          value: method[1].id
        });
      });
    }
  }

  private setAsbestosQuantity(options) {
    this.asbestosQuantity = [];
    if (options) {
      options.forEach(option => {
        this.asbestosQuantity.push({
          id: option.id,
          text: option.id === 1 ? 'ASBESTOS_QUANTITY_1' : 'ASBESTOS_QUANTITY_2',
          value: option.id,
          additional: {riskValue: option.id === 1 ? 3 : 1}
        });
      });
    }
    this.asbestosQuantity.sort((a, b) => {
      return b.id - a.id;
    });
  }

  private setPlaceUtilisation(options) {
    this.placeUtilisation = [];
    if (options) {
      options.forEach(option => {
        this.placeUtilisation.push({
          id: option.id,
          text: option.slug,
          value: option.id
        });
      });
    }
  }

  private setExternalInfluence(options) {
    this.externalInfluence = [];
    if (options) {
      options.forEach(option => {
        this.externalInfluence.push({
          id: option.id,
          text: option.id === 1 ? 'EXTERNAL_INFLUENCE_1' : 'EXTERNAL_INFLUENCE_2',
          value: option.id,
          additional: {riskValue: option.id === 1 ? 1 : 0}
        });
      });
    }
  }

  private setSurfaceState(options) {
    this.surfaceState = [];
    if (options) {
      options.forEach(option => {
        this.surfaceState.push({
          id: option.id,
          text: option.id === 1 ? 'SURFACE_STATE_1' : option.id === 2 ? 'SURFACE_STATE_2' : 'SURFACE_STATE_3',
          value: option.id,
          additional: {riskValue: option.id === 1 ? 1 : option.id === 2 ? 0 : -1}
        });
      });
    }
  }

  private setSanitation(options) {
    this.sanitation = [];
    if (options) {
      options.forEach(option => {
        this.sanitation.push({
          id: option.id,
          text: option.id === 1 ? 'SANITATION_1' : 'SANITATION_2',
          value: option.id
        });
      });
    }
  }

  private setAdditionalInfoOptions(options: AdditionalInfoOptions[]) {
    this.additionalInfoOptions = [];
    if (options) {
      options.forEach(option => {
        this.additionalInfoOptions.push({
          id: option.id,
          text: option.id === 1 ? 'ADDITIONAL_INFO_OPTION_1' : 'ADDITIONAL_INFO_OPTION_2',
          value: option.id
        });
      });
    }
  }

  private setPlans(plans: Plan[]) {
    this.plansDropdown = [];
    if (plans) {
      Object.entries(plans).map(plan => {
        this.plansDropdown.push({
          id: plan[1].id,
          text: plan[1].name,
          value: plan[1].path,
          additional: {
            width: plan[1].width,
            height: plan[1].height
          }
        });
      });
    }
  }


  private checkOrderValidity(orderName, state) {
    switch (orderName) {
      case 'admin.cancelled':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.cancelled':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.creating':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.ordered':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.inprocess':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.analyzed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = true;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.preveling':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.sent':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.inprocess':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.completed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = true;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeStep(state, 4, 'FINISHED');
        break;
      case 'client.completed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = true;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeStep(state, 4, 'FINISHED');
        break;
    }
  }

  private initializeStep(state, step, label): StepCounter {
    return {
      button: 'button-selected',
      label,
      icon: 'icon-number-selected',
      number: step,
      date: state[step]?.pivot.updated_at
    };
  }

  // @ts-ignore
  private initializeDefault(step, label): StepCounter {
    // @ts-ignore
    return {
      number: step,
      icon: 'icon-number-unselected',
      button: 'button-unselected',
      label,
      dateLabel: 'WAITING'
    };
  }

  private validateForm(formData: FormData): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      const subscription = this.diagnosticOrdersService.updateOrder(formData).subscribe(result => {
        this.getOrderDetails(this.orderId).then();
        resolve(true);
      }, error => {
        this.functions.showError(error);

        this.sampleForm = new FormData();
        this.initiatedSubmit = false;
      });
      this.subscriptions.add(subscription);
    }));
  }

  handleEmailError($event: boolean) {
    if ($event) {
      this.error = true;
      this.toast.showBottomCenterError(this.translate.instant('FORM.EMAIL'));
      this.initiatedSubmit = false;
    } else {
      this.error = false;
    }
  }

  handleOrderError($event: any) {
    if ($event.length > 0) {
      this.error = true;
      $event.forEach(x => {
        this.toast.showBottomCenterError(this.translate.instant(x));
      });
      this.initiatedSubmit = false;
    } else {
      this.error = false;
    }
  }

  getScoreCardModelHeaders(sampleTypeId: string) {
    return this.scorecardModels ? this.scorecardModels.find(x => x.sample_type_id === Number(sampleTypeId))?.scorecard_field : null;
  }

  handleTarifsFormEvent($event: FormGroup) {
    this.toFormData($event.value);
  }

  checkReportValue(): Promise<boolean> {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    return new Promise<boolean>((resolve => {
      const subscription = this.diagnosticOrdersService.checkReportValues(body).subscribe(result => {
        resolve(result.error);
      }, error => {
        if (error.error.error) {
          this.toast.showBottomCenterError(error.error.message);
        }
        resolve(error.error.error);
      });
      this.subscriptions.add(subscription);
    }));
  }

  printReportClicked() {
    if ((this.order.pdf_report_path == null && this.order.latest_state_id === 5)
      || (this.order.pdf_report_path == null && this.order.latest_state_id === 10)) {
      this.checkReportValue().then(res => {
        if (!res) {
          const ref = this.dialogService.open(BasicReportInfoComponent, {
            showHeader: false,
            width: '80%',
            data: {regenerate: 0, samples: this.order.samples.prolabo}
          });

          ref.onClose.subscribe(form => {
            if (form) {
              const formData = new FormData();
              for (const key of Object.keys(form)) {
                const value = form[key];
                formData.append(key, value);
              }
              // @ts-ignore
              formData.append('order_id', this.order.id);
              formData.append('locale', this.resources.getLanguage().description);
              const subscription = this.diagnosticOrdersService.getReportStatus(formData).subscribe(result => {
                this.toast.showBottomCenterInfo(result.message);
                if (result.url) {
                  window.open(environment.storageUrl + result.url, '_blank');
                  window.location.reload();
                }
                this.getOrderDetails(this.order.id).then(() => {
                });
              }, error => {
                this.functions.showError(error);
              });
              this.subscriptions.add(subscription);
            }
          });
        }
      });
    } else {
      window.open(environment.storageUrl + this.order.pdf_report_path, '_blank');
    }
  }

  printAtReportClicked() {
    if (this.order.pdf_at_report_path) {
      window.open(environment.storageUrl + this.order.pdf_at_report_path, '_blank');
    } else {
      const headers = new Array(0);
      headers.push(...this.samplesHeader);
      headers.push({title: 'RESULT', sortLabel: AdminStatics.type});
      const body2 = {
        order_id: this.order.id,
        locale: this.resources.getLanguage().description
      };
      this.checkReportValue().then(res => {
        if (!res) {
          const subscription = this.diagnosticOrdersService.getCorruptedSamples(body2).subscribe(x => {
            const ref = this.dialogService.open(CorruptedSamplesComponent, {
              showHeader: false,
              width: '80%',
              data: {samples: x.samples, headers}
            });
            // tslint:disable-next-line:variable-name
            ref.onClose.subscribe(modal_result => {
              if (modal_result && modal_result.ids && modal_result.form) {
                const body1 = {
                  order_id: this.order.id,
                  inspection_date: modal_result.form.inspection_date,
                  asbestos_removal_company: modal_result.form.asbestos_removal_company,
                  regenerate_report: 0,
                  locale: this.resources.getLanguage().description
                };
                const formData = new FormData();
                for (const key of Object.keys(body1)) {
                  const value = body1[key];
                  formData.append(key, value);
                }
                modal_result.ids.forEach(xy => {
                  formData.append('sample_ids[]', xy);
                });
                // tslint:disable-next-line:no-shadowed-variable
                modal_result.annexes.forEach(x => {
                  formData.append(`${x.name}[]`, x);
                });

                this.diagnosticOrdersService.getReportAtStatus(formData).subscribe(result => {
                  this.toast.showBottomCenterInfo(result.message);
                  if (result.url) {
                    window.open(environment.storageUrl + result.url, '_blank');
                    window.location.reload();
                  }
                  this.getOrderDetails(this.orderId).then(() => {
                  });
                }, error => {
                  this.functions.showError(error);
                });
              }
            });
          });
          this.subscriptions.add(subscription);
        }
      });
    }
  }

  sendReportByMail(value) {
    const ref = this.dialogService.open(StepThreeModalDiagnosticComponent, {
      showHeader: false,
      width: '80%',
      data: {order: this.order, reportByMail: true}
    });
    ref.onClose.subscribe(res => {
      if (res) {
        res.after_work_version = value;
        const subscription = this.diagnosticOrdersService.sendReport(res).subscribe(result => {
          this.toast.showBottomCenterSuccess(result.message);
        }, error => {
          this.functions.showError(error);
        });
        this.subscriptions.add(subscription);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  regenerateReport(type) {
    this.checkReportValue().then(res => {
      if (!res) {
        if (type === 'basic') {
          const ref = this.dialogService.open(BasicReportInfoComponent, {
            showHeader: false,
            width: '80%',
            data: {regenerate: 1, samples: this.order.samples.prolabo}
          });

          ref.onClose.subscribe(form => {
            if (form) {
              const formData = new FormData();
              for (const key of Object.keys(form)) {
                const value = form[key];
                formData.append(key, value);
              }
              // @ts-ignore
              formData.append('order_id', this.order.id);
              formData.append('locale', this.resources.getLanguage().description);
              const subscription = this.diagnosticOrdersService.getReportStatus(formData).subscribe(result => {
                this.toast.showBottomCenterInfo(result.message);
                if (result.url) {
                  window.open(environment.storageUrl + result.url, '_blank');
                  window.location.reload();
                }
                this.getOrderDetails(this.order.id).then(() => {
                });
              }, error => {
                this.functions.showError(error);
              });
              this.subscriptions.add(subscription);
            }
          });
        } else if (type === 'after-work') {
          const headers = new Array(0);
          headers.push(...this.samplesHeader);
          headers.push({title: 'RESULT', sortLabel: AdminStatics.type});
          const body2 = {
            order_id: this.order.id,
            locale: this.resources.getLanguage().description
          };
          const subscription = this.diagnosticOrdersService.getCorruptedSamples(body2).subscribe(x => {
            const ref = this.dialogService.open(CorruptedSamplesComponent, {
              showHeader: false,
              width: '80%',
              data: {samples: x.samples, headers}
            });

            // tslint:disable-next-line:variable-name
            ref.onClose.subscribe(modal_result => {
              if (modal_result.ids && modal_result.form) {
                const body = {
                  order_id: this.order.id,
                  inspection_date: modal_result.form.inspection_date,
                  asbestos_removal_company: modal_result.form.asbestos_removal_company,
                  regenerate_report: 1,
                  locale: this.resources.getLanguage().description
                };
                const formData = new FormData();
                for (const key of Object.keys(body)) {
                  const value = body[key];
                  formData.append(key, value);
                }
                modal_result.ids.forEach(xy => {
                  formData.append('sample_ids[]', xy);
                });
                // tslint:disable-next-line:no-shadowed-variable
                modal_result.annexes.forEach(x => {
                  formData.append(`${x.name}[]`, x);
                });
                this.diagnosticOrdersService.getReportAtStatus(formData).subscribe(result => {
                  this.toast.showBottomCenterInfo(result.message);
                  if (result.url) {
                    window.open(environment.storageUrl + result.url, '_blank');
                    window.location.reload();
                  }
                  this.getOrderDetails(this.orderId).then();
                }, error => {
                  this.functions.showError(error);
                });
              }
            });
          });
          this.subscriptions.add(subscription);
        }
      }
    });
  }

  handleLangError($event: boolean) {
    if ($event) {
      this.error = true;
      this.toast.showBottomCenterError(this.translate.instant('FORM.LANG'));
      this.initiatedSubmit = false;
    } else {
      this.error = false;
    }
  }

  updateDeadlineValue($event: string, sampleTypeId: any) {
    const body = {
      order_id: this.order.id,
      deadline_id: $event,
      locale: this.resources.getLanguage().description,
      sample_type_id: +sampleTypeId
    };
    this.ordersService.modifyDeadline(body).subscribe(result => {
      this.getOrderDetails(this.order.id).then(() => {
      });
      this.toast.showBottomCenterInfo(result.message);
    }, error => {
      this.functions.showError(error);
    });
  }

  public getDeadline(deadlineId, sampleTypeId): DropdownModel {
    return this.deadlines[sampleTypeId].find(x => x.id === deadlineId);
  }

  findDeadline(sampleTypeId) {
    if (this.order.deadlines) {
      return this.order.deadlines.find(x => x.sample_type_id === +sampleTypeId);
    } else {
      return null;
    }
  }

  generateInvoice() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    this.bexio.generateInvoice(body).subscribe(result => {
      if (result.open_modal) {
        const ref = this.dialogService.open(RegenerateInvoiceComponent, {
          showHeader: false,
          width: '90%',
          data: result.message
        });

        ref.onClose.subscribe(res => {
          if (res) {
            this.regenerateInvoice();
          }
        });
      } else {
        this.toast.showBottomCenterInfo(result.message);
        this.getOrderDetails(this.order.id).then(() => {});
      }
    }, error => this.functions.showError(error));
  }


  regenerateInvoice() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    this.bexio.reGenerateInvoice(body).subscribe(result => {
      this.toast.showBottomCenterInfo(result.message);
      this.getOrderDetails(this.order.id).then(() => {});
    }, error => this.functions.showError(error));
  }

  printInvoice() {
    this.functions.showPdf(this.order.invoices.invoice.content, this.order.order_number);
  }
}
