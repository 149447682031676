<div class="step-container" (click)="onStepClick(step)">
  <div class="step">
    <span>
      <p>{{ 'STEP' | translate }}</p>
      <div class="number">
        <span>{{ step }}</span>
      </div>
    </span>
    <div class="border"></div>
  </div>
  <div class="status">
    <img src="../../../../../../assets/images/{{image}}.svg" />
    <p class="name">{{ title | translate }}</p>
    <div class="number">
      <span>{{ total }}</span>
    </div>
  </div>
</div>
