import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Media } from '../../../../../../admin/models/plan-response';
import { DiagnosticOrder } from '../../../../../../admin/models/diagnostic-order-response';
import { environment } from '../../../../../../../environments/environment';
import { DiagnosticPlanModalComponent } from '../../../../../../admin/pages/orders/modals/diagnostic-plan-modal/diagnostic-plan-modal.component';

@Component({
  selector: 'app-prolabo-diagnostic-plan-item',
  templateUrl: './diagnostic-plan-item.component.html',
  styleUrls: ['./diagnostic-plan-item.component.scss']
})
export class DiagnosticPlanItemComponent implements OnChanges{
  @Input() new = false;
  @Input() plan: Media;
  @Input() order: DiagnosticOrder;

  imageSrc: string;

  constructor(private dialogService: DialogService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.plan) {
      this.imageSrc = `${environment.storageUrl}${this.plan?.path}`;
    }
  }

  update() {
    this.dialogService.open(DiagnosticPlanModalComponent, {
      showHeader: false,
      width: '70%',
      data: { plan: this.plan, update: true }
    });
  }
}
