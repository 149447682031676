import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { NextSample, OrderOwners, SampleCounts, SimpleOrderResponse } from 'src/app/admin/models/simple-order-response';
import { DeadlineResponse } from 'src/app/models/deadline';
import { ResourcesService } from '../../shared/services/resources.service';
import { AdminRoutesStatics } from '../statics/admin-routes-statics';
import { CollectMethodsResponse } from '../models/plan-response';
import { Type } from '../models/type';
import { AdditionalInfoOptionsResponse } from '../models/diagnostic-order-response';
import { LaboratoryResponse } from '../../models/laboratorie';

@Injectable({
  providedIn: 'root'
})
export class AdminOrdersService {

  constructor(private http: HttpClient, private resources: ResourcesService) {
  }

  public getOrderSampleCounts(body): Observable<SampleCounts> {
    return this.http.post<SampleCounts>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderSamplesCount}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getAdditionalInfoOptions(): Observable<AdditionalInfoOptionsResponse> {
    return this.http.post<AdditionalInfoOptionsResponse>
    (`${environment.adminApiUrl}${AdminRoutesStatics.getAdditionalInfoOptions}`,
      {locale: this.resources.getLanguage().description}, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getOrders(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrders}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public exportOrders(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.exportOrders}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public deleteOrder(body): Observable<any> {
    return this.http.delete<any>(`${environment.adminApiUrl}${AdminRoutesStatics.deleteOrder}`,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
        params: body
      });
  }

  public getOrderSubtype(): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderSubtypes}`,
      {locale: this.resources.getLanguage().description},
      {
        headers: this.getHeaders(this.resources.getStorageUser().token)
      });
  }

  public getDeadlines(body): Observable<DeadlineResponse> {
    return this.http.post<DeadlineResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderDeadlines}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public modifyDeadline(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.modifyDeadline}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getCollectMethods(): Observable<CollectMethodsResponse> {
    return this.http.post<CollectMethodsResponse>(`${environment.apiUrl}${AdminRoutesStatics.getCollectMethods}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getAllSampleTypes(): Observable<Type[]> {
    return this.http.post<Type[]>(`${environment.adminApiUrl}${AdminRoutesStatics.getAllSampleTypes}`,
      {locale: this.resources.getLanguage().description}, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getNextSampleId(body): Observable<NextSample> {
    return this.http.post<NextSample>(`${environment.adminApiUrl}${AdminRoutesStatics.getNextSampleId}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getSampleTypes(body): Observable<Type[]> {
    return this.http.post<Type[]>(`${environment.adminApiUrl}${AdminRoutesStatics.getSampleTypes}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public analyzeField(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.analyzeField}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public updateAnalyzedField(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.updateAnalyzedField}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getOrder(body): Observable<SimpleOrderResponse> {
    return this.http.post<SimpleOrderResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrder}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public checkAll(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.analyzeMultipleCheckboxes}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getUsers(body): Observable<OrderOwners> {
    return this.http.post<OrderOwners>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderUsers}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public deleteScoreCardRow(body): Observable<OrderOwners> {
    return this.http.post<OrderOwners>(`${environment.adminApiUrl}${AdminRoutesStatics.deleteScoreCardRow}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getLaboratories(): Observable<LaboratoryResponse> {
    return this.http.post<LaboratoryResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getLaboratories}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `
    Bearer ${token}`
    });
  }

  private getMultiPartHeader(token): HttpHeaders {
    return new HttpHeaders({
      Authorization: `
    Bearer ${token}`
    });
  }
}
