import { Component, OnInit } from '@angular/core';
import { Laboratory } from '../../../../models/laboratorie';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-laboratories-modal',
  templateUrl: './laboratories-modal.component.html',
  styleUrls: ['./laboratories-modal.component.scss']
})
export class LaboratoriesModalComponent implements OnInit {
  laboratories: Laboratory[];

  constructor(private config: DynamicDialogConfig, public ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.laboratories = this.config.data;
  }

}
