import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-prolabo-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true
  }]
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input() keyword: string;
  @Input() type: string;
  @Input() id: string;
  @Input() for: string;
  @Input() inputType: string;
  @Input() defaultValue = '';
  @Input() required = true;
  @Input() class: string;
  @Input() placeholder: string;
  @Input() accept: string;
  @Input() disabled: boolean;


  @Output() OnValueChange: EventEmitter<any> = new EventEmitter();

  public value: string;

  public inputControl: FormControl;
  public nonRequired: FormControl;
  public emailControl: FormControl;
  public passwordControl: FormControl;
  public pinControll: FormControl;
  public numberControl: FormControl;
  public notZero: FormControl;
  public notZero2: FormControl;

  constructor() {
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  itemChange($event) {
    this.onChange($event.currentTarget.value);
    this.OnValueChange.emit($event.currentTarget.value);
  }

  ngOnInit(): void {
    this.inputControl = new FormControl({value: this.defaultValue, disabled: this.disabled}, [Validators.required]);
    this.nonRequired = new FormControl({value: this.defaultValue, disabled: this.disabled});
    this.emailControl = new FormControl({
      value: this.defaultValue,
      disabled: this.disabled
    }, [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]);
    this.passwordControl = new FormControl({
      value: this.defaultValue,
      disabled: this.disabled
    }, [Validators.required, Validators.minLength(8)]);
    this.pinControll = new FormControl({
      value: this.defaultValue,
      disabled: this.disabled
    }, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]);
    this.numberControl = new FormControl({
      value: this.defaultValue,
      disabled: this.disabled
    }, [Validators.required, Validators.pattern('^[0-9]{1,6}$')]);
    this.notZero = new FormControl({
      value: this.defaultValue,
      disabled: this.disabled
    }, [Validators.required, Validators.pattern('^\\s*(?=[1-9].*[0-9])\\d*(?:\\.\\d{1,5})?\\s*$')]);
    this.notZero2 = new FormControl({
      value: this.defaultValue,
      disabled: this.disabled
    }, [Validators.required, Validators.pattern('^\\s*(?=[0-9][0-9]*)\\d*(?:\\.\\d{1,5})?\\s*$')]);
  }

  focusInput() {
    document.getElementById(this.id).autofocus = true;

    setTimeout(() => {
      document.getElementById(this.id).focus();
    }, 0);
  }
}
