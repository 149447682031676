<div class="p-grid" *ngIf="order != null">
  <div class="p-col-12 p-xl-8">
    <div class="main-title-container {{ stepDone ? 'secondary-container' : null }}">
      <div class="p-grid">
        <div class="p-col-12 p-md-6 title-block">
          <img src="../../../../../../../assets/icons/board-white.svg">
          <span class="order-group">
            <p class="order-language">{{'DIAGNOSTIC.ORDER' | translate}} - {{order?.user?.company}}</p>
            <p class="order-date {{ stepDone ? 'white-order-date' : null }}">
              {{order?.order_number}} {{ 'CREATED_ON' | translate }} {{order?.created_at | date }}</p>
          </span>
        </div>
        <div class="p-col-12 p-md-6 buttons-block">
          <div class="p-grid">
            <div class="p-col-6">
              <ng-container *ngIf="!step3">
                <app-prolabo-icon-button [icon]="'close-white'"
                                         [name]="'CANCEL'"
                                         type="{{ stepDone ? 'secondary' : 'primary-radius' }}"
                                         class="{{ stepDone ? 'white-border' : null }}"
                                         (click)="openConfirmDialog2()">
                </app-prolabo-icon-button>
              </ng-container>
            </div>
            <div class="p-col-6" *ngIf="step1">
              <app-prolabo-icon-button icon="upload-white"
                                       type="secondary"
                                       [disabled]="!samplesAdded || this.plans.length < 1"
                                       name="SUBMIT"
                                       (OnClick)="submitOrder()">
              </app-prolabo-icon-button>
            </div>
            <div class="p-col-6" *ngIf="!step1 && !stepDone">
              <app-prolabo-button type="secondary"
                                  name="LABORATORY_ADDRESS"
                                  (OnClick)="viewAddress()">
              </app-prolabo-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="final-step-container" *ngIf="stepDone">
      <div class="p-col-12 p-md-4">
        <app-prolabo-order-final-step-container [title]="'REPORT'"
                                                [textReport]="'REPORT_SENT'"
                                                [firstButtonIcon]="'printer'"
                                                [firstButtonTitle]="order?.pdf_report_path === null ?  'EXPORT_REPORT': 'PRINT'"
                                                [reportsDate]="generatedReportDate"
                                                [disableFirstButton]="disablePrint"
                                                [user]="true"
                                                [disableSecondButton]="!order?.pdf_report_path"
                                                (firstButton)="printReportClicked()">
        </app-prolabo-order-final-step-container>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-order-final-step-container [title]="'REPORT_AFTER_WORK'"
                                                [textReport]="'REPORT_SENT'"
                                                [firstButtonIcon]="'printer'"
                                                [user]="true"
                                                [firstButtonTitle]="order?.pdf_at_report_path === null ? 'GENERATE_REPORT_AFTER_WORK': 'PRINT'"
                                                [reportsDate]="generatedReportAtDate"
                                                [disableFirstButton]="disablePrint"
                                                [disableSecondButton]="!order?.pdf_at_report_path"
                                                (firstButton)="printAtReportClicked()">
        </app-prolabo-order-final-step-container>
      </div>
      <div class="p-col-12 p-md-4">
        <app-prolabo-order-final-step-container [title]="'BILLING'"
                                                [user]="true"
                                                [textInvoices]="'INVOICES_GENERATED'"
                                                [invoicesNumber]="numGeneratedInvoices"
                                                [invoicesDate]="generatedInvoicesDate"
                                                [firstButtonTitle]="order.invoice_generated === 1 ? order.invoices.invoice?.name : 'PRINT'"
                                                [firstButtonIcon]="'printer'"
                                                (firstButton)="printInvoice()"
                                                [disableFirstButton]="order.invoice_generated === 0">
        </app-prolabo-order-final-step-container>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 plans">
        <app-prolabo-diagnostic-plan-item *ngIf="step1"
                                          [new]="true"
                                          [order]="order"
                                          (addPlan)="planCreate($event)">
        </app-prolabo-diagnostic-plan-item>
        <ng-container *ngFor="let plan of plans">
          <app-prolabo-diagnostic-plan-item [new]="false"
                                            [plan]="plan"
                                            [step1]="step1"
                                            [step2]="step2"
                                            [stepDone]="stepDone"
                                            (deletePlan)="openConfirmationDialog($event)">
          </app-prolabo-diagnostic-plan-item>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="samplesByType && samplesByType.length > 0">
      <ng-container *ngFor="let samples of samplesByType">
        <p-card>
          <div class="p-grid spacing-bottom">
            <div class="p-col-12 p-md-8 title">
              <p-header>
                {{ 'SAMPLING' | translate }}
                {{ functions.getTypeName(samples[0]) | translate }} ({{ samples[1]?.samples.length }})
              </p-header>
              <ng-container *ngIf="deadlines.hasOwnProperty(samples[0])" class="deadline-title">
                <div class="p-grid deadline-container" *ngIf="!stepDone">
                  <div class="p-col-12">
                    <app-prolabo-dropdown [options]="deadlines[samples[0]]"
                                          class="deadline-dropdown"
                                          (OnValueChange)="updateDeadlineValue($event, samples[0])"
                                          [defaultValue]="findDeadline(samples[0]) ? findDeadline(samples[0]).deadline_id : null"
                                          placeholder="DEADLINE"></app-prolabo-dropdown>
                  </div>
                </div>
                <p *ngIf="stepDone" class="deadline-title">
                  {{getDeadline(order.deadlines ? order.deadlines[0].deadline_id : null, samples[0]).text | translate}}
                </p>
              </ng-container>
            </div>
            <div class="p-col-12 p-md-4">
              <ng-container *ngIf="step1 || step2">
                <app-prolabo-icon-button
                  icon="add"
                  type="primary"
                  name="ADD.NEW.SAMPLE"
                  [disabled]="!plans || plans.length == 0"
                  (OnClick)="createSample(samples[0])">
                </app-prolabo-icon-button>
              </ng-container>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <ng-container *ngIf="order">
                <app-prolabo-pagination-list-diagnostic-samples
                  [items]="samples[1].samples"
                  [step1]="step1"
                  [step2]="step2"
                  [stepDone]="stepDone"
                  [order]="order"
                  [headers]="stepDone ? samplesHeader : samplesHeaderNoEmpty"
                  [userSide]="true"
                  [user]="true"
                  [sampleAnalyzed]="samples[1].analyzed"
                  (riskModal)="riskAssessmentForSample($event)"
                  (itemToEdit)="editSample($event)"
                  (itemDelete)="handleDelete($event)">
                </app-prolabo-pagination-list-diagnostic-samples>
              </ng-container>
            </div>
          </div>
        </p-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="samplesByType && samplesByType.length == 0">
      <p-card>
        <div class="p-grid spacing-bottom">
          <div class="p-col-12 p-md-8 title">
            <p-header>
              {{ 'SAMPLING' | translate }}
            </p-header>
          </div>
          <div class="p-col-12 p-md-4">
            <app-prolabo-icon-button
              icon="add"
              type="secondary"
              name="ADD.NEW.SAMPLE"
              [disabled]="!plans || plans.length == 0"
              (OnClick)="createSample(null)">
            </app-prolabo-icon-button>
          </div>
        </div>
      </p-card>
    </ng-container>
  </div>


  <div class="p-col-12 p-xl-4" *ngIf="order">
    <app-prolabo-diagnostic-order-administration [submitClicked]="initiatedSubmit"
                                                 [user]="true"
                                                 [additionalInformationOptions]="additionalInfoOptions"
                                                 (visitInfoUpdate)="updateVisitInfo($event)"
                                                 [order]="order"
                                                 [isUser]="true"
                                                 [invoiceTypes]="invoiceTypes"
                                                 [laboratories]="laboratories"
                                                 [visitInformationError]="visitInformationError"
                                                 (clientEvent)="handleClientEvent($event)"
                                                 (orderEvent)="handleOrderError($event)"
                                                 (requestGetOrder)="getOrderDetails(order.id)"
                                                 (orderFormEvent)="handleOrderFormEvent($event)">
    </app-prolabo-diagnostic-order-administration>
    <app-prolabo-diagnostic-order-report [submitClicked]="initiatedSubmit"
                                         (emailEvents)="handleEmailEvent($event)"
                                         (email)="handleEmailError($event)"
                                         (languageEvent)="handleLanguageEvent($event)"
                                         [getOrderRequested]="getOrderInitiated"
                                         [order]="order"
                                         (lang)="handleLangError($event)">
    </app-prolabo-diagnostic-order-report>
  </div>
</div>
<app-prolabo-confirmation-modal
  [display]="displayModal"
  [header]="'MODAL_CONFIRMATION'"
  (confirmClicked)="deleteSelectedPlan()"
  (cancelClicked)="displayModal = false;">
</app-prolabo-confirmation-modal>

<app-prolabo-confirmation-modal
  [display]="displayModal2"
  [header]="'MODAL_CONFIRMATION_CANCEL'"
  (confirmClicked)="cancelOrder()"
  (cancelClicked)="closeConfirmDialog2()">
</app-prolabo-confirmation-modal>
