<div class="orders-container" *ngIf="currentUser">
  <p-card class="orders-card">
    <div class="p-grid">
      <div class="p-col-12 p-sm-3">
        <app-prolabo-icon-button
          [name]="'NEW_ORDER'"
          type="primary"
          (click)="currentUser.role_id === 4 ? createOrderTemp() : createOrder()"
          [icon]="'add'"></app-prolabo-icon-button>
      </div>
      <div class="p-col-12 p-sm-9">
        <app-prolabo-icon-input
          [keyword]="'RESEARCH'"
          [icon]="'search'"
          (OnValueChange)="onSearchParamChange($event)"></app-prolabo-icon-input>
      </div>
<!--      <div class="p-col-12 p-sm-3">-->
<!--        <app-prolabo-icon-button-->
<!--          [name]="'EXPORT'"-->
<!--          type="primary"-->
<!--          icon="printer"-->
<!--          (click)="exportOrders()"></app-prolabo-icon-button>-->
<!--      </div>-->
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-orders-pagination-list
          [items]="orders"
          [headers]="orderHeaders"
          [total]="totalOrders"
          [itemValues]="samples"
          [totalItems]="totalSamples"
          [filter]="stepParam"
          [filterItems]="sampleTypes"
          [laboratories]="laboratories"
          (filterBy)="filterBySample($event)"
          (filterDelete)="deleteParam()"
          (filterByDate)="filterByDate($event)"
          (itemDelete)="deleteOrder($event)"
          (limitValue)="changeLimit($event)"
          (pageValue)="changeOffset($event)"
          [selectedLaboratory]="selectedLaboratory"
          (filterByOrderState)="handleFilterByOrderState($event)"
          (filterByOrderCity)="handleFilterByOrderCity($event)"
          (sortValue)="sortByLabel($event)">
        </app-prolabo-orders-pagination-list>
      </div>
    </div>
  </p-card>
</div>
