import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BexioPdf, BexioResponse, GetBexioResponse, PdfPreview } from '../models/bexio-pdf';
import { environment } from '../../../environments/environment';
import { BexioStatics } from '../statics/bexio-statics';
import { ResourcesService } from '../../shared/services/resources.service';

@Injectable({
  providedIn: 'root'
})
export class BexioService {

  constructor(private http: HttpClient, private resources: ResourcesService) {
  }


  public getPdf(body): Observable<PdfPreview> {
    return this.http.post<PdfPreview>(`${environment.adminApiUrl}${BexioStatics.previewInvoice}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public generateInvoice(body): Observable<BexioResponse> {
    return this.http.post<BexioResponse>(`${environment.adminApiUrl}${BexioStatics.generateInvoice}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public reGenerateInvoice(body): Observable<BexioResponse> {
    return this.http.post<BexioResponse>(`${environment.adminApiUrl}${BexioStatics.reGenerateInvoice}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getInvoices(body): Observable<GetBexioResponse> {
    return this.http.post<GetBexioResponse>(`${environment.adminApiUrl}${BexioStatics.getInvoices}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public deleteInvoice(body): Observable<BexioResponse> {
    return this.http.post<BexioResponse>(`${environment.adminApiUrl}${BexioStatics.deleteInvoice}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }
}
