import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService, UserService} from '../../../../../shared/services';
import {AdminClientsService} from '../../../../services/admin-clients.service';
import {AdminSimpleOrdersService} from '../../../../services/admin-simple-orders.service';
import {ResourcesService} from '../../../../../shared/services/resources.service';
import {TranslateService} from '@ngx-translate/core';
import {ResponsibleService} from '../../../../../common/services/responsible.service';
import {Order} from '../../../../models/order';

@Component({
  selector: 'app-prolabo-step-three-diagnostic',
  templateUrl: './step-three-diagnostic-modal.component.html',
  styleUrls: ['./step-three-diagnostic-modal.component.scss']
})
export class StepThreeModalDiagnosticComponent implements OnInit {
  step3Form: FormGroup;
  reportByMail: boolean;

  order: Order;
  subject: string;
  description: string;

  get cc(): FormArray {
    return this.step3Form.get('cc') as FormArray;
  }

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private responsibleService: ResponsibleService,
              private clientsService: AdminClientsService,
              private ordersService: AdminSimpleOrdersService,
              private toast: ToastService,
              private translate: TranslateService,
              private resources: ResourcesService,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.reportByMail = this.config.data.reportByMail;
    this.order = this.config.data.order;
    this.translate.getTranslation(this.order.language.description).subscribe(x => {
      this.subject = x['SUBJECT.EMAIL'].replace('{{siteReference}}', this.order.site);
      this.description = x['DESCRIPTION.EMAIL'].replace('{{siteReference}}', this.order.site);
      this.step3Form = this.formBuilder.group({
        order_id: [this.config.data.order.id, Validators.required],
        email: [this.order.email ? this.order.email[0].email : '', Validators.required],
        cc: this.formBuilder.array([this.buildCc(this.order.email[1]?.email ?? '')]),
        subject: [this.subject, Validators.required],
        content: [this.description],
        locale: [this.resources.getLanguage().description, Validators.required]
      });

      if (this.order.email && this.order.email.length > 0) {
        this.order.email.forEach(email => {
          if (email.email !== this.order.email[0]?.email && email.email !== this.order.email[1]?.email) {
            this.addCcValue(email.email);
          }
        });
      }

    });


  }

  submit(sendEmail: boolean) {
    if (sendEmail) {
      const body: any = {};
      if (this.cc.value.length > 0) {
        const cc = new Array(0);
        this.cc.value.forEach(email => {
          if (email.cc !== '') {
            cc.push(email.cc);
          } else {
            this.step3Form.removeControl('cc');
          }
        });
        body.cc = cc;
      } else {
        this.step3Form.removeControl('cc');
      }
      if (!this.step3Form.controls.content.value) {
        this.step3Form.removeControl('content');
      } else {
        body.content = this.step3Form.controls.content.value;
      }
      if (!this.step3Form.valid) {
        this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
      } else {
        body.email = this.step3Form.controls.email.value;
        body.subject = this.step3Form.controls.subject.value;
        body.locale = this.resources.getLanguage().description;
        body.order_id = this.config.data.order.id;
        body.send_email = true;
        this.ref.close(body);
      }
    } else if (!sendEmail) {
      const mail = {
        send_email: false,
        order_id: this.config.data.order.id,
        locale: this.resources.getLanguage().description
      };
      this.ref.close(mail);
    }
  }

  buildCc(email): FormGroup {
    return this.formBuilder.group({
      cc: email
    });
  }

  buildCcValue(value: string): FormGroup {
    return this.formBuilder.group({
      cc: value
    });
  }

  addCc(): void {
    this.cc.push(this.buildCc(''));
  }

  addCcValue(value): void {
    this.cc.push(this.buildCcValue(value));
  }

  close() {
    this.ref.close();
  }

  removeCc(id: any) {
    this.cc.removeAt(id);
  }
}
