<div class="clients-container" style="height: 250px">
  <app-dialog-header [title]="'FREE_OPTION_MODAL'" (iconClick)="ref.close()"></app-dialog-header>
  <form [formGroup]="form">
    <app-prolabo-input [for]="'free_option'"
                       [id]="'free_option'"
                       [keyword]="'FREE_OPTION'"
                       [type]="'text'"
                       [inputType]="'basic'"
                       formControlName="input"></app-prolabo-input>
  </form>

  <div class="p-grid client-dialog-footer">
    <div class="p-col-12 p-md-8">
    </div>
    <div class="p-col-12 p-md-4">
      <app-prolabo-icon-button (click)="submit()"
                               [icon]="'check'"
                               [name]="'VALIDATE'"
                               [disabled]="!form.valid"
                               type="secondary">
      </app-prolabo-icon-button>
    </div>
  </div>
</div>

