import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-prolabo-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageDropdownComponent),
      multi: true
    }
  ]
})
export class LanguageDropdownComponent implements OnInit, OnChanges {

  @Input() options: DropdownModel[];
  @Input() control: string;
  @Input() type: string;
  @Input() disabled = false;
  @Input() selectedOption: DropdownModel;
  @Input() placeholder: string;
  @Input() defaultValue: any;

  @Output() OnValueChange = new EventEmitter<string>();
  @Output() OnDropdownClick = new EventEmitter<MouseEvent>();

  selectedOptionFormControl: FormControl;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOption) {
      this.selectedOptionFormControl.setValue(this.selectedOption);
    }
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.selectedOptionFormControl.disable();
      } else {
        this.selectedOptionFormControl.enable();
      }
    }
  }

  onClick(event: MouseEvent) {
    this.OnDropdownClick.emit(event);
  }

  onChange(event) {
    this.OnValueChange.emit(event.value);
  }

  ngOnInit(): void {
    this.selectedOptionFormControl = new FormControl({value: this.defaultValue, disabled: this.disabled});
  }
}
