import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '../../../../../shared/services';
import { DropdownModel } from '../../../../../shared/components/dropdown/dropdown.model';
import { DiagnosticSample } from '../../../../models/diagnostic-order-response';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { environment } from '../../../../../../environments/environment';
import { Media } from '../../../../models/plan-response';

@Component({
  selector: 'app-prolabo-diagnostic-evaluation',
  templateUrl: './diagnostic-evaluation-modal.component.html',
  styleUrls: ['./diagnostic-evaluation-modal.component.scss']
})
export class DiagnosticEvaluationModalComponent implements OnInit {
  evaluationForm: FormGroup;

  sample: DiagnosticSample;
  additionalInfoOptions: DropdownModel[];
  photo: string;
  sampleMedia: Media;
  sampleNumber = '+';

  surfaceState: DropdownModel[];
  placeUtilisation: DropdownModel[];
  asbestosQuantity: DropdownModel[];
  externalInfluence: DropdownModel[];
  sanitation: DropdownModel[];
  showOtherField = false;
  vdiMeasures: DropdownModel[] = [
    {id: 2, text: 'NO', value: 2},
    {id: 1, text: 'YES', value: 1}
  ];

  risk1Dropdown: DropdownModel[] = [
    {id: 0, text: 'Choisissez dans la liste déroulante', value: 0},
    {id: 1, text: 'Lancer immédiatement les travaux d’assainissement', value: 1},
    {id: 2, text: 'Prendre évent. des mesures temporaires ou d’urgence', value: 2},
    {id: 3, text: 'Effectuer évent. des mesures de qualité de l’air', value: 3},
    {id: 4, text: this.translate.instant('OTHER'), value: 4},
  ];

  risk2Dropdown: DropdownModel[] = [
    {id: 0, text: 'Choisissez dans la liste déroulante', value: 0},
    {id: 1, text: 'Effectuer l’assainissement au plus tard avant le lancement d’autres travaux', value: 1},
    {
      id: 2,
      text: 'Procéder à une réévaluation en cas d’incidents, de modification de l’utilisation des locaux ou au plus tard après 2 à 5 ans',
      value: 2
    },
    {id: 3, text: 'Effectuer évent. des mesures de qualité de l’air', value: 3},
    {id: 4, text: this.translate.instant('OTHER'), value: 4},
  ];

  risk3Dropdown: DropdownModel[] = [
    {id: 0, text: 'Choisissez dans la liste déroulante', value: 0},
    {id: 1, text: ' Effectuer l’assainissement avant de lancer d’autres travaux', value: 1},
    {
      id: 2,
      text: 'Procéder à une réévaluation en cas d’incidents ou de modification de l’utilisation des locaux',
      value: 2
    },
    {id: 4, text: this.translate.instant('OTHER'), value: 4},
  ];

  riskValue = 'I';
  showImg = false;

  constructor(private translate: TranslateService,
              private cdr: ChangeDetectorRef,
              public toast: ToastService,
              private resources: ResourcesService,
              private config: DynamicDialogConfig,
              public ref: DynamicDialogRef,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.additionalInfoOptions = this.config.data.additionalInfo;
    this.sample = this.config.data.sample;
    this.placeUtilisation = this.config.data.placeUtilisation;
    this.asbestosQuantity = this.config.data.asbestosQuantity;
    this.externalInfluence = this.config.data.externalInfluence;
    this.surfaceState = this.config.data.surfaceState;
    this.sanitation = this.config.data.sanitation;

    if (this.sample && this.sample.media) {
      this.photo = environment.storageUrl + this.sample.media.path;
      this.sampleMedia = this.sample.media;
    }

    this.evaluationForm = this.formBuilder.group({
      id: [this.sample?.id, Validators.required],
      surface_state_id: [this.sample?.report_value && this.sample?.report_value?.surface_state_id ?
        this.sample.report_value.surface_state_id : this.surfaceState[0].id, Validators.required],
      asbestos_quantity_id: [this.sample?.report_value && this.sample?.report_value?.asbestos_quantity_id ?
        this.sample.report_value.asbestos_quantity_id : this.asbestosQuantity[0].id, Validators.required],
      // tslint:disable-next-line:max-line-length
      place_utilisation_id: [this.sample?.report_value && this.sample?.report_value?.place_utilisation_id ? this.sample.report_value.place_utilisation_id : this.placeUtilisation[0].id, Validators.required],
      // tslint:disable-next-line:max-line-length
      external_influence_id: [this.sample?.report_value && this.sample?.report_value?.external_influence_id ? this.sample.report_value.external_influence_id : this.externalInfluence[0].id, Validators.required],
      vdi_measure_before_works: [true, Validators.required],
      // tslint:disable-next-line:max-line-length
      immediate_measurements: [this.sample?.report_value && this.sample?.report_value?.immediate_measurements ? this.sample.report_value.immediate_measurements : '', Validators.required],
      // tslint:disable-next-line:max-line-length
      sanitation_id: [this.sample?.report_value && this.sample?.report_value?.sanitation_id ? this.sample.report_value.sanitation_id : this.sanitation[0].id, Validators.required],
      // tslint:disable-next-line:max-line-length
      risk_evaluation: [this.sample?.report_value && this.sample?.report_value?.risk_evaluation ? this.sample.report_value.risk_evaluation : '', Validators.required],
      locale: [this.resources.getLanguage().description, Validators.required],
      // tslint:disable-next-line:max-line-length
      coordinate_x: [this.sample?.report_value && this.sample?.report_value?.coordinate_x ? this.sample.report_value.coordinate_x : '', Validators.required],
      // tslint:disable-next-line:max-line-length
      coordinate_y: [this.sample?.report_value && this.sample?.report_value?.coordinate_y ? this.sample.report_value.coordinate_y : '', Validators.required],
      sanitation_txt: [this.sample?.report_value && this.sample?.report_value?.sanitation_txt ? this.sample?.report_value?.sanitation_txt : 'Travaux à réaliser par une entreprise spécialisée']
    });

    this.getImmediateMeasuresDefaultValue();

    if (this.sample?.report_value && this.sample?.report_value?.coordinate_x
      && this.sample?.report_value?.coordinate_y && this.sample?.media?.path && this.photo) {
      setTimeout(() => {
        this.setCoordinates(this.sample?.report_value?.coordinate_x,
          this.sample?.report_value?.coordinate_y, this.sample?.media?.width, this.sample.media?.height);
      }, 300);
    }

    this.calculate();
  }

  getImmediateMeasuresDefaultValue() {
    let val = null;
    switch (this.riskValue) {
      case ('I'):
        val = this.risk1Dropdown[0].text;
        break;
      case ('II'):
        val = this.risk2Dropdown[0].text;
        break;
      case ('III'):
        val = this.risk3Dropdown[0].text;
        break;
    }

    this.evaluationForm.patchValue({
      immediate_measurements: val
    });

    return val;
  }

  updateAsbestosQuantity(id) {
    this.evaluationForm.patchValue({
      asbestos_quantity_id: id
    });
    this.calculate();
  }

  updateImmediateMeasurements(id: any, dropdown: DropdownModel[]) {
    this.evaluationForm.patchValue({
      immediate_measurements: dropdown.filter(value => value.id === id)[0].text
    });
    this.showOtherField = id === 4;
  }

  findIMValue(text: string, dropdown: DropdownModel[]) {
    const value = dropdown.filter(val => val.text === text);
    let id = null;
    if (value.length > 0) {
      id = dropdown.filter(val => val.text === text)[0].id;
    }
    return id;
  }

  updatePlaceUtilisation(id) {
    this.evaluationForm.patchValue({
      place_utilisation_id: id
    });
    this.calculate();
  }

  updateSanitation(id) {
    this.evaluationForm.patchValue({
      sanitation_id: id
    });
  }

  updateExternalInfluence(id) {
    this.evaluationForm.patchValue({
      external_influence_id: id
    });
    this.calculate();
  }

  updateSurfaceState(id) {
    this.evaluationForm.patchValue({
      surface_state_id: id
    });
    this.calculate();
  }

  updateVdiMeasures(id) {
    this.evaluationForm.patchValue({
      vdi_measure_before_works: id === 1 ? true : false
    });
  }

  calculate() {
    let result = 0;

    const asbestosQuantityValue = this.asbestosQuantity
      .filter(value => value.id === this.evaluationForm.value.asbestos_quantity_id)[0];
    const surfaceStateValue = this.surfaceState
      .filter(value => value.id === this.evaluationForm.value.surface_state_id)[0];
    const externalInfluenceValue = this.externalInfluence
      .filter(value => value.id === this.evaluationForm.value.external_influence_id)[0];
    const placeUtilisationValue = this.placeUtilisation
      .filter(value => value.id === this.evaluationForm.value.place_utilisation_id)[0];

    // @ts-ignore
    result += asbestosQuantityValue.additional.riskValue;
    // @ts-ignore
    result += surfaceStateValue.additional.riskValue;
    // @ts-ignore
    result += externalInfluenceValue.additional.riskValue;

    // if A
    if (result <= 1 && placeUtilisationValue.text === 'A') {
      this.riskValue = 'III';
    }
    if (result === 2 && placeUtilisationValue.text === 'A') {
      this.riskValue = 'II';
    }
    if (result === 3 && placeUtilisationValue.text === 'A') {
      this.riskValue = 'I';
    }
    if (result >= 4 && placeUtilisationValue.text === 'A') {
      this.riskValue = 'I';
    }

    // if B
    if (result <= 1 && placeUtilisationValue.text === 'B') {
      this.riskValue = 'III';
    }
    if (result === 2 && placeUtilisationValue.text === 'B') {
      this.riskValue = 'II';
    }
    if (result === 3 && placeUtilisationValue.text === 'B') {
      this.riskValue = 'II';
    }
    if (result >= 4 && placeUtilisationValue.text === 'B') {
      this.riskValue = 'I';
    }

    // if C
    if (result <= 1 && placeUtilisationValue.text === 'C') {
      this.riskValue = 'III';
    }
    if (result === 2 && placeUtilisationValue.text === 'C') {
      this.riskValue = 'III';
    }
    if (result === 3 && placeUtilisationValue.text === 'C') {
      this.riskValue = 'II';
    }
    if (result >= 4 && placeUtilisationValue.text === 'C') {
      this.riskValue = 'I';
    }

    this.evaluationForm.patchValue({
      risk_evaluation: this.riskValue
    });
  }

  save() {
    if (this.sample.sample_type_id !== 2) {
      this.evaluationForm.removeControl('surface_state_id');
      this.evaluationForm.removeControl('asbestos_quantity_id');
      this.evaluationForm.removeControl('place_utilisation_id');
      this.evaluationForm.removeControl('external_influence_id');
      this.evaluationForm.removeControl('vdi_measure_before_works');
      this.evaluationForm.removeControl('surface_state_id');
      this.evaluationForm.removeControl('risk_evaluation');
    } else {
      this.evaluationForm.patchValue({
        risk_evaluation: this.riskValue
      });
    }

    if (this.evaluationForm.valid) {
      this.ref.close(this.evaluationForm.value);
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  clickImage(event) {
    const img = document.getElementById('image');
    const imgSize = document.getElementById('image') as HTMLImageElement;

    // format coordinates for natural size image
    // tslint:disable-next-line:radix
    const naturalWidth = Number.parseInt(this.sampleMedia?.width);
    // tslint:disable-next-line:radix
    const naturalHeight = Number.parseInt(this.sampleMedia?.height);

    // format values for preview
    const x = event.offsetX ? (event.offsetX) : img.clientWidth - img.offsetLeft;
    const y = event.offsetY ? (event.offsetY) : img.clientHeight - img.offsetTop;

    const naturalClickPosX = (naturalWidth / imgSize.width) * x;
    const naturalClickPosY = (naturalHeight / imgSize.height) * y;

    this.evaluationForm.patchValue({
      coordinate_x: Math.round(naturalClickPosX),
      coordinate_y: Math.round(naturalClickPosY)
    });

    const circle = document.getElementById('circle-add');
    circle.style.display = 'block';
    circle.style.position = 'absolute';
    circle.style.left = x + 'px';
    circle.style.top = y + 'px';
  }

  private setCoordinates(x, y, width, height) {
    const photoDiv = document.getElementById('coordinates-img');
    const circle = this.createCircle(x, y, width, height);
    photoDiv.appendChild(circle);
  }

  private createCircle(coordX, coordY, width, height) {
    const imgSize = document.getElementById('image') as HTMLImageElement;
    const x = (imgSize.width / width) * coordX;
    const y = (imgSize.height / height) * coordY;

    const circle = document.createElement('div');
    circle.style.display = 'block';
    circle.style.position = 'absolute';
    circle.style.width = '40px';
    circle.style.height = '40px';
    circle.style.textAlign = 'center';
    circle.style.borderRadius = '50%';
    circle.style.backgroundColor = '#C3D800';
    circle.style.fontFamily = 'Nunito-SemiBold';
    circle.style.color = 'white';
    circle.style.left = x + 'px';
    circle.style.top = y + 'px';

    const span = document.createElement('span');
    span.style.marginTop = '9px';
    span.style.display = 'flex';
    span.style.justifyContent = 'center';

    circle.appendChild(span);
    span.innerText = '+';

    return circle;
  }
}
