<div class="container">
  <div class="p-grid">
    <div class="p-col-12 content-container">
      <div class="p-sm-fixed side-bar">
        <app-side-bar
          stockRoute="analyst"
          [sidebarItems]="sidebarItems"
          [title]="user?.first_name + ' ' + user?.last_name"></app-side-bar>
      </div>
      <div class="p-grid">
        <div class="p-col-12 p-sm-8">
          <div class="breadcrumbs"
               *ngIf="showBreadcrumbs">
            <app-breadcrumbs
              [first]="firstBreadcrumb"
              [second]="secondBreadcrumb"
              [third]="thirdBreadcrumb"
              [state]="orderState"
              [logout]="logout"
              stockRoute="analyst"
              (logoutClicked)="handleLogoutClicked($event)"></app-breadcrumbs>
          </div>
          <div class="title-container" *ngIf="!showBreadcrumbs && showTitle">
            <p-header class="title">{{ currentPageLoaded?.name | translate }}</p-header>
          </div>
        </div>
        <div class="p-col-12 p-sm-4">
          <div class="p-grid container-padding">
            <div class="p-col-6">
              <ng-container *ngIf="search">
                <app-prolabo-icon-input
                  [keyword]="'RESEARCH'"
                  [icon]="'search'"
                  (OnValueChange)="onSearchParamChange($event)"></app-prolabo-icon-input>
              </ng-container>
            </div>
            <div class="p-col-1">
            </div>
            <div class="p-col-3 languages">
              <app-prolabo-language-dropdown [options]="languages"
                                             (OnValueChange)="updateLanguageValue($event)"
                                             [defaultValue]="currentLang?.id"></app-prolabo-language-dropdown>
            </div>
            <div class="p-col-2 profile">
              <img routerLink="/analyst/profile" class="profile-image"
                   src="../../../../assets/icons/pharmacy.svg" alt="profile">
            </div>
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-12">
          <div class="content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

