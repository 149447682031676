import { Component, OnDestroy } from '@angular/core';
import { ToastService } from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'prolabo-frontend';

  displayPosition: boolean;
  subscription: any;
  position: string;

  constructor(private toast: ToastService) {
    this.subscription = this.toast.toastPresetEvent.subscribe(value => {
      if ( value ) {
        this.showPositionDialog();
      } else {
        this.close();
      }
    });
  }

  close() {
    this.displayPosition = false;
  }

  showPositionDialog() {
    this.position = 'bottom';
    this.displayPosition = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
