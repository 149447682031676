import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {User} from 'src/app/models';
import {I18nService, ToastService, UserService} from 'src/app/shared/services';
import {AdminUsersService} from 'src/app/admin/services/admin-users.service';
import {TranslateService} from '@ngx-translate/core';
import {FunctionsService} from '../../../services/functions.service';
import {AdminDashboardService} from '../../../services/admin-dashboard.service';
import {forkJoin} from 'rxjs';
import {InvoiceType, Laboratory} from '../../../../models/laboratorie';
import {DropdownModel} from '../../../../shared/components/dropdown/dropdown.model';
import {ResourcesService} from '../../../../shared/services/resources.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  currentUser: User;
  emptyUser: User = {
    active: 0,
    address: {
      city: '',
      address: '',
      postal_code: '',
      created_at: '',
      id: 0,
      updated_at: ''
    },
    address_id: 1,
    company: '',
    company_logo: '',
    created_at: '',
    created_by: '',
    deleted_at: '',
    deleted_by: '',
    email: '',
    invoice_email: '',
    email_verified_at: '',
    first_name: '',
    id: 0,
    initials: '',
    invoicing_key: '',
    invoice_type_id: 0,
    is_company: 1,
    laboratory_id: 1,
    laboratory: {
      id: 1,
      city: '',
      address: '',
      name: '',
      code: '',
      postal_code: ''
    },
    lang_id: 1,
    language: {
      description: '',
      id: 0,
      language: ''
    },
    last_name: '',
    mobile: '',
    notes: '',
    pin: '',
    phone: '',
    price: {
      created_at: '',
      hap: 0,
      id: 0,
      lead: 0,
      pcb: 0,
      updated_at: '',
      user_id: 0,
      vdi: 0,
      created_by: 0,
      deleted_at: '',
      deleted_by: 0,
      updated_by: 0,
      asbestos_express: 0,
      asbestos_long_duration: 0,
      asbestos_standard: 0,
      asbestos_urgent: 0,
      buffer_qualitative: 0,
      buffer_quantitative: 0,
    },
    prices: [],
    responsible: [],
    role: {
      id: 0,
      role: ''
    },
    role_id: 3,
    title: {
      id: 0,
      name: ''
    },
    title_id: 1,
    updated_at: '',
    updated_bt: '',
    password: '',
    password_confirmation: '',
  };
  user: User;
  isUser = true;
  loaded: boolean;
  state: string;
  validateClicked: boolean;
  userOriginal: any;
  userUpdated: FormGroup;
  administrationOriginal: any;
  administrationUpdated: FormGroup;
  styleForm: any;
  roleForm: FormGroup;
  laboratories: DropdownModel[] = [];
  invoiceTypes: DropdownModel[] = [];

  constructor(private route: ActivatedRoute,
              private adminService: AdminUsersService,
              private formBuilder: FormBuilder,
              private toast: ToastService,
              private translate: TranslateService,
              private functions: FunctionsService,
              private dashboardService: AdminDashboardService,
              private userService: UserService,
              private resources: ResourcesService,
              private lang: I18nService) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.setInvoiceTypes(this.resources.getInvoiceTypes());
    this.route.paramMap.subscribe(snapshot => {
      const id = snapshot.get('id');
      if (+id > 0) {
        this.getUser(id).then(user => {
          this.user = user;
          this.loaded = true;
          this.state = 'update';
        });
      } else {
        this.user = this.emptyUser;
        this.loaded = false;
        this.state = 'create';
      }
    });
    const laboratories = this.dashboardService.getLaboratories();
    forkJoin([laboratories]).subscribe(result => {
      this.setLaboratories(result[0].laboratories);
    });
  }

  private setLaboratories(laboratories: Laboratory[]) {
    laboratories.forEach(laboratory => {
      this.laboratories.push({id: laboratory.id, text: laboratory.city, value: laboratory.id});
    });
  }

  getUser(id): Promise<User> {
    return new Promise<User>((resolve) => {
      const body = {
        id,
        locale: this.lang.currentLang
      };
      this.adminService.getSingleUser(body).subscribe(result => {
        resolve(result.user);
      }, error => {
        resolve(error);
      });
    });
  }

  onUpdatedUser(id) {
    this.getUser(id).then(user => {
      this.user = user;
      this.loaded = true;
      this.state = 'update';
    });
  }

  handleUserForm(event) {
    this.userUpdated = event;
  }

  handleAdministrationForm(event) {
    this.administrationUpdated = event;
  }

  handleValidationClicked(event) {
    this.validateClicked = event;
    if (this.state === 'create' && this.validateClicked) {
      setTimeout(() => {
        if (this.createFormsValid()) {
          const values: Array<{ key, value }> = new Array<{ key; value }>(0);
          values.push(...this.toArray(this.userUpdated.value));
          values.push(...this.toArray(this.administrationUpdated.value));
          values.push(...this.toArray(this.roleForm.value));
          if (this.styleForm) {
            values.push(this.styleForm);
          }
          this.adminService.createUser(this.buildFormData(values)).subscribe(result => {
            this.toast.showBottomCenterSuccess(result.message);
          }, error => {
            this.functions.showError(error);
          });
        }
      }, 100);

    } else if (this.state === 'update' && this.validateClicked) {
      setTimeout(() => {
        if (this.formsValid()) {
          const promise1 = this.detectChange(this.userOriginal, this.userUpdated.value);
          const promise3 = this.detectChange(this.administrationOriginal, this.administrationUpdated.value);
          Promise.all([promise1, promise3]).then(promiseResult => {
            const values = [];
            promiseResult.forEach((obj) => {
              values.push(...obj);
            });
            this.adminService.updateUser(this.buildFormData(values)).subscribe(result => {
              this.toast.showBottomCenterSuccess(result.message);
            }, error => {
              this.functions.showError(error);
            });
          });
        }
      }, 100);
    }
  }

  handleOriginalUserForm(event) {
    this.userOriginal = event;
  }

  handleOriginalAdministrationForm(event) {
    this.administrationOriginal = event;
  }

  private setInvoiceTypes(invoiceTypes: InvoiceType[]) {
    invoiceTypes.forEach(invoiceType => {
      this.invoiceTypes.push({id: invoiceType.id, text: invoiceType.slug, value: invoiceType.id});
    });
  }

  detectChange(original, updated) {
    return new Promise<any>((resolve) => {
      const obj = new Array(0);
      for (const key of Object.keys(updated)) {
        if (original[key] !== updated[key]) {
          const value = updated[key];
          obj.push({key, value});
        }
      }
      resolve(obj);
    });
  }

  buildFormData(values: any[]): FormData {
    const formData = new FormData();
    if (this.state === 'create') {
      values.forEach(value => {
        formData.append(value.key, value.value);
      });
    } else if (this.state === 'update') {
      formData.append('locale', this.lang.currentLang);
      // @ts-ignore
      formData.append('id', this.user.id);
      if (this.userUpdated.controls.company) {
        formData.append('company', this.userUpdated.controls.company.value);
      }
      values.forEach(value => {
        formData.append(value.key, value.value);
      });
    }
    return formData;

  }

  formsValid() {
    if (this.userUpdated && this.administrationUpdated) {
      return true;
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  createFormsValid() {
    if (this.userUpdated.valid && this.administrationUpdated.valid && this.roleForm.valid) {
      return true;
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  handleRoleFormEvent($event) {
    this.roleForm = $event;
    this.isUser = $event.value.role_id === 3 || $event.value.role_id === 5;
  }

  handleStyleFormEvent($event) {
    this.styleForm = $event;
  }

  private toArray<T>(formValue: T) {
    const formData = new Array(0);

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      if (value !== '') {
        formData.push({key, value});
      }
    }

    return formData;
  }

  priceChanged($event: any) {
    this.getUser(this.user.id).then(user => {
      this.user = user;
      this.loaded = true;
      this.state = 'update';
    });
  }
}
