import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthService, ToastService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { DropdownModel, RadioModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { InputValidationError } from 'src/app/models/input-error';
import SignaturePad from 'signature_pad';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { forkJoin } from 'rxjs';
import { FunctionsService } from '../../../admin/services/functions.service';
import { InvoiceType } from '../../../models/laboratorie';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private translate: TranslateService,
              private authService: AuthService,
              private router: Router,
              private resources: ResourcesService,
              private functions: FunctionsService,
              private toast: ToastService) {
    window.addEventListener('keydown', this.event);
  }

  registerForm: FormGroup;
  currentDate;
  languages: DropdownModel[] = [];
  titles: DropdownModel[] = [];
  isAdmin: boolean;
  path: string;
  class: string;
  company: RadioModel[] = [];
  selectedType: any = null;
  isCompany: boolean;
  invoiceTypes: DropdownModel[] = [];
  pinRequested = false;

  signaturePad: any;
  @ViewChild('sPad', {static: true}) signaturePadElement;

  private toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }

    return formData;
  }

  event = (event: KeyboardEvent) => {
    if (event.which === 13) {
      this.register();
    }
  }

  ngOnInit(): void {
    const location = window.location.pathname.split('/');
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {backgroundColor: 'rgb(255,255,255)'});
    if (location[1] === 'admin') {
      this.isAdmin = true;
      this.path = '/admin/login';
      this.class = '';
    } else {
      this.isAdmin = false;
      this.path = '/login';
      this.class = 'remove-signature';
    }

    this.setEnterprise();

    if (this.resources.getPlatformTitles() && this.resources.getPlatformLanguages() && this.resources.getInvoiceTypes()) {
      this.setTitles(this.resources.getPlatformTitles().titles);
      this.setLanguages(this.resources.getPlatformLanguages().languages);
      this.setInvoiceTypes(this.resources.getInvoiceTypes());
    } else {
      const titles = this.authService.getTitles();
      const langs = this.authService.getLanguages();
      const invoiceTypes = this.authService.getInvoiceTypes();
      forkJoin([titles, langs, invoiceTypes]).subscribe(result => {
        this.setTitles(result[0].titles);
        this.setLanguages(result[1].languages);
        this.setInvoiceTypes(result[2]);
      });
    }

    this.currentDate = this.datePipe.transform(new Date().toISOString(), 'shortDate');
    switch (this.isAdmin) {
      case true:
        this.registerForm = this.formBuilder.group({
          first_name: ['', [Validators.required]],
          last_name: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
          invoice_email: ['', [Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
          password: ['', [Validators.required]],
          password_confirmation: ['', [Validators.required]],
          company: ['', Validators.required],
          title_id: [1, [Validators.required]],
          address: ['', [Validators.required]],
          postal_code: ['', [Validators.required]],
          city: ['', [Validators.required]],
          phone: [''],
          mobile: ['', [Validators.required]],
          lang_id: [1, [Validators.required]],
          signature: [''],
          is_company: ['', Validators.required],
          locale: [this.resources.getLanguage()?.description || 'fr']
        });
        break;
      case false:
        this.registerForm = this.formBuilder.group({
          first_name: ['', [Validators.required]],
          last_name: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
          invoice_email: ['', [Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
          password: ['', [Validators.required]],
          password_confirmation: ['', [Validators.required]],
          company: ['', Validators.required],
          title_id: [1, [Validators.required]],
          address: ['', [Validators.required]],
          postal_code: ['', [Validators.required]],
          city: ['', [Validators.required]],
          phone: [''],
          mobile: ['', [Validators.required]],
          lang_id: [1, [Validators.required]],
          is_company: ['', Validators.required],
          invoice_type_id: [2, Validators.required],
          pin_protect: [0, Validators.required],
          pin: [''],
          locale: [this.resources.getLanguage()?.description || 'fr']
        });
        break;
    }
  }

  handleTypeChange($event: any) {
    if ($event.key === 0) {
      this.isCompany = false;
      this.registerForm.patchValue({
        is_company: 0
      });
    } else if ($event.key === 1) {
      this.isCompany = true;
      this.registerForm.patchValue({
        is_company: 1
      });
    }
  }

  register() {
    this.registerForm.patchValue({
      locale: this.getLanguageDescription(this.registerForm.controls.lang_id.value)
    });
    if (this.isAdmin) {
      this.registerForm.removeControl('is_company');
    } else if (!this.isCompany) {
      this.registerForm.removeControl('company');
    }

    if (this.registerForm.controls.phone.value.length === 0) {
      this.registerForm.removeControl('phone');
    }

    if (this.registerForm.controls.invoice_email.value === '' || this.registerForm.controls.invoice_email.value == null) {
      this.registerForm.removeControl('invoice_email');
    }

    if (this.registerForm.valid) {
      switch (this.isAdmin) {
        case true:
          if (this.signaturePad.isEmpty()) {
            this.toast.showBottomCenterError(this.translate.instant('FORM.SIGNATURE'));
          } else {
            const file = new File([this.dataURLToBlob(this.signaturePad.toDataURL('image/jpeg'))],
              'signature', {type: `image/jpeg`});
            this.registerForm.patchValue({
              signature: file
            });
            this.authService.registerAdmin(this.toFormData(this.registerForm.value)).subscribe(result => {
              this.toast.showBottomCenterInfo(result.message);
              this.router.navigateByUrl('/admin/login').then(() => {});
            }, (error: InputValidationError) => {
              this.functions.showError(error);
              if (!this.isCompany) {
                this.registerForm.addControl('company', new FormControl('', Validators.required));
                this.registerForm.addControl('phone', new FormControl(''));
                this.registerForm.addControl('invoice_email', new FormControl('', [Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]));
              }
            });
          }
          break;
        case false:
          this.registerForm.patchValue({
            pin_protect: this.pinRequested ? 1 : 0
          });
          if (!this.pinRequested) {
            this.registerForm.removeControl('pin');
          }

          this.authService.registerUser(this.registerForm.value).subscribe(result => {
            this.toast.showBottomCenterInfo(result.message);
            this.router.navigateByUrl('/login').then(() => {});
          }, (error) => {
            this.functions.showError(error);
            this.registerForm.addControl('pin', new FormControl(''));
            if (!this.isCompany) {
              this.registerForm.addControl('company', new FormControl('', Validators.required));
              this.registerForm.addControl('phone', new FormControl(''));
              this.registerForm.addControl('invoice_email', new FormControl('', [Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]));
            }
          });
          break;
      }
    } else {
      this.getErrorFieldsFromAdministrationForm(this.registerForm).forEach(x => {
        this.toast.showBottomCenterError(this.translate.instant(x));
      });
      if (!this.isCompany) {
        this.registerForm.addControl('company', new FormControl('', Validators.required));
      }
      this.registerForm.addControl('phone', new FormControl(''));
      this.registerForm.addControl('invoice_email', new FormControl('', [Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]));
    }
  }

  private setEnterprise() {
    this.company.push({key: 0, name: this.translate.instant('PRIVATE')});
    this.company.push({key: 1, name: this.translate.instant('COMPANY')});
  }

  checkPasswords(value) {
    this.registerForm.patchValue({
      password_confirmation: value
    });
    if (this.registerForm.controls.password.value !== this.registerForm.controls.password_confirmation.value) {
      this.toast.showBottomCenterError(this.translate.instant('FORM.PASSWORD'));
    }
  }

  updateLanguageValue(value) {
    this.registerForm.patchValue({
      lang_id: value
    });
  }

  updateTitleValue(value) {
    this.registerForm.patchValue({
      title_id: value
    });
  }

  updateInvoiceType(value) {
    this.registerForm.patchValue({
      invoice_type_id: value
    });
  }

  private setInvoiceTypes(invoiceTypes: InvoiceType[]) {
    invoiceTypes.forEach(invoiceType => {
      this.invoiceTypes.push({id: invoiceType.id, text: invoiceType.slug, value: invoiceType.id});
    });
  }

  private setLanguages(languages) {
    languages.forEach(lng => {
      const name = lng.language.toUpperCase();
      this.languages.push({id: lng.id, text: name, value: lng.language, description: lng.description});
    });
  }

  private setTitles(titles) {
    titles.forEach(title => {
      const name = title.name.toUpperCase();
      this.titles.push({id: title.id, text: name, value: title.name});
    });
  }

  getLanguageDescription(id: number): string {
    const result = this.languages.find(x => x.id === id);
    if (result) {
      return result.description;
    }
    return environment.defaultLanguage;
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.event);
  }

  clear() {
    this.signaturePad.clear();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  private getErrorFieldsFromAdministrationForm(orderForm: FormGroup): string[] {
    const errorFields = new Array(0);
    if (orderForm.controls.first_name.status === 'INVALID') {
      errorFields.push('first_name'.toUpperCase());
    }
    if (orderForm.controls.last_name.status === 'INVALID') {
      errorFields.push('last_name'.toUpperCase());
    }
    if (orderForm.controls.email.status === 'INVALID') {
      errorFields.push('email'.toUpperCase());
    }
    if (orderForm.controls.password.status === 'INVALID') {
      errorFields.push('password'.toUpperCase());
    }
    if (orderForm.controls.password_confirmation.status === 'INVALID') {
      errorFields.push('password_confirmation'.toUpperCase());
    }
    if (orderForm.controls.title_id.status === 'INVALID') {
      errorFields.push('title_id'.toUpperCase());
    }
    if (orderForm.controls.address.status === 'INVALID') {
      errorFields.push('address'.toUpperCase());
    }
    if (orderForm.controls.city.status === 'INVALID') {
      errorFields.push('city'.toUpperCase());
    }
    if (orderForm.controls.mobile.status === 'INVALID') {
      errorFields.push('mobile'.toUpperCase());
    }
    if (orderForm.controls.lang_id.status === 'INVALID') {
      errorFields.push('lang_id'.toUpperCase());
    }

    if (orderForm.controls.locale.status === 'INVALID') {
      errorFields.push('locale'.toUpperCase());
    }
    if (!this.isAdmin) {
      if (orderForm.controls.invoice_type_id.status === 'INVALID') {
        errorFields.push('invoice_type_id'.toUpperCase());
      }
      if (orderForm.controls.is_company.status === 'INVALID') {
        errorFields.push('is_company'.toUpperCase());
      }
      if (this.isCompany) {
        if (orderForm.controls.company.status === 'INVALID') {
          errorFields.push('company'.toUpperCase());
        }
      }
    }
    if (this.isAdmin) {
      if (orderForm.controls.company.status === 'INVALID') {
        errorFields.push('company'.toUpperCase());
      }
    }
    return errorFields;
  }

  check($event: any) {
    this.pinRequested = $event.checked;
  }
}
