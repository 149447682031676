import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../../shared/services';

@Component({
  selector: 'app-basic-report-info',
  templateUrl: './basic-report-info.component.html',
  styleUrls: ['./basic-report-info.component.scss']
})
export class BasicReportInfoComponent implements OnInit {
  form: FormGroup;
  sampleTypes: string[];
  asbestosFieldAdded = false;
  hapFieldAdded = false;
  pcbFieldAdded = false;
  pcbCpFieldAdded = false;
  leadFieldAdded = false;

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              private formBuilder: FormBuilder,
              private translate: TranslateService,
              private toast: ToastService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      urgency_and_measures: ['', Validators.required],
      process_of_elimination: [''],
      regenerate_report: [this.config.data.regenerate]
    });
    this.configureFields(Object.keys(this.config.data.samples));
  }

  configureFields(sampleTypes) {
    sampleTypes.forEach(sampleType => {
      switch (sampleType) {
        case '2':
          this.form.addControl('process_of_elimination_asbestos', new FormControl(''));
          this.asbestosFieldAdded = true;
          break;
        case '3':
          this.form.addControl('process_of_elimination_lead', new FormControl(''));
          this.leadFieldAdded = true;
          break;
        case '4':
          this.form.addControl('process_of_elimination_pcb', new FormControl(''));
          this.pcbFieldAdded = true;
          break;
        case '5':
          this.form.addControl('process_of_elimination_hap', new FormControl(''));
          this.hapFieldAdded = true;
          break;
        case '7':
          this.form.addControl('process_of_elimination_pcb_cp', new FormControl(''));
          this.pcbCpFieldAdded = true;
          break;
      }
    });
  }

  save() {
    const removedFields = new Array(0);
    if (this.asbestosFieldAdded) {
      if (this.form.controls.process_of_elimination_asbestos.value.length === 0) {
        this.form.removeControl('process_of_elimination_asbestos');
        removedFields.push('process_of_elimination_asbestos');
      }
    }
    if (this.leadFieldAdded) {
      if (this.form.controls.process_of_elimination_lead.value.length === 0) {
        this.form.removeControl('process_of_elimination_lead');
        removedFields.push('process_of_elimination_lead');
      }
    }
    if (this.pcbFieldAdded) {
      if (this.form.controls.process_of_elimination_pcb.value.length === 0) {
        this.form.removeControl('process_of_elimination_pcb');
        removedFields.push('process_of_elimination_pcb');
      }
    }
    if (this.pcbCpFieldAdded) {
      if (this.form.controls.process_of_elimination_pcb_cp.value.length === 0) {
        this.form.removeControl('process_of_elimination_pcb_cp');
        removedFields.push('process_of_elimination_pcb_cp');
      }
    }
    if (this.hapFieldAdded) {
      if (this.form.controls.process_of_elimination_hap.value.length === 0) {
        this.form.removeControl('process_of_elimination_hap');
        removedFields.push('process_of_elimination_hap');
      }
    }
    if (this.form.controls.process_of_elimination.value.length === 0) {
      this.form.removeControl('process_of_elimination');
      removedFields.push('process_of_elimination');
    }
    if (this.form.valid) {
      this.ref.close(this.form.value);
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
      if (removedFields.length > 0) {
        removedFields.forEach(x => {
          this.form.addControl(x, new FormControl(''));
        });
      }
    }
  }

  valueChange(event, property) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: ProgressEvent) => {
        const file = new File([this.dataURLToBlob((event.target as FileReader).result)],
          property, {type: `image/jpeg`});
        this.form.patchValue({
          [property]: file
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }
}
