import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Client } from 'src/app/admin/models/client';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { Language } from 'src/app/models';
import { RadioModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { Email } from 'src/app/admin/models/simple-order-response';
import { DiagnosticOrder } from '../../../admin/models/diagnostic-order-response';
import { ToastService } from '../../../shared/services';
import { AdminSimpleOrdersService } from '../../../admin/services/admin-simple-orders.service';
import { InputValidation } from '../../../models/input-validation';
import { FormGroup } from '@angular/forms';
import { FunctionsService } from '../../../admin/services/functions.service';

@Component({
  selector: 'app-prolabo-diagnostic-order-report',
  templateUrl: './diagnostic-order-report.component.html',
  styleUrls: ['./diagnostic-order-report.component.scss']
})
export class DiagnosticOrderReportComponent implements OnInit, OnChanges {
  @Input() submitClicked = false;
  @Input() order: DiagnosticOrder;
  @Input() isUser: boolean;
  @Input() getOrderRequested = false;

  selectedLanguage: any = null;
  languagesRadio: RadioModel[] = [];
  emails: RadioModel[] = [];
  emailsArray: string[] = new Array(0);
  selectedEmail: any = [true];
  addEmailClicked = false;
  newMail: any = null;
  languages: Language[];

  @Output() emailEvents = new EventEmitter<string[]>();
  @Output() languageEvent = new EventEmitter<number>();
  @Output() email = new EventEmitter<boolean>();
  @Output() lang = new EventEmitter<boolean>();

  constructor(private resources: ResourcesService,
              private toast: ToastService,
              private functions: FunctionsService,
              private ordersService: AdminSimpleOrdersService) {
  }

  ngOnInit() {
    this.languages = this.resources.getPlatformLanguages().languages;
    this.setLanguages(this.languages);
    if (this.order?.lang_id) {
      const lang = this.languagesRadio.find(x => x.key === this.order.lang_id);
      this.selectedLanguage = lang;
    }
  }

  private setLanguages(languages: Language[]) {
    languages.forEach(lng => {
      this.languagesRadio.push({key: lng.id, name: lng.language});
    });
  }

  setEmails(emails: Email[]) {
    emails.forEach(email => {
      this.emails.push({key: email.id, name: email.email});
      this.emailsArray.push(email.email);
    });
  }

  addEmail() {
    this.addEmailClicked = true;
  }

  public delete(email) {
    const e = this.emails.find(em => em.name === email.name);
    const ea = this.emailsArray.find(em => em === email);
    const index = this.emails.indexOf(e, 0);
    const indexE = this.emailsArray.indexOf(ea, 0);
    if (index > -1) {
      this.emails.splice(index, 1);
      this.emailsArray.splice(indexE, 1);
    }
  }


  public submit(mail) {
    this.emails.push({key: mail, name: mail});
    this.emailsArray.push(mail);
    this.newMail = '';
    this.addEmailClicked = false;
    const formData = new FormData();
    for (let i = 0; i < this.emailsArray.length; i++) {
      formData.append(`email[${i}]`, this.emailsArray[i]);
    }
    formData.append('locale', this.resources.getLanguage().description);
    // @ts-ignore
    formData.append('order_id', this.order.id);
    this.onValueChange(formData, `email`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submitClicked) {
      if (this.submitClicked) {
        if (this.emailsArray.length > 0 && this.selectedLanguage != null) {
          this.email.emit(false);
          this.lang.emit(false);
          const ea = this.emailsArray.find(em => em === this.selectedEmail?.name);
          const index = this.emailsArray.indexOf(ea, 0);
          this.emailsArray.unshift(this.emailsArray.splice(index, 1)[0]);
          this.emailEvents.emit(this.emailsArray);
          this.languageEvent.emit(this.selectedLanguage.key);
          this.submitClicked = false;
        } else {
          if (this.emailsArray.length === 0 && this.selectedLanguage == null) {
            this.email.emit(true);
            this.lang.emit(true);
          } else if (this.emailsArray.length === 0) {
            this.email.emit(true);
          } else if (this.selectedLanguage == null) {
            this.lang.emit(true);
          }
          this.submitClicked = false;
        }
      }
    }
    if (changes.getOrderRequested) {
      if (this.getOrderRequested) {
        if (this.order.email) {
          this.getOrderRequested = false;
          this.emails = [];
          this.emailsArray = [];
          this.setEmails(this.order.email);
        }
      }
    }
  }

  public cancel() {
    this.addEmailClicked = false;
  }

  public handleLanguageChange(event) {
    this.onValueChange(event.key, 'lang_id');
  }

  public onValueChange($event: any, field: string) {
    if (field === 'email') {
      this.ordersService.updateOrder($event).subscribe(result => {
        this.toast.showBottomCenterSuccess('');
      }, error => {
        this.functions.showError(error);
      });
    } else {
      const body = {
        [field]: $event,
        order_id: this.order.id,
        locale: this.resources.getLanguage().description
      };
      this.ordersService.updateOrder(this.toFormData(body)).subscribe(result => {
        this.toast.showBottomCenterSuccess('');
      }, error => {
        this.functions.showError(error);
      });
    }
  }

  private toFormData(formValue): FormData {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }
    return formData;
  }
}
