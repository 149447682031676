import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DropdownModel } from '../dropdown/dropdown.model';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prolabo-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownFieldComponent),
      multi: true
    }
  ]
})
export class DropdownFieldComponent implements OnChanges, OnInit {
  @Input() options: DropdownModel[];
  @Input() control: string;
  @Input() type: string;
  @Input() disabled = false;
  @Input() selectedOption: DropdownModel;
  @Input() placeholder: string;
  @Input() defaultValue: number;
  @Input() inputId: any;

  @Output() OnValueChange = new EventEmitter<string>();
  @Output() OnDropdownClicked = new EventEmitter<MouseEvent>();
  @Output() OnHideHappened = new EventEmitter<string>();

  selectedOptionFormControl: FormControl;

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOption) {
      this.selectedOptionFormControl.setValue(this.selectedOption);
    }
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.selectedOptionFormControl.disable();
      } else {
        this.selectedOptionFormControl.enable();
      }
    }
  }

  onClick(event: MouseEvent) {
    this.OnDropdownClicked.emit(event);
  }

  onChange(event) {
    this.OnValueChange.emit(event.value);
  }

  ngOnInit(): void {
    this.selectedOptionFormControl = new FormControl({value: this.defaultValue, disabled: this.disabled});
  }

  onHide($event: any) {
    this.OnHideHappened.emit($event);
  }
}
