<div class="centered">
  <div class="p-mr-2">
    <app-logo-container [isAdmin]="isAdmin"></app-logo-container>
    <p-card>
      <div class="" *ngIf="!userActivated">
        <app-prolabo-button [name]="'RESEND_VERIFICATION'" (OnClick)="resendVerificationEmail()"></app-prolabo-button>
      </div>
      <div class="p-d-flex p-jc-between p-mb-10" *ngIf="userActivated">
        <div class="title">
          <p-header>{{'CONNECTION' | translate}}</p-header>
        </div>
        <div class="p-mt-1">
          <p-header *ngIf="!isAdmin" [routerLink]="path"
                    class="text-gray padding-left text-mmedium link">{{'REGISTRATION' | translate}}</p-header>
        </div>
      </div>
      <form [formGroup]="loginForm" *ngIf="userActivated">
        <app-prolabo-input [keyword]="'USER'"
                           [type]="'text'"
                           [for]="'name'"
                           [id]="'name'"
                           [inputType]="'email'"
                           formControlName="email"></app-prolabo-input>
        <app-prolabo-input [keyword]="'PASSWORD'"
                           [type]="'password'"
                           [for]="'password'"
                           [id]="'password'"
                           [inputType]="'password'"
                           formControlName="password"></app-prolabo-input>
      </form>
      <div class="p-grid" *ngIf="userActivated">
        <div class="p-col-6">
          <app-prolabo-button [name]="'LOGIN'" (OnClick)="submit()"></app-prolabo-button>
        </div>
        <div class="p-col-6">
          <div class="middle">
            <p-header [routerLink]="['/forgot-password' , {admin: isAdmin}]"
                      class="text-gray text-mmedium link">{{'FORGOT.PASSWORD' | translate}}</p-header>
          </div>
        </div>
      </div>
      <app-prolabo-language-dropdown
        [options]="languages"
        (OnValueChange)="updateLanguageValue($event)"
        [defaultValue]="currentLang.id">
      </app-prolabo-language-dropdown>
    </p-card>
  </div>
</div>
