import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../models';
import { UserService } from '../../../shared/services';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileHeaderComponent implements OnInit {

  @Input() home: boolean;
  @Input() stockRoute: string;

  display = false;
  user: User;

  constructor(private router: Router, private userService: UserService) {

  }

  navigate() {
    this.router.navigateByUrl(`${this.stockRoute}/dashboard`);
  }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();
  }
}
