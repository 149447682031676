<div class="clients-container">
  <app-dialog-header [title]="'CONFIRM_DEADLINES'" (iconClick)="ref.close(-1)"></app-dialog-header>
  <div class="p-grid deadline-container">
    <div class="p-col-12">
      <app-prolabo-dropdown [options]="deadlines['2']"
                            class="deadline-dropdown"
                            (OnValueChange)="updateDeadlineValue($event)"
                            [defaultValue]="deadlineId"
                            placeholder="DEADLINE">
      </app-prolabo-dropdown>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-6">
    </div>
    <div class="p-col-6">
      <app-prolabo-button type="secondary"
                          name="CONFIRM"
                          (OnClick)="ref.close(newDeadlineId)">
      </app-prolabo-button>
    </div>
  </div>
</div>
