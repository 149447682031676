import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { DropdownModel } from '../../../../../shared/components/dropdown/dropdown.model';
import { ToastService } from '../../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-scorecard-modal',
  templateUrl: './create-scorecard-modal.component.html',
  styleUrls: ['./create-scorecard-modal.component.scss']
})
export class CreateScorecardModalComponent implements OnInit {

  scorecardForm: FormGroup;
  sampleTypes: DropdownModel[] = [];

  constructor(private formBuilder: FormBuilder,
              private resources: ResourcesService,
              private config: DynamicDialogConfig,
              private toast: ToastService,
              private translate: TranslateService,
              private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.sampleTypes = this.config.data;
    this.scorecardForm = this.formBuilder.group({
      name: ['', Validators.required],
      sample_type_id: [this.sampleTypes[0].id, Validators.required],
      locale: [this.resources.getLanguage().description, Validators.required]
    });
  }

  submit() {
    if (this.scorecardForm.valid) {
      this.ref.close(this.scorecardForm.value);
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  updateSampleTypeValue($event: string) {
    this.scorecardForm.patchValue({
      sample_type_id: $event
    });
  }

  back() {
    this.ref.close();
  }
}
