<p-table [value]="items"
         [scrollable]="true"
         [style]="{'width': '100%'}"
         styleClass="p-datatable-sm">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup *ngFor="let header of headers">
      <col class="columns">
    </colgroup>
    <ng-container *ngIf="scoreCardModels !== null && sampleAnalyzed">
      <colgroup *ngFor="let models of scoreCardModels">
        <col class="columns">
      </colgroup>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let header of headers">
        <th>
          <p class="overflow"
             pTooltip="{{ header['title']| translate }}"
             tooltipPosition="top">
            {{ header['title'] | translate }}
          </p>
        </th>
      </ng-container>
      <ng-container *ngIf="scoreCardModels !== null && sampleAnalyzed">
        <ng-container *ngFor="let model of scoreCardModels">
          <th>
            <p class="overflow"
               pTooltip="{{ model.field_name | translate }}"
               tooltipPosition="top">
              {{ model.field_name | translate }}
            </p>
          </th>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr ngClass="{{ item.corrupted ? 'mark-red' : '' }}">
      <td>
        <p class="overflow centered-btns">
          <app-prolabo-icon class="icon-white"
                            (click)="updateSample(item.id)"
                            icon="edit">
          </app-prolabo-icon>
          <app-prolabo-icon class="icon-white"
                            (click)="openConfirmDialog(item.id)"
                            icon="trash-bin">
          </app-prolabo-icon>
        </p>
      </td>
      <td><p class="overflow" pTooltip="{{ item['sample_id'] | translate }}"
             tooltipPosition="left">
        {{item['sample_id']}}
      </p></td>
      <td>
        <p class="overflow"
           pTooltip="{{ item.sample_number }}"
           tooltipPosition="left">
          {{ item.sample_number }}
        </p>
      </td>
      <td>
        <p class="overflow" pTooltip="{{ item.type?.slug | translate }}"
           tooltipPosition="top">{{ item.type?.slug | translate }}</p>
      </td>
      <td *ngIf="!isVdi">
        <p class="overflow" pTooltip="{{ item.description }}"
           tooltipPosition="top">{{ item.description }}</p>
      </td>
      <ng-container *ngIf="sampleAnalyzed">
        <ng-container *ngFor="let field of item.scorecard_values">
          <td>
            <p class="overflow"
               pTooltip="{{ getSampleValue(field.scorecard_field?.slug, item.scorecard_value, item.sample_type_id) }}"
               tooltipPosition="top">
              {{ getSampleValue(field.scorecard_field?.slug, item.scorecard_value, item.sample_type_id) }}
            </p>
          </td>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>
</p-table>
<app-prolabo-confirmation-modal
  [display]="displayModal"
  [header]="'MODAL_CONFIRMATION'"
  (confirmClicked)="deleteItem()"
  (cancelClicked)="closeConfirmDialog()">
</app-prolabo-confirmation-modal>



