import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { Deadline } from '../../../../../models/deadline';

@Component({
  selector: 'app-deadline-confirmation',
  templateUrl: './deadline-confirmation.component.html',
  styleUrls: ['./deadline-confirmation.component.scss']
})
export class DeadlineConfirmationComponent implements OnInit {
  deadlines: Deadline[];
  deadlineId: number;
  newDeadlineId = -1;

  constructor(public ref: DynamicDialogRef, private resources: ResourcesService, private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.deadlines = this.config.data.deadlines;
    this.deadlineId = this.config.data.deadlineId;
    this.newDeadlineId = this.config.data.deadlineId;
  }

  updateDeadlineValue(value) {
    this.newDeadlineId = value;
  }

}
