import { Component, Input, OnInit } from '@angular/core';
import { StepCounter } from '../../../admin/models/step-counter';

@Component({
  selector: 'app-prolabo-order-step-container',
  templateUrl: './order-step-container.component.html',
  styleUrls: ['./order-step-container.component.scss']
})
export class OrderStepContainerComponent{
  @Input() step1: StepCounter;
  @Input() step2: StepCounter;
  @Input() step3: StepCounter;
  @Input() step4: StepCounter;

}
