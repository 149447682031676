<div class="pagination-list">
  <ng-container *ngIf="items">
    <div class="p-grid">
      <div class="p-col-12 list">
        <div class="list-labels">
          <div class="sublist-items {{listType === 'clients' ? 'clients' : ''}}">
            <ng-container *ngFor="let header of headers">
                            <span *ngIf="header.title !== 'DATE'">
                                <p class="{{listType === 'clients' ? header.sortLabel : ''}} sort-label"
                                   (click)="sortByLabel(header.sortLabel)">
                                    {{ header.title | translate }}
                                  <span *ngIf="getAscValueForHeader(header.sortLabel)" class="icon">
                                        <img src="../../../../assets/icons/down-arrow.svg"/>
                                    </span>
                                    <span *ngIf="!getAscValueForHeader(header.sortLabel)" class="icon">
                                        <img src="../../../../assets/icons/up-arrow.svg"/>
                                    </span>
                                </p>
                            </span>
            </ng-container>
          </div>
          <p class="date sort-label"
             (click)="sortByLabel(getLastHeader()['sortLabel'])">
            {{ getLastHeader()['title'] | translate }}
            <span *ngIf="getAscValueForHeader('created_at')" class="icon">
                            <img src="../../../../assets/icons/down-arrow.svg"/>
                        </span>
            <span *ngIf="!getAscValueForHeader('created_at')" class="icon">
                            <img src="../../../../assets/icons/up-arrow.svg"/>
                        </span>
          </p>
        </div>
        <ng-container *ngFor="let item of items; let i = index">
          <div class="list-item">
            <div class="sublist-items" *ngIf="listType === 'users'" (click)="navigate(item.id)">
              <p>{{ (page * 10) + i + 1 }}</p>
              <p class="pointer">{{ item.first_name }}</p>
              <p>{{ item.last_name }}</p>
              <p>{{ item.initials }}</p>
              <p>{{ item.role | uppercase | translate }}</p>
              <p>{{ item.is_company ? ('COMPANY' | translate) : ('PRIVATE' | translate) }}</p>
              <p>{{ item.company}}</p>
            </div>
            <div class="sublist-items clients" *ngIf="listType === 'clients'">
              <p class="id">
                {{ item.id }}
              </p>
              <p class="first_name pointer" (click)="onItemClicked(item)">
                {{ item.first_name }}
              </p>
              <p class="last_name">
                {{ item.last_name }}
              </p>
              <p class="overflow company"
                 pTooltip="{{item.company}}"
                 tooltipPosition="top">
                {{ item.company }}
              </p>
              <p class="overflow address"
                 pTooltip="{{item.address}}"
                 tooltipPosition="top">
                {{ item.address + ' ' + item.postal_code + ' ' + item.city}}
              </p>
            </div>
            <p>
              {{ item.created_at | toDateObj | date: "dd.MM.yyyy" }}
              <ng-container *ngIf="activeItem != item.id && storageUser.role === 'super_admin'">
                <app-prolabo-icon class="icon-white" (click)="openConfirmDialog(item.id)"
                                  icon="trash-bin"></app-prolabo-icon>
              </ng-container>
            </p>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <p class="total">
          {{ totalItems }}
          {{ listType == 'users' ? ('USERS' | translate | lowercase) : ('CLIENTS' | translate | lowercase) }}
        </p>
      </div>
      <div class="p-col-12 p-md-6 inline">
        <p>{{ 'ROWS_PER_PAGE' | translate }}: </p>
        <p-dropdown
          [options]="[10, 20, 30]"
          class="row-dropdown"
          (onChange)="onLimitChange($event)"></p-dropdown>
        <p>&nbsp; {{ numberRows * page + 1 }} - {{ numberRows * page + numberRows }} of {{ totalItems }}</p>
        <p-paginator
          class="paginator"
          [rows]="numberRows"
          [totalRecords]="totalItems"
          [showJumpToPageDropdown]="false"
          [showPageLinks]="false"
          (onPageChange)="onPageChange($event)"></p-paginator>
      </div>
    </div>
  </ng-container>
</div>
<app-prolabo-confirmation-modal
  [display]="displayModal"
  [header]="'MODAL_CONFIRMATION'"
  (confirmClicked)="deleteItem()"
  (cancelClicked)="closeConfirmDialog()">
</app-prolabo-confirmation-modal>
