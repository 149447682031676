<div class="clients-container">
  <app-dialog-header [title]="'PROCESS'" (iconClick)="ref.close()"></app-dialog-header>
  <form [formGroup]="form">
    <div class="p-grid content">
      <div class="p-col-12">
        <app-prolabo-input [for]="'urgency_and_measures'"
                           [id]="'urgency_and_measures'"
                           [keyword]="'URGENCY_MEASURES'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [required]="true"
                           formControlName="urgency_and_measures">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'process_of_elimination'"
                           [id]="'process_of_elimination'"
                           [keyword]="'PROCESS_ELIMINATION'"
                           [type]="'text'"
                           [inputType]="'basic'"
                           [required]="false"
                           formControlName="process_of_elimination">
        </app-prolabo-input>
      </div>
      <div class="p-col-12" *ngIf="asbestosFieldAdded">
        <app-prolabo-icon-input [for]="'process_of_elimination_asbestos'"
                                [id]="'process_of_elimination_asbestos'"
                                [keyword]="'PROCESS_OF_ELIMINATION_ASBESTOS'"
                                [type]="'file'"
                                [regularInput]="true"
                                [inputType]="'basic'"
                                [required]="false"
                                [accept]="'image/*,application/pdf'"
                                [icon]="'visible'"
                                (change)="valueChange($event, 'process_of_elimination_asbestos')">
        </app-prolabo-icon-input>
      </div>
      <div class="p-col-12" *ngIf="leadFieldAdded">
        <app-prolabo-icon-input [for]="'process_of_elimination_lead'"
                                [id]="'process_of_elimination_lead'"
                                [keyword]="'PROCESS_OF_ELIMINATION_LEAD'"
                                [type]="'file'"
                                [regularInput]="true"
                                [inputType]="'basic'"
                                [required]="false"
                                [accept]="'image/*,application/pdf'"
                                [icon]="'visible'"
                                (change)="valueChange($event, 'process_of_elimination_lead')">
        </app-prolabo-icon-input>
      </div>
      <div class="p-col-12" *ngIf="pcbFieldAdded">
        <app-prolabo-icon-input [for]="'process_of_elimination_pcb'"
                                [id]="'process_of_elimination_pcb'"
                                [keyword]="'PROCESS_OF_ELIMINATION_PCB'"
                                [type]="'file'"
                                [regularInput]="true"
                                [inputType]="'basic'"
                                [required]="false"
                                [accept]="'image/*,application/pdf'"
                                [icon]="'visible'"
                                (change)="valueChange($event, 'process_of_elimination_pcb')">
        </app-prolabo-icon-input>
      </div>
      <div class="p-col-12" *ngIf="pcbCpFieldAdded">
        <app-prolabo-icon-input [for]="'process_of_elimination_pcb_cp'"
                                [id]="'process_of_elimination_pcb_cp'"
                                [keyword]="'PROCESS_OF_ELIMINATION_PCB_CP'"
                                [type]="'file'"
                                [regularInput]="true"
                                [inputType]="'basic'"
                                [required]="false"
                                [accept]="'image/*,application/pdf'"
                                [icon]="'visible'"
                                (change)="valueChange($event, 'process_of_elimination_pcb_cp')">
        </app-prolabo-icon-input>
      </div>
      <div class="p-col-12" *ngIf="hapFieldAdded">
        <app-prolabo-icon-input [for]="'process_of_elimination_hap'"
                                [id]="'process_of_elimination_hap'"
                                [keyword]="'PROCESS_OF_ELIMINATION_HAP'"
                                [type]="'file'"
                                [regularInput]="true"
                                [inputType]="'basic'"
                                [required]="false"
                                [accept]="'image/*,application/pdf'"
                                [icon]="'visible'"
                                (change)="valueChange($event, 'process_of_elimination_hap')">
        </app-prolabo-icon-input>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-8"></div>
      <div class="p-col-4">
        <app-prolabo-icon-button [name]="'VALIDATE'"
                                 (click)="save()"
                                 [icon]="'check'"
                                 type="secondary">
        </app-prolabo-icon-button>
      </div>
    </div>
  </form>
</div>
