import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourcesService } from '../../shared/services/resources.service';
import { Observable } from 'rxjs';
import { ScoreCardFieldUpdateResponse, ScoreCardResponse } from '../../admin/models/score-card-model';
import { environment } from '../../../environments/environment';
import { AdminRoutesStatics } from '../../admin/statics/admin-routes-statics';
import { FieldTypeResponse } from '../../admin/models/field-types';

@Injectable({
  providedIn: 'root'
})
export class AdminAnalystCreatorService {

  constructor(private http: HttpClient, private resources: ResourcesService) { }

  getScoreCardModels(orderId?: number): Observable<ScoreCardResponse> {
    return this.http.post<ScoreCardResponse>(`${environment.apiUrl}${AdminRoutesStatics.getScoreCardModels}`,
      {locale: this.resources.getLanguage().description, order_id: orderId},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  createScoreCardModal(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${AdminRoutesStatics.createScoreCardModel}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  updateScoreCardModal(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${AdminRoutesStatics.updateScoreCardModel}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  deleteScoreCardModal(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${AdminRoutesStatics.deleteScoreCardModel}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  createScoreCardField(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${AdminRoutesStatics.createScoreCardField}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  updateScoreCardField(body): Observable<ScoreCardFieldUpdateResponse> {
    return this.http.post<ScoreCardFieldUpdateResponse>(`${environment.apiUrl}${AdminRoutesStatics.updateScoreCardField}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  deleteScoreCardField(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${AdminRoutesStatics.deleteScoreCardField}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  createScoreCardDropdownOption(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${AdminRoutesStatics.createFieldDropdownOption}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  deleteScoreCardDropdownOption(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${AdminRoutesStatics.deleteFieldDropdownOption}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  getScoreCardFieldTypes(): Observable<FieldTypeResponse> {
    return this.http.post<FieldTypeResponse>(`${environment.apiUrl}${AdminRoutesStatics.getScoreCardField}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }
}
