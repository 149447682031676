<p-sidebar [(visible)]="display" position="right" [showCloseIcon]="false" class="card sidebar">
  <div class="mobile-sidebar-container">
    <div class="title-container">
      <div class="title-section">
        <p>{{ 'WELCOME' | translate }}</p>
        <p>{{ title }}</p>
        <div class="logout">
          <app-prolabo-button (click)="logout()" [name]="'LOGOUT'" class="logout-btn"></app-prolabo-button>
        </div>
      </div>
    </div>
    <div class="title-section footer text-medium">
      <p>Analysis Lab SA © {{ date.getFullYear() }}</p>
      <p>Powered by Tokiwi</p>
    </div>
  </div>
</p-sidebar>
