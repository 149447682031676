<form>
  <ng-container *ngIf="inputType === 'text'">
    <input [class]="extraClass" [id]="id" [type]="type" pInputText
           (change)="itemChange($event)" [disabled]="disabled"
           [formControl]="inputControl" (blur)="onTouch($event)" (keydown)="onChange($event.target.value)">
  </ng-container>
  <ng-container *ngIf="inputType === 'number'">
    <input [id]="id" [type]="type" pInputText (change)="itemChange($event)"
           [formControl]="numberControl" [disabled]="disabled" (blur)="onTouch($event)"
           (keydown)="onChange($event.target.value)">
  </ng-container>
  <ng-container *ngIf="inputType === 'not_zero'">
    <input [id]="id" [type]="type" pInputText (change)="itemChange($event)"
           [formControl]="notZero" [disabled]="disabled" (blur)="onTouch($event)"
           (keydown)="onChange($event.target.value)">
  </ng-container>
  <ng-container *ngIf="inputType === 'with_zero'">
    <input [id]="id" [type]="type" pInputText (change)="itemChange($event)"
           [formControl]="withZero" [disabled]="disabled" (blur)="onTouch($event)"
           (keydown)="onChange($event.target.value)">
  </ng-container>
</form>
