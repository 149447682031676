<div class="p-grid" *ngIf="order">
  <div class="p-col-12">
    <p-card [style]="{'background-color': 'black'}">
      <div class="p-grid">
        <div class="p-col-12 p-md-6 title-block">
          <img src="../../../../../../../assets/icons/shopping-basket-small.svg">
          <span class="order-group">
            <p class="order-language">{{'SIMPLE.ORDER' | translate}} - {{order.user?.company}}</p>
            <p class="order-date">
              {{order?.order_number}} {{ 'CREATED_ON' | translate }} {{order?.created_at | date }}</p>
            <p class="order-date">
              {{ 'SITE' | translate }} - {{order?.site}} </p>
          </span>
        </div>
      </div>
    </p-card>
    <app-prolabo-order-step-container [step1]="stepCounter1" [step2]="stepCounter2"
                                      [step3]="stepCounter3" [step4]="stepCounter4"
    ></app-prolabo-order-step-container>
    <ng-container *ngIf="samplesByType && samplesByType.length > 0">
      <ng-container *ngFor="let samples of samplesByType">
        <p-card>
          <div class="p-grid spacing-bottom">
            <div class="p-col-12 p-md-8 title">
              <p-header>
                {{ 'SAMPLING' | translate }} {{ functions.getTypeName(samples[0]) | translate }}
                ({{ samples[1].samples.length }})
              </p-header>
              <p *ngIf="samples[0] === '2' || samples[0]==='8'" class="deadline-title">
                {{order.deadline.slug | translate}}
              </p>
            </div>
            <div class="p-col-12 p-md-4">
              <app-prolabo-icon-button
                icon="check"
                type="secondary"
                [name]="samples[1].analyzed ? 'ANALYSED' : 'ANALYSE' "
                (OnClick)="openScoreCard(samples[1].samples, samples[1].analyzed)">
              </app-prolabo-icon-button>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <ng-container *ngIf="order">
                <app-prolabo-analyst-orders-pagination-list-samples
                  [items]="samples[1].samples"
                  [step2]="step2"
                  [step3]="step3"
                  [isVdi]="samples[0] === '1'"
                  [sampleAnalyzed]="samples[1].analyzed"
                  [headers]="samplesHeader"
                  [scoreCardModels]="getScoreCardModelHeaders(samples[0])"
                  (itemToEdit)="handleSampleUpdate($event)"
                  (itemDelete)="handleDelete($event)">
                </app-prolabo-analyst-orders-pagination-list-samples>
              </ng-container>
            </div>
          </div>
        </p-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="samplesByType && samplesByType.length == 0">
      <p-card>
        <div class="p-grid spacing-bottom">
          <div class="p-col-12 p-md-8 title">
            <p-header>
              {{ 'SAMPLING' | translate }}
            </p-header>
          </div>
        </div>
        <div class="p-grid">
        </div>
      </p-card>
    </ng-container>
  </div>
</div>
