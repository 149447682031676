<div class="last-orders-container">
  <p-card class="users-card">
    <div class="p-grid large-view">
      <div class="p-col-12 p-sm-8">
        <p class="title">{{ 'LAST_ORDERS' | translate }}</p>
      </div>
      <div class="p-col-12 p-sm-4">
        <app-prolabo-button
          routerLink="/admin/orders"
          [name]="'ALL_ORDERS'"
          type="primary"></app-prolabo-button>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-order-calendar [statistics]="items" [dashboard]="true"></app-prolabo-order-calendar>
      </div>
    </div>
  </p-card>
  <p-card>
    <div class="p-grid">
      <div class="p-col-12">
        <p class="title">{{ 'CRITICAL_DEADLINES' | translate }}</p>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-last-orders-list
          [items]="critical"
          [headers]="headersCritical"
          [critical]="true"
          (filterBy)="filter($event)"
          (filterByDate)="filterByDate($event, 'critical')"
          (sortValue)="sortByLabel($event)">
        </app-prolabo-last-orders-list>
      </div>
    </div>
  </p-card>
</div>
