import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { I18nService, ToastService, UserService } from '../../../../shared/services';
import { User } from '../../../../admin/models/user';
import { FormGroup } from '@angular/forms';
import { InputValidation } from '../../../../models/input-validation';
import { AnalystService } from '../../../services/analyst.service';
import { FunctionsService } from '../../../../admin/services/functions.service';

@Component({
  selector: 'app-analyst-profile',
  templateUrl: './analyst-profile.component.html',
  styleUrls: ['./analyst-profile.component.scss']
})
export class AnalystProfileComponent implements OnInit {
  currentUser: User;
  profileForm: FormGroup;
  profileUpdatedForm: FormGroup;
  validateClicked: boolean;

  constructor(private userService: UserService,
              private toast: ToastService,
              private lang: I18nService,
              private functions: FunctionsService,
              private analystService: AnalystService) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
  }

  handleProfileFormEvent($event: FormGroup) {
    this.profileUpdatedForm = $event;
  }

  handleProfileOriginalFormEvent($event: any) {
    this.profileForm = $event;
  }

  handleValidateCLickedProfile($event: boolean) {
    this.validateClicked = $event;
    setTimeout(() => {
      const promise1 = this.detectChange(this.profileForm, this.profileUpdatedForm.value);
      Promise.all([promise1]).then(promiseResult => {
        const values = [];
        promiseResult.forEach((obj) => {
          values.push(...obj);
        });

        this.analystService.updateProfile(this.buildFormData(values)).subscribe(value => {
          this.toast.showBottomCenterSuccess(value.message);
          this.userService.setCurrentUser(value.user);
        }, error => {
          this.functions.showError(error);
        });
      });
    }, 300);
  }

  detectChange(original, updated) {
    return new Promise<any>((resolve) => {
      const obj = new Array(0);
      for (const key of Object.keys(updated)) {
        if (original[key] !== updated[key]) {
          const value = updated[key];
          obj.push({key, value});
        }
      }
      resolve(obj);
    });
  }

  buildFormData(values: any[]): FormData {
    const formData = new FormData();
    formData.append('locale', this.lang.currentLang);
    if (this.profileUpdatedForm.controls.company) {
      formData.append('company', this.profileUpdatedForm.controls.company.value);
    }
    values.forEach(value => {
      formData.append(value.key, value.value);
    });
    return formData;
  }

  onDataUpdate(val) {
    this.currentUser = this.userService.getCurrentUser();
  }

}
