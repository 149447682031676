import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourcesService } from '../../shared/services/resources.service';
import { AdminRoutesStatics } from '../../admin/statics/admin-routes-statics';
import { ModifyResponsible, ResponsiblesGet } from '../../admin/models/responsible-person';

@Injectable({
  providedIn: 'root'
})
export class ResponsibleService {

  constructor(private http: HttpClient, private resources: ResourcesService) { }

  public createResponsibleUser(body): Observable<ModifyResponsible> {
    return this.http.post<ModifyResponsible>(`${environment.apiUrl}${AdminRoutesStatics.createResponsible}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getResponsibles(body): Observable<ResponsiblesGet> {
    return this.http.post<ResponsiblesGet>(`${environment.apiUrl}${AdminRoutesStatics.getResponsibles}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public deleteResponsibleUser(body): Observable<ModifyResponsible> {
    return this.http.delete<ModifyResponsible>(`${environment.apiUrl}${AdminRoutesStatics.deleteResponsible}`, {
      headers: this.getHeaders(this.resources.getStorageUser().token),
      params: body
    });
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }

}
