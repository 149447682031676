import {APP_INITIALIZER, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {SharedModule} from '../shared/shared.module';
import {AuthGuard} from '../shared/guards';
import {ContainerComponent} from './pages/container/container.component';
import {CommonModule} from '../common/common.module';
import {ProfileComponent} from './pages/profile/profile-main/profile.component';
import {UsersComponent} from './pages/users/users-main/users.component';
import {PaginationListComponent} from '../common/shared/pagination-list/pagination-list.component';
import {PaginatorModule} from 'primeng/paginator';
import {ReactiveFormsModule} from '@angular/forms';
import {ProfileResponsibleComponent} from './pages/profile/profile-responsible/profile-responsible.component';
import {ProfileRoleChangeComponent} from './pages/profile/profile-role-change/profile-role-change.component';
import {ProfileAdministrationComponent} from './pages/profile/profile-administration/profile-administration.component';
import {ProfileFormComponent} from './pages/profile/profile-form/profile-form.component';
import {ClientsComponent} from './pages/clients/clients.component';
import {TooltipModule} from 'primeng/tooltip';
import {UserCreateComponent} from './pages/users/user-create/user-create.component';
import {UserFormComponent} from './pages/users/user-form/user-form.component';
import {ProfileTariffComponent} from './pages/profile/profile-tarrifs/profile-tariff.component';
import {UsersAdministrationComponent} from 'src/app/admin/pages/users/users-administration/users-administration.component';
import {UserRoleChangeComponent} from './pages/users/user-role-change/user-role-change.component';
import {AddClientComponent} from './pages/clients/add-client/add-client.component';
import {OrdersComponent} from './pages/orders/orders-main/orders.component';
import {MobileHeaderComponent} from '../common/shared/mobile-header/mobile-header.component';
import {SidebarModule} from 'primeng/sidebar';
import {MobileSideBarComponent} from '../common/shared/mobile-header/mobile-side-bar/mobile-side-bar.component';
import {
  SimpleOrderContainerComponent
} from './pages/orders/order-types/simple-order/simple-order-container/simple-order-container.component';
import {NewOrderComponent} from './pages/dashboard/new-order/new-order.component';
import {OrderActivitiesComponent} from './pages/dashboard/order-activities/order-activities.component';
import {StepComponent} from './pages/dashboard/order-activities/step/step.component';
import {LastOrdersComponent} from './pages/dashboard/last-orders/last-orders.component';
import {LastOrdersListComponent} from './pages/dashboard/last-orders/list/list.component';
import {CreateOrderComponent} from './pages/orders/modals/create-order-modal/create-order.component';
import {DialogService} from 'primeng/dynamicdialog';
import {SelectClientModalComponent} from 'src/app/admin/pages/orders/modals/select-client-modal/select-client-modal.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {OrdersPaginationListComponent} from './pages/orders/orders-pagination-list/pagination-list.component';
import {SampleCreateModalComponent} from './pages/orders/modals/sample-create-modal/sample-create-modal.component';
// tslint:disable-next-line:max-line-length
import {
  PaginationListDiagnosticSamplesComponent
} from 'src/app/admin/pages/orders/orders-pagination-list-diagnostic-samples/pagination-list-diagnostic-samples.component';
import {StepTwoModalSimpleComponent} from './pages/orders/modals/step-two-modal-simple/step-two-modal-simple.component';
import {CheckboxModule} from 'primeng/checkbox';
import {RippleModule} from 'primeng/ripple';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ScoreCardModalComponent} from './pages/orders/modals/score-card-modal/score-card-modal.component';
import {ScoreListComponent} from './pages/orders/modals/score-card-modal/list/list.component';
// tslint:disable-next-line:max-line-length
import {
  DiagnosticOrderContainerComponent
} from './pages/orders/order-types/diagnostic-order/diagnostic-order-container/diagnostic-order-container.component';
import {DiagnosticPlanModalComponent} from './pages/orders/modals/diagnostic-plan-modal/diagnostic-plan-modal.component';
import {
  DiagnosticOrderAdministrationComponent
} from '../common/orders/diagnostic-order-administration/diagnostic-order-administration.component';
import {DiagnosticOrderReportComponent} from '../common/orders/diagnostic-order-report/diagnostic-order-report.component';
import {
  DiagnosticPlanItemComponent
} from './pages/orders/order-types/diagnostic-order/diagnostic-order-container/diagnostic-plan-item/diagnostic-plan-item.component';
import {
  DiagnosticSampleCreateModalComponent
} from './pages/orders/modals/diagnostic-sample-create-modal/diagnostic-sample-create-modal.component';
import {OrderStepContainerComponent} from '../common/shared/order-step-container/order-step-container.component';
import {OrderStepComponent} from '../common/shared/order-step-container/order-step/order-step.component';
import {
  PaginationListSimpleSamplesComponent
} from './pages/orders/orders-pagination-list-simple-samples/pagination-list-simple-samples.component';
import {StepTwoModalDiagnosticComponent} from './pages/orders/modals/step-two-modal-diagnostic/step-two-modal-diagnostic.component';
import {
  AnalystCreatorPaginationListComponent
} from './pages/analyst-creator/analyst-creator-pagination-list/analyst-creator-pagination-list.component';
import {
  CreateScorecardModalComponent
} from './pages/analyst-creator/analyst-creator-modals/create-scorecard-modal/create-scorecard-modal.component';
import {
  CreateScorecardFieldComponent
} from './pages/analyst-creator/analyst-creator-modals/create-scorecard-field/create-scorecard-field.component';
import {
  CreateScorecardDropdownOptionComponent
} from './pages/analyst-creator/analyst-creator-modals/create-scorecard-dropdown-option/create-scorecard-dropdown-option.component';
import {
  DiagnosticExtraInformationComponent
} from './pages/orders/modals/diagnostic-extra-information/diagnostic-extra-information.component';
import {OrderFinalStepContainerComponent} from './pages/orders/order-final-step-container/order-final-step-container.component';
import {
  StepThreeModalDiagnosticComponent
} from './pages/orders/modals/step-three-diagnostic-modal/step-three-diagnostic-modal.component';
import {NewVersionModalComponent} from './pages/orders/modals/new-version-modal/new-version-modal.component';
import {
  DiagnosticEvaluationModalComponent
} from './pages/orders/modals/diagnostic-evaluation-modal/diagnostic-evaluation-modal.component';
import {TableModule} from 'primeng/table';
import {PreloadingService} from '../shared/services/preloading.service';
import {CorruptedSamplesComponent} from './pages/orders/modals/corrupted-samples/corrupted-samples.component';
import {CalendarModule} from 'primeng/calendar';
import {BasicReportInfoComponent} from './pages/orders/modals/basic-report-info/basic-report-info.component';
import {InvoicesComponent} from './bexio/invoices/invoices.component';
import {RegenerateInvoiceComponent} from './pages/orders/modals/regenerate-invoice/regenerate-invoice.component';
import {InvoicesPaginationListComponent} from './bexio/invoices-pagination-list/pagination-list.component';
import {CalendarComponent} from '../common/shared/calendar/calendar.component';
import {InputNumberModule} from 'primeng/inputnumber';
import {SelectButtonModule} from 'primeng/selectbutton';
import {TariffsComponent} from './pages/tariffs/tariffs.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {UpdateDateModalComponent} from './pages/orders/modals/update-date-modal/update-date-modal.component';
import { FreeOptionComponent } from './pages/orders/modals/free-option/free-option.component';


const routes: Routes = [
  {
    path: `admin`, component: ContainerComponent, canActivate: [AuthGuard], children: [
      {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      {path: 'orders', component: OrdersComponent, canActivate: [AuthGuard]},
      {path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard]},
      {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
      {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
      {path: 'users/create-user/:id', component: UserCreateComponent, canActivate: [AuthGuard]},
      {path: 'users/update-user/:id', component: UserCreateComponent, canActivate: [AuthGuard]},
      {path: 'orders/simple-order/:id', component: SimpleOrderContainerComponent, canActivate: [AuthGuard]},
      {path: 'orders/diagnostic-order/:id', component: DiagnosticOrderContainerComponent, canActivate: [AuthGuard]},
      //   {path: 'clients', component: ClientsComponent, canActivate: [AuthGuard]},
      // {path: 'analyst-creator', component: AnalystCreatorContainerComponent, canActivate: [AuthGuard]},
      {path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard]},
      {path: 'tariffs', component: TariffsComponent, canActivate: [AuthGuard]},
    ]
  },
];

@NgModule({
  declarations: [
    DashboardComponent,
    ContainerComponent,
    PaginationListComponent,
    ProfileComponent,
    UsersComponent,
    ClientsComponent,
    ProfileResponsibleComponent,
    ProfileRoleChangeComponent,
    ProfileAdministrationComponent,
    ProfileFormComponent,
    UserCreateComponent,
    UserFormComponent,
    ProfileTariffComponent,
    UsersAdministrationComponent,
    UserRoleChangeComponent,
    AddClientComponent,
    OrdersComponent,
    MobileHeaderComponent,
    MobileSideBarComponent,
    SimpleOrderContainerComponent,
    MobileSideBarComponent,
    NewOrderComponent,
    OrderActivitiesComponent,
    StepComponent,
    LastOrdersComponent,
    LastOrdersListComponent,
    CreateOrderComponent,
    SelectClientModalComponent,
    OrdersPaginationListComponent,
    SampleCreateModalComponent,
    PaginationListDiagnosticSamplesComponent,
    PaginationListSimpleSamplesComponent,
    StepTwoModalSimpleComponent,
    StepTwoModalDiagnosticComponent,
    ScoreCardModalComponent,
    ScoreListComponent,
    OrderStepContainerComponent,
    OrderStepComponent,
    DiagnosticOrderContainerComponent,
    DiagnosticPlanModalComponent,
    DiagnosticOrderReportComponent,
    DiagnosticOrderAdministrationComponent,
    DiagnosticPlanItemComponent,
    DiagnosticSampleCreateModalComponent,
    AnalystCreatorPaginationListComponent,
    CreateScorecardModalComponent,
    CreateScorecardFieldComponent,
    CreateScorecardDropdownOptionComponent,
    DiagnosticExtraInformationComponent,
    OrderFinalStepContainerComponent,
    StepThreeModalDiagnosticComponent,
    NewVersionModalComponent,
    DiagnosticEvaluationModalComponent,
    CorruptedSamplesComponent,
    BasicReportInfoComponent,
    InvoicesComponent,
    RegenerateInvoiceComponent,
    InvoicesPaginationListComponent,
    TariffsComponent,
    UpdateDateModalComponent,
    FreeOptionComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    CommonModule,
    PaginatorModule,
    TooltipModule,
    SidebarModule,
    RadioButtonModule,
    CheckboxModule,
    RippleModule,
    InputTextareaModule,
    TableModule,
    CalendarModule,
    InputNumberModule,
    SelectButtonModule,
    MultiSelectModule
  ],
  providers: [
    DialogService,
    {provide: APP_INITIALIZER, useFactory: preloadServiceFactory, deps: [PreloadingService], multi: true},

  ],
  exports: [
    MobileHeaderComponent,
    OrderStepContainerComponent,
    AnalystCreatorPaginationListComponent,
    PaginationListSimpleSamplesComponent,
    OrderFinalStepContainerComponent,
    PaginationListDiagnosticSamplesComponent,
    DiagnosticOrderReportComponent,
    DiagnosticOrderAdministrationComponent,
    LastOrdersListComponent
  ],
})
export class AdminModule {
}

export function preloadServiceFactory(service: PreloadingService) {
  return () => service.load();
}
