import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services';
import { User } from '../../models/user';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { Price } from '../../../models/laboratorie';
import { FunctionsService } from '../../services/functions.service';

@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.scss']
})
export class TariffsComponent implements OnInit {
  public currentUser: User;
  public prices: Price[];

  constructor(private userService: UserService,
              private functions: FunctionsService,
              private dashboardService: AdminDashboardService) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.dashboardService.getPrices().subscribe(x => {
      this.prices = x;
    }, error => this.functions.showError(error));


  }

  priceChanged($event: any) {
    this.dashboardService.getPrices().subscribe(x => {
      this.prices = x;
    }, error => this.functions.showError(error));
  }
}
