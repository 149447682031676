import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Role, User } from 'src/app/models';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { AdminUsersService } from 'src/app/admin/services/admin-users.service';
import { I18nService, ToastService } from 'src/app/shared/services';
import { InputValidation } from 'src/app/models/input-validation';

@Component({
  selector: 'app-prolabo-user-role-change',
  templateUrl: './user-role-change.component.html',
  styleUrls: ['./user-role-change.component.scss']
})
export class UserRoleChangeComponent implements OnInit, OnChanges {

  @Input() user: User;
  @Input() state: string;
  @Input() validationClicked = false;

  roles: DropdownModel[] = [];
  roleForm: FormGroup;
  roless: Role[];
  @Output() roleFormEvent = new EventEmitter<FormGroup>();

  constructor(private translate: TranslateService,
              private resources: ResourcesService,
              private adminService: AdminUsersService,
              private formBuilder: FormBuilder,
              private toast: ToastService,
              private lang: I18nService
  ) {
  }

  ngOnInit(): void {
    this.roless = this.resources.getPlatformRoles();
    if (this.roless) {
      this.setRoles(this.roless);
    }
    this.roleForm = this.formBuilder.group({
      role_id: [this.user.role_id, [Validators.required]]
    });
  }

  updateRoleValue(value) {
    if (this.state === 'create') {
      this.roleForm.patchValue({
        role_id: value
      });
      this.roleFormEvent.emit(this.roleForm);
    } else if (this.state === 'update') {
      const formData = new FormData();
      formData.append('role_id', value);
      formData.append('locale', this.lang.currentLang);
      formData.append('id', this.user.id.toString());

      this.adminService.updateUser(formData).subscribe(result => {
        this.toast.showBottomCenterSuccess(result.message);
      }, error => {
        this.toast.showBottomCenterError(error.message);
      });
      this.roleForm.patchValue({
        role_id: value
      });
    }

  }

  checkObj(error: string): void {
    if (error) {
      this.toast.showBottomCenterError(error);
    }
  }

  private setRoles(roles) {
    roles.forEach(role => {
      const name = this.translate.instant(role.role.toUpperCase());
      this.roles.push({id: role.id, text: name, value: role.id});
    });
  }

  showError(error: InputValidation) {
    this.checkObj(error.locale);
    this.checkObj(error.role_id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validationClicked) {
      setTimeout(() => {
        if (this.validationClicked && this.state === 'create') {
          this.roleFormEvent.emit(this.roleForm);
        }
      }, 0);
    }
  }
}
