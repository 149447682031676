import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StorageUser} from './models';
import {AuthGuard} from './shared/guards';

const storageUser: StorageUser = JSON.parse(localStorage.getItem('prolaboUser'));

const routes: Routes = [
  {path: '', redirectTo: `${storageUser?.role}/dashboard`, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: '**', redirectTo: `${storageUser?.role}/dashboard`},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
