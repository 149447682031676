import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Client} from 'src/app/admin/models/client';
import {ToastService, UserService} from 'src/app/shared/services';
import {User} from 'src/app/models';
import {ResponsiblePerson} from 'src/app/admin/models/responsible-person';
import {DropdownModel} from 'src/app/shared/components/dropdown/dropdown.model';
import {forkJoin} from 'rxjs';
import {AdminSimpleOrdersService} from 'src/app/admin/services/admin-simple-orders.service';
import {ResourcesService} from '../../../shared/services/resources.service';
import {AdminClientsService} from '../../../admin/services/admin-clients.service';
import {OrderOwner, SimpleOrder} from '../../../admin/models/simple-order-response';
import {ResponsibleService} from '../../services/responsible.service';
import {FunctionsService} from '../../../admin/services/functions.service';
import {AdminStatics} from '../../../admin/statics/admin-statics';

@Component({
  selector: 'app-prolabo-simple-order-administration',
  templateUrl: './simple-order-administration.component.html',
  styleUrls: ['./simple-order-administration.component.scss']
})
export class SimpleOrderAdministrationComponent implements OnInit, OnChanges {
  @Input() submitClicked = false;
  @Input() order: SimpleOrder;
  @Input() isUser: boolean;
  @Input() user = false;
  @Input() laboratories: DropdownModel[];
  @Input() invoiceTypes: DropdownModel[];
  disableFields: boolean;
  orderForm: FormGroup;
  iconClicked: boolean;
  clients: Client[];
  value: string;
  currentUser: User;
  responsibles: DropdownModel[] = [];
  users: DropdownModel[] = [];
  address = ' ';
  client: Client;
  editAddressState = false;
  siteValue: string;
  subtypeValue: string;
  responsiblesLoaded = false;

  @Output() clientEvent = new EventEmitter<Client>();
  @Output() orderFormEvent = new EventEmitter<FormGroup>();
  @Output() imageEvent = new EventEmitter<any>();
  @Output() orderEvent = new EventEmitter<string[]>();
  @Output() requestGetOrder = new EventEmitter<any>();

  searchParams: any;
  limit = 10000;
  offset = 0;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private responsibleService: ResponsibleService,
              private clientsService: AdminClientsService,
              private ordersService: AdminSimpleOrdersService,
              private toast: ToastService,
              private functions: FunctionsService,
              private resources: ResourcesService) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    const body = {
      user_id: this.order.user_id,
      locale: this.resources.getLanguage().description
    };
    this.disableFields = ((this.currentUser.role_id === 3 || this.currentUser.role_id === 5) && this.order.latest_state_id > 8);
    this.orderForm = this.formBuilder.group({
      responsible_id: ['', Validators.required],
      laboratory_id: ['', Validators.required],
      site: ['', Validators.required],
      invoicing_address: ['', Validators.required],
      invoice_type_id: [''],
      postal_code: ['', Validators.required],
      department: [''],
      contact_person: [''],
      city: ['', Validators.required],
      remarks: [''],
      company: [''],
      user_id: ['', Validators.required]
    });
    this.getUsers();

    const responsibles = this.responsibleService.getResponsibles(body);
    forkJoin([responsibles]).subscribe(result => {
        this.setResponsibles(result[0].responsibles);
        this.responsiblesLoaded = true;
        if (this.order.invoice_address.length > 0) {
          this.orderForm.patchValue({
            invoicing_address: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.address,
            postal_code: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.postal_code,
            city: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.city,
            company: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.company,
            department: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.department,
            contact_person: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.contact_person
          });
        }
        if (this.order.responsible_id) {
          this.orderForm.patchValue({
            responsible_id: this.order.responsible_id
          });
        } else {
          this.orderForm.patchValue({
            responsible_id: this.responsibles[0].id
          });
        }
        if (this.order.laboratory_id) {
          this.orderForm.patchValue({
            laboratory_id: this.order.laboratory_id
          });
        } else {
          this.orderForm.patchValue({
            laboratory_id: this.laboratories[0].id
          });
        }
        if (this.order.site) {
          this.siteValue = this.order.site;
          this.orderForm.patchValue({
            site: this.order.site,
          });
        }
        if (this.order.user) {
          this.orderForm.patchValue({
            user_id: this.order.user_id,
          });
        }
        if (this.order.monthly_invoice) {
          if (this.order.monthly_invoice === 1) {
            this.orderForm.patchValue({
              invoice_type_id: 1,
            });
          } else {
            this.orderForm.patchValue({
              invoice_type_id: 2,
            });
          }
        }
      }
      , error => {
        this.functions.showError(error);
      });
  }

  getResponsibles($event) {
    const body = {
      user_id: $event,
      locale: this.resources.getLanguage().description
    };
    this.responsibleService.getResponsibles(body).subscribe(x => {
      this.setResponsibles(x.responsibles);
      if (this.order.responsible_id) {
        this.orderForm.patchValue({
          responsible_id: this.order.responsible_id
        });
      } else {
        this.orderForm.patchValue({
          responsible_id: this.responsibles[0].id
        });
      }
    }, error => this.functions.showError(error));
  }

  updateResponsibleValue($event: string) {
    this.orderForm.patchValue({
      responsible_id: $event
    });
    this.onValueChange($event, 'responsible_id');
  }

  updateLaboratoryId($event: string) {
    this.orderForm.patchValue({
      laboratory_id: $event
    });
    this.onValueChange($event, 'laboratory_id');
  }

  getUsers() {
    if (!this.isUser) {
      const params: any = {
        locale: this.resources.getLanguage().description,
        order_id: this.order.id
      };

      if (this.searchParams) {
        params.search = this.searchParams;
      }

      // if this limit is removed, the default pagination from the API is applied
      params[AdminStatics.limit] = this.limit;
      params[AdminStatics.offset] = this.offset;
      this.ordersService.getUsers(params).subscribe(result => {
        this.setUsers(result.users);
      });
    }
  }

  updateUserId($event: string) {
    this.orderForm.patchValue({
      user_id: $event
    });
    this.onValueChange($event, 'user_id');
  }

  onFilter($event) {
    const message = $event.originalEvent.path[3].innerText.toString();
    if (message.includes('No results found')) {
      this.searchParams = $event.filter;
      this.getUsers();
    } else if ($event.filter == null) {
      this.searchParams = null;
      this.getUsers();
    }
  }

  updateTypeValue($event: string) {
    this.orderForm.patchValue({
      order_subtype: $event
    });
  }

  private setResponsibles(responsibles: ResponsiblePerson[]) {
    this.responsibles = new Array(0);
    responsibles.forEach(responsible => {
      this.responsibles.push({
        id: responsible.id,
        text: responsible.first_name + ' ' + responsible.last_name,
        value: responsible.id
      });
    });
  }

  private setUsers(users: OrderOwner[]) {
    this.users = new Array(0);
    users.forEach(user => {
      this.users.push({
        id: user.id,
        text: this.setUser(user),
        value: user.id
      });
    });
  }

  setUser(user: OrderOwner) {
    if (user.is_company === 1) {
      return user.first_name + ' ' + user.last_name + ' | ' + user.company;
    } else {
      return user.first_name + ' ' + user.last_name;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submitClicked) {
      if (this.submitClicked) {
        if (this.orderForm.valid) {
          this.orderEvent.emit([]);
          this.orderFormEvent.emit(this.orderForm);
          this.submitClicked = false;
        } else {
          this.orderEvent.emit(this.getErrorFieldsFromAdministrationForm(this.orderForm));
          this.submitClicked = false;
        }
      }
    }
    if (changes.order) {
      this.currentUser = this.userService.getCurrentUser();
      this.disableFields = ((this.currentUser.role_id === 3 || this.currentUser.role_id === 5) && this.order.latest_state_id > 8);
    }
  }

  onValueChange($event: any, field: string) {
    const body = this.getOnChangeBody($event, field);
    this.ordersService.updateOrder(this.toFormData(body)).subscribe(result => {
      if (field === 'user_id') {
        this.getResponsibles($event);
        this.requestGetOrder.emit();
        this.orderForm.patchValue({
          invoicing_address: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.address,
          postal_code: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.postal_code,
          city: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.city,
          company: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.company,
          department: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.department,
          contact_person: this.order.invoice_address[(this.order.invoice_address.length - 1)]?.contact_person,
        });
      }
      if (field === 'laboratory_id' || field === 'responsible_id' || field === 'site') {
        this.requestGetOrder.emit();
      }
      this.toast.showBottomCenterSuccess('');
    }, error => {
      this.functions.showError(error);
    });
  }

  private toFormData(formValue): FormData {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }
    return formData;
  }

  private getErrorFieldsFromAdministrationForm(orderForm: FormGroup): string[] {
    const errorFields = new Array(0);
    if (orderForm.controls.site.status === 'INVALID') {
      errorFields.push('site'.toUpperCase());
    }
    if (orderForm.controls.invoicing_address.status === 'INVALID') {
      errorFields.push('invoicing_address'.toUpperCase());
    }
    if (orderForm.controls.postal_code.status === 'INVALID') {
      errorFields.push('postal_code'.toUpperCase());
    }
    if (orderForm.controls.city.status === 'INVALID') {
      errorFields.push('city'.toUpperCase());
    }
    return errorFields;
  }

  private getOnChangeBody(value, field) {
    switch (field) {
      case 'invoicing_address':
        return {
          [field]: value,
          postal_code: this.orderForm.controls.postal_code.value,
          city: this.orderForm.controls.city.value,
          order_id: this.order.id,
          locale: this.resources.getLanguage().description,
          department: this.orderForm.controls.department.value,
          contact_person: this.orderForm.controls.contact_person.value
        };
      case 'city':
        return {
          invoicing_address: this.orderForm.controls.invoicing_address.value,
          postal_code: this.orderForm.controls.postal_code.value,
          [field]: value,
          order_id: this.order.id,
          company: this.orderForm.controls.company.value,
          locale: this.resources.getLanguage().description,
          department: this.orderForm.controls.department.value,
          contact_person: this.orderForm.controls.contact_person.value
        };
      case 'postal_code':
        return {
          invoicing_address: this.orderForm.controls.invoicing_address.value,
          [field]: value,
          city: this.orderForm.controls.city.value,
          order_id: this.order.id,
          company: this.orderForm.controls.company.value,
          locale: this.resources.getLanguage().description,
          department: this.orderForm.controls.department.value,
          contact_person: this.orderForm.controls.contact_person.value
        };
      case 'department':
        return {
          invoicing_address: this.orderForm.controls.invoicing_address.value,
          [field]: value !== '' ? value : null,
          postal_code: this.orderForm.controls.postal_code.value,
          city: this.orderForm.controls.city.value,
          order_id: this.order.id,
          company: this.orderForm.controls.company.value,
          locale: this.resources.getLanguage().description,
          contact_person: this.orderForm.controls.contact_person.value
        };
      case 'contact_person':
        return {
          invoicing_address: this.orderForm.controls.invoicing_address.value,
          [field]: value !== '' ? value : null,
          postal_code: this.orderForm.controls.postal_code.value,
          city: this.orderForm.controls.city.value,
          order_id: this.order.id,
          company: this.orderForm.controls.company.value,
          locale: this.resources.getLanguage().description,
          department: this.orderForm.controls.department.value
        };
      case 'company':
        return {
          invoicing_address: this.orderForm.controls.invoicing_address.value,
          [field]: value,
          city: this.orderForm.controls.city.value,
          order_id: this.order.id,
          postal_code: this.orderForm.controls.postal_code.value,
          locale: this.resources.getLanguage().description,
          department: this.orderForm.controls.department.value,
          contact_person: this.orderForm.controls.contact_person.value
        };
      case 'site':
        return {
          [field]: value.target.value,
          order_id: this.order.id,
          locale: this.resources.getLanguage().description
        };
      default:
        return {
          [field]: value,
          order_id: this.order.id,
          locale: this.resources.getLanguage().description
        };
    }
  }

  onChange($event: any) {
  }

  updateInvoiceTypes($event: string) {
    if (this.order.monthly_invoice === 1) {
      this.orderForm.patchValue({
        invoice_type_id: 1,
      });
    } else {
      this.orderForm.patchValue({
        invoice_type_id: 2,
      });
    }
    this.onValueChange($event, 'invoice_type_id');
  }
}
