<p-card [style]="{'margin-top': '20px'}" *ngIf="laboratories.length >0">
  <div class="p-grid spacing-bottom">
    <div class="p-col-6 title">
      <p-header>{{'ADMINISTRATION' | translate}}</p-header>
    </div>
  </div>
  <form [formGroup]="administrationForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12">
        <app-prolabo-dropdown [options]="invoiceTypes"
                              (OnValueChange)="updateInvoiceTypes($event)"
                              [defaultValue]="currentUser.invoice_type_id"
                              placeholder="INVOICE_TYPE"></app-prolabo-dropdown>
      </div>
    </div>
  </form>
  <div class="p-grid">
    <div class="p-col-4">
      <app-prolabo-button
        type="primary"
        [name]="'VIEW_LABORATORIES'"
        (click)="viewLaboratories()"></app-prolabo-button>
    </div>
    <div class="p-col-4"></div>
    <div class="p-col-4">
      <app-prolabo-icon-button
        type="secondary"
        [icon]="'check'"
        [name]="'VALIDATE'"
        (click)="validate()"></app-prolabo-icon-button>
    </div>
  </div>
</p-card>
