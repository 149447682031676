<div class="orders-container">
  <p-card class="orders-card">
    <div class="p-grid">
      <div class="p-col-12 p-sm-3">
        <app-prolabo-icon-button
          [name]="'NEW_ORDER'"
          type="primary"
          (click)="createOrder()"
          [icon]="'add'"></app-prolabo-icon-button>
      </div>
      <div class="p-col-12 p-sm-9">
        <app-prolabo-icon-input
          [keyword]="'RESEARCH'"
          [icon]="'search'"
          (OnValueChange)="onSearchParamChange($event)"></app-prolabo-icon-input>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-analyst-orders-pagination-list
          [items]="orders"
          [headers]="orderHeaders"
          [total]="totalOrders"
          [itemValues]="samples"
          [totalItems]="totalSamples"
          [filter]="stepParam"
          [laboratories]="laboratories"
          [filterItems]="sampleTypes"
          (filterBy)="filterBySample($event)"
          (filterDelete)="deleteParam($event)"
          (limitValue)="changeLimit($event)"
          (pageValue)="changeOffset($event)"
          (filterByOrderState)="handleFilterByOrderState($event)"
          (filterByOrderCity)="handleFilterByOrderCity($event)"
          (sortValue)="sortByLabel($event)"></app-prolabo-analyst-orders-pagination-list>
      </div>
    </div>
  </p-card>
</div>
