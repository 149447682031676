// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appTitle: 'Analysis',
  envName: 'PROD',
  apiUrl: 'https://api.analysis-lab.preview-tokiwi.ch/api',
  adminApiUrl: 'https://api.analysis-lab.preview-tokiwi.ch/api/v1/admin/',
  defaultLanguage: 'fr',
  defaultLanguageObj: { id: 3, language: 'French', description: 'fr' },
  availableLanguages: ['en', 'fr', 'it', 'ge'],
  availableLanguageCodes: ['en-CH', 'fr-CH', 'it-CH', 'ge-CH'],
  defaultHomepageUrl: 'https://analysis-lab.preview-tokiwi.ch/',
  storageUrl: 'https://analysis-lab.s3-eu-central-1.amazonaws.com/',
  bexioUrl: 'https://api.bexio.com/'
};
