import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-regenerate-invoice',
  templateUrl: './regenerate-invoice.component.html',
  styleUrls: ['./regenerate-invoice.component.scss']
})
export class RegenerateInvoiceComponent implements OnInit {
  message: string;

  constructor(private dialogConfig: DynamicDialogConfig, public dialogRef: DynamicDialogRef) { }

  ngOnInit(): void {
    this.message = this.dialogConfig.data;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  submit() {
    this.dialogRef.close(true);
  }
}
