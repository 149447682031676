import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AdminRoutesStatics } from '../../admin/statics/admin-routes-statics';
import { ResourcesService } from './resources.service';
import { Calendar, CalendarResult } from '../../models/Calendar';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private http: HttpClient, private resources: ResourcesService) { }

  public getCalendarAdmin(body): Observable<CalendarResult> {
    return this.http.post<CalendarResult>(`${environment.adminApiUrl}${AdminRoutesStatics.getCalendar}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)}
    )
      ;
  }

  public getCalendarAnalyst(body): Observable<CalendarResult> {
    return this.http.post<CalendarResult>(`${environment.adminApiUrl}${AdminRoutesStatics.getCalendarAnalyst}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }
}
