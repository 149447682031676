import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models';
import { I18nService, ToastService, UserService } from 'src/app/shared/services';
import { FormGroup } from '@angular/forms';
import { InvoiceType, Laboratory, Price } from 'src/app/models/laboratorie';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { AdminDashboardService } from '../../../services/admin-dashboard.service';
import { forkJoin } from 'rxjs';
import { FunctionsService } from '../../../services/functions.service';
import { ResourcesService } from '../../../../shared/services/resources.service';

@Component({
  selector: 'app-prolabo-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  currentUser: User;
  validateClicked: boolean;

  administrationForm: any;
  administrationUpdatedForm: FormGroup;
  profileForm: any;
  profileUpdatedForm: FormGroup;
  file: File;
  laboratories: DropdownModel[] = [];
  invoiceTypes: DropdownModel[] = [];
  prices: Price[];

  constructor(private userService: UserService,
              private toast: ToastService,
              private lang: I18nService,
              private functions: FunctionsService,
              private resources: ResourcesService,
              private dashboardService: AdminDashboardService) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    const laboratories = this.dashboardService.getLaboratories();
    const prices = this.dashboardService.getPrices();
    forkJoin([laboratories]).subscribe(result => {
      this.setLaboratories(result[0].laboratories);
     // this.prices = result[1];
    });
    if (this.resources.getInvoiceTypes()) {
      this.setInvoiceTypes(this.resources.getInvoiceTypes());
    } else {
      this.setInvoiceTypes(this.resources.backupInvoiceTypes);
    }
  }

  onDataUpdate() {
    this.getUser().then(user => {
      this.currentUser = user;
      this.userService.setCurrentUser(user);
    });
  }

  getUser(): Promise<User> {
    return new Promise<User>((resolve) => {
      const body = {
        locale: this.lang.currentLang
      };
      this.dashboardService.getProfileData(body).subscribe(result => {
        resolve(result.user);
      }, error => {
        resolve(error);
      });
    });
  }

  handleAdministrationForm($event: FormGroup) {
    this.administrationUpdatedForm = $event;
  }

  handleValidateClicked($event: boolean) {
    this.validateClicked = $event;
  }

  handleProfileFormEvent($event: FormGroup) {
    this.profileUpdatedForm = $event;
  }

  handleAdministrationOriginalForm($event: any) {
    this.administrationForm = $event;
  }

  handleProfileOriginalFormEvent($event: any) {
    this.profileForm = $event;
  }

  handleValidateCLickedProfile($event: boolean) {
    this.validateClicked = $event;
    setTimeout(() => {
      const promise1 = this.detectChange(this.profileForm, this.profileUpdatedForm.value);
      const promise2 = this.detectChange(this.administrationForm, this.administrationUpdatedForm.value);
      Promise.all([promise1, promise2]).then(promiseResult => {
        const values = [];
        promiseResult.forEach((obj) => {
          values.push(...obj);
        });
        this.dashboardService.updateProfile(this.buildFormData(values)).subscribe(value => {
          value.user.invoice_type_id = +value.user.invoice_type_id;
          this.userService.setCurrentUser(value.user);
          this.toast.showBottomCenterSuccess(value.message);
          this.userService.setCurrentUser(value.user);
        }, error => {
          this.functions.showError(error);
        });
      });
    }, 100);
  }

  detectChange(original, updated) {
    return new Promise<any>((resolve) => {
      const obj = new Array(0);
      for (const key of Object.keys(updated)) {
        if (original[key] !== updated[key]) {
          const value = updated[key];
          obj.push({key, value});
        }
      }
      resolve(obj);
    });
  }

  buildFormData(values: any[]): FormData {
    const formData = new FormData();
    formData.append('locale', this.lang.currentLang);
    if (this.profileUpdatedForm.controls.company) {
      formData.append('company', this.profileUpdatedForm.controls.company.value);
    }
    values.forEach(value => {
      formData.append(value.key, value.value);
    });
    return formData;
  }

  private setLaboratories(laboratories: Laboratory[]) {
    laboratories.forEach(laboratory => {
      this.laboratories.push({id: laboratory.id, text: laboratory.city, value: laboratory.id});
    });
  }

  private setInvoiceTypes(invoiceTypes: InvoiceType[]) {
    invoiceTypes.forEach(invoiceType => {
      this.invoiceTypes.push({id: invoiceType.id, text: invoiceType.slug + '_PROFILE', value: invoiceType.id});
    });
  }

  priceChangedEventHandler($event: any) {
    this.dashboardService.getPrices().subscribe(x => {
      this.prices = x;
    });
  }
}
