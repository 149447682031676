<button pButton
        type="button"
        (click)="click($event)"
        [disabled]="disabled"
        label="{{!loading ? (name | translate) : '  '}}"
        class="p-button {{ type ? type : 'primary' }} {{ class ? class : null }}">
        <ng-container *ngIf="position !== 'left'">
                <span class="icon {{ position ? 'right-arrow' : '' }}">
                        <img src="../../../../assets/icons/{{icon}}.svg"/>
                </span>
                <span *ngIf="loading">
                  <p-progressSpinner class="custom-btn-spinner"></p-progressSpinner>
                </span>
        </ng-container>
        <ng-container *ngIf="position === 'left'">
                      <span class="left-icon">
                        <img src="../../../../assets/icons/{{icon}}.svg"/>
                      </span>
        </ng-container>
</button>
