import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../../models/user';
import {ToastService, UserService} from '../../../../../shared/services';
import {AdminClientsService} from '../../../../services/admin-clients.service';
import {AdminSimpleOrdersService} from '../../../../services/admin-simple-orders.service';
import {ResourcesService} from '../../../../../shared/services/resources.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../../environments/environment';
import { ResponsibleService } from '../../../../../common/services/responsible.service';

@Component( {
  selector: 'app-prolabo-diagnostic-plan',
  templateUrl: './diagnostic-plan-modal.component.html',
  styleUrls: [ './diagnostic-plan-modal.component.scss' ]
} )
export class DiagnosticPlanModalComponent implements OnInit {

  planForm: FormGroup;
  currentUser: User;
  imageSrc;

  plan = null;
  update = false;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private responsibleService: ResponsibleService,
              private clientsService: AdminClientsService,
              private ordersService: AdminSimpleOrdersService,
              private toast: ToastService,
              private translate: TranslateService,
              private resources: ResourcesService,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.update = this.config.data.update;
    this.currentUser = this.userService.getCurrentUser();

    this.planForm = this.formBuilder.group({
      order_id: [this.config.data.order_id, Validators.required],
      name: ['', Validators.required],
      plan: ['', Validators.required],
      locale: [this.resources.getLanguage().description, Validators.required]
    });

    if (this.update) {
      this.plan = this.config.data.plan;
      this.imageSrc = `${environment.storageUrl}${this.plan.path}`;
      const extension = this.imageSrc.substr(this.imageSrc.length - 3);
      if (extension !== 'jpg' && extension !== 'png') {
        this.imageSrc = null;
      }
      setTimeout(() => {
        this.setCoordinates();
      }, 500);
    }
  }

  valueChange(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: ProgressEvent) => {
        this.imageSrc = reader.result;
        const extension = this.imageSrc.substr(this.imageSrc.length - 3);
        if (extension !== 'jpg' && extension !== 'png') {
          this.imageSrc = null;
        }
        const file = new File([this.dataURLToBlob((event.target as FileReader).result)],
          'plan', {type: `image/jpeg`});
        this.planForm.patchValue({
          plan: file
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  submit() {
    if (!this.planForm.valid) {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    } else {
      this.ref.close(this.toFormData(this.planForm.value));
    }
  }

  close() {
    this.ref.close();
  }

  private setCoordinates() {
    const photoDiv = document.getElementById('photo');
    if (this.plan.diagnostic.length > 0 && this.imageSrc) {
      this.plan.diagnostic.forEach(sample => {
        sample.coordinates.forEach(coordinates => {
          const circle = this.createCircle(coordinates.coordinate_x, coordinates.coordinate_y, sample.sample);
          photoDiv.appendChild(circle);
        });
      });
    }
  }

  private createCircle(sampleX, sampleY, sample) {
    const imgSize = document.getElementById('plan-img') as HTMLImageElement;
    const x = (imgSize.width / this.plan.width) * sampleX;
    const y = (imgSize.height / this.plan.height) * sampleY;

    const circle = document.createElement('div');
    circle.style.display = 'block';
    circle.style.position = 'absolute';
    circle.style.width = '40px';
    circle.style.height = '40px';
    circle.style.textAlign = 'center';
    circle.style.borderRadius = '50%';
    if (sample.collect_method_id === 3) {
      circle.style.backgroundColor = '#FF5757';
    } else {
      circle.style.backgroundColor = '#C3D800';
    }
    circle.style.fontFamily = 'Nunito-SemiBold';
    circle.style.color = 'white';
    circle.style.left = x + 'px';
    circle.style.top = y + 'px';

    const span = document.createElement('span');
    span.style.marginTop = '9px';
    span.style.display = 'flex';
    span.style.justifyContent = 'center';

    circle.appendChild(span);
    span.innerText = sample.sample_number;

    return circle;
  }

  private toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }

    return formData;
  }
}
