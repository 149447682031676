<div class="container">
  <app-mobile-header stockRoute="user"></app-mobile-header>
  <div class="p-grid">
    <div class="p-col-12 content-container">
      <div class="p-sm-fixed side-bar">
        <app-side-bar
          stockRoute="user"
          [sidebarItems]="sidebarItems"
          [title]="user?.first_name + ' ' + user?.last_name"
          (billingClicked)="billingClicked($event)"
          [pinRequested]="user.pin_protect"></app-side-bar>
      </div>
      <div class="p-grid">
        <div class="p-col-12 p-sm-8">
          <div class="breadcrumbs"
               *ngIf="showBreadcrumbs">
            <app-breadcrumbs
              [first]="firstBreadcrumb"
              [second]="secondBreadcrumb"
              [third]="thirdBreadcrumb"
              [state]="orderState"
              [logout]="logout"
              stockRoute="user"
              (logoutClicked)="handleLogoutClicked($event)"></app-breadcrumbs>
          </div>
          <div class="title-container" *ngIf="!showBreadcrumbs && showTitle">
            <p-header class="title">{{ currentPageLoaded?.name | translate }}</p-header>
          </div>
        </div>
        <div class="p-col-12 p-sm-4">
          <div class="p-grid pdng-top">
            <div class="p-col-6">
              <ng-container *ngIf="search">
                <app-prolabo-icon-input
                  [keyword]="'RESEARCH'"
                  [icon]="'search'"
                  (OnValueChange)="onSearchParamChange($event)"></app-prolabo-icon-input>
              </ng-container>
            </div>
            <div class="p-col-1">
            </div>
            <div class="p-col-3 languages">
              <app-prolabo-language-dropdown [options]="languages"
                                             (OnValueChange)="updateLanguageValue($event)"
                                             [defaultValue]="currentLang?.id"></app-prolabo-language-dropdown>
            </div>
            <div class="p-col-2 profile" style="padding-right: 0">
              <img routerLink="/user/profile" class="profile-image"
                   src="../../../../assets/icons/pharmacy.svg" alt="profile">
            </div>
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-12">
          <div class="content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [header]="'PIN' | translate" [(visible)]="displayResponsive" [style]="{width: '50vw', background: 'white; !important'}"
          [draggable]="false" [resizable]="false">
  <form [formGroup]="pinForm">
    <div class="p-grid">
      <div class="p-col-2">
        <input id="p1" type="password" maxlength="1" pInputText (change)="itemChange($event)" class="myinput"
               (keyup)="onChange('p1', 'p2')" formControlName="p1">
      </div>
      <div class="p-col-2">
        <input id="p2" type="password" maxlength="1" pInputText (change)="itemChange($event)" class="myinput"
               (keyup)="onChange('p2', 'p3')" formControlName="p2">
      </div>
      <div class="p-col-2">
        <input id="p3" type="password" maxlength="1" pInputText (change)="itemChange($event)" class="myinput"
               (keyup)="onChange('p3', 'p4')" formControlName="p3">
      </div>
      <div class="p-col-2">
        <input id="p4" type="password" maxlength="1" pInputText (change)="itemChange($event)" class="myinput"
               (keyup)="onChange('p4', 'p5')" formControlName="p4">
      </div>
      <div class="p-col-2">
        <input id="p5" type="password" maxlength="1" pInputText (change)="itemChange($event)" class="myinput"
               (keyup)="onChange('p5', 'p6')" formControlName="p5">
      </div>
      <div class="p-col-2">
        <input id="p6" type="password" maxlength="1" pInputText (change)="itemChange($event)" class="myinput"
               formControlName="p6">
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <app-prolabo-button
      type="primary"
      [name]="'SUBMIT'"
      (click)="submit()"></app-prolabo-button>
  </ng-template>
</p-dialog>


