import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourcesService } from '../../shared/services/resources.service';
import {
  SimpleSample,
  SimpleSampleGetResponse,
  SimpleSampleModifyResponse,
  SimpleSampleUpdateDeleteResponse
} from '../models/simple-order-response';
import { AdminRoutesStatics } from '../statics/admin-routes-statics';
import { SampleDiagnosticUpdateDeleteResponse } from '../models/diagnostic-order-response';

@Injectable({
  providedIn: 'root'
})
export class AdminSimpleSamplesService {

  constructor(private http: HttpClient, private resources: ResourcesService) { }

  public createSample(body): Observable<SimpleSampleGetResponse> {
    return this.http.post<SimpleSampleGetResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.createSimpleSample}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public updateSample(body): Observable<SimpleSampleUpdateDeleteResponse> {
    return this.http.post<SimpleSampleUpdateDeleteResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.updateSimpleSample}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public deleteSample(body): Observable<SimpleSampleUpdateDeleteResponse> {
    return this.http.delete<SimpleSampleUpdateDeleteResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.deleteSimpleSample}`,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
        params: body
      });
  }

  public updateSampleType(body): Observable<SimpleSampleModifyResponse> {
    return this.http.post<SimpleSampleModifyResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.modifySampleId}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public updateSampleRegular(body): Observable<SimpleSample> {
    return this.http.post<SimpleSample>
    (`${environment.adminApiUrl}${AdminRoutesStatics.updateSimpleSample}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public checkSampleNumber(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.checkSampleNumber}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public splitSample(body): Observable<any>{
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.splitSample}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }

  private getMultiPartHeader(token): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }
}
