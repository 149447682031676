import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoginResponse, StorageUser, User } from '../../models';
import { ResourcesService } from './resources.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public static ADMIN = 'Admin';
  public static USER = 'User';
  public static ANALYST = 'Analyst';

  private roles: string[] = [UserService.ADMIN, UserService.ANALYST, UserService.USER];
  private currentUserRoles: string[] = [];
  private readonly userLocale: string;
  private user: User;
  private isAuth = false;
  private readonly accessToken: string = null;
  private storageUser: StorageUser;

  public locale(): string {
    return this.userLocale;
  }

  public currentRoles(): string[] {
    return this.currentUserRoles;
  }

  public hasRole(role: string) {
    return (this.currentUserRoles.indexOf(role) > -1);
  }

  public currentUser(): User {
    let storageUser: User;
    const storageUserString = localStorage.getItem('prolaboUser');
    try {
      storageUser = JSON.parse(storageUserString);
    } catch (e) {
      storageUser = null;
      localStorage.removeItem('prolaboUser');
    }
    return storageUser || null;
  }

  public getCurrentUser() {
    return this.user;
  }

  public setCurrentUser(user) {
    this.user = user;
  }

  public userToken(): string {
    return this.accessToken;
  }

  public userRoles(): string[] {
    return this.roles;
  }

  constructor(@Inject(ApiService) private apiService: ApiService, private router: Router, private resources: ResourcesService) {

    this.isAuth = Boolean(localStorage.getItem('prolaboUserIsAuth'));
    this.userLocale = environment.defaultLanguage;
    this.accessToken = localStorage.getItem('prolaboUserToken');
  }

  public attemptAuth(credentials, isAdmin: boolean): Observable<LoginResponse> {
    switch (isAdmin) {
      case true:
        return this.apiService.post('/v1/admin/login', credentials)
          .pipe(map(
            data => {
              this.setAuth(data);
              return data;
            }
          ));
      case false:
        return this.apiService.post('/v1/analyse/login', credentials)
          .pipe(map(
            data => {
              this.setAuth(data);
              return data;
            }
          ));
    }
  }

  private setAuth(data: LoginResponse): void {
    this.storageUser = {
      id: data.user.id,
      name: data.user.first_name + ' ' + data.user.last_name,
      role: data.user.role.role.toLowerCase(),
      token: data.access_token,
    };

    localStorage.setItem('prolaboUser', JSON.stringify(this.storageUser));
    this.resources.setStorageUser(this.storageUser);
    this.resources.setLanguage(data.user.language);
  }

}
