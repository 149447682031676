import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-prolabo-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;
  @Input() step: number;
  @Input() total: number;

  @Output() stepClick: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
  }

  onStepClick(step) {
    this.stepClick.emit(step);
  }
}
