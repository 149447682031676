import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, I18nService, ToastService, UserService} from '../../../shared/services';
import {ActivatedRoute, Router} from '@angular/router';
import {Language} from '../../../models';
import {TranslateService} from '@ngx-translate/core';
import {ResourcesService} from '../../../shared/services/resources.service';
import {DropdownModel} from 'src/app/shared/components/dropdown/dropdown.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  languages: DropdownModel[] = [];
  langs: Language[];
  currentLang: any;
  loginForm: FormGroup;
  userActivated = true;
  path: string;
  isAdmin: boolean;
  userRole: string;
  redirectedFromMail: any;
  event = (event: KeyboardEvent) => {
    if (event.which === 13 && this.userActivated) {
      this.submit();
    } else if (event.which === 13 && !this.userActivated) {
      this.resendVerificationEmail();
    }
  };

  constructor(private formBuilder: FormBuilder,
              private apiService: AuthService,
              private userService: UserService,
              private router: Router,
              private translate: TranslateService,
              private resources: ResourcesService,
              private route: ActivatedRoute,
              private toast: ToastService,
              public lang: I18nService) {
    window.addEventListener('keydown', this.event);
  }

  ngOnInit(): void {
    if (this.resources.getPlatformLanguages()) {
      this.langs = this.resources.getPlatformLanguages().languages;
    } else {
      this.langs = this.resources.backupLanguages.languages;
    }
    this.currentLang = this.langs.find(lang => lang.description === this.lang.currentLang);
    this.setLanguages(this.langs);

    const orderId = this.route.snapshot.paramMap.get('orderId');
    const type = this.route.snapshot.paramMap.get('type');
    const userType = this.route.snapshot.paramMap.get('userType');
    if (orderId && type && userType) {
      this.redirectedFromMail = {
        orderId,
        type,
        userType,
      };
    }

    const location = window.location.pathname.split('/');
    if (location[1] === 'admin') {
      this.isAdmin = true;
      this.path = '/admin/register';
    } else {
      this.isAdmin = false;
      this.path = '/register';
    }
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      locale: [this.resources.getLanguage()?.description || 'fr']
    });
  }

  private setLanguages(languages) {
    languages.forEach(lng => {
      const name = this.translate.instant(lng.description.toUpperCase());
      this.languages.push({id: lng.id, text: name, value: lng.language, description: lng.description});
    });
  }

  updateLanguageValue(value) {
    const language = this.langs.find(lang => lang.id === value);
    this.translate.setDefaultLang(language.description);
    this.lang.setCurrentLang(language.description, language);
    this.resources.setLanguage(language);
  }

  submit() {
    this.loginForm.patchValue({
      locale: this.resources.getLanguage()?.description || 'fr'
    });
    if (this.loginForm.valid) {
      this.userService.attemptAuth(this.loginForm.value, this.isAdmin).subscribe(res => {
        this.userService.setCurrentUser(res.user);
        this.loginForm.reset();
        if (this.redirectedFromMail) {
          if (res.user.role.role.toLowerCase() === 'super_admin') {
            this.router.navigate([`admin/dashboard`, this.redirectedFromMail]);
          } else {
            if (res.user.role.role.toLowerCase() === 'client_lenzburg') {
              this.router.navigate([`user/dashboard`, this.redirectedFromMail]);
            } else {
              this.router.navigate([`${res.user.role.role.toLowerCase()}/dashboard`, this.redirectedFromMail]);
            }
          }
        } else {
          if (res.user.role.role.toLowerCase() === 'super_admin') {
            this.router.navigateByUrl(`admin/dashboard`);
          } else {
            if (res.user.role.role.toLowerCase() === 'client_lenzburg') {
              this.router.navigate([`user/dashboard`]);
            } else {
              this.router.navigate([`${res.user.role.role.toLowerCase()}/dashboard`]);
            }
          }
        }
      }, error => {
        if (error.verified !== undefined && error.verified === false) {
          this.userActivated = false;
        }
        this.toast.showBottomCenterError(error.message);
      });
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  resendVerificationEmail() {
    this.apiService.resendVerificationToken({
      email: this.loginForm.controls.email.value,
      locale: this.loginForm.controls.locale.value
    })
      .subscribe(result => {
        this.toast.showBottomCenterInfo(result.message);
        if (this.isAdmin) {
          this.router.navigateByUrl('admin/login');
        } else {
          this.router.navigateByUrl('/login');
        }
      }, error => {
        this.toast.showBottomCenterError(error?.error?.message);
      });
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.event);
  }
}
