<p-card>
  <div class="p-grid steps-container">
    <div class="p-col-12 p-md-3">
      <app-prolabo-order-step [number]="step1?.number"
                              [dateLabel]="step1?.dateLabel"
                              [icon]="step1?.icon"
                              [createdAt]="step1?.date"
                              [label]="step1?.label"
                              [button]="step1?.button">
      </app-prolabo-order-step>
    </div>
    <div class="p-col-12 p-md-3">
      <app-prolabo-order-step [number]="step2?.number"
                              [dateLabel]="step2?.dateLabel"
                              [icon]="step2?.icon"
                              [createdAt]="step2?.date"
                              [label]="step2?.label"
                              [button]="step2?.button">
      </app-prolabo-order-step>
    </div>
    <div class="p-col-12 p-md-3">
      <app-prolabo-order-step [number]="step3?.number"
                              [dateLabel]="step3?.dateLabel"
                              [icon]="step3?.icon"
                              [createdAt]="step3?.date"
                              [label]="step3?.label"
                              [button]="step3?.button">
      </app-prolabo-order-step>
    </div>
    <div class="p-col-12 p-md-3">
      <app-prolabo-order-step [number]="step4?.number"
                              [dateLabel]="step4?.dateLabel"
                              [icon]="step4?.icon"
                              [createdAt]="step4?.date"
                              [label]="step4?.label"
                              [button]="step4?.button">
      </app-prolabo-order-step>
    </div>
  </div>
</p-card>
