<div class="users-container">
  <p-card class="users-card">
    <div class="p-grid">
      <div class="p-col-12 p-sm-3">
        <app-prolabo-icon-button
          [name]="'NEW_USER'"
          type="primary"
          [icon]="'add'" [routerLink]="['create-user',0]"></app-prolabo-icon-button>
      </div>
      <div class="p-col-12 p-sm-9">
        <app-prolabo-icon-input
          [keyword]="'RESEARCH'"
          [icon]="'search'"
          (OnValueChange)="onSearchParamChange($event)"></app-prolabo-icon-input>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-pagination-list
          [items]="users"
          [headers]="userHeaders"
          [activeItem]="resources.getStorageUser().id"
          [totalItems]="totalUsers"
          [listType]="'users'"
          [routerLinkDetails]="'/admin/users/update-user'"
          (itemDelete)="deleteUser($event)"
          (limitValue)="changeLimit($event)"
          (pageValue)="changeOffset($event)"
          (sortValue)="sortByLabel($event)"></app-pagination-list>
      </div>
    </div>
  </p-card>
</div>
