<p-card>
  <div class="p-grid responsibles">
    <div class="p-col-10 title">
      <p-header>{{'SAMPLING.MANAGER' | translate}}</p-header>
    </div>
    <div class="p-col-2 icon">
      <app-prolabo-icon class="icon-black" (click)="addResponsible()" icon="add"></app-prolabo-icon>
    </div>
  </div>


  <ng-container *ngIf="addResponsibleClicked">
    <ul class="responsible-list p-grid">
        <div class="p-col-8 create-responsible">
          <li class="list-text">
            <form [formGroup]="createForm" class="create-responsible-form">
              <input type="text" formControlName="first_name"
                    [placeholder]="'NAME' | translate" class="list-input" pInputText>
              <input type="text" formControlName="last_name"
                    [placeholder]="'SURNAME' | translate" class="list-input" pInputText>
              <input type="text" formControlName="email"
                    [placeholder]="'EMAIL' | translate" class="list-input" pInputText>
            </form>
          </li>
        </div>
        <div class="p-col-4 responsible-btns">
          <app-prolabo-icon class="icon-green" (click)="submit()" icon="check"></app-prolabo-icon>
          <app-prolabo-icon class="icon-white" (click)="removeResponsible()" icon="trash-bin"></app-prolabo-icon>
        </div>
    </ul>
</ng-container>

  <ul *ngFor="let responsible of user.responsible" class="responsible-list p-grid">
    <div class="p-col-10">
      <li class="list-text">{{responsible.first_name}} {{responsible.last_name}}  |  {{responsible.email}}</li>
    </div>
    <div class="p-col-2 icon">
      <app-prolabo-icon class="icon-white" (click)="openConfirmDialog(responsible.id)" icon="trash-bin"></app-prolabo-icon>
    </div>
  </ul>
</p-card>

<app-prolabo-confirmation-modal
  [display]="displayModal"
  [header]="'MODAL_CONFIRMATION'"
  (confirmClicked)="deleteResponsibleUser()"
  (cancelClicked)="closeConfirmDialog()">
</app-prolabo-confirmation-modal>
