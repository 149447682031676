import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, ToastService } from '../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from '../../../shared/services/resources.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotPasswordForm: FormGroup;
  path: string;
  event = (event: KeyboardEvent) => {
    if (event.which === 13) {
      this.forgotPassword();
    }
  }

  constructor(private formBuilder: FormBuilder,
              private apiService: AuthService,
              private toast: ToastService,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private resources: ResourcesService,
              private router: Router) {
    window.addEventListener('keydown', this.event);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.admin === 'true') {
        this.path = '/admin/login';
      } else if (params.admin === 'false') {
        this.path = '/login';
      }
    });
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      locale: [this.resources.getLanguage()?.description]
    });
  }

  forgotPassword() {
    if (this.forgotPasswordForm.valid) {
      this.apiService.forgotPassword(this.forgotPasswordForm.value).subscribe(result => {
        this.toast.showBottomCenterInfo(result.message);
        this.router.navigateByUrl('/login');
      }, error => {
        this.toast.showBottomCenterError(error.error.message);
      });
    } else {
      this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.event);
  }
}
