 <p-card *ngIf="order">
  <div class="p-grid top-header spacing-bottom">
    <div class="p-col-6 title">
      <p-header>{{'PRICES' | translate}}</p-header>
    </div>
  </div>
  <form [formGroup]="tarifsForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'asbestos_express'"
                               [id]="'asbestos_express'"
                               [keyword]="'ASBESTOS.EXPRESS'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.asbestos_express"
                               (OnValueChange)="onValueChange($event,'asbestos_express')"
                               formControlName="asbestos_express">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'asbestos_urgent'"
                               [id]="'asbestos_urgent'"
                               [keyword]="'ASBESTOS.URGENT'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.asbestos_urgent"
                               (OnValueChange)="onValueChange($event,'asbestos_urgent')"
                               formControlName="asbestos_urgent">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'asbestos_standard'"
                               [id]="'asbestos_standard'"
                               [keyword]="'ASBESTOS.STANDARD'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.asbestos_standard"
                               (OnValueChange)="onValueChange($event,'asbestos_standard')"
                               formControlName="asbestos_standard">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'asbestos_long_duration'"
                               [id]="'asbestos_long_duration'"
                               [keyword]="'ASBESTOS.LONG'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.asbestos_long_duration"
                               (OnValueChange)="onValueChange($event,'asbestos_long_duration')"
                               formControlName="asbestos_long_duration">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'lead'"
                               [id]="'lead'"
                               [keyword]="'LEAD_TEXT'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.lead"
                               (OnValueChange)="onValueChange($event,'lead')"
                               formControlName="lead">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'pcb'"
                               [id]="'pcb'"
                               [keyword]="'PCB_TEXT'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.pcb"
                               (OnValueChange)="onValueChange($event,'pcb')"
                               formControlName="pcb">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'hap'"
                               [id]="'hap'"
                               [keyword]="'HAP_TEXT'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.hap"
                               (OnValueChange)="onValueChange($event,'hap')"
                               formControlName="hap">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'vdi'"
                               [id]="'vdi'"
                               [keyword]="'VDI_TEXT'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.vdi"
                               (OnValueChange)="onValueChange($event,'vdi')"
                               formControlName="vdi">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'buffer_quantitative'"
                               [id]="'buffer_quantitative'"
                               [keyword]="'QUANTITATIVE.INPUT'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.buffer_quantitative"
                               (OnValueChange)="onValueChange($event,'buffer_quantitative')"
                               formControlName="buffer_quantitative">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'buffer_qualitative'"
                               [id]="'buffer_qualitative'"
                               [keyword]="'QUALITATIVE.INPUT'"
                               [type]="'text'"
                               [required]="false"
                               [inputType]="'basic'"
                               [defaultValue]="order.prices?.buffer_qualitative"
                               (OnValueChange)="onValueChange($event,'buffer_qualitative')"
                               formControlName="buffer_qualitative">
            </app-prolabo-input>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-card>
