import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-prolabo-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldComponent),
    multi: true
  }]
})
export class InputFieldComponent implements ControlValueAccessor, OnInit {
  @Input() keyword: string;
  @Input() type: string;
  @Input() id: string;
  @Input() for: string;
  @Input() defaultValue: any;
  @Input() disabled: boolean;
  @Input() inputType: string;
  @Input() extraClass: string;

  @Output() OnValueChange: EventEmitter<any> = new EventEmitter();

  public value: string;

  public inputControl: FormControl;
  public numberControl: FormControl;
  public notZero: FormControl;
  public withZero: FormControl;


  constructor() {
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  itemChange($event) {
    this.onChange($event.currentTarget.value);
    this.OnValueChange.emit($event.currentTarget.value);
  }

  ngOnInit(): void {
    this.inputControl = new FormControl({value: this.defaultValue, disabled: this.disabled}, [Validators.required]);
    this.numberControl = new FormControl({
      value: this.defaultValue,
      disabled: this.disabled
    }, [Validators.required, Validators.pattern('^\\d+$')]);
    this.notZero = new FormControl({
      value: this.defaultValue,
      disabled: this.disabled
    }, [Validators.required, Validators.pattern('^\\s*(?=[1-9][0-9]*)\\d*(?:\\.\\d{1,3})?\\s*$')]);
    this.withZero = new FormControl({
      value: this.defaultValue,
      disabled: this.disabled
    }, [Validators.required, Validators.pattern('^\\s*(?=d*.*[0-9])\\d*(?:\\.\\d{1,3})?\\s*$')]);
  }
}
