<div class="breadcrumb-container">
  <div class="left">
    <div [routerLink]="'/' + stockRoute+ '/dashboard'" class="home-icon">
      <img src="../../../../assets/icons/hous_blue.svg"/>
    </div>
    <span [routerLink]="'/' + stockRoute+ '/dashboard'" class="cursor">
      {{'HOME' | translate}}
    </span>
    <span class="breadcrumb-item">/</span>
    <span [routerLink]="first.router" class="cursor">{{first.name | translate}} </span>
    <span *ngIf="second" class="breadcrumb-item">/</span>
    <span *ngIf="second" [routerLink]="second.router" class="cursor"> {{second.name| translate}} </span>
    <span *ngIf="third" class="breadcrumb-item">/</span>
    <span *ngIf="third" [routerLink]="third.router" class="cursor"> {{third.name | translate}}</span>
  </div>
  <div class="right">
    <span *ngIf="logout" (click)="logOut()" class="cursor logout"> {{'LOGOUT' | translate}}</span>
    <span *ngIf="(second?.path === 'diagnostic-order' || second?.path === 'simple-order') &&
    orderState !== null && stockRoute === 'user'"
          class="state">
      {{ orderState | translate }}
    </span>
  </div>
</div>
