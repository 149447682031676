<div class="clients-container" *ngIf="subject">
  <app-dialog-header [title]="'VALIDATE'" (iconClick)="ref.close()"></app-dialog-header>
  <form [formGroup]="step3Form">

    <div class="p-grid">
      <div class="p-col-10">
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'email'"
                               [id]="'email'"
                               [keyword]="'SENDING_ADDRESS'"
                               defaultValue="{{ order.email ? order.email[0]['email'] : null }}"
                               [type]="'email'"
                               [inputType]="'email'"
                               [required]="true"
                               formControlName="email">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid" formArrayName="cc">
          <div class="addCc">
            <app-prolabo-icon icon="add" class="icon-black"
                              (click)="addCc()"></app-prolabo-icon>
          </div>
          <ng-container [formGroupName]="i" *ngFor="let email of cc?.controls; let i =index">
            <div class="p-col-3">
              <app-prolabo-input [for]="'cc'"
                                 [id]="'cc'"
                                 [keyword]="'CC'"
                                 [type]="'text'"
                                 [defaultValue]="email.value.cc"
                                 [required]="false"
                                 [inputType]="'email'"
                                 formControlName="cc">
              </app-prolabo-input>
            </div>
            <div class="p-col-1 removeCcItem" (click)="removeCc(i)">
              <app-prolabo-icon icon="close-white" class="icon-black"></app-prolabo-icon>
            </div>
          </ng-container>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'subject'"
                               [id]="'subject'"
                               [keyword]="'SUBJECT'"
                               [defaultValue]="subject.length > 40 ? subject.slice(0,40) + '...' : subject"
                               [type]="'text'"
                               [required]="true"
                               [inputType]="'basic'"
                               formControlName="subject">
            </app-prolabo-input>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-12">
            <app-prolabo-input [for]="'content'"
                               [id]="'content'"
                               [keyword]="'CONTENT'"
                               [defaultValue]="description"
                               [type]="'text'"
                               [class]="'large'"
                               [required]="false"
                               [inputType]="'basic'"
                               formControlName="content">
            </app-prolabo-input>
          </div>
        </div>
      </div>
      <div class="p-col-2">

      </div>
    </div>
  </form>
  <div class="p-grid extra"></div>
  <div class="p-grid client-dialog-footer">
    <div class="p-col-12 p-md-5">
    </div>
    <div class="p-col-12 p-md-3">
      <ng-container *ngIf="!reportByMail">
        <app-prolabo-button (click)="submit(false)"
                            [name]="'VALIDATE_WITHOUT_MAIL'"
                            type="primary">
        </app-prolabo-button>
      </ng-container>
    </div>
    <div class="p-col-12 p-md-4">
      <app-prolabo-icon-button (click)="submit(true)"
                               [icon]="'check'"
                               [name]="'VALIDATE_WITH_MAIL'"
                               type="secondary">
      </app-prolabo-icon-button>
    </div>
  </div>
</div>
