import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../admin/models/user';
import { DropdownModel } from '../../../../shared/components/dropdown/dropdown.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Laboratory } from '../../../../models/laboratorie';
import { LaboratoriesModalComponent } from '../laboratories-modal/laboratories-modal.component';

@Component({
  selector: 'app-prolabo-user-profile-administration',
  templateUrl: './user-profile-administration.component.html',
  styleUrls: ['./user-profile-administration.component.scss']
})
export class UserProfileAdministrationComponent implements OnInit {
  @Input() currentUser: User;
  @Input() laboratories: DropdownModel[];
  @Input() invoiceTypes: DropdownModel[];
  @Input() laboratoriesModal: Laboratory[];

  @Output() validateClicked = new EventEmitter<boolean>();
  @Output() administrationFormEvent = new EventEmitter<FormGroup>();
  @Output() administrationOriginalFormEvent = new EventEmitter<any>();
  administrationForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.administrationForm = this.formBuilder.group({
      initials: [this.currentUser.initials, Validators.required],
      notes: [this.currentUser.notes, Validators.required],
      invoice_type_id: ['', Validators.required]
    });
    this.administrationOriginalFormEvent.emit(this.administrationForm.value);
  }

  validate() {
    this.validateClicked.emit(true);
    this.administrationFormEvent.emit(this.administrationForm);
  }

  updateInvoiceTypes($event: string) {
    this.administrationForm.patchValue({
      invoice_type_id: $event
    });
  }

  viewLaboratories() {
    this.dialogService.open(LaboratoriesModalComponent, {
      showHeader: false,
      width: '60%',
      data: this.laboratoriesModal
    });
  }
}
