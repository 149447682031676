import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DatePipe} from '@angular/common';
import {PrimeNGConfig} from 'primeng/api';
import {LocaleSettings} from 'primeng/calendar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-update-date-modal',
  templateUrl: './update-date-modal.component.html',
  styleUrls: ['./update-date-modal.component.scss']
})
export class UpdateDateModalComponent implements OnInit {

  form: FormGroup;
  locale: LocaleSettings;
  modalOpened = false;
  disabledDates: Date[];

  constructor(public ref: DynamicDialogRef,
              private translateService: TranslateService,
              private config: PrimeNGConfig,
              private datePipe: DatePipe,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      date: ['', Validators.required]
    });

    this.translateService.get('primeng').subscribe(res => {
      this.config.setTranslation(res);
    });

    this.disabledDates = this.getDates(new Date('01/01/2000'), this.addDays(new Date(), 3));
  }

  submit() {
    this.ref.close(this.datePipe.transform(this.form.controls.date.value, 'dd.MM.yyyy'));
  }

  onSelect($event: any) {
    this.modalOpened = !this.modalOpened;
  }

  onClickOutside($event: any) {
    this.modalOpened = false;
  }

  addDays(date: Date, days) {
    const modifiedDate = date;
    modifiedDate.setDate(modifiedDate.getDate() + days);
    return modifiedDate;
  }

  getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = this.addDays(currentDate, 1);
    }
    return dateArray;
  }
}
