import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {UserService} from '../services';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(@Inject(UserService) private userService: UserService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const role = route.data.role as string;

        return this.userService.hasRole(role);
    }
}
