import { Component, Input, OnInit } from '@angular/core';

@Component( {
  selector: 'app-prolabo-icon',
  templateUrl: './icon.component.html',
  styleUrls: [ './icon.component.scss' ]
} )
export class IconComponent implements OnInit {
  @Input() class: any;
  @Input() icon: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
