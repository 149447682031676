<form>
  <span class="p-float-input">
    <span class="p-label" (click)="focusInput()">
      <p pTooltip="{{ keyword | translate }}{{ required === false ? '' : '*' }}"
         tooltipPosition="top">
        {{ keyword | translate | uppercase }}
        {{ required === false ? '' : '*' }}
      </p>
    </span>
    <!--suppress HtmlFormInputWithoutLabel -->
    <ng-container *ngIf="inputType==='basic'">
      <input [id]="id"
             [type]="type"
             pInputText
             [placeholder]="placeholder ? placeholder : ''"
             (change)="itemChange($event)"
             [formControl]="inputControl"
             (blur)="onTouch($event)"
             [value]="defaultValue"
             class="{{class ? class : null}}"
             [attr.disabled]="disabled ? true : null"
             (keydown)="onChange($event.target.value)">
    </ng-container>
    <ng-container *ngIf="inputType==='nonRequired'">
      <input [id]="id" [type]="type" pInputText (change)="itemChange($event)" [disabled]="disabled"
             [formControl]="nonRequired" (blur)="onTouch($event)" (keydown)="onChange($event.target.value)">
    </ng-container>
    <ng-container *ngIf="inputType==='email'">
      <input [id]="id" [type]="type" pInputText (change)="itemChange($event)" [disabled]="disabled"
             [formControl]="emailControl" (blur)="onTouch($event)" (keydown)="onChange($event.target.value)">
    </ng-container>
    <ng-container *ngIf="inputType==='password'">
      <input [id]="id" [type]="type" pInputText (change)="itemChange($event)" [disabled]="disabled"
             [formControl]="passwordControl" (blur)="onTouch($event)" (keydown)="onChange($event.target.value)">
    </ng-container>
     <ng-container *ngIf="inputType==='pin'">
      <input [id]="id" [type]="type" pInputText (change)="itemChange($event)" [disabled]="disabled"
             [formControl]="pinControll" (blur)="onTouch($event)" (keydown)="onChange($event.target.value)">
    </ng-container>
    <ng-container *ngIf="inputType==='number'">
      <input [id]="id" [type]="type" pInputText (change)="itemChange($event)" [disabled]="disabled"
             [formControl]="numberControl" (blur)="onTouch($event)" (keydown)="onChange($event.target.value)">
    </ng-container>
     <ng-container *ngIf="inputType==='differentThanZero'">
      <input [id]="id" [type]="type" pInputText (change)="itemChange($event)" [disabled]="disabled"
             [formControl]="notZero" (blur)="onTouch($event)" (keydown)="onChange($event.target.value)">
    </ng-container>
    <ng-container *ngIf="inputType==='with_zero'">
      <input [id]="id" [type]="type" pInputText (change)="itemChange($event)" [disabled]="disabled"
             [formControl]="notZero2" (blur)="onTouch($event)" (keydown)="onChange($event.target.value)">
    </ng-container>
</span>
</form>
