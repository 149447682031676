<div class="clients-container">
  <app-dialog-header [title]="'CHECK_SAMPLES'" (iconClick)="ref.close()"></app-dialog-header>
  <div class="step2-pagination-list">
    <ng-container *ngIf="items">
      <div class="list-labels">
        <div class="p-grid sublist-items">
          <ng-container *ngFor="let header of headers">
            <p class="sort-label overflow" pTooltip="{{ header['title'] | translate }}"
               tooltipPosition="top">
              {{ header['title'] | translate }}
            </p>
          </ng-container>
          <p class="sort-label check-box">
            <span class="overflow" pTooltip="{{ 'SWITCH_EVERYTHING' | translate }}"
                  tooltipPosition="top">{{ 'SWITCH_EVERYTHING' | translate }} &nbsp;</span>
            <p-checkbox class="checkbox-icon" name="groupname" value="all" [(ngModel)]="selectedAll"
                        (ngModelChange)="check($event)">
            </p-checkbox>
          </p>
        </div>
      </div>
      <ng-container *ngFor="let item of items">
        <div class="list-item">
          <p class="overflow" pTooltip="{{ item['sample_id'] }}"
             tooltipPosition="top">{{ item['sample_id'] }}</p>
          <p class="overflow" pTooltip="{{ item['sample_number'] }}"
             tooltipPosition="top">
            {{item['sample_number']}}
          </p>
          <p class="overflow" pTooltip="{{ item['type']?.slug | translate }}"
             tooltipPosition="top">{{ item['type']?.slug | translate }}</p>
          <p class="overflow" pTooltip="{{ item['description'] }}"
             tooltipPosition="top">{{ item['description'] }}</p>
          <p class="overflow" pTooltip="{{ item['diagnostic']['approximate_quantity'] }}"
             tooltipPosition="top">{{ item['diagnostic']['approximate_quantity'] }}</p>
          <p class="overflow" pTooltip="{{ getCollectMethod(item['diagnostic']['collect_method']?.id) | translate }}"
             tooltipPosition="top">{{ getCollectMethod(item['diagnostic']['collect_method']?.id) | translate }}</p>
          <p class="check-box-item">
            <p-checkbox class="checkbox-icon" name="groupname" [value]="item.id"
                        [(ngModel)]="selectedValues"></p-checkbox>
          </p>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="p-grid footer">
    <div class="p-col-12 p-md-9 check-box-container">
      <p-checkbox name="email" inputId="email" [value]="true" [(ngModel)]="emailSelected"></p-checkbox>
      <label class="label">{{ 'SEND_EMAIL' | translate }}</label>
    </div>
    <div class="p-col-12 p-md-3">
      <app-prolabo-icon-button
        (click)="validate()"
        [icon]="'arrow-right-white'"
        [name]="'VALIDATE'"
        type="primary"></app-prolabo-icon-button>
    </div>

  </div>
</div>
