<div class="invoices-pagination-list">
  <ng-container *ngIf="items">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="list-labels">
          <div class="sublist-items">
            <ng-container *ngFor="let header of headers">
                            <span *ngIf="header.title !== 'DATE'">
                                <p class="sort-label {{header.sortLabel}}"
                                   (click)="header.title !== 'VALID_FROM' && header.title !== 'VALID_TO' &&
                                  header.title !== 'DOCUMENT' && header.title !== 'STATUS' ? sortByLabel(header.sortLabel) : null">
                                    {{ header.title | translate }}

                                  <span *ngIf="getAscValueForHeader(header.sortLabel) &&
                                  header.title !== 'VALID_FROM' && header.title !== 'VALID_TO' &&
                                  header.title !== 'DOCUMENT' && header.title!== 'PREVIEW' && header.title !== 'STATUS'"
                                        class="icon">
                                    <img src="../../../../assets/icons/down-arrow.svg"/>
                                  </span>
                                    <span *ngIf="!getAscValueForHeader(header.sortLabel) &&
                                  header.title !== 'VALID_FROM' && header.title !== 'VALID_TO' &&
                                  header.title !== 'DOCUMENT' && header.title!== 'PREVIEW'" class="icon">
                                    <img src="../../../../assets/icons/up-arrow.svg"/>
                                  </span>
                                  <span
                                    *ngIf="header.sortLabel === 'kb_item_status_id'"
                                    pTooltip="{{ 'FILTER_STATUS' | translate }}"
                                    tooltipPosition="top"
                                    class="filter-icon">
                                    <img
                                      src="{{statusIsSelected ?'../../../../../assets/icons/filter_blue.svg' : '../../../../../assets/icons/filter.svg'}}">
                                    <p-dropdown
                                      [options]="filterByStatus"
                                      [formControl]="selectedStatus"
                                      optionValue="id"
                                      optionLabel="text"
                                      (onChange)="handleFilterStatus($event)"
                                      class="dropdown-hidden">
                                    </p-dropdown>
                                  </span>
                                </p>
                            </span>
              <span *ngIf="header.title === 'DATE'">
                 <p class="sort-label">
            {{ getLastHeader()['title'] | translate }}
                   <span *ngIf="getAscValueForHeader('updated_at')"
                         class="icon"
                         (click)="sortByLabel(getLastHeader()['sortLabel'])">
              <img src="../../../../assets/icons/down-arrow.svg"/>
            </span>
            <span *ngIf="!getAscValueForHeader('updated_at')"
                  class="icon"
                  (click)="sortByLabel(getLastHeader()['sortLabel'])">
              <img src="../../../../assets/icons/up-arrow.svg"/>
            </span>
          </p>
              </span>
            </ng-container>
          </div>
        </div>

        <ng-container *ngFor="let item of items">
          <div class="list-item">
            <div class="sublist-items">
              <p>
                <app-prolabo-icon class="icon-white"
                                  (click)="preview(item.id)"
                                  icon="visible">
                </app-prolabo-icon>
                <app-prolabo-icon class="icon-white"
                                  *ngIf="user?.role?.id !== 3"
                                  (click)="edit(item.id)"
                                  icon="edit">
                </app-prolabo-icon>
              </p>
              <p class="overflow"
                 pTooltip="{{item.document_nr}}"
                 tooltipPosition="top">
                {{ item.document_nr }}
              </p>
              <p class="overflow"
                 pTooltip="{{item.total_net | number : '1.2-2'}}"
                 tooltipPosition="top">
                {{ item.total_net | number : '1.2-2' }}
              </p>
              <p class="overflow"
                 pTooltip="{{item.total | number : '1.2-2' }}"
                 tooltipPosition="top">
                {{ item.total | number : '1.2-2' }}
              </p>
              <p class="overflow"
                 pTooltip="{{ item.is_valid_from | toDateObj | date: 'dd.MM.yyyy' }}"
                 tooltipPosition="top">
                {{ item.is_valid_from | toDateObj | date: 'dd.MM.yyyy'  }}
              </p>
              <p class="overflow"
                 pTooltip="{{item.is_valid_to | toDateObj | date: 'dd.MM.yyyy' }}"
                 tooltipPosition="top">
                {{ item.is_valid_to | toDateObj | date: 'dd.MM.yyyy' }}
              </p>
              <p class="overflow kb_item_status_id"
                 pTooltip="{{ getStatus(item.kb_item_status_id, item.is_valid_to) | translate }}"
                 tooltipPosition="top">
                {{ getStatus(item.kb_item_status_id, item.is_valid_to) | translate }}
              </p>
              <p>
                {{ item.updated_at | toDateObj | date: "dd.MM.yyyy" }}
                <span class="light-text time">&nbsp; &nbsp; {{ item.updated_at | toDateObj | date: "HH:mm" }}</span>
                <app-prolabo-icon class="icon-white" (click)="openConfirmDialog(item.id)"
                                  *ngIf="user?.role?.id !== 3"
                                  icon="trash-bin">
                </app-prolabo-icon>
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <p class="total">
          {{ 'INVOICES' | translate }}{{ ': ' }}{{ total }}
        </p>
      </div>
      <div class="p-col-12 p-md-6 inline">
        <p>{{ 'ROWS_PER_PAGE' | translate }}: </p>
        <p-dropdown
          [options]="[10, 20, 30]"
          class="row-dropdown"
          (onChange)="onLimitChange($event)"></p-dropdown>
        <p>&nbsp; {{ numberRows * page + 1 }} - {{ numberRows * page + numberRows }} of {{ total }}</p>
        <p-paginator
          class="paginator"
          [rows]="numberRows"
          [totalRecords]="total"
          [showJumpToPageDropdown]="false"
          [showPageLinks]="false"
          (onPageChange)="onPageChange($event)"></p-paginator>
      </div>
    </div>
  </ng-container>
</div>
<app-prolabo-confirmation-modal
  [display]="displayModal"
  [header]="'MODAL_CONFIRMATION'"
  (confirmClicked)="deleteItem()"
  (cancelClicked)="closeConfirmDialog()">
</app-prolabo-confirmation-modal>
