import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ResetPassword} from '../../../models';
import {AuthService, ToastService} from '../../../shared/services';
import {InputError, InputValidationError} from '../../../models/input-error';
import {TranslateService} from '@ngx-translate/core';
import {ResourcesService} from '../../../shared/services/resources.service';
import {FunctionsService} from '../../../admin/services/functions.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  verifyTokenObj: ResetPassword;
  resetPasswordForm: FormGroup;
  setPasswordForm: FormGroup;
  tokenValid = false;
  isSetPassword: boolean;

  event = (event: KeyboardEvent) => {
    if (event.which === 13) {
      this.resetPassword();
    }
  };

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private apiService: AuthService,
              private toast: ToastService,
              private translate: TranslateService,
              private resources: ResourcesService,
              private functions: FunctionsService,
              private router: Router) {
    window.addEventListener('keydown', this.event);
  }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('id');
    this.isSetPassword = !this.router.url.includes('reset-password');
    if (!this.isSetPassword) {
      this.verifyTokenObj = {
        locale: this.resources.getLanguage().description,
        token
      };
      this.resetPasswordTokenVerification().then(res => {
        this.tokenValid = res;
        this.resetPasswordForm = this.formBuilder.group({
          token: [token],
          password: ['', [Validators.required, Validators.minLength(8)]],
          password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
          locale: [this.resources.getLanguage()?.description || 'fr']
        });
      }).catch(() => {
        this.router.navigateByUrl('/login');
      });
    } else {
      this.setPasswordForm = this.formBuilder.group({
        id: [token],
        password: ['', [Validators.required, Validators.minLength(8)]],
        password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
        locale: [this.resources.getLanguage()?.description || 'fr']
      });
    }
  }

  resetPassword(): void {
    if (this.isSetPassword) {
      if (this.setPasswordForm.valid) {
        this.apiService.setPasswordVerification(this.setPasswordForm.value).subscribe(result => {
          if (result.role_id === 3 || result.role_id === 5) {
            this.toast.showBottomCenterInfo(result.message);
            this.router.navigateByUrl('/login');
          } else {
            this.router.navigateByUrl('/admin/login');
          }
        }, error => {
          this.functions.showError(error);
          this.router.navigateByUrl('/login');
        });
      } else {
        this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
      }
    } else {
      if (this.resetPasswordForm.valid) {
        this.apiService.resetPassword(this.resetPasswordForm.value).subscribe(result => {
          this.toast.showBottomCenterInfo(result.message);
          if (result.role.id === 3 || result.role.id === 5) {
            this.router.navigateByUrl('/login');
          } else {
            this.router.navigateByUrl('/admin/login');
          }
        }, (error) => {
          this.functions.showError(error);
        });
      } else {
        this.toast.showBottomCenterError(this.translate.instant('FORM.VALIDATION'));
      }
    }
  }

  checkPasswords(value) {
    if (this.isSetPassword) {
      this.setPasswordForm.patchValue({
        password_confirmation: value
      });
      if (this.setPasswordForm.controls.password.value !== this.setPasswordForm.controls.password_confirmation.value) {
        this.toast.showBottomCenterError(this.translate.instant('FORM.PASSWORD'));
      }
    } else {
      this.resetPasswordForm.patchValue({
        password_confirmation: value
      });
      if (this.resetPasswordForm.controls.password.value !== this.resetPasswordForm.controls.password_confirmation.value) {
        this.toast.showBottomCenterError(this.translate.instant('FORM.PASSWORD'));
      }
    }
  }

  resetPasswordTokenVerification(): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.apiService.resetPasswordTokenVerification(this.verifyTokenObj).subscribe(result => {
        if (result.type === 'success') {
          resolve(true);
        }
      }, error => {
        this.functions.showError(error);
        this.router.navigateByUrl('/login');
      });
    }));
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.event);
  }

}
