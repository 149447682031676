import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateOrderComponent } from 'src/app/admin/pages/orders/modals/create-order-modal/create-order.component';
import { AdminSimpleOrdersService } from 'src/app/admin/services/admin-simple-orders.service';
import { Router } from '@angular/router';
import { Deadline } from 'src/app/models/deadline';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { AdminStatics } from '../../../statics/admin-statics';
import { AdminOrdersService } from '../../../services/admin-orders.service';
import { AdminDiagnosticOrdersService } from '../../../services/admin-diagnostic-orders.service';
import { AdminDashboardService } from '../../../services/admin-dashboard.service';
import { Laboratory } from '../../../../models/laboratorie';
import { FunctionsService } from '../../../services/functions.service';
import { User } from '../../../models/user';
import { UserService } from '../../../../shared/services';

@Component({
  selector: 'app-prolabo-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit {

  deadlines: DropdownModel[] = [];
  laboratories: Laboratory[] = [];
  currentUser: User;

  constructor(private dialogService: DialogService,
              private ordersService: AdminOrdersService,
              private simpleOrdersService: AdminSimpleOrdersService,
              private diagnosticOrdersService: AdminDiagnosticOrdersService,
              private dashboardService: AdminDashboardService,
              private functions: FunctionsService,
              private translate: TranslateService,
              private resources: ResourcesService,
              private userService: UserService,
              private router: Router) {
  }

  getLaboratories() {
    this.dashboardService.getLaboratories().subscribe(res => {
      this.laboratories = res.laboratories;
    });
  }

  createOrderTemp() {
    const body = {
      order_type_id: 1,
      locale: this.resources.getLanguage().description,
    };
    this.simpleOrdersService.orderCreate(body).subscribe(result => {
      this.router.navigate(['/admin/orders/simple-order', result.order.id]).then(() => {
      });
    }, err => this.functions.showError((err)));
  }

  createOrder() {
    const ref = this.dialogService.open(CreateOrderComponent, {
      showHeader: false,
      width: '70%',
      styleClass: 'dialog',
      data: {deadlines: this.deadlines, user: false, laboratories: this.laboratories, isUser: false}
    });

    ref.onClose.subscribe(res => {
      if (res) {
        if (res.type === AdminStatics.simpleOrder) {
          const body = {
            order_type_id: 1,
            locale: this.resources.getLanguage().description,
            user_id: res.user_id
          };
          this.simpleOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/admin/orders/simple-order', result.order.id]).then(() => {
            });
          });
        } else if (res.type === AdminStatics.diagnostics) {
          const body = {
            order_type_id: 2,
            locale: this.resources.getLanguage().description,
          };
          this.diagnosticOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/admin/orders/diagnostic-order', result.order.id]).then(() => {
            });
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.ordersService.getDeadlines({locale: this.resources.getLanguage().description}).subscribe(result => {
      this.setDeadlines(result.deadlines);
    });
  }

  private setDeadlines(types: Deadline[]) {
    types.forEach(type => {
      this.deadlines.push({
        id: type.id,
        text: type.slug,
        value: type.id
      });
    });
  }
}
