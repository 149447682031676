export class BexioStatics {
  public static readonly pdf = 'pdf';
  public static readonly v1 = '1.0';
  public static readonly v2 = '2.0';
  public static readonly v3 = '3.0';
  public static readonly v4 = '4.0';
  public static readonly v5 = '5.0';
  public static readonly generateInvoice = 'order/invoice/create';
  public static readonly reGenerateInvoice = 'order/invoice/regenerate';

  // API routes
  public static readonly getInvoices = 'invoices/get';
  public static readonly deleteInvoice = 'invoice/delete';
  public static readonly previewInvoice = 'invoice/preview';

  // properties
  public static readonly title = 'title';
  public static readonly total = 'total';
  public static readonly documentNr = 'document_nr';
  public static readonly totalGross = 'total_gross';
  public static readonly isValidFrom = 'is_valid_from';
  public static readonly isValidTo = 'is_valid_to';
  public static readonly totalNet = 'total_net';
  public static readonly updatedAt = 'updated_at';
  public static readonly desc = '_desc';
  public static readonly status = 'kb_item_status_id';

}
