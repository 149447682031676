<div class="clients-container">
    <p-card class="clients-card">
        <div class="p-grid">
            <div class="p-col-12 p-sm-3">
                <app-prolabo-icon-button
                    [name]="'NEW_CLIENT'"
                    type="primary"
                    (click)="createClient()"
                    [icon]="'add'"></app-prolabo-icon-button>
            </div>
            <div class="p-col-12 p-sm-9">
                <app-prolabo-icon-input
                    [keyword]="'RESEARCH'"
                    [icon]="'search'"
                    (OnValueChange)="onSearchParamChange($event)"></app-prolabo-icon-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <app-pagination-list
                    [items]="clients"
                    [headers]="clientHeaders"
                    [listType]="'clients'"
                    [totalItems]="totalClients"
                    (itemClicked)="updateClient($event)"
                    (itemDelete)="deleteClient($event)"
                    (limitValue)="changeLimit($event)"
                    (pageValue)="changeOffset($event)"
                    (sortValue)="sortByLabel($event)"></app-pagination-list>
            </div>
        </div>
    </p-card>
</div>
