import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from 'src/app/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Price } from '../../../../models/laboratorie';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { AdminDashboardService } from '../../../services/admin-dashboard.service';
import { FunctionsService } from '../../../services/functions.service';
import { ToastService } from '../../../../shared/services';

@Component({
  selector: 'app-prolabo-profile-tariff',
  templateUrl: './profile-tariff.component.html',
  styleUrls: ['./profile-tariff.component.scss']
})
export class ProfileTariffComponent implements OnInit {
  @Input() currentUser: User;
  @Input() prices: Price[];
  @Input() userPrices: boolean;
  stateOptions = [{label: 'PRIVATE', value: 'PRIVATE'}, {label: 'COMPANY', value: 'COMPANY'}];
  isCompany: boolean;
  @Output() priceChangedEvent = new EventEmitter();
  selectedOption: string;

  constructor(private resources: ResourcesService,
              private functions: FunctionsService,
              private toastService: ToastService,
              private dashboardService: AdminDashboardService) {
  }

  ngOnInit(): void {
    this.isCompany = this.currentUser.is_company === 1;
    this.selectedOption = this.currentUser.is_company === 1 ? 'COMPANY' : 'PRIVATE';
  }

  priceChanged($event: any, price: Price) {
    let body;

    if (this.isCompany) {
      body = {
        locale: this.resources.getLanguage().description,
        price_id: price.id,
        company: +$event
      };
    } else {
      body = {
        locale: this.resources.getLanguage().description,
        price_id: price.id,
        private: +$event
      };
    }

    if (this.userPrices) {
      this.dashboardService.updateUserPrice(body).subscribe(x => {
        this.priceChangedEvent.emit();
        this.toastService.showBottomCenterSuccess(x.message);
      }, error => this.functions.showError(error));
    } else {
      this.dashboardService.updatePrice(body).subscribe(x => {
        this.priceChangedEvent.emit();
        this.toastService.showBottomCenterSuccess(x.message);
      }, error => this.functions.showError(error));
    }

  }

  selectionChanged($event: any) {
    this.isCompany = $event.value === 'COMPANY';
  }

  restorePrices() {
    const body = {
      user_id: this.currentUser.id,
      locale: this.resources.getLanguage().description
    };
    this.dashboardService.restorePrices(body).subscribe(x => {
      this.priceChangedEvent.emit();
      this.toastService.showBottomCenterSuccess(x.message);
    }, error => this.functions.showError(error));
  }
}
