<div class="p-grid" *ngIf="order">
  <div class="p-col-12 p-xl-8">
    <div class="main-title-container {{ stepDone ? 'secondary-container' : null }}">
      <div class="p-grid">
        <div class="p-col-12 p-md-6 title-block">
          <img src="../../../../../../../assets/icons/shopping-basket-small.svg">
          <span class="order-group">
            <p class="order-language">{{'SIMPLE.ORDER' | translate}} - {{order.user?.company}}</p>
            <p class="order-date {{ stepDone ? 'white-order-date' : null }}">
              {{order?.order_number}} {{ 'CREATED_ON' | translate }} {{order?.created_at | date }}</p>
          </span>
        </div>
        <div class="p-col-12 p-md-6 buttons-block">
          <div class="p-grid">
            <div class="p-col-6">
              <ng-container *ngIf="!step3">
                <app-prolabo-icon-button [icon]="'close-white'"
                                         [name]="'CANCEL'"
                                         type="{{ stepDone ? 'secondary' : 'primary-radius' }}"
                                         class="{{ stepDone ? 'white-border' : null }}"
                                         (click)="openConfirmDialog()">
                </app-prolabo-icon-button>
              </ng-container>
            </div>
            <div class="p-col-6" *ngIf="step1">
              <app-prolabo-icon-button icon="upload-white"
                                       type="secondary"
                                       [disabled]="!samplesAdded"
                                       name="SUBMIT"
                                       (OnClick)="submitOrder()">
              </app-prolabo-icon-button>
            </div>
            <div class="p-col-6" *ngIf="!step1 && !stepDone">
              <app-prolabo-button type="secondary"
                                  name="LABORATORY_ADDRESS"
                                  (OnClick)="viewAddress()">
              </app-prolabo-button>
            </div>
          </div>
        </div>
        <div class="p-col-12 buttons-block" *ngIf="step1"
             style="margin-bottom: 0; margin-top: -10px">
          <div class="p-grid">
            <div class="p-col-6">
              <p style="margin: 0;  padding: 0" pTooltip="{{ 'DOWNLOAD' | translate }}"
                 tooltipPosition="top">
                <app-prolabo-icon-button [icon]="'printer'"
                                         [name]="'DOWNLOAD'"
                                         type="primary-radius"
                                         class="white-border"
                                         (click)="downloadTemplate()">
                </app-prolabo-icon-button>
              </p>
            </div>
            <div class="p-col-6">
              <p style="margin: 0;  padding: 0" pTooltip="{{ 'IMPORT' | translate }}"
                 tooltipPosition="top">
                <app-prolabo-icon-input [for]="'import'"
                                        [id]="'import'"
                                        [type]="'file'"
                                        [importInput]="true"
                                        [inputType]="'basic'"
                                        [required]="false"
                                        [icon]="'upload-white'"
                                        [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                        [multiple]="false"
                                        keyword="IMPORT"
                                        class="regular-input-color"
                                        (change)="uploadTemplate($event)">
                </app-prolabo-icon-input>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="final-step-container" *ngIf="stepDone">
      <div class="p-col-6">
        <app-prolabo-order-final-step-container [title]="'REPORT'"
                                                [reportsDate]="generatedReportDate"
                                                [textReport]="'REPORT_SENT'"
                                                [firstButtonIcon]="'printer'"
                                                [user]="true"
                                                [firstButtonTitle]="order?.pdf_report_path === null ? 'EXPORT_REPORT': 'PRINT'"
                                                (firstButton)="printClicked()">
        </app-prolabo-order-final-step-container>
      </div>
      <div class="p-col-6">
        <app-prolabo-order-final-step-container [title]="'BILLING'"
                                                [user]="true"
                                                [textInvoices]="'INVOICES_GENERATED'"
                                                [invoicesNumber]="numGeneratedInvoices"
                                                [invoicesDate]="generatedInvoicesDate"
                                                [allowedToDownload]="order.invoice_visible_to_user === 1"
                                                [firstButtonTitle]="order.invoice_generated === 1 ? order.invoice_visible_to_user === 1 ?  order.invoices.invoice?.name : 'PRINT' : 'PRINT'"
                                                [firstButtonIcon]="'printer'"
                                                (firstButton)="printInvoice()"
                                                [disableFirstButton]="order.invoice_generated === 0 || order.invoice_visible_to_user === 0">
        </app-prolabo-order-final-step-container>
      </div>
    </div>
    <ng-container *ngIf="samplesByType && samplesByType.length > 0">
      <ng-container *ngFor="let samples of samplesByType">
        <p-card [style]="{'margin-bottom': '15px'}">
          <div class="p-grid spacing-bottom">
            <div class="p-col-12 p-md-8 title">
              <p-header>
                {{ 'SAMPLING' | translate }} {{ functions.getTypeName(samples[0]) | translate }}
                ({{ samples[1].samples.length }})
              </p-header>
              <ng-container *ngIf="deadlines.hasOwnProperty(samples[0])" class="deadline-title">
                <div class="p-grid deadline-container" *ngIf="step1">
                  <div class="p-col-12">
                    <app-prolabo-dropdown [options]="deadlines[samples[0]]"
                                          class="deadline-dropdown"
                                          [deadlineAt]="order.deadline_at"
                                          [chosenDeadlineId]="order.deadline_id"
                                          (OnValueChange)="updateDeadlineValue($event, samples[0])"
                                          [defaultValue]="order.deadlines ? order.deadlines[0].deadline_id : null"
                                          [disabled]="stepDone"
                                          placeholder="DEADLINE"></app-prolabo-dropdown>
                  </div>
                </div>
                <ng-container *ngIf="samples[0][0] === '8'">
                  <p *ngIf="!step1" class="deadline-title">
                  {{getTranslation(getDeadline(order.deadlines ? order.deadlines[0].deadline_id : null, samples[0]).text | translate)}}
                  </p>
                </ng-container>
                <ng-container *ngIf="samples[0][0] !== '8'">
                  <p *ngIf="!step1" class="deadline-title">
                    {{getDeadline(order.deadlines ? order.deadlines[0].deadline_id : null, samples[0]).text | translate}}
                  </p>
                </ng-container>

              </ng-container>
            </div>
            <ng-container *ngIf="order.accompanying_sheet_path != null">
              <div class="p-col-12 p-md-3">
                <app-prolabo-icon-button
                  icon="printer"
                  type="primary"
                  name="SHEET"
                  (OnClick)="openEtiquets()">
                </app-prolabo-icon-button>
              </div>
            </ng-container>
            <ng-container *ngIf="stepDone && samples[0] === '2'">
              <app-prolabo-icon-button
                icon="printer"
                type="primary"
                name="EXPORT_SAMPLES"
                (OnClick)="exportSamples()">
              </app-prolabo-icon-button>
            </ng-container>
            <div class="p-col-12 p-md-4">
              <ng-container *ngIf="step1">
                <app-prolabo-icon-button
                  icon="add"
                  type="primary"
                  name="ADD.NEW.SAMPLE"
                  (OnClick)="createSample(samples[0])">
                </app-prolabo-icon-button>
              </ng-container>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <ng-container *ngIf="order">
                <app-prolabo-pagination-list-simple-samples
                  [items]="samples[1].samples"
                  [stepDone]="stepDone"
                  [step1]="step1"
                  [step2]="step2"
                  [user]="true"
                  [headers]="samplesHeader"
                  [userSide]="true"
                  (itemDelete)="handleDelete($event)"
                  (itemToEdit)="handleSampleUpdate($event)">
                </app-prolabo-pagination-list-simple-samples>
              </ng-container>
            </div>
          </div>
        </p-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="samplesByType && samplesByType.length == 0">
      <p-card [style]="{'margin-bottom': '15px'}">
        <div class="p-grid spacing-bottom">
          <div class="p-col-12 p-md-8 title">
            <p-header>
              {{ 'SAMPLING' | translate }}
            </p-header>
          </div>
          <div class="p-col-12 p-md-4">
            <app-prolabo-icon-button
              icon="add"
              type="primary"
              name="ADD.NEW.SAMPLE"
              (OnClick)="createSample(sampleTypeId)">
            </app-prolabo-icon-button>
          </div>
        </div>
      </p-card>
    </ng-container>
  </div>


  <div class="p-col-12 p-xl-4" *ngIf="order">
    <app-prolabo-simple-order-administration [submitClicked]="initiatedSubmit"
                                             [order]="order"
                                             [isUser]="true"
                                             [invoiceTypes]="invoiceTypes"
                                             (orderEvent)="handleOrderError($event)"
                                             [laboratories]="laboratories"
                                             (requestGetOrder)="getOrderDetails(order.id)"
                                             (orderFormEvent)="handleOrderFormEvent($event)">
    </app-prolabo-simple-order-administration>
    <app-prolabo-simple-order-report [submitClicked]="initiatedSubmit"
                                     (emailEvents)="handleEmailEvent($event)"
                                     (email)="handleEmailError($event)"
                                     (languageEvent)="handleLanguageEvent($event)"
                                     [order]="order"
                                     (lang)="handleLangError($event)"
                                     [isUser]="true"
                                     [getOrderRequested]="getOrderInitiated"
                                     [client]="selectedClient">
    </app-prolabo-simple-order-report>
  </div>
</div>

<app-prolabo-confirmation-modal
  [display]="displayModal"
  [header]="'MODAL_CONFIRMATION_CANCEL'"
  (confirmClicked)="cancelOrder()"
  (cancelClicked)="closeConfirmDialog()">
</app-prolabo-confirmation-modal>
