import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetUserResponse, LoginResponse, ModifyUser, UsersGetResponse } from '../../models';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourcesService } from '../../shared/services/resources.service';
import { AdminRoutesStatics } from '../statics/admin-routes-statics';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {

  constructor(private http: HttpClient, private resources: ResourcesService) {
  }

  public getUser(body): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getProfileData}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getUsers(body): Observable<UsersGetResponse> {
    return this.http.post<UsersGetResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getUsers}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getSingleUser(body): Observable<GetUserResponse> {
    return this.http.post<GetUserResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getUser}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  createUser(body): Observable<ModifyUser> {
    return this.http.post<ModifyUser>(`${environment.adminApiUrl}${AdminRoutesStatics.createUser}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  updateUser(body): Observable<ModifyUser> {
    return this.http.post<ModifyUser>(`${environment.adminApiUrl}${AdminRoutesStatics.updateUser}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  deleteUser(body): Observable<ModifyUser> {
    return this.http.delete<any>(`${environment.adminApiUrl}${AdminRoutesStatics.deleteUser}`, {
      headers: this.getHeaders(this.resources.getStorageUser().token),
      params: body
    });
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }

  private getMultiPartHeader(token): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }

}
