<p-table [value]="samples"
         [scrollable]="true"
         [style]="{'width': '100%'}"
         styleClass="p-datatable-sm">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col class="columns">
    </colgroup>
    <colgroup>
      <col class="columns">
    </colgroup>
    <colgroup>
      <col class="columns description">
    </colgroup>
    <colgroup *ngFor="let header of items">
      <col class="columns {{header.slug}} {{header.scorecard_field_type.slug}}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="border-bottom: 1px solid;">
      <th>
        <p class="overflow" pTooltip="{{ 'SAMPLE.ID' | translate }}"
           tooltipPosition="top">{{ 'SAMPLE.ID' | translate }}</p>
      </th>
      <th>
        <p class="overflow" pTooltip="{{ 'SAMPLE.NUMBER' | translate }}"
           tooltipPosition="top">
          {{ 'SAMPLE.NUMBER' | translate }}
        </p>
      </th>
      <th>
        <p class="overflow" pTooltip="{{ 'SAMPLE.DESCRIPTION' | translate }}"
           tooltipPosition="top">
          {{ 'SAMPLE.DESCRIPTION' | translate }}
        </p>
      </th>
      <ng-container *ngFor="let header of items">
        <ng-container *ngIf="header.scorecard_field_type.slug ==='checkbox'">
          <th>
            <p class="overflow" pTooltip="{{ header.field_name | translate }}"
               tooltipPosition="top">
              <p-checkbox name="checkAll"
                          (onChange)="checkAll(header, getCheckBoxValue(header))"
                          [value]="true" [ngModel]="[getCheckBoxValue(header)]" [id]="header.field_name"></p-checkbox>
              {{ header.field_name | translate }}
            </p>
          </th>
        </ng-container>
        <ng-container *ngIf="header.scorecard_field_type.slug !=='checkbox'">
          <th>
            <p class="overflow" pTooltip="{{ header.field_name | translate }}"
               tooltipPosition="top">
              {{ header.field_name | translate }}
            </p>
          </th>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr style="border-bottom: 1px solid;">
      <td>
        <p class="overflow" pTooltip="{{ item.sample_id | translate }}"
           tooltipPosition="top">
          {{item.sample_id}}
        </p>
      </td>
      <td>
        <p class="overflow" pTooltip="{{ item.sample_number | translate }}"
           tooltipPosition="top">
          {{item.sample_number}}
        </p>
      </td>
      <td>
        <p class="overflow description" pTooltip="{{ item.description | translate }}"
           tooltipPosition="top">
          {{item.description}}
        </p>
      </td>
      <ng-container *ngFor="let field of item.headers">
        <td *ngIf="field.scorecard_field_type.slug === 'text'">
          <p class="overflow input-field" pTooltip="{{ getFieldValue(field.slug, 'text', item.id, 1) }}"
             tooltipPosition="top">
            <ng-container *ngIf="field.slug !== 'remarks'">
              <app-prolabo-input-field
                (OnValueChange)="inputChange($event, item.id,field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id ,1,this.idOptions[item.sample_number +'-'+field.slug])"
                [id]="this.idOptions[item.sample_number +'-'+field.slug] | number"
                [for]="this.idOptions[item.sample_number +'-'+field.slug] | number"
                [type]="field.type"
                [disabled]="field.disabled === 1"
                [inputType]="field.input_type"
                [defaultValue]="getFieldValue(field.slug, 'text', item.id, 1)">
              </app-prolabo-input-field>
            </ng-container>

            <ng-container *ngIf="field.slug ==='remarks'">
              <textarea rows="5" cols="70" pInputTextarea style="width: 100% !important; height: 170px !important;"
                        [id]="this.idOptions[item.sample_number +'-'+field.slug] | number"
                        [disabled]="field.disabled === 1"
                        [defaultValue]="getFieldValue(field.slug, 'text', item.id, 1)"
                        (change)="inputChange($event.target.value, item.id,field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id ,1,this.idOptions[item.sample_number +'-'+field.slug])"
              ></textarea>
            </ng-container>
          </p>
        </td>
        <td *ngIf="field.scorecard_field_type.slug === 'text_button'">
          <div>
            <p-inputNumber mode="decimal"
                           (onInput)="inputChange($event.value, item.id,field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id ,1,this.idOptions[item.sample_number +'-'+field.slug], $event)"
                           [showButtons]="true" [step]="0.5" [min]="-100"
                           [max]="100"
                           [minFractionDigits]="2"
                           [inputId]="this.idOptions[item.sample_number +'-'+field.slug] | number"
                           [placeholder]="getFieldValue(field.slug, 'text_button', item.id, 1)"
                           incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
            </p-inputNumber>
          </div>
        </td>
        <td *ngIf="field.scorecard_field_type.slug === 'checkbox'">
          <p class="overflow">
            <p-checkbox name="email"
                        (onChange)="checkBoxChange($event, item.id,field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id, 1,this.idOptions[item.sample_number +'-'+field.slug] )"
                        [value]="true" [ngModel]="[getFieldValue(field.slug, 'checkbox', item.id, 1)]"
                        [inputId]="this.idOptions[item.sample_number +'-'+field.slug] | number"></p-checkbox>

          </p>
        </td>
        <td
          *ngIf="field.scorecard_field_type.slug === 'dropdown' && field.slug != 'magnification'">
          <div class="dropdown-field"
               pTooltip="{{ options[field.id+'_'+item.sample_id][getFieldValue(field.slug, 'dropdown', item.id, 1)]?.text | translate }}"
               tooltipPosition="top">
            <app-prolabo-dropdown-field
              [options]="options[field.id+'_'+item.sample_id]"
              [inputId]="this.idOptions[item.sample_number +'-'+field.slug] | number"
              (OnValueChange)="updateDropdownOption($event, item.id, field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id, 1,this.idOptions[item.sample_number +'-'+field.slug], false, item.sample_id)"
              [defaultValue]="getFieldValue(field.slug, 'dropdown', item.id, 1)">
            </app-prolabo-dropdown-field>
          </div>
        </td>
        <td *ngIf="field.scorecard_field_type.slug === 'dropdown_w_search_n_input'">
          <div class="dropdown-field"
               pTooltip="{{ options[field.id+'_'+item.sample_id][getFieldValue(field.slug, 'dropdown', item.id, 1)]?.text | translate }}"
               tooltipPosition="top">
            <app-dropdown-select
              [options]="options[field.id+'_'+item.sample_id]"
              icon="assets/icons/close.svg"
              (deleteClickedEvent)="deleteFreeOption($event, field.id, item.sample_id)"
              [shouldAddIcon]="field.is_deletable"
              (OnValueChange)="updateDropdownOption($event, item.id, field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id, 1,this.idOptions[item.sample_number +'-'+field.slug], false, item.sample_id)"
              [defaultValue]="getFieldValue(field.slug, 'dropdown', item.id, 1)"
              [offset]="10000"></app-dropdown-select>
          </div>
        </td>
        <td *ngIf="field.slug === 'magnification'">
          <div class="dropdown-field overflow"
               pTooltip="{{ magnificationsLoaded ? dropdownOptions[getFieldValue(field.slug, 'dropdown', item.id, 1)]?.text : allDropdownOptions[getFieldValue(field.slug, 'dropdown', item.id, 1)]?.text | translate }}"
               tooltipPosition="top">
            <app-prolabo-dropdown-field
              (OnDropdownClicked)="getMagnificationDropdownOptions(item.id)"
              [options]="magnificationsLoaded ? dropdownOptions : allDropdownOptions"
              [inputId]="this.idOptions[item.sample_number +'-'+field.slug] | number"
              (OnValueChange)="updateDropdownOption($event, item.id,field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id, 1 ,this.idOptions[item.sample_number +'-'+field.slug], false, item.sample_id)"
              [defaultValue]="getFieldValue(field.slug, 'dropdown', item.id, 1)">
            </app-prolabo-dropdown-field>
          </div>
        </td>
        <td *ngIf="field.scorecard_field_type.slug === 'dropdown_w_input'">
          <div class="dropdown-field"
               pTooltip="{{ options[field.id+'_'+item.sample_id][getFieldValue(field.slug, 'multiselect', item.id, 1)]?.text | translate }}"
               tooltipPosition="top">

            <p-multiSelect [options]="options[field.id+'_'+item.sample_id]" optionValue="value" optionLabel="text"
                           [inputId]="this.idOptions[item.sample_number +'-'+field.slug] | number"
                           [filter]="field.slug!=='result'"
                           [showToggleAll]="field.slug!=='result'"
                           [showHeader]="field.slug!=='result'"
                           (ngModelChange)="updateDropdownOption($event, item.id, field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id, 1,this.idOptions[item.sample_number +'-'+field.slug], true, item.sample_id)"
                           [ngModel]="selectedItems[field.slug + '_' + item.sample_id]"
                           resetFilterOnHide="true">
              <ng-template let-option pTemplate="item">
                <div class="flex align-items-center gap-2" *ngIf="option.isDeletable ==1">
                  <div><img src="assets/icons/close.svg" style="width:15px;"
                            [class]="'flag flag-' + option.text.toLowerCase()"
                            (click)="deleteFreeOption(option.id, field.id, item.sample_id)">
                    {{option.text  | translate}}</div>
                </div>
                <ng-container *ngIf="option.isDeletable ==0">
                  <div>{{option.text  | translate}}</div>
                </ng-container>
              </ng-template>
            </p-multiSelect>
          </div>
        </td>
      </ng-container>
    </tr>
    <ng-container *ngIf="item.number_of_rows > 0 ">
      <ng-container *ngFor="let j of [].constructor(item.number_of_rows); let i = index">
        <tr>
          <td>

          </td>
          <td>

          </td>
          <td>

          </td>
          <ng-container *ngFor="let field of item.headers">
            <td *ngIf="field.scorecard_field_type.slug === 'text' && field.slug != 'remarks'">
              <p class="overflow input-field">
                <app-prolabo-input-field
                  (OnValueChange)="inputChange($event, item.id,field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id, i+2, this.idOptions[item.sample_number +'-'+field.slug+'-'+(i+2)])"
                  [id]="this.idOptions[item.sample_number +'-'+field.slug+'-'+(i+2)]"
                  [for]="this.idOptions[item.sample_number +'-'+field.slug+'-'+(i+2)]"
                  [type]="field.type"
                  [disabled]="field.disabled === 1"
                  [inputType]="field.input_type"
                  [defaultValue]="getFieldValue(field.slug, 'text', item.id, i+2)">
                </app-prolabo-input-field>
              </p>
            </td>
            <td
              *ngIf="field.scorecard_field_type.slug === 'dropdown' && field.slug != 'magnification' && field.slug != 'microscope'">
              <div class="dropdown-field">
                <app-prolabo-dropdown-field
                  [options]="options[field.id+'_'+item.sample_id]"
                  [inputId]="this.idOptions[item.sample_number +'-'+field.slug+'-'+(i+2)]"
                  (OnValueChange)="updateDropdownOption($event, item.id,field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id , i+2,this.idOptions[item.sample_number +'-'+field.slug+'-'+(i+2)], false, item.sample_id)"
                  [defaultValue]="getFieldValue(field.slug, 'dropdown', item.id,i+2)">
                </app-prolabo-dropdown-field>
              </div>
            </td>
            <td *ngIf="field.slug === 'magnification'">
              <div class="dropdown-field">
                <app-prolabo-dropdown-field
                  (OnHideHappened)="onHide($event)"
                  (OnDropdownClicked)="getMagnificationDropdownOptions(item.id)"
                  [options]="magnificationsLoaded ? dropdownOptions : allDropdownOptions"
                  [inputId]="this.idOptions[item.sample_number +'-'+field.slug+'-'+(i+2)]"
                  (OnValueChange)="updateDropdownOption($event, item.id,field.scorecard_model_id, field.id, field.slug, item.order_id, item.sample_type_id,i+2, this.idOptions[item.sample_number +'-'+field.slug+'-'+(i+2)], false, item.sample_id)"
                  [defaultValue]="getFieldValue(field.slug, 'dropdown', item.id, i+2)">
                </app-prolabo-dropdown-field>
              </div>
            </td>
            <td *ngIf="field.slug === 'microscope'">
              <div class="dropdown-field" style="display: inline-flex">
                <ng-container *ngIf="i+1 === item.number_of_rows">
                  <app-prolabo-icon style="padding-left: 20px" class="icon-white border-red"
                                    (click)="deleteRow(i+2, item.id)"
                                    icon="close"></app-prolabo-icon>
                  <app-prolabo-icon style="padding-left: 20px" class="icon-green"
                                    (click)="addTemporaryRow(i+2, item.id)"
                                    icon="add"></app-prolabo-icon>
                </ng-container>
              </div>
            </td>
          </ng-container>
        </tr>

      </ng-container>
    </ng-container>
    <ng-container *ngIf="item.number_of_rows === 0 ">
      <tr>
        <td>

        </td>
        <td>

        </td>
        <td>

        </td>
        <td>
          <div style="display:inline-flex">
            <app-prolabo-icon style="padding-left: 20px" class="icon-green" (click)="addTemporaryRow(1, item.id)"
                              icon="add"></app-prolabo-icon>
          </div>
        </td>
    </ng-container>
  </ng-template>
</p-table>

