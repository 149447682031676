import { Component, OnInit } from '@angular/core';
import { ToastService, UserService } from 'src/app/shared/services';
import { AdminUsersService } from '../../../services/admin-users.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateOrderComponent } from 'src/app/admin/pages/orders/modals/create-order-modal/create-order.component';
import { AdminSimpleOrdersService } from 'src/app/admin/services/admin-simple-orders.service';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { AdminStatics } from '../../../statics/admin-statics';
import { AdminSimpleSamplesService } from '../../../services/admin-simple-samples.service';
import { Type } from '../../../models/type';
import { AdminOrdersService } from '../../../services/admin-orders.service';
import { AdminDiagnosticOrdersService } from '../../../services/admin-diagnostic-orders.service';
import { FunctionsService } from '../../../services/functions.service';
import { Laboratory } from '../../../../models/laboratorie';
import { AdminDashboardService } from '../../../services/admin-dashboard.service';
import { User } from '../../../models/user';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-prolabo-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [DialogService]
})
export class OrdersComponent implements OnInit {

  orders: [];
  samples: any;
  totalSamples;
  totalOrders;
  sortParams;
  searchParam;
  limit = 10;
  offset = 0;
  stateIds = [];
  stepParam: number;
  deadlines: DropdownModel[] = [];
  sampleTypes: Type[] = [];
  sampleTypeId: number;
  laboratories: Laboratory[] = [];
  laboratoryIds = [];
  currentUser: User;
  selectedLaboratory: number;

  orderHeaders = [
    {title: 'NUMBER', sortLabel: AdminStatics.orderNumber},
    {title: 'CLIENT', sortLabel: AdminStatics.usersFirstName},
    {title: 'SITE', sortLabel: AdminStatics.site},
    {title: 'STATE', sortLabel: AdminStatics.latestState},
    {title: 'SAMPLE', sortLabel: AdminStatics.sample},
    {title: 'COMPANY', sortLabel: AdminStatics.company},
    {title: 'BRANCH', sortLabel: AdminStatics.branch},
    {title: 'DATE', sortLabel: AdminStatics.createdAt}
  ];
  private sortByDate: boolean;
  private fromDate: string;
  private toDate: string;
  private filterByState: boolean;
  private filterByCity: boolean;

  constructor(
    private adminService: AdminUsersService,
    private samplesService: AdminSimpleSamplesService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private simpleOrdersService: AdminSimpleOrdersService,
    private diagnosticOrdersService: AdminDiagnosticOrdersService,
    private ordersService: AdminOrdersService,
    private resources: ResourcesService,
    private functions: FunctionsService,
    private dashboardService: AdminDashboardService,
    private userService: UserService,
    private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.selectedLaboratory = this.currentUser.role_id === 4 ? 0 : this.currentUser.laboratory_id;

    const stepParam = this.route.snapshot.queryParamMap.get('step');
    if (stepParam) {
      const steps = stepParam.split(',');
      steps.forEach(step => {
        // tslint:disable-next-line:radix
        this.stateIds.push(Number.parseInt(step));
      });

      // tslint:disable-next-line:radix
      this.stepParam = Number.parseInt(stepParam[0]);
    }
    if (this.selectedLaboratory === 0) {
      this.filterByCity = true;
      this.laboratoryIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    }
    this.getOrders();
    this.filterByCity = false;
    this.getAllSampleTypes();
    this.getLaboratories();
  }

  exportOrders() {
    const params: any = {locale: this.resources.getLanguage().description};
    if (this.sortParams) {
      params.order_by = this.sortParams;
    }

    if (this.searchParam) {
      params.search = this.searchParam;
    }

    if (this.stateIds && this.stateIds.length > 0) {
      params.state_ids = this.stateIds;
    }

    if (this.sampleTypeId) {
      params.sample_type_id = this.sampleTypeId;
    }

    if (this.sortByDate) {
      params.date_from = this.fromDate;
      params.date_to = this.toDate;
    }

    if (this.filterByState) {
      params.state_ids = this.stateIds;
    }

    if (this.filterByCity) {
      params.laboratory_ids = this.laboratoryIds;
    }

    params[AdminStatics.limit] = this.limit;
    params[AdminStatics.offset] = this.offset;

    this.ordersService.exportOrders(params).subscribe(res => {
      window.open(environment.storageUrl + res.csv, '_blank');
    }, error => this.functions.showError(error));
  }

  getLaboratories() {
    this.dashboardService.getLaboratories().subscribe(res => {
      this.laboratories = res.laboratories;
    });
  }

  createOrderTemp() {
    const body = {
      order_type_id: 1,
      locale: this.resources.getLanguage().description,
    };
    this.simpleOrdersService.orderCreate(body).subscribe(result => {
      this.router.navigate(['/admin/orders/simple-order', result.order.id]).then(() => {
      });
    }, err => this.functions.showError((err)));
  }

  createOrder() {
    const ref = this.dialogService.open(CreateOrderComponent, {
      showHeader: false,
      width: '70%',
      styleClass: 'dialog',
      data: {deadlines: this.deadlines, user: false, laboratories: this.laboratories}
    });

    ref.onClose.subscribe(res => {
      if (res) {
        if (res.type === AdminStatics.simpleOrder) {
          const body = {
            order_type_id: 1,
            locale: this.resources.getLanguage().description,
            user_id: res.user_id
          };
          this.simpleOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/admin/orders/simple-order', result.order.id]).then(() => {
            });
          }, err => this.functions.showError((err)));
        } else if (res.type === AdminStatics.diagnostics) {
          const body = {
            order_type_id: 2,
            locale: this.resources.getLanguage().description,
          };
          this.diagnosticOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/admin/orders/diagnostic-order', result.order.id]).then(() => {
            });
          }, err => this.functions.showError((err)));
        }
      }

    });
  }

  getOrders() {
    const params: any = {locale: this.resources.getLanguage().description};
    if (this.sortParams) {
      params.order_by = this.sortParams;
    }

    if (this.searchParam) {
      params.search = this.searchParam;
    }

    if (this.stateIds && this.stateIds.length > 0) {
      params.state_ids = this.stateIds;
    }

    if (this.sampleTypeId) {
      params.sample_type_id = this.sampleTypeId;
    }

    if (this.sortByDate) {
      params.date_from = this.fromDate;
      params.date_to = this.toDate;
    }

    if (this.filterByState) {
      params.state_ids = this.stateIds;
    }

    if (this.filterByCity) {
      params.laboratory_ids = this.laboratoryIds;
    }

    params[AdminStatics.limit] = this.limit;
    params[AdminStatics.offset] = this.offset;

    this.ordersService.getOrders(params).subscribe(res => {
      this.orders = res[1];
      this.totalOrders = res[0].orders?.total;
      this.totalSamples = res[0].samples?.total;
      this.samples = [];
      if (res[0].samples) {
        Object.entries(res[0].samples).map(sample => {
          if (sample[0] !== AdminStatics.total) {
            this.samples.push(sample[1]);
          }
        });
      }
    });

  }

  getAllSampleTypes() {
    this.ordersService.getAllSampleTypes().subscribe(res => {
      this.sampleTypes = res;
    });
  }

  deleteOrder(id) {
    const params = {locale: this.resources.getLanguage().description, id};
    this.ordersService.deleteOrder(params).subscribe(res => {
        this.toast.showBottomCenterInfo(res.message);
        this.searchParam = null;
        this.getOrders();
      },
      error => {
        this.functions.showError(error);
      });
  }

  sortByLabel(param) {
    this.sortParams = param.asc ? param.param + AdminStatics.asc : param.param + AdminStatics.desc;
    this.getOrders();
  }

  changeLimit(limit) {
    this.limit = limit;
    this.offset = 0;
    this.getOrders();
  }

  changeOffset(page) {
    this.offset = page * this.limit;
    this.getOrders();
  }

  onSearchParamChange(param) {
    this.searchParam = param;
    this.getOrders();
  }

  filterBySample(sampleId) {
    if (sampleId === -1) {
      this.sampleTypeId = null;
    } else {
      this.sampleTypeId = sampleId;
    }

    this.getOrders();
  }

  deleteParam() {
    this.stepParam = null;
    this.stateIds = [];
    this.router.navigate(
      ['.'],
      {relativeTo: this.route, queryParams: {}}
    );

    this.getOrders();
  }


  filterByDate($event: string[]) {
    this.sortByDate = true;
    this.fromDate = $event[0];
    this.toDate = $event[1];

    this.getOrders();
  }

  handleFilterByOrderState($event: any) {
    this.filterByState = true;
    switch ($event) {
      case -1:
        this.stateIds = [];
        break;
      case 0:
        this.stateIds = [1, 7];
        break;
      case 1:
        this.stateIds = [2, 8];
        break;
      case 2:
        this.stateIds = [3, 9];
        break;
      case 3:
        this.stateIds = [4];
        break;
      case 4:
        this.stateIds = [5, 10];
        break;
      case 5:
        this.stateIds = [6, 11];
        break;
    }

    this.getOrders();
  }

  handleFilterByOrderCity($event: any) {
    this.filterByCity = true;
    if ($event === -1) {
      this.laboratoryIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    } else {
      this.laboratoryIds = [$event];
    }

    this.getOrders();

  }
}
