<div class="centered">
  <div class="p-grid center-content">
    <div class="p-mr-2">
      <app-logo-container [routerLink]="path" [isAdmin]="isAdmin"></app-logo-container>
    </div>
    <p-card>
      <div class="p-grid spacing-bottom">
        <div class="p-col-6 title">
          <p-header>{{'BASIC.INFO' | translate}}</p-header>
        </div>
        <div class="p-mt-1 p-col-6 title-right text-gray text-large link">
          <p-header [routerLink]="path">{{'LOGIN' | translate}}</p-header>
        </div>
      </div>
      <div class="p-grid">
        <form [formGroup]="registerForm">
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-4 p-lg-4">
              <app-prolabo-input [for]="'name'"
                                 [id]="'name'"
                                 [keyword]="'NAME'"
                                 [type]="'text'"
                                 [inputType]="'basic'"
                                 formControlName="first_name"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-4 p-lg-4">
              <app-prolabo-input [for]="'surname'"
                                 [id]="'surname'"
                                 [keyword]="'SURNAME'"
                                 [type]="'text'"
                                 [inputType]="'basic'"
                                 formControlName="last_name"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-4 p-lg-4">
              <app-prolabo-dropdown [options]="titles"
                                    [defaultValue]="1"
                                    (OnValueChange)="updateTitleValue($event)"
                                    placeholder="TITLE"></app-prolabo-dropdown>
            </div>
            <div class="p-col-12" *ngIf="!isAdmin">
              <div class="p-grid">
                <div class="p-col-4">
                  <div class="p-grid company-radio-container">
                    <ng-container *ngFor="let type of company">
                      <div class="p-col-6 company-radio">
                        <p-radioButton [inputId]="type.key" [(ngModel)]="selectedType"
                                       [ngModelOptions]="{standalone: true}"
                                       class="radio-icon"
                                       name="type"
                                       (ngModelChange)="handleTypeChange($event)"
                                       [value]="type"></p-radioButton>
                        <p class="radio-label">{{type.name}}</p>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="p-col-8">
                  <ng-container *ngIf="isCompany">
                    <app-prolabo-input [for]="'company'"
                                       [id]="'company'"
                                       [keyword]="'COMPANY'"
                                       [type]="'text'"
                                       [inputType]="'basic'"
                                       formControlName="company"></app-prolabo-input>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12" *ngIf="isAdmin">
              <app-prolabo-input [for]="'company'"
                                 [id]="'company'"
                                 [keyword]="'COMPANY'"
                                 [type]="'text'"
                                 [inputType]="'basic'"
                                 formControlName="company"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12" *ngIf="!isAdmin">
              <app-prolabo-dropdown [options]="invoiceTypes"
                                    [defaultValue]="2"
                                    (OnValueChange)="updateInvoiceType($event)"
                                    placeholder="INVOICE_TYPE"></app-prolabo-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <app-prolabo-input [for]="'email'"
                                 [id]="'email'"
                                 [keyword]="'EMAIL'"
                                 [type]="'text'"
                                 [inputType]="'email'"
                                 formControlName="email"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <app-prolabo-input [for]="'invoice_email'"
                                 [id]="'invoice_email'"
                                 [keyword]="'INVOICE_MAIL'"
                                 [type]="'text'"
                                 [inputType]="'email'"
                                 [required]="false"
                                 formControlName="invoice_email"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-6 p-lg-6">
              <app-prolabo-input [for]="'phone'"
                                 [id]="'phone'"
                                 [keyword]="'PHONE'"
                                 [type]="'number'"
                                 [required]="false"
                                 [inputType]="'nonRequired'"
                                 formControlName="phone"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-6 p-lg-6">
              <app-prolabo-input [for]="'mobile'"
                                 [id]="'mobile'"
                                 [keyword]="'MOBILE'"
                                 [type]="'number'"
                                 [inputType]="'basic'"
                                 formControlName="mobile"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <app-prolabo-dropdown [options]="languages"
                                    (OnValueChange)="updateLanguageValue($event)"
                                    [defaultValue]="1"
                                    placeholder="LANGUAGE"></app-prolabo-dropdown>
            </div>
            <div class="p-col-12 p-md-4 p-lg-4">
              <app-prolabo-input [for]="'address'"
                                 [id]="'address'"
                                 [keyword]="'ADDRESS'"
                                 [type]="'text'"
                                 [inputType]="'basic'"
                                 formControlName="address"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-4 p-lg-4">
              <app-prolabo-input [for]="'postal_code'"
                                 [id]="'postal_code'"
                                 [keyword]="'POSTCODE'"
                                 [type]="'text'"
                                 [inputType]="'basic'"
                                 formControlName="postal_code"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-4 p-lg-4">
              <app-prolabo-input [for]="'city'"
                                 [id]="'city'"
                                 [keyword]="'CITY'"
                                 [type]="'text'"
                                 [inputType]="'basic'"
                                 formControlName="city"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <app-prolabo-input [for]="'password'"
                                 [id]="'password'"
                                 [keyword]="'PASSWORD'"
                                 [type]="'password'"
                                 [inputType]="'password'"
                                 formControlName="password"></app-prolabo-input>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <app-prolabo-input [for]="'confirm_password'"
                                 [id]="'confirm_password'"
                                 [keyword]="'PASSWORD_CONFIRMATION'"
                                 [type]="'password'"
                                 [inputType]="'password'"
                                 (OnValueChange)="checkPasswords($event)"
                                 formControlName="password_confirmation"></app-prolabo-input>
            </div>
            <div class="p-md-2 p-lg-2">
              <p class="sort-label check-box"
                 pTooltip="{{ 'SWITCH_EVERYTHING_PIN' | translate }}"
                 tooltipPosition="top">
                <span class="overflow">{{ 'SWITCH_EVERYTHING_PIN' | translate }} &nbsp;</span>
                <p-checkbox class="checkbox-icon" name="groupname" value="0"
                            (onChange)="check($event)">
                </p-checkbox>
            </div>
            <ng-container *ngIf="pinRequested">
              <div class="p-col-12 p-md-6 p-lg-6">
                <app-prolabo-input [for]="'pin'"
                                   [id]="'pin'"
                                   [keyword]="'PIN'"
                                   [type]="'password'"
                                   [inputType]="'pin'"
                                   formControlName="pin"></app-prolabo-input>
              </div>
            </ng-container>
            <div class="{{pinRequested ? '' : 'p-col-12 p-md-6 p-lg-6'}}">
              <div class="signature-pad {{class}}">
                <span class="p-label-signature" *ngIf="isAdmin">{{ 'SIGNATURE' | translate | uppercase }}</span>
                <div class="signature-pad--body {{class}}">
                  <canvas class="{{class}}" #sPad height="65px" class="touch-action"></canvas>
                </div>
                <ng-container *ngIf="isAdmin">
                  <div class="icons">
                    <i class="pi pi-times" (click)="clear()"></i>
                    <i class="pi pi-undo" (click)="undo()"></i>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="p-md-2 p-lg-2">
            </div>
            <div class="p-col-12 p-md-2 p-lg-2 register-button">
              <app-prolabo-icon-button (click)="register()" [name]="'REGISTER'" type="secondary"
                                       [icon]="'check'"></app-prolabo-icon-button>
            </div>
          </div>
        </form>
      </div>
    </p-card>
  </div>
</div>
