import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models';
import { ResponsiblePerson } from 'src/app/admin/models/responsible-person';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminUsersService } from 'src/app/admin/services/admin-users.service';
import { ToastService, UserService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { ResponsibleService } from '../services/responsible.service';
import { FunctionsService } from '../../admin/services/functions.service';

@Component({
  selector: 'app-prolabo-user-responsible',
  templateUrl: './user-responsible.component.html',
  styleUrls: ['./user-responsible.component.scss']
})
export class UserResponsibleComponent implements OnInit, OnDestroy {
  @Input() user: User;

  responsiblePeople: ResponsiblePerson[];
  createForm: FormGroup;
  addResponsibleClicked = false;
  emptyResponsibleList: Array<any>;

  displayModal = false;
  itemToDelete = null;

  @Output() updatedUser = new EventEmitter<number>();

  event = (event: KeyboardEvent) => {
    if (event.which === 13 && this.addResponsibleClicked) {
      this.submit();
    }
  }

  constructor(private adminService: AdminUsersService,
              private responsibleService: ResponsibleService,
              private userService: UserService,
              private formBuilder: FormBuilder,
              private router: Router,
              private toast: ToastService,
              private functions: FunctionsService,
              private resources: ResourcesService) {
    window.addEventListener('keydown', this.event);

  }

  ngOnInit(): void {
    this.emptyResponsibleList = new Array(0);
    this.createForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      locale: [this.resources.getLanguage().description],
      user_id: [this.user.id]
    });
  }

  deleteResponsibleUser() {
    this.displayModal = false;
    const body = {
      responsible_id: this.itemToDelete,
      user_id: this.user.id,
      locale: this.resources.getLanguage().description
    };
    this.responsibleService.deleteResponsibleUser(body).subscribe(result => {
        this.toast.showBottomCenterSuccess(result.message);
        this.updatedUser.emit(this.user.id);
        this.itemToDelete = null;
      },
      error => {
        this.itemToDelete = null;
        this.functions.showError(error);
      });

  }

  openConfirmDialog(id) {
    this.displayModal = true;
    this.itemToDelete = id;
  }

  closeConfirmDialog() {
    this.itemToDelete = null;
    this.displayModal = false;
  }

  submit() {
    this.createForm.patchValue({
      user_id: this.user.id,
      locale: this.resources.getLanguage().description
    });
    this.responsibleService.createResponsibleUser(this.createForm.value).subscribe(result => {
      this.toast.showBottomCenterSuccess(result.message);
      this.updatedUser.emit(this.user.id);
      this.createForm.reset();
      this.addResponsibleClicked = false;
    }, error => {
      this.functions.showError(error);
    });
  }

  addResponsible() {
    this.emptyResponsibleList.push({first_name: '', last_name: '', email: ''});
    this.addResponsibleClicked = true;
  }

  removeResponsible() {
    this.createForm.reset();
    this.addResponsibleClicked = false;
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.event);
  }
}
