import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AdminStatics } from '../../../../statics/admin-statics';
import { FunctionsService } from '../../../../services/functions.service';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { DropdownModel } from '../../../../../shared/components/dropdown/dropdown.model';

@Component({
  selector: 'app-prolabo-last-orders-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class LastOrdersListComponent implements OnInit, OnChanges {

  @Input() items: any[];
  @Input() headers: any[];
  @Input() critical = false;

  @Input() shouldSort = true;
  @Input() user = false;

  @Input() filterItems: [];

  @Output() sortValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterBy: EventEmitter<[]> = new EventEmitter<[]>();
  @Output() filterByDate: EventEmitter<string[]> = new EventEmitter<[]>();

  sortHeaderValues = [];
  page = 0;
  numberRows = 10;

  totalSteps = 4;

  filterValues: DropdownModel[];

  selectedSample: FormControl = new FormControl();

  date: string;

  rangeDates: Date[];

  sortingDates: string[];
  sampleIsSelected = false;

  constructor(private translate: TranslateService,
              public functions: FunctionsService,
              public datePipe: DatePipe,
              private router: Router
  ) {
    this.date = this.datePipe.transform(new Date().toISOString(), 'yyyy-MM-dd HH:mm:ss');
    this.filterValues = [
      {text: this.translate.instant('ALL'), id: -1, value: -1},
      {text: this.translate.instant('CREATION'), id: 0, value: 0},
      {text: this.translate.instant('ORDERED'), id: 1, value: 1},
      {text: this.translate.instant('ANALYSING'), id: 2, value: 2},
      {text: this.translate.instant('ANALYSED'), id: 3, value: 3}
    ];
  }

  ngOnInit() {
    this.headers.forEach(header => {
      this.sortHeaderValues.push({param: header[AdminStatics.sortLabel], asc: true});
    });

    this.sampleIsSelected = this.selectedSample.value && this.selectedSample.value !== -1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.filterItems && this.filterItems.length > 0) {
      this.filterValues = this.functions.getFilterItemValues(this.filterItems);
    }

    this.sampleIsSelected = this.selectedSample.value && this.selectedSample.value !== -1 || this.selectedSample.value === 0;
  }

  navigate(id, type, state) {
    if (state !== 6) {
      if (type === AdminStatics.simpleOrder) {
        if (this.user) {
          this.router.navigate(['/user/orders/simple-order', id]);
        } else {
          this.router.navigate(['/admin/orders/simple-order', id]);
        }
      }
      if (type === AdminStatics.diagnosticOrder) {
        if (this.user) {
          this.router.navigate(['/user/orders/diagnostic-order', id]);
        } else {
          this.router.navigate(['/admin/orders/diagnostic-order', id]);
        }
      }
    }
  }

  handleFilterDropdownChange(event) {
    const step = event.value + 1;
    if (event.value !== -1) {
      let value = [];
      if (step === 5) {
        value = [5, 10];
      } else if (step === 4) {
        value = [4];
      } else if (step === 1) {
        value = [1, 7];
      } else if (step === 2) {
        value = [2, 8];
      } else if (step === 3) {
        value = [3, 9];
      }
      // @ts-ignore
      this.filterBy.emit(value);
    } else {
      this.filterBy.emit([]);
    }
  }

  sortByLabel(param: string) {
    this.clearOtherValues(param);
    const sortValue = this.sortHeaderValues.filter(x => x.param === param)[0];
    sortValue.asc = !sortValue.asc;

    this.sortValue.emit(sortValue);
  }

  getLastHeader() {
    return this.headers[this.headers.length - 1];
  }

  clearOtherValues(param) {
    this.sortHeaderValues.forEach(header => {
      if (param !== header.param) {
        header.asc = true;
      }
    });
  }

  getAscValueForHeader(header) {
    return this.sortHeaderValues.filter(x => x.param === header)[0].asc;
  }

  dateChange($event: any) {
    this.sortingDates = new Array(0);
    let countNotNull = 0;
    this.rangeDates.forEach(x => {
      if (x != null) {
        countNotNull++;
        this.sortingDates.push(this.datePipe.transform(x.toISOString(), 'yyyy-MM-dd'));
      }
    });
    if (countNotNull === 2) {
      this.filterByDate.emit(this.sortingDates);
    }
  }
}
