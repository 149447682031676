<div class="clients-container">
  <div class="p-grid pdng">
    <div class="p-col-3">
    </div>
    <div class="p-col-6">
      <form [formGroup]="scorecardFieldForm">
        <app-prolabo-input keyword="FILE_FIELD" [required]="true" type="text"
                           id="file_name" for="file_name"
                           formControlName="slug"
                           inputType="basic"></app-prolabo-input>
        <div class="p-grid p-fluid pdng-top">
          <div class="p-col-12 p-md-12">
            <app-prolabo-dropdown [options]="dropdownModels"
                                  (OnValueChange)="updateDropdownModel($event)"
                                  [defaultValue]="dropdownModels[0].id"
                                  placeholder="FIELD.TYPE"></app-prolabo-dropdown>
          </div>
        </div>
      </form>
    </div>
    <div class="p-col-3">
    </div>
  </div>
  <div class="p-grid client-dialog-footer">
    <div class="p-col-12 p-md-6">
      <app-prolabo-icon-button
        (click)="back()"
        [icon]="'left-arrow'"
        [name]="'PREVIOUS'"
        [position]="'left'"
        type="primary"></app-prolabo-icon-button>
    </div>
    <div class="p-col-12 p-md-6">
      <app-prolabo-icon-button
        (click)="submit()"
        [icon]="'arrow-right-white'"
        [name]="'NEXT'"
        [position]="'right-arrow'"
        type="primary"></app-prolabo-icon-button>
    </div>
  </div>
</div>
