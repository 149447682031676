<div class="clients-container">
  <app-dialog-header [title]="'REGENERATE_INVOICE'" (iconClick)="dialogRef.close(false)"></app-dialog-header>
  <div class="p-grid">
    <div class="p-col-12">
      <p class="message">{{message}}</p>
    </div>
  </div>
  <div class="p-grid extra"></div>
  <div class="p-grid client-dialog-footer">
    <div class="p-col-12 p-md-6">
      <app-prolabo-icon-button (click)="cancel()"
                               [icon]="'close-white'"
                               [name]="'CANCEL'"
                               type="secondary">
      </app-prolabo-icon-button>
    </div>
    <div class="p-col-12 p-md-6">
      <app-prolabo-icon-button (click)="submit()"
                               [icon]="'check'"
                               [name]="'VALIDATE'"
                               type="secondary">
      </app-prolabo-icon-button>
    </div>
  </div>
</div>
