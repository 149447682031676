import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';

@Component({
  selector: 'app-prolabo-administration',
  templateUrl: './profile-administration.component.html',
  styleUrls: ['./profile-administration.component.scss']
})
export class ProfileAdministrationComponent implements OnInit {
  @Input() currentUser: User;
  @Input() laboratories: DropdownModel[];
  @Input() invoiceTypes: DropdownModel[];

  @Output() validateClicked = new EventEmitter<boolean>();
  @Output() administrationFormEvent = new EventEmitter<FormGroup>();
  @Output() administrationOriginalFormEvent = new EventEmitter<any>();
  administrationForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.administrationForm = this.formBuilder.group({
      initials: [this.currentUser.initials, Validators.required],
      notes: [this.currentUser.notes, Validators.required],
      laboratory_id: ['', Validators.required],
      invoice_type_id: ['', Validators.required]
    });
    this.administrationOriginalFormEvent.emit(this.administrationForm.value);
  }

  validate() {
    this.validateClicked.emit(true);
    this.administrationFormEvent.emit(this.administrationForm);
  }

  updateLaboratoriesEvent($event: any) {
    this.administrationForm.patchValue({
      laboratory_id: $event
    });
  }

  updateInvoiceTypes($event: string) {
    this.administrationForm.patchValue({
      invoice_type_id: $event
    });
  }
}
