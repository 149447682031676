import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ModifyOrder,
  SimpleOrderDeleteResponse,
  SampleCounts,
} from 'src/app/admin/models/simple-order-response';
import { DeadlineResponse } from 'src/app/models/deadline';
import { ResourcesService } from '../../shared/services/resources.service';
import { AdminRoutesStatics } from '../statics/admin-routes-statics';
import {
  CollectMethodsResponse,
  PlanDeleteResponse,
  PlanResponse,
  PlansResponse
} from '../models/plan-response';
import { DiagnosticOrder, DiagnosticOrderResponse, DiagnosticSubmitOrder } from '../models/diagnostic-order-response';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminDiagnosticOrdersService {

  constructor(private http: HttpClient, private resources: ResourcesService) {
  }

  public getOrder(body): Observable<DiagnosticOrderResponse> {
    return this.http.post<DiagnosticOrderResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrder}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getOrderSampleCounts(body): Observable<SampleCounts> {
    return this.http.post<SampleCounts>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderSamplesCount}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getOrders(body): Observable<DiagnosticOrder[]> {
    return this.http.post<DiagnosticOrder[]>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrders}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public orderCreate(body): Observable<DiagnosticOrderResponse> {
    return this.http.post<DiagnosticOrderResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.createOrder}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public updateOrder(body): Observable<DiagnosticOrderResponse> {
    return this.http.post<DiagnosticOrderResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.updateOrder}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public deleteOrder(body): Observable<SimpleOrderDeleteResponse> {
    return this.http.delete<SimpleOrderDeleteResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.deleteOrder}`,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
        params: body
      });
  }

  public getOrderSubtype(): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderSubtypes}`,
      {locale: this.resources.getLanguage().description},
      {
        headers: this.getHeaders(this.resources.getStorageUser().token)
      });
  }

  public updateVisitInfo(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.updateVisitInfo}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public getDeadlines(): Observable<DeadlineResponse> {
    return this.http.post<DeadlineResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getOrderDeadlines}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public submitOrder(body): Observable<DiagnosticSubmitOrder> {
    return this.http.post<DiagnosticSubmitOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.submitOrder}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public submitStep2(body): Observable<DiagnosticSubmitOrder> {
    return this.http.post<DiagnosticSubmitOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.submitStepTwo}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public submitStep3(body): Observable<DiagnosticSubmitOrder> {
    return this.http.post<DiagnosticSubmitOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.submitStepThree}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public createNewVersion(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.newVersion}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public cancelOrder(body): Observable<ModifyOrder> {
    return this.http.post<ModifyOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.cancelOrder}`, body,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
      }
    );
  }

  public reActivateOrder(body): Observable<ModifyOrder> {
    return this.http.post<ModifyOrder>(`${environment.adminApiUrl}${AdminRoutesStatics.restore}`, body,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
      }
    );
  }

  public planCreate(body): Observable<PlanResponse> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.createPlan}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public deletePlan(body): Observable<PlanDeleteResponse> {
    return this.http.delete<SimpleOrderDeleteResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.deletePlan}`,
      {
        headers: this.getHeaders(this.resources.getStorageUser().token),
        params: body
      });
  }

  public getPlans(id): Observable<PlansResponse> {
    return this.http.post<PlansResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getPlans}`,
      {id, locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getCollectMethods(): Observable<CollectMethodsResponse> {
    return this.http.post<CollectMethodsResponse>(`${environment.apiUrl}${AdminRoutesStatics.getCollectMethods}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getReport(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getDiagnosticReport}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getReportStatus(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getReportStatus}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public getReportAtStatus(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getReportAtStatus}`,
      body, {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public sendReport(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.sendReport}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getCorruptedSamples(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getCorruptedSamples}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public checkReportValues(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.checkReportValues}`,
      body, {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }


  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }

  private getMultiPartHeader(token): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }
}
