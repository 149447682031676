import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prolabo-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {

  @Input() class: any;
  @Input() title: any;
  @Input() link: any;
  @Input() icon: any;
  @Input() active: any;
  @Input() pinRequested: number;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  navigate(link: any) {
    if (!this.pinRequested) {
      this.router.navigateByUrl(link);
    } else {
      if (this.title !== 'BILLING_USER') {
        this.router.navigateByUrl(link);
      }
    }
  }
}
