<div class="clients-container">
  <ng-container *ngIf="state === 'create'">
    <app-dialog-header [title]="'ADD_SAMPLE'" (iconClick)="ref.close()"></app-dialog-header>
  </ng-container>
  <ng-container *ngIf="state === 'update'">
    <app-dialog-header [title]="'UPDATE_SAMPLE'" (iconClick)="ref.close()"></app-dialog-header>
  </ng-container>
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="!vdiFormType">
      <form [formGroup]="sampleForm">
        <div class="p-grid p-fluid content">
          <div class="p-col-12">
            <div class="p-col-12 no-space">
              <div class="card-input">
                <p class="order-number-text">{{'NUMBER' | translate | uppercase }}</p>
                <ng-container *ngIf="state === 'create'">
                  <p class="order-number">{{nextSampleId}}</p>
                </ng-container>
                <ng-container *ngIf="state === 'update'">
                  <p class="order-number">{{sample.sample_id}}</p>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="p-col-12">
            <app-prolabo-dropdown (OnValueChange)="updateTypeValue($event)"
                                  [defaultValue]="sample?.sample_type_id || sampleId"
                                  [options]="types"
                                  [disabled]="disabled"
                                  placeholder="TYPE"></app-prolabo-dropdown>
          </div>
          <div class="p-col-12">
            <app-prolabo-input [for]="'sample_number'"
                               [id]="'sample_number'"
                               [keyword]="'SAMPLENUMBER'"
                               [type]="'text'"
                               [inputType]="'basic'"
                               [defaultValue]="sample?.sample_number"
                               (OnValueChange)="updateSampleNumber($event)"
                               formControlName="sample_number"></app-prolabo-input>
          </div>
          <div class="p-col-12">
            <app-prolabo-input [for]="'description'"
                               [id]="'description'"
                               [keyword]="'DESCRIPTION'"
                               [type]="'text'"
                               [inputType]="'basic'"
                               [defaultValue]="sample?.description"
                               formControlName="description"></app-prolabo-input>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="vdiFormType">
      <form [formGroup]="vdiForm">
        <div class="p-grid p-fluid content">
          <div class="p-col-12">
            <div class="p-grid">
              <div class="p-col-12 no-space">
                <div class="card-input">
                  <p class="order-number-text">{{ 'NUMBER' | translate | uppercase }}</p>
                  <p class="order-number">{{nextSampleId}}</p>
                </div>
              </div>
              <div class="p-col-12">
                <app-prolabo-dropdown (OnValueChange)="updateTypeValue($event)"
                                      [defaultValue]="sample?.sample_type_id || sampleId"
                                      [options]="types"
                                      [disabled]="disabled"
                                      placeholder="TYPE"></app-prolabo-dropdown>
              </div>
              <div class="p-col-12">
                <app-prolabo-input [for]="'sample_number'"
                                   [id]="'sample_number'"
                                   [keyword]="'SAMPLENUMBER'"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   [defaultValue]="sample?.sample_number"
                                   (OnValueChange)="updateSampleNumber($event)"
                                   formControlName="sample_number"></app-prolabo-input>
              </div>
              <div class="p-col-12">
                <app-prolabo-input [for]="'description'"
                                   [id]="'description'"
                                   [keyword]="'DESCRIPTION'"
                                   [type]="'text'"
                                   [inputType]="'basic'"
                                   [defaultValue]="sample?.description"
                                   formControlName="description"></app-prolabo-input>
              </div>
              <div class="p-col-12">
                <app-prolabo-input [for]="'areaField'"
                                   [id]="'areaField'"
                                   [keyword]="'VOLUME.AIR.TAKEN'"
                                   [type]="'number'"
                                   [inputType]="'number'"
                                   [defaultValue]="sample?.vdi_info?.volume_of_air_taken"
                                   formControlName="volume_of_air_taken"></app-prolabo-input>
              </div>
              <div class="p-col-12">
                <app-prolabo-input [for]="'temperature'"
                                   [id]="'temperature'"
                                   [keyword]="('TEMPERATURE' | translate) + ' (°C)'"
                                   [type]="'number'"
                                   [inputType]="'number'"
                                   [required]="false"
                                   [defaultValue]="sample?.vdi_info?.temperature"
                                   formControlName="temperature"></app-prolabo-input>
              </div>
              <div class="p-col-12">
                <app-prolabo-input [for]="'relative_humidity'"
                                   [id]="'relative_humidity'"
                                   [keyword]="('RELATIVE_HUMIDITY' | translate) + ' (%)'"
                                   [type]="'number'"
                                   [inputType]="'number'"
                                   [required]="false"
                                   [defaultValue]="sample?.vdi_info?.relative_humidity"
                                   formControlName="relative_humidity"></app-prolabo-input>
              </div>
              <div class="p-col-12" *ngIf="sample?.sample_type_id == 1 || this.sampleId === 1">
                <div class="calendar-design">
                  <label style="padding-left: 9px"
                         for="buttonbar">{{"DATE_OF_SAMPLING" | translate | uppercase}}</label>
                  <p-calendar (onSelect)="dateChange($event)"
                              placeholder="{{'DATE_OF_SAMPLING' | translate | uppercase}}"
                              [showIcon]="true"
                              inputId="basic"
                              (onClearClick)="clearClicked($event)"
                              showButtonBar="true"
                              formControlName="date_sampling"
                              dateFormat="dd/mm/yy"
                              id="buttonbar"
                  ></p-calendar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <div class="p-grid">
      <div class="p-col-12" *ngIf="vdiFormType">
        <app-prolabo-icon-button
          [icon]="'arrow-right-white'"
          [name]="'VALIDATE'"
          (click)="validateVdiForm()"
          type="primary"></app-prolabo-icon-button>
      </div>
      <div class="p-col-12" *ngIf="!vdiFormType">
        <app-prolabo-icon-button
          [icon]="'arrow-right-white'"
          [name]="'VALIDATE'"
          (click)="validateSampleForm()"
          type="primary"></app-prolabo-icon-button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loading" class="centered">
    <p class="text">{{"PLEASE.WAIT" | translate}}</p>
  </ng-container>
</div>
