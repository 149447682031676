<div class="order-activities">
  <h3 class="title">{{ 'ORDER_ACTIVITIES' | translate }}</h3>
  <ng-container *ngFor="let activity of orderActivities">
    <app-prolabo-step
      [image]="activity.icon"
      [title]="activity.name"
      [step]="activity.step"
      [total]="getTotals(activity.keyword)"
      (stepClick)="onStepClick($event)">
    </app-prolabo-step>
  </ng-container>
</div>
