import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, LowerCasePipe, UpperCasePipe } from '@angular/common';
import { ApiService, LoaderService } from './shared/services';
import { PreloadingService } from './shared/services/preloading.service';
import { environment } from '../environments/environment';
import { LoaderInterceptor } from './shared/interceptors/loader-interceptor.service';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { CommonModule } from './common/common.module';
import { AnalystModule } from './analyst/analyst.module';
import { AdminModule } from './admin/admin.module';
import { UserModule } from './user/user.module';
import { AnalystCreatorContainerComponent } from './admin/pages/analyst-creator/analyst-creator-container/analyst-creator-container.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgControl } from '@angular/forms';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    AnalystCreatorContainerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: environment.defaultLanguage
    }),
    SharedModule,
    HttpClientModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    CommonModule,
    AnalystModule,
    AdminModule,
    UserModule,
    SharedModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    DatePipe,
    LowerCasePipe,
    UpperCasePipe,
    TranslatePipe,
    ApiService,
    LoaderService,
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: preloadResourceServiceFactory, deps: [ResourcesService], multi: true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function preloadServiceFactory(service: PreloadingService) {
  return () => service.load();
}

export function preloadResourceServiceFactory(service: ResourcesService) {
  return () => service.load();
}
