import { Injectable } from '@angular/core';
import { I18nService, UserService } from '.';
import { ActivatedRoute, Router } from '@angular/router';
import { Language, StorageUser } from '../../models';
import { AdminUsersService } from '../../admin/services/admin-users.service';
import { environment } from '../../../environments/environment';
import { ResourcesService } from 'src/app/shared/services/resources.service';

@Injectable({
  providedIn: 'root'
})
export class PreloadingService {

  simpleRoutes = [
    'login',
    'register',
    'forgot-password;admin=false',
    'forgot-password;admin=true',
  ];

  tokenRoutes = [
    'verify-account',
    'reset-password',
    'order-redirect'
  ];

  private storageUser: StorageUser;

  constructor(private userService: UserService,
              private resources: ResourcesService,
              private router: Router, private admin: AdminUsersService, private lang: I18nService) {
  }

  checkLocation(location: string, url: string[]): string | boolean {
    const checkLoginOrRegister = this.simpleRoutes.find(x => x === url[2]);
    const checkSimpleRoutes = this.simpleRoutes.find(x => x === location);
    const checkTokenRoutes = this.tokenRoutes.find(x => x === location);
    if (checkLoginOrRegister) {
      return `${location}/${url[2]}`;
    } else if (checkSimpleRoutes) {
      return location;
    } else if (checkTokenRoutes && url[2]) {
      return url.join('/');
    } else if (location === '') {
      return '/login';
    } else {
      return false;
    }
  }

  checkIfNotAllowed(location: string, url: string[]): boolean {
    const checkLoginOrRegister = this.simpleRoutes.find(x => x === url[2]);
    const checkSimpleRoutes = this.simpleRoutes.find(x => x === location);
    const checkTokenRoutes = this.tokenRoutes.find(x => x === location);
    if (checkLoginOrRegister) {
      return true;
    } else if (checkSimpleRoutes) {
      return true;
    } else if (checkTokenRoutes && url[2]) {
      return true;
    } else if (location === '') {
      return false;
    } else {
      return false;
    }
  }


  load() {
    const currentLang: Language = this.getStorageLanguage();
    this.resources.setLanguage(currentLang);
    const location = window.location.pathname.split('/');
    const loc = this.checkLocation(location[1], location);
    this.storageUser = this.getStorageUser();
    this.resources.setStorageUser(this.storageUser);
    if (currentLang == null) {
      localStorage.setItem('prolaboDefaultLanguage', JSON.stringify(environment.defaultLanguageObj));
    }
    this.lang.currentLang = currentLang?.description;
    const url = location.slice(2);

    return new Promise<boolean>((resolve) => {
      if (this.storageUser) {
        if (this.storageUser?.role === 'admin' || this.storageUser?.role === 'super_admin') {
          this.admin.getUser({locale: 'en'}).subscribe(user => {
            this.userService.setCurrentUser(user.user);
            this.resources.setStorageUser(this.storageUser);
            resolve(true);
            if (location[1] === 'order-redirect') {
              const urlSplited = url[0].split(';');
              const orderIdUrl = urlSplited[1];
              const typeUrl = urlSplited[2];
              const userTypeUrl = urlSplited[3];
              const item: any = {
                orderId: orderIdUrl.split('=')[1],
                type: typeUrl.split('=')[1],
                userType: userTypeUrl.split('=')[1]
              };
              setTimeout(() => {
                this.router.navigate(['admin/dashboard', item]).then(() => {
                });
              }, 100);
            } else {
              if (this.checkIfNotAllowed(location[1], location)) {
                this.router.navigateByUrl(`admin/dashboard`);
              } else {
                this.router.navigateByUrl(`admin/${url.join('/') || 'dashboard'}`);
              }
            }
          }, error => {
            this.router.navigateByUrl('/admin/login').then(() => {
              localStorage.removeItem('prolaboUser');
              window.location.reload();
            });
          });
        } else if (this.storageUser?.role === 'analyst') {
          this.admin.getUser({locale: 'en'}).subscribe(user => {
            this.userService.setCurrentUser(user.user);
            this.resources.setStorageUser(this.storageUser);
            resolve(true);
            if (this.checkIfNotAllowed(location[1], location)) {
              this.router.navigateByUrl(`${this.storageUser?.role}/dashboard`);
            } else {
              this.router.navigateByUrl(`${this.storageUser?.role}/${url.join('/') || 'dashboard'}`);
            }
          }, error => {
            this.router.navigateByUrl('/admin/login').then(() => {
              localStorage.removeItem('prolaboUser');
              window.location.reload();
            });
          });
        } else if (this.storageUser?.role === 'user' || this.storageUser?.role === 'client_lenzburg') {
          this.admin.getUser({locale: 'en'}).subscribe(user => {
            this.userService.setCurrentUser(user.user);
            this.resources.setStorageUser(this.storageUser);
            resolve(true);
            if (location[1] === 'order-redirect') {
              const urlSplited = url[0].split(';');
              const orderIdUrl = urlSplited[1];
              const typeUrl = urlSplited[2];
              const userTypeUrl = urlSplited[2];
              const item: any = {
                orderId: orderIdUrl.split('=')[1],
                type: typeUrl.split('=')[1],
                userType: userTypeUrl.split('=')[1]
              };
              setTimeout(() => {
                this.router.navigate([`user/dashboard'`, item]).then(() => {
                });
              }, 100);
            } else {
              if (this.checkIfNotAllowed(location[1], location) || (user.user.pin_protect === 1 && location.find(x => x === 'invoices'))) {
                this.router.navigateByUrl(`user/dashboard`);
              } else {
                this.router.navigateByUrl(`user/${url.join('/') || 'dashboard'}`);
              }
            }
          }, error => {
            this.router.navigateByUrl('/login').then(() => {
              localStorage.removeItem('prolaboUser');
              window.location.reload();
            });
          });
        }
      } else if (loc) {
        resolve(true);
        this.router.navigateByUrl(`${loc}`);
      } else {
        resolve(true);
        this.router.navigateByUrl('admin/login');
        alert('Route not found');
      }
    });
  }


  getStorageUser() {
    let storageUser: StorageUser;
    const storageUserString = localStorage.getItem('prolaboUser');
    try {
      storageUser = JSON.parse(storageUserString);
    } catch (e) {
      storageUser = null;
      localStorage.removeItem('prolaboUser');
    }
    return storageUser;
  }


  getStorageLanguage() {
    let storageLanguage: Language;
    const storageLanguageString = localStorage.getItem('prolaboDefaultLanguage');
    try {
      storageLanguage = JSON.parse(storageLanguageString);
    } catch (e) {
      storageLanguage = null;
      localStorage.removeItem('prolaboDefaultLanguage');
    }
    return storageLanguage;
  }

}
