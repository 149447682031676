import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent {
  @Input() title: string;

  @Output() iconClick: EventEmitter<any> = new EventEmitter<any>();

  onIconClick() {
    this.iconClick.emit(true);
  }

  constructor(private translate: TranslateService) {

  }


  translateOption(option) {
    if (option === 'DEADLINE.EIGHT') {
      return this.translate.instant(option).replace('DEADLINE.EIGHT.DATE', this.translate.instant('DEADLINE.EIGHT.DATE'));
    } else {
      return this.translate.instant(option);
    }
  }
}
