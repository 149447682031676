import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-prolabo-icon-input',
  templateUrl: './icon-input.component.html',
  styleUrls: ['./icon-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IconInputComponent),
    multi: true
  }]
})
export class IconInputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() keyword: string;
  @Input() type: string;
  @Input() id: string;
  @Input() for: string;
  @Input() inputType: string;
  @Input() icon: string;
  @Input() required = false;
  @Input() regularInput = false;
  @Input() importInput = false;
  @Input() defaultValue = '';
  @Input() class: string;
  @Input() accept: string;
  @Input() multiple: boolean;

  @Output() OnValueChange = new EventEmitter<any>();
  @Output() iconClickedEvent = new EventEmitter<boolean>();

  @ViewChild('fileInput3') input: ElementRef;

  public inputControl: FormControl;


  public value: string;
  public disabled: boolean;


  constructor() {
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  focusInput() {
    document.getElementById(this.id).autofocus = true;

    setTimeout(() => {
      document.getElementById(this.id).focus();
    }, 0);
  }

  itemChange($event) {
    this.onChange($event.currentTarget.value);
    this.OnValueChange.emit($event.currentTarget.value);
  }

  iconClicked() {
    this.iconClickedEvent.emit(true);
  }

  ngOnInit(): void {
    this.inputControl = new FormControl(this.defaultValue, [Validators.required]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValue) {
      this.inputControl = new FormControl(this.defaultValue, [Validators.required]);
    }
  }
}
