import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ResponsiblePerson } from 'src/app/admin/models/responsible-person';
import { ToastService, UserService } from 'src/app/shared/services';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { AdminUsersService } from 'src/app/admin/services/admin-users.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models';
import { InputValidation } from 'src/app/models/input-validation';
import { Router } from '@angular/router';
import { AdminDashboardService } from '../../../services/admin-dashboard.service';
import { ResponsibleService } from '../../../../common/services/responsible.service';
import { FunctionsService } from '../../../services/functions.service';

@Component({
  selector: 'app-prolabo-responsible',
  templateUrl: './profile-responsible.component.html',
  styleUrls: ['./profile-responsible.component.scss']
})
export class ProfileResponsibleComponent implements OnInit, OnDestroy {
  @Input() currentUser: User;

  @Output() dataUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  responsiblePeople: ResponsiblePerson[];
  createForm: FormGroup;
  addResponsibleClicked = false;
  emptyResponsibleList: Array<any>;

  displayModal = false;
  itemToDelete = null;

  event = (event: KeyboardEvent) => {
    if (event.which === 13 && this.addResponsibleClicked) {
      this.submit();
    }
  }

  constructor(private adminService: AdminUsersService,
              private dashboardService: AdminDashboardService,
              private responsibleService: ResponsibleService,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private toast: ToastService,
              private router: Router,
              private functions: FunctionsService,
              private resources: ResourcesService) {
    window.addEventListener('keydown', this.event);

  }

  ngOnInit(): void {
    this.emptyResponsibleList = new Array(0);
    this.createForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      locale: [this.resources.getLanguage().description],
      user_id: [this.currentUser.id]
    });
  }

  deleteResponsibleUser() {
    this.displayModal = false;
    const body = {
      responsible_id: this.itemToDelete,
      user_id: this.currentUser.id,
      locale: this.resources.getLanguage().description
    };
    this.responsibleService.deleteResponsibleUser(body).subscribe(result => {
        this.toast.showBottomCenterSuccess(result.message);
        this.dashboardService.getProfileData({locale: this.resources.getLanguage().description}).subscribe(res => {
          this.userService.setCurrentUser(res.user);
          this.dataUpdate.emit(true);
          this.router.navigate(['/admin/profile']);
        });
        this.itemToDelete = null;
      },
      error => {
        this.itemToDelete = null;
        this.functions.showError(error);
      });

  }

  openConfirmDialog(id) {
    this.displayModal = true;
    this.itemToDelete = id;
  }

  closeConfirmDialog() {
    this.itemToDelete = null;
    this.displayModal = false;
  }

  submit() {
    this.createForm.patchValue({
      user_id: this.currentUser.id,
      locale: this.resources.getLanguage().description
    });
    this.responsibleService.createResponsibleUser(this.createForm.value).subscribe(result => {
      this.toast.showBottomCenterSuccess(result.message);
      this.adminService.getUser({locale: this.resources.getLanguage().description}).subscribe(res => {
        this.userService.setCurrentUser(res.user);
        this.dataUpdate.emit(true);
        this.createForm.reset();
        this.router.navigate(['/admin/profile']).then(() => {
          this.addResponsibleClicked = false;
        });
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  addResponsible() {
    this.addResponsibleClicked = true;
    this.emptyResponsibleList.push({first_name: '', last_name: '', email: ''});
  }

  removeResponsible() {
    this.createForm.reset();
    this.addResponsibleClicked = false;
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.event);
  }
}
