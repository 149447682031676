export class AdminStatics {
  public static readonly orderBy = 'order_by';
  public static readonly secondOrderBy = 'second_order_by';
  public static readonly search = 'search';
  public static readonly limit = 'limit';
  public static readonly offset = 'offset';
  public static readonly asc = ' ASC';
  public static readonly desc = ' DESC';
  public static readonly sortLabel = 'sortLabel';
  public static readonly id = 'id';
  public static readonly firstName = 'first_name';
  public static readonly lastName = 'last_name';
  public static readonly company = 'company';
  public static readonly sampleType = 'sample_type_id';
  public static readonly sampleNumber = 'sample_number';
  public static readonly sampleId = 'sample_id';
  public static readonly description = 'description';
  public static readonly collectMethod = 'collect_method_id';
  public static readonly approxQuantity = 'approximate_quantity';
  public static readonly address = 'address';
  public static readonly email = 'email';
  public static readonly sample = 'sample';
  public static readonly createdAt = 'created_at';
  public static readonly orderNumber = 'order_number';
  public static readonly initials = 'initials';
  public static readonly site = 'site';
  public static readonly role = 'role';
  public static readonly type = 'type';
  public static readonly isCompany = 'is_company';
  public static readonly latestState = 'latest_state';
  public static readonly deadline = 'deadline_at';
  public static readonly orderTypesName = 'order_types.name';
  public static readonly usersFirstName = 'users.first_name';
  public static readonly branch = 'city';
  public static readonly simpleOrder = 'simple';
  public static readonly diagnosticOrder = 'diagnostic';
  public static readonly diagnostics = 'diagnostics';
  public static readonly vdi = 'VDI';
  public static readonly asbestos = 'ASBESTOS';
  public static readonly lead = 'LEAD';
  public static readonly pcb = 'PCB';
  public static readonly hap = 'HAP';
  public static readonly pcbcp = 'PCB_CP';
  public static readonly asbestosInPowder = 'QUANTITATIVE_BUFFER';
  public static readonly rapidAnalyses = 'RAPID_ANALYSES';
  public static readonly asbestosRocks = 'ASBESTOS_PLUS';
  public static readonly stateCreate = 'create';
  public static readonly stateUpdate = 'updateExisting';
  public static readonly stateChoose = 'update';
  public static readonly total = 'total';
  public static readonly actionsGeneratedReports = 'generated.report';
  public static readonly actionsGeneratedAtReports = 'generated.report_at';
  public static readonly emailSent = 'sent.email';
  public static readonly inspectionMethod = 'inspection_method';
  public static readonly reportStandard = 'standard';
  public static readonly reportAfterWork = 'after_work';
}
