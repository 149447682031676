import { Component, Input, OnInit } from '@angular/core';
import { Order } from '../../../../admin/models/order';

@Component({
  selector: 'app-prolabo-user-order-view-tariffs',
  templateUrl: './order-view-tariffs.component.html',
  styleUrls: ['./order-view-tariffs.component.scss']
})
export class OrderViewTariffsComponent implements OnInit {
  @Input() order: Order;

  constructor() { }

  ngOnInit(): void {
  }

}
