import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourcesService } from '../../shared/services/resources.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AdminRoutesStatics } from '../statics/admin-routes-statics';
import { LoginResponse, UpdateResponse } from '../../models';
import { InvoiceType, LaboratoryResponse, Price } from '../../models/laboratorie';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService {

  constructor(private http: HttpClient, private resources: ResourcesService) { }

  public getDashboardNumbers(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getDashboardNumbers}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getActivities(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.getActivities}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getProfileData(body): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getProfileData}`, body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public updateProfile(body): Observable<UpdateResponse> {
    return this.http.post<UpdateResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.updateProfile}`,
      body,
      {headers: this.getMultiPartHeader(this.resources.getStorageUser().token)});
  }

  public getLaboratories(): Observable<LaboratoryResponse> {
    return this.http.post<LaboratoryResponse>(`${environment.adminApiUrl}${AdminRoutesStatics.getLaboratories}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public getPrices(): Observable<Price[]> {
    return this.http.post<Price[]>(`${environment.adminApiUrl}${AdminRoutesStatics.getPrices}`,
      {locale: this.resources.getLanguage().description},
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public updatePrice(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.updatePrice}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public updateUserPrice(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.updateUserPrice}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  public restorePrices(body): Observable<any> {
    return this.http.post<any>(`${environment.adminApiUrl}${AdminRoutesStatics.restoreUserPrices}`,
      body,
      {headers: this.getHeaders(this.resources.getStorageUser().token)});
  }

  private getHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
      Authorization: `Bearer ${token}`
    });
  }

  private getMultiPartHeader(token): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }
}
