import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { DropdownModel } from 'src/app/shared/components/dropdown/dropdown.model';
import { Role, User } from 'src/app/models';
import { AuthService, I18nService, ToastService } from 'src/app/shared/services';
import { InputValidation } from 'src/app/models/input-validation';
import { Router } from '@angular/router';
import { AdminDashboardService } from '../../../services/admin-dashboard.service';
import { FunctionsService } from '../../../services/functions.service';

@Component({
  selector: 'app-prolabo-profile-change',
  templateUrl: './profile-role-change.component.html',
  styleUrls: ['./profile-role-change.component.scss']
})
export class ProfileRoleChangeComponent implements OnInit {
  @Input() currentUser: User;

  roles: DropdownModel[] = [];
  roleForm: FormGroup;
  roless: Role[];

  constructor(private translate: TranslateService,
              private resources: ResourcesService,
              private dashboardService: AdminDashboardService,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private toast: ToastService,
              private router: Router,
              private lang: I18nService,
              private functions: FunctionsService
  ) {
  }


  ngOnInit(): void {
    this.roless = this.resources.getPlatformRoles();
    if (this.roless) {
      this.setRoles(this.roless);
    }
    this.roleForm = this.formBuilder.group({
      role_id: [this.currentUser.role_id, [Validators.required]]
    });
  }

  updateRoleValue(value) {
    const formData = new FormData();
    formData.append('role_id', value);
    formData.append('locale', this.lang.currentLang);
    this.dashboardService.updateProfile(formData).subscribe(() => {
      this.toast.showBottomCenterInfo(this.translate.instant('REDIRECT'));
      setTimeout(() => {
        this.authService.logOut('/v1/logout', {locale: this.lang.currentLang}, this.resources.getStorageUser().token).subscribe(result => {
          if (value === 1) {
            localStorage.removeItem('prolaboUser');
            this.router.navigateByUrl('/admin/login').then(() => {
              this.toast.showBottomCenterInfo(result.message);
            });
          } else if (value === 2) {
            localStorage.removeItem('prolaboUser');
            this.router.navigateByUrl('/admin/login').then(() => {
              this.toast.showBottomCenterInfo(result.message);
            });
          } else if (value === 3) {
            localStorage.removeItem('prolaboUser');
            this.router.navigateByUrl('/login').then(() => {
              this.toast.showBottomCenterInfo(result.message);
            });
          } else if (value === 4) {
            localStorage.removeItem('prolaboUser');
            this.router.navigateByUrl('/admin/login').then(() => {
              this.toast.showBottomCenterInfo(result.message);
            });
          }
        });
      });
    }, error => {
      this.functions.showError(error);
    });
    this.roleForm.patchValue({
      role_id: value
    });

  }

  checkObj(error: string): void {
    if (error) {
      this.toast.showBottomCenterError(error);
    }
  }

  private setRoles(roles) {
    roles.forEach(role => {
      const name = this.translate.instant(role.role.toUpperCase());
      this.roles.push({id: role.id, text: name, value: role.id});
    });
  }

  showError(error: InputValidation) {
    this.checkObj(error.locale);
    this.checkObj(error.role_id);
  }

}
