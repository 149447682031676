import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Laboratory } from '../../../../../models/laboratorie';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { User } from '../../../../../admin/models/user';

@Component({
  selector: 'app-laboratory-address',
  templateUrl: './laboratory-address.component.html',
  styleUrls: ['./laboratory-address.component.scss']
})
export class LaboratoryAddressComponent implements OnInit {
  laboratory: Laboratory;
  currentUser: User;

  constructor(public ref: DynamicDialogRef, private resources: ResourcesService, private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.laboratory = this.config.data;
  }

}
