import { Component, OnInit } from '@angular/core';
import { User } from '../../../../admin/models/user';
import { FormGroup } from '@angular/forms';
import { I18nService, ToastService, UserService } from '../../../../shared/services';
import { ProlaboAnalyseService } from '../../../services/prolabo-analyse.service';
import { AdminDashboardService } from '../../../../admin/services/admin-dashboard.service';
import { DropdownModel } from '../../../../shared/components/dropdown/dropdown.model';
import { forkJoin } from 'rxjs';
import { InvoiceType, Laboratory } from '../../../../models/laboratorie';
import { FunctionsService } from '../../../../admin/services/functions.service';
import { ResourcesService } from '../../../../shared/services/resources.service';

@Component({
  selector: 'app-prolabo-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  currentUser: User;
  profileForm: FormGroup;
  profileUpdatedForm: FormGroup;
  administrationForm: any;
  administrationUpdatedForm: FormGroup;
  validateClicked: boolean;
  laboratories: DropdownModel[] = [];
  invoiceTypes: DropdownModel[] = [];
  laboratoriesModal: Laboratory[];

  constructor(private userService: UserService,
              private toast: ToastService,
              private lang: I18nService,
              private dashboard: AdminDashboardService,
              private functions: FunctionsService,
              private resources: ResourcesService,
              private analyseService: ProlaboAnalyseService) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    const laboratories = this.dashboard.getLaboratories();
    this.setInvoiceTypes(this.resources.getInvoiceTypes());

    forkJoin([laboratories]).subscribe(result => {
      this.laboratoriesModal = result[0].laboratories;
      this.setLaboratories(result[0].laboratories);
    });
  }

  handleProfileFormEvent($event: FormGroup) {
    this.profileUpdatedForm = $event;
  }

  handleProfileOriginalFormEvent($event: any) {
    this.profileForm = $event;
  }

  handleValidateCLickedProfile($event: boolean) {
    this.validateClicked = $event;
    setTimeout(() => {
      const promise1 = this.detectChange(this.profileForm, this.profileUpdatedForm.value);
      const promise2 = this.detectChange(this.administrationForm, this.administrationUpdatedForm.value);
      Promise.all([promise1, promise2]).then(promiseResult => {
        const values = [];
        promiseResult.forEach((obj) => {
          values.push(...obj);
        });

        this.analyseService.updateProfile(this.buildFormData(values)).subscribe(value => {
          this.toast.showBottomCenterSuccess(value.message);
          this.userService.setCurrentUser(value.user);
          this.onUpdatedUser();
        }, error => {
          this.functions.showError(error);
        });
      });
    }, 300);
  }

  detectChange(original, updated) {
    return new Promise<any>((resolve) => {
      const obj = new Array(0);
      for (const key of Object.keys(updated)) {
        if (original[key] !== updated[key]) {
          const value = updated[key];
          obj.push({key, value});
        }
      }
      resolve(obj);
    });
  }

  onUpdatedUser() {
    this.getUser().then(user => {
      this.currentUser = user;
      this.userService.setCurrentUser(user);
    });
  }

  getUser(): Promise<User> {
    return new Promise<User>((resolve) => {
      const body = {
        locale: this.lang.currentLang
      };
      this.dashboard.getProfileData(body).subscribe(result => {
        resolve(result.user);
      }, error => {
        resolve(error);
      });
    });
  }

  buildFormData(values: any[]): FormData {
    const formData = new FormData();
    formData.append('locale', this.lang.currentLang);
    if (this.profileUpdatedForm.controls.company) {
      formData.append('company', this.profileUpdatedForm.controls.company.value);
    }
    values.forEach(value => {
      formData.append(value.key, value.value);
    });
    return formData;
  }

  onDataUpdate(val) {
    this.currentUser = this.userService.getCurrentUser();
  }

  private setLaboratories(laboratories: Laboratory[]) {
    laboratories.forEach(laboratory => {
      this.laboratories.push({id: laboratory.id, text: laboratory.city, value: laboratory.id});
    });
  }

  private setInvoiceTypes(invoiceTypes: InvoiceType[]) {
    invoiceTypes.forEach(invoiceType => {
      this.invoiceTypes.push({id: invoiceType.id, text: invoiceType.slug, value: invoiceType.id});
    });
  }

  handleValidationClicked($event: boolean) {
    this.validateClicked = $event;
  }

  handleAdministrationFormEvent($event: FormGroup) {
    this.administrationForm = $event;
  }

  handleAdministrationUpdatedFormEvent($event: FormGroup) {
    this.administrationUpdatedForm = $event;
  }
}
