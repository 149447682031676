import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DropdownModel} from './dropdown.model';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-prolabo-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent implements OnChanges, OnInit {
  @Input() options: DropdownModel[];
  @Input() control: string;
  @Input() type: string;
  @Input() disabled = false;
  @Input() selectedOption: DropdownModel;
  @Input() placeholder: string;
  @Input() defaultValue: number;
  @Input() required = true;
  @Input() inputId: any;
  @Input() deadlineAt: string;
  @Input() chosenDeadlineId: number;

  @Output() OnValueChange = new EventEmitter<string>();
  @Output() OnDropdownClick = new EventEmitter<MouseEvent>();

  selectedOptionFormControl: FormControl;

  constructor(private translate: TranslateService, private datePipe: DatePipe) {
    this.translate.onLangChange.subscribe(() => {
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOption) {
      this.selectedOptionFormControl.setValue(this.selectedOption);
    }
  }

  onClick(event: MouseEvent) {
    this.OnDropdownClick.emit(event);
  }

  onChange(event) {
    this.OnValueChange.emit(event.value);
  }

  translateOption(option, id) {
    if (id === 9 && this.chosenDeadlineId === 9) {
      return this.deadlineAt != null ?
        this.translate.instant(option).replace('DEADLINE.EIGHT.DATE', this.datePipe.transform(this.deadlineAt, 'd.MM.yyyy')) :
        this.translate.instant(option).replace('DEADLINE.EIGHT.DATE', this.translate.instant('DEADLINE.EIGHT.DATE'));
    } else {
      return this.translate.instant(option);
    }
  }

  ngOnInit(): void {
    this.selectedOptionFormControl = new FormControl({value: this.defaultValue, disabled: this.disabled});
  }
}
