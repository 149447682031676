import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, I18nService } from '../../../../shared/services';
import { ResourcesService } from '../../../../shared/services/resources.service';

@Component({
  selector: 'app-mobile-side-bar',
  templateUrl: './mobile-side-bar.component.html',
  styleUrls: ['./mobile-side-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileSideBarComponent implements OnChanges {
  @Input() title: string;
  @Input() display: boolean;

  date = new Date();

  constructor(private router: Router,
              private resources: ResourcesService,
              private authService: AuthService,
              private lang: I18nService) {
  }

  ngOnChanges(): void {
  }

  navigate() {
    this.router.navigateByUrl('admin/dashboard');
  }

  logout() {
    this.authService.logOut('/v1/logout', {locale: this.lang.currentLang}, this.resources.getStorageUser().token).subscribe(() => {
      this.router.navigateByUrl(`/admin/login`).then(() => {
        localStorage.removeItem('prolaboUser');
      });
    }, () => {
      this.router.navigateByUrl('/admin/login');
    });
  }
}
